import React, { useEffect, useState } from "react";
import logoImg from "../assets/img/nationalaccess-logo.png";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { domainUrl, logOutHandler } from "../utils/constants";
import axios from "axios";
import { Oval } from "react-loader-spinner";
import { useLocation, useNavigate } from "react-router-dom";
import { getCheckWebFormGet } from "../utils/redux/slice/getCheckWebFormSlice";

const WebformPageClient = () => {
  const navigate = useNavigate();

  const [dataClient, setDataClient] = useState({});
  const [loading, setLoading] = useState(false);
  const paymentPlanString = {
    weekly: "Weekly Payments",
    bi_weekly: "Bi-Weekly Payments",
    semi_monthly_dates: "Semi-Monthly By Date",
    semi_monthly_days: "Semi-Monthly By Day",
    monthly: "Monthly Payments",
  };
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const token = searchParams.get("token");

  const tokenData = { token };
  const checkAllowed = async () => {
    localStorage.clear();
    const res = await dispatch(getCheckWebFormGet(token, navigate));
    if (res.payload) {
      setDataClient(res.payload);
    }
    if (res.error) {
      toast.error(res.error.message, { id: 1 });
      navigate("/forbidden");
    }
  };
  useEffect(() => {
    checkAllowed();
  }, []);

  const [selectedCheckbox, setSelectedCheckbox] = useState(null);

  const handleCheckboxChange = (checkboxValue) => {
    setSelectedCheckbox(checkboxValue);
    setClientDetails({ ...clientDetails, account_type: checkboxValue });
  };

  const dispatch = useDispatch();
  const [clientDetails, setClientDetails] = useState({
    cardholder: "",
    card_number: "",
    expiration_date: "",
    cvv: "",
    account_type: "",
    bank_account_number: "",
    bank_routing_number: "",
    debtor_ssn: "",
    debtor_dob: "",
    codebtor_ssn: "",
    codebtor_dob: "",
  });

  const [typingTimeout, setTypingTimeout] = useState(0);

  const errMsg = {
    card_number: "Card Number should be 16 digit",
    cvv: "CVV should be 3 digit",
    cardholder: "Card Holder name exceeds limit",
    expiration_date: "Expiry format doesn't match",
    bank_account_number:
      "The bank account number must be between 5 and 18 digits in length",
    bank_routing_number: "Routing number should be 9 digit number",
  };

  const [errorFlag, setErrorFlag] = useState({
    cardholder: "",
    card_number: "",
    expiration_date: "",
    cvv: "",
    account_type: "",
    bank_account_number: "",
    bank_routing_number: "",
    debtor_ssn: "",
    debtor_dob: "",
    codebtor_ssn: "",
    codebtor_dob: "",
  });

  const length_validate = {
    cvv: 4,
    bank_account_number: 16,
    bank_routing_number: 9,
    card_number: 16,
  };

  const filterNonNumbers = (value) => {
    return value.replace(/[^0-9]/g, "");
  };
  const filterNumberLength = (value, max) => {
    const numericValue = value.replace(/[^0-9]/g, "");

    if (numericValue.length > max) {
      return numericValue.slice(0, max);
    }
    return numericValue;
  };

  const filterPositiveDecimal = (value) => {
    const validPositiveDecimalRegex = /^\d+(\.\d*)?$/;
    const parts = value.split(".");
    if (parts.length > 2) {
      value = parts.slice(0, 2).join(".") + parts.slice(2).join("");
    }
    const match = value.match(validPositiveDecimalRegex);
    return match ? match[0] : value.slice(0, -1);
  };

  const validateCVV = (cvv) => {
    let requiredlength = 4;
    if (!cvv.trim()) {
      return false;
    }
    if ((cvv.length > requiredlength) | (cvv.length < requiredlength)) {
      return false;
    }
    const regex = /^\d{3}$/;

    if (!regex.test(cvv)) {
      return false;
    }
    return true;
  };

  const processCardExpiryInput = (input) => {
    const cleanedInput = input.replace(/\D/g, "");

    if (cleanedInput.length >= 4) {
      const month = cleanedInput.slice(0, 2);
      const year = cleanedInput.slice(2, 4);

      const formattedDate = `${month}/${year}`;

      return formattedDate;
    } else {
      return input;
    }
  };

  const validateCardExpiry = (date) => {
    const regex = /^(0[1-9]|1[0-2])\/\d{2}$/;

    return regex.test(date);
  };
  const formatAlphabetsWithSpace = (value) => {
    let formattedAlphabets;
    const { length } = value;

    const regex = () => value.replace(/[^a-zA-Z\s]+/g, "");

    if (length > 0) {
      formattedAlphabets = regex();
    }

    return formattedAlphabets;
  };
  function formatUSD(number) {
    if (typeof number !== "number" || isNaN(number)) {
      if (typeof number == "string") {
        number = parseFloat(number);
      } else {
        return "Invalid number";
      }
    }

    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    return formatter.format(number);
  }

  const formHandler = async (e) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    if (e.target) {
      if (e.target.name === "card_number") {
        setClientDetails({
          ...clientDetails,
          [e.target.name]: filterNumberLength(
            e.target.value,
            length_validate[e.target.name]
          ),
        });
      } else if (e.target.name === "cardholder") {
        setClientDetails({
          ...clientDetails,
          [e.target.name]: formatAlphabetsWithSpace(e.target.value),
        });
      } else if (e.target.name === "expiration_date") {
        setClientDetails({
          ...clientDetails,
          [e.target.name]: processCardExpiryInput(e.target.value),
        });
      } else if (e.target.name === "cvv") {
        setClientDetails({
          ...clientDetails,
          [e.target.name]: filterNumberLength(
            e.target.value,
            length_validate[e.target.name]
          ),
        });
      } else if (e.target.name === "bank_account_number") {
        setClientDetails({
          ...clientDetails,
          [e.target.name]: filterNumberLength(
            e.target.value,
            length_validate[e.target.name]
          ),
        });
      } else if (e.target.name === "bank_routing_number") {
        setClientDetails({
          ...clientDetails,
          [e.target.name]: filterNumberLength(
            e.target.value,
            length_validate[e.target.name]
          ),
        });
      } else if (
        e.target.name == "debtor_ssn" ||
        e.target.name == "codebtor_ssn"
      ) {
        setClientDetails({
          ...clientDetails,
          [e.target.name]: e.target.value,
        });
      } else if (
        e.target.name == "debtor_dob" ||
        e.target.name == "codebtor_dob"
      ) {
        setClientDetails({
          ...clientDetails,
          [e.target.name]: e.target.value,
        });
      } else {
        setClientDetails({
          ...clientDetails,
          [e.target.name]: e.target.value,
        });
      }
    }

    setTypingTimeout(setTimeout(async () => validateFormFields(e), 300));
  };

  const validateFormFields = async (e) => {
    if (e.target) {
      if (e.target.name == "cardholder") {
        setErrorFlag((prevErrorFlag) => ({
          ...prevErrorFlag,

          [e.target.name]:
            e.target.value.length > 30 ? errMsg[e.target.name] : "",
        }));
      } else if (e.target.name == "cvv") {
        setErrorFlag((prevErrorFlag) => ({
          ...prevErrorFlag,

          [e.target.name]: !validateCVV(e.target.value)
            ? errMsg[e.target.name]
            : "",
        }));
      } else if (e.target.name == "expiration_date") {
        setErrorFlag((prevErrorFlag) => ({
          ...prevErrorFlag,

          [e.target.name]: !validateCardExpiry(e.target.value)
            ? errMsg[e.target.name]
            : "",
        }));
      } else if (e.target.name == "card_number") {
        setErrorFlag((prevErrorFlag) => ({
          ...prevErrorFlag,

          [e.target.name]:
            e.target.value.length > 16 ? errMsg[e.target.name] : "",
        }));
      } else if (e.target.name == "bank_account_number") {
        setErrorFlag((prevErrorFlag) => ({
          ...prevErrorFlag,
          [e.target.name]:
            e.target.value.length > 18 ? errMsg[e.target.name] : "",
        }));
      } else if (e.target.name == "bank_routing_number") {
        setErrorFlag((prevErrorFlag) => ({
          ...prevErrorFlag,
          [e.target.name]:
            e.target.value.length != 9 ? errMsg[e.target.name] : "",
        }));
      }
    } else {
      return null;
    }
  };

  const submitWebFormHandler = async () => {
    try {
      setLoading(true);

      const res = await axios.post(`${domainUrl}webform`, clientDetails, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (res.data) {
        setLoading(false);
        localStorage.removeItem("web_form");
        localStorage.removeItem("client_status");
        localStorage.setItem("waiting_flag", true);
        navigate("/");
      }
    } catch (error) {
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
      setLoading(false);
    }
  };

  const [confirmCheck, setConfirmCheck] = useState(false);

  return (
    <>
      {loading ? (
        <div className="w-full h-screen flex justify-center items-center">
          <Oval
            height={80}
            width={80}
            color="#1a3253"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#396181"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <div className="bg-gray-100 sm:p-5">
          <div className="relative bg-white mx-2 my-10 border rounded-xl p-5">
            <form>
              <div className="space-y-12">
                <div className="absolute right-5 top-5 ">
                  <img src={logoImg} alt="natl-logo" className="h-8 sm:h-10" />
                </div>
                <div className="w-full flex flex-col  items-center">
                  <h2 className="text-base font-semibold leading-7 text-gray-900">
                    NATIONAL ACCESS, LLC
                  </h2>
                  <p className="mt-1 text-sm text-center text-black font-bold">
                    RECURRING PAYMENT AUTHORIZATION & CONSENT FORM
                  </p>
                </div>
                <div className="border-b border-gray-900/10 pb-12">
                  <h2 className="bg-gray-200 text-base font-semibold leading-7 text-gray-900">
                    Payment Plan:
                  </h2>

                  <div>
                    {dataClient?.contracts &&
                    dataClient?.contracts[0]?.payment_plan == "monthly" ? (
                      <>
                        <div>
                          <span className="text-lg font-bold">
                            {dataClient?.contracts &&
                              paymentPlanString[
                                dataClient?.contracts[0]?.payment_plan
                              ]}
                          </span>
                          <span className="font-semibold">
                            , every 1{" "}
                            <span className="font-thin">
                              (date of the month)
                            </span>
                            , starting on
                          </span>
                          <span className="ml-2 text-lg font-bold">
                            {dataClient?.contracts &&
                              dataClient?.contracts[0]?.start_date}
                          </span>
                        </div>
                      </>
                    ) : null}
                    {dataClient?.contracts &&
                    dataClient?.contracts[0]?.payment_plan ==
                      "semi_monthly_days" ? (
                      <>
                        <div>
                          <span className="text-lg font-bold">
                            {
                              paymentPlanString[
                                dataClient?.contracts[0]?.payment_plan
                              ]
                            }
                          </span>
                          <span className="font-semibold">
                            , every 1 <span className="font-thin">(days)</span>
                            from week 1, week 2 starting on
                          </span>
                          <span className="ml-2 text-lg font-bold">
                            {dataClient?.contracts[0]?.start_date}
                          </span>
                        </div>
                      </>
                    ) : null}
                    {dataClient?.contracts &&
                    dataClient?.contracts[0]?.payment_plan ==
                      "semi_monthly_dates" ? (
                      <>
                        <div>
                          <span className="text-lg font-bold">
                            {
                              paymentPlanString[
                                dataClient?.contracts[0]?.payment_plan
                              ]
                            }
                          </span>
                          <span className="font-semibold">
                            , every 1{" "}
                            <span className="font-thin">
                              (dates of the month)
                            </span>
                            , starting on
                          </span>
                          <span className="ml-2 text-lg font-bold">
                            {dataClient?.contracts[0]?.start_date}
                          </span>
                        </div>
                      </>
                    ) : null}{" "}
                    {dataClient?.contracts &&
                    dataClient?.contracts[0]?.payment_plan == "bi_weekly" ? (
                      <>
                        <div>
                          <span className="text-lg font-bold">
                            {
                              paymentPlanString[
                                dataClient?.contracts[0]?.payment_plan
                              ]
                            }
                          </span>
                          <span className="font-semibold">
                            , every monday
                            <span className="font-thin">(day of the week)</span>
                            , starting on
                          </span>
                          <span className="ml-2 text-lg font-bold">
                            {dataClient?.contracts[0]?.start_date}
                          </span>
                        </div>
                      </>
                    ) : null}
                    {dataClient?.contracts &&
                    dataClient?.contracts[0]?.payment_plan == "weekly" ? (
                      <>
                        <div>
                          <span className="text-lg font-bold">
                            {
                              paymentPlanString[
                                dataClient?.contracts[0]?.payment_plan
                              ]
                            }
                          </span>
                          <span className="font-semibold">
                            , every monday
                            <span className="font-thin">(day of the week)</span>
                            , starting on
                          </span>
                          <span className="ml-2 text-lg font-bold">
                            {dataClient?.contracts[0]?.start_date}
                          </span>
                        </div>
                      </>
                    ) : null}
                  </div>

                  <h2 className="bg-gray-200 text-base font-semibold leading-7 text-gray-900">
                    Basic Information:
                  </h2>
                  <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div className="col-span-3 sm:col-span-3">
                      <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                        Firm Name
                      </label>
                      <div className="mt-2">
                        <div className="mt-2 block w-full  border-b-2 text-black">
                          {dataClient?.contracts ? (
                            <>
                              {dataClient?.contracts[0].attorney.firm.firm_name}
                            </>
                          ) : (
                            <>--</>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-3 sm:col-span-3">
                      <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                        Attorney
                      </label>
                      <div className="mt-2">
                        <div className="mt-2 block w-full  border-b-2 text-black">
                          {dataClient?.contracts ? (
                            <>
                              {`${dataClient?.contracts[0].attorney.user.first_name} ${dataClient?.contracts[0].attorney.user.last_name}`}
                            </>
                          ) : (
                            <>--</>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-3 sm:col-span-3">
                      <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                        Debtor's Name
                      </label>
                      <div className="mt-2">
                        <div className="mt-2 block w-full  border-b-2 text-black">
                          {dataClient?.user ? (
                            <>{`${dataClient?.user.first_name} ${dataClient?.user.last_name}`}</>
                          ) : (
                            <>--</>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-span-3 sm:col-span-3">
                      <div className="w-full">
                        <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                          Debtor's Email
                        </label>
                        <div className="mt-2">
                          <div className="mt-2 block w-full  border-b-2 text-black">
                            {dataClient?.user ? (
                              <>{dataClient?.user.email}</>
                            ) : (
                              <>--</>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-3 sm:col-span-3">
                      <div className="w-full">
                        <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                          Debtor's Cell Phone
                        </label>
                        <div className="mt-2">
                          <div className="mt-2 block w-full  border-b-2 text-black">
                            {dataClient?.user ? (
                              <>{dataClient?.user.cell_phone_number}</>
                            ) : (
                              <>--</>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-span-3">
                      <label
                        htmlFor="address_line1"
                        className="block text-md font-medium leading-6 text-[#9ca0b3]"
                      >
                        Address 1
                      </label>
                      <div className="mt-2">
                        <div className="mt-2 block w-full  border-b-2 text-black">
                          {dataClient?.address_line1 ? (
                            <>{dataClient?.address_line1}</>
                          ) : (
                            <>--</>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-3">
                      <label
                        htmlFor="address_line2"
                        className="block text-md font-medium leading-6 text-[#9ca0b3]"
                      >
                        Address 2
                      </label>
                      <div className="mt-2">
                        <div className="mt-2 block w-full  border-b-2 text-black">
                          {dataClient?.address_line2 ? (
                            <>{dataClient?.address_line2}</>
                          ) : (
                            <>--</>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-span-3 sm:col-span-2">
                      <label
                        htmlFor="state"
                        className="block text-md font-medium leading-6 text-[#9ca0b3]"
                      >
                        State
                      </label>
                      <div className="mt-2">
                        <div className="mt-2 block w-full  border-b-2 text-black">
                          {dataClient ? <>{dataClient?.state}</> : <>--</>}
                        </div>
                      </div>
                    </div>

                    <div className="col-span-3 sm:col-span-2">
                      <label
                        htmlFor="city"
                        className="block text-md font-medium leading-6 text-[#9ca0b3]"
                      >
                        City
                      </label>
                      <div className="mt-2">
                        <div className="mt-2 block w-full  border-b-2 text-black">
                          {dataClient ? <>{dataClient?.city}</> : <>--</>}
                        </div>
                      </div>
                    </div>

                    <div className="col-span-3 sm:col-span-2">
                      <label
                        htmlFor="zip_code"
                        className="block text-md font-medium leading-6 text-[#9ca0b3]"
                      >
                        Zip Code
                      </label>
                      <div className="mt-2">
                        <div className="mt-2 block w-full  border-b-2 text-black">
                          {dataClient ? <>{dataClient?.zip_code}</> : <>--</>}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-3 sm:col-span-2">
                      <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                        Case Type
                      </label>
                      <div className="mt-2">
                        <div className="mt-2 block w-full  border-b-2 text-black">
                          {dataClient?.contracts ? (
                            <>{dataClient?.contracts[0].case_type}</>
                          ) : (
                            <>--</>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-3 sm:col-span-2">
                      <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                        Case Number
                      </label>
                      <div className="mt-2">
                        <div className="mt-2 block w-full  border-b-2 text-black">
                          {dataClient?.contracts ? (
                            <>{dataClient?.contracts[0].case_number}</>
                          ) : (
                            <>--</>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-3 sm:col-span-2">
                      <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                        Contract Amount
                      </label>
                      <div className="mt-2">
                        <div className="mt-2 block w-full  border-b-2 text-black">
                          {dataClient?.contracts ? (
                            <>
                              {formatUSD(
                                dataClient?.contracts[0]?.contract_amount
                              )}
                            </>
                          ) : (
                            <>--</>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-3 sm:col-span-2">
                      <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                        Contract Period
                      </label>
                      <div className="mt-2">
                        <div className="mt-2 block w-full  border-b-2 text-black">
                          {dataClient?.contracts ? (
                            <>{dataClient?.contracts[0]?.tenure} Months</>
                          ) : (
                            <>--</>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-3 sm:col-span-3">
                      <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                        Payment Plan
                      </label>
                      <div className="mt-2">
                        <div className="mt-2 block w-full  border-b-2 text-black">
                          {dataClient?.contracts ? (
                            <>
                              {
                                paymentPlanString[
                                  dataClient?.contracts[0]?.payment_plan
                                ]
                              }
                            </>
                          ) : (
                            <>--</>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="border-b border-gray-900/10 pb-12">
                  <h2 className="bg-gray-200 text-base font-semibold leading-7 text-gray-900">
                    Debit Card & Bank Account Information: (Field to be filled
                    by Client)
                  </h2>

                  <div className="mt-10 space-y-10">
                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                      <div className="sm:col-span-3">
                        <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                          Name on Debit Card:
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="cardholder"
                            id="cardholder"
                            value={clientDetails.cardholder}
                            onChange={(e) => formHandler(e)}
                            className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                          />
                          <span className="text-red-500 text-xs">
                            {errorFlag.cardholder}
                          </span>
                        </div>
                      </div>
                      <div className="sm:col-span-3">
                        <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                          Card Number:
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="card_number"
                            id="card_number"
                            onChange={(e) => formHandler(e)}
                            value={clientDetails.card_number}
                            className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                          />
                          <span className="text-red-500 text-xs">
                            {errorFlag.card_number}
                          </span>
                        </div>
                      </div>

                      <div className="sm:col-span-3">
                        <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                          Card Expires:(MM/YY)
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="expiration_date"
                            id="expiration_date"
                            onChange={(e) => formHandler(e)}
                            value={clientDetails.expiration_date}
                            className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                          />
                          <span className="text-red-500 text-xs">
                            {errorFlag.expiration_date}
                          </span>
                        </div>
                      </div>
                      <div className="sm:col-span-3">
                        <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                          CVV/CVC (3-digit code on back of card)
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="cvv"
                            id="cvv"
                            onChange={(e) => formHandler(e)}
                            value={clientDetails.cvv}
                            className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                          />
                          <span className="text-red-500 text-xs">
                            {errorFlag.cvv}
                          </span>
                        </div>
                      </div>
                      <div className="sm:col-span-4">
                        <div className="my-6 mx-2 flex items-center gap-5">
                          <div className="relative flex gap-x-2">
                            <div className="flex h-6 items-center ">
                              <input
                                id="savings"
                                name="savings"
                                type="checkbox"
                                value={"savings"}
                                checked={selectedCheckbox === "savings"}
                                onChange={() => handleCheckboxChange("savings")}
                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                              />
                            </div>
                            <div className=" leading-6">
                              <label
                                htmlFor="savings"
                                className="font-medium text-gray-900 text-md"
                              >
                                Savings
                              </label>
                            </div>
                          </div>
                          <div className="relative flex gap-x-2 ">
                            <div className="flex h-6 items-center ng-yellow-300">
                              <input
                                id="checking"
                                name="checking"
                                type="checkbox"
                                value={"checking"}
                                checked={selectedCheckbox === "checking"}
                                onChange={() =>
                                  handleCheckboxChange("checking")
                                }
                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                              />
                            </div>
                            <div className=" leading-6">
                              <label
                                htmlFor="check"
                                className="font-medium text-gray-900 text-md"
                              >
                                Checking
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="sm:col-span-3">
                        <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                          Account Number
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="bank_account_number"
                            id="bank_account_number"
                            onChange={(e) => formHandler(e)}
                            value={clientDetails.bank_account_number}
                            className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                          />
                          <span className="text-red-500 text-xs">
                            {errorFlag.bank_account_number}
                          </span>
                        </div>
                      </div>

                      <div className="sm:col-span-3">
                        <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                          Routing Number
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="bank_routing_number"
                            id="bank_routing_number"
                            onChange={(e) => formHandler(e)}
                            value={clientDetails.bank_routing_number}
                            className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                          />
                          <span className="text-red-500 text-xs">
                            {errorFlag.bank_routing_number}
                          </span>
                        </div>
                      </div>
                    </div>

                    <h2 className="bg-gray-200 text-base font-semibold leading-7 text-gray-900">
                      General Information (fill in all applicable fields)
                    </h2>

                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                      <div className="sm:col-span-3">
                        <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                          Debtor's SSN:
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="debtor_ssn"
                            id="debtor_ssn"
                            value={clientDetails.debtor_ssn}
                            onChange={(e) => formHandler(e)}
                            className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                          />
                          <span className="text-red-500 text-xs">
                            {errorFlag.debtor_ssn}
                          </span>
                        </div>
                      </div>
                      <div className="sm:col-span-3">
                        <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                          Debtor's Date of Birth:
                        </label>
                        <div className="mt-2">
                          <input
                            type="date"
                            name="debtor_dob"
                            id="debtor_dob"
                            onChange={(e) => formHandler(e)}
                            value={clientDetails.debtor_dob}
                            className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                          />
                          <span className="text-red-500 text-xs">
                            {errorFlag.debtor_dob}
                          </span>
                        </div>
                      </div>

                      <div className="sm:col-span-3">
                        <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                          Co-Debtor's SSN
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="codebtor_ssn"
                            id="codebtor_ssn"
                            onChange={(e) => formHandler(e)}
                            value={clientDetails.codebtor_ssn}
                            className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                          />
                          <span className="text-red-500 text-xs">
                            {errorFlag.codebtor_ssn}
                          </span>
                        </div>
                      </div>
                      <div className="sm:col-span-3">
                        <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                          Co-Debtor's Date of Birth
                        </label>
                        <div className="mt-2">
                          <input
                            type="date"
                            name="codebtor_dob"
                            id="codebtor_dob"
                            onChange={(e) => formHandler(e)}
                            value={clientDetails.codebtor_dob}
                            className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                          />
                          <span className="text-red-500 text-xs">
                            {errorFlag.codebtor_dob}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="px-4 py-6 flex gap-4">
                      <div className="h-6">
                        <input
                          id="comments"
                          name="comments"
                          type="checkbox"
                          checked={confirmCheck}
                          onClick={() => setConfirmCheck(!confirmCheck)}
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 hover:cursor-pointer"
                        />
                      </div>
                      <div className="font-medium text-gray-900 text-md leading-6">
                        By signing below, the person or persons listed above as
                        “Debtor” and/or “Co-Debtor” (1) authorize National
                        Access (“NA”), as an agent of the Law Firm, to collect
                        the payments described above and debit the listed debit
                        card and/or bank account until the Contract Amount, and
                        any modifications, is paid in full with NO REFUND, (2)
                        authorize their depository institution to debit the same
                        to their account, (3) agree that the Law Firm may
                        collaterally assign to NA the payments due the Law Firm
                        as collateral for financing, (4) authorize NA to
                        communicate with them via mail, e-mail, chat, text,
                        and/or telephone until payments have been complete, (5)
                        agree that if a payment does not process for any reason,
                        the payment may be re-attempted multiple times and on
                        different dates until the payment clears, even if it
                        means the end date of this payment contract will be
                        extended, (6) agree to notify NA in writing of any
                        changes in their account(s) information at least 5 days
                        prior to the next billing date, (7) agree that NA may
                        accept and rely upon telephonic or electronic
                        communications from them confirming modified payment
                        terms, (8) agree that an additional $15.00 or 10%,
                        whichever is greater, of any payment may be charged for
                        each missed, late or returned payment, which charge may
                        be debited from their account separately from their
                        regular recurring payment, (9) agree that all costs and
                        expense, including attorney’s fees, will be paid at the
                        debtors expense should a collection action be initiated
                        against the debtor by NA or Law Firm, (10) certify that
                        they are an authorized user of this debit card and/or
                        bank account, and (11) agree to utilize the NA platform
                        at:{" "}
                        <a href="https://app.natlaccess.com/users/sign_in">
                          https://app.natlaccess.com/users/sign_in
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-6 flex items-center justify-end gap-x-6">
                <button
                  type="button"
                  disabled={!confirmCheck}
                  onClick={() => {
                    submitWebFormHandler();
                  }}
                  className={`rounded-md bg-[#7a5cfa] px-4 py-3 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-gray-300 hover:bg-indigo-500`}
                >
                  Submit
                </button>
                <button
                  type="button"
                  onClick={() => {
                    localStorage.clear();
                    navigate("/login");
                  }}
                  className="rounded-md bg-[#7a5cfa] px-4 py-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default WebformPageClient;
