import React, { useEffect, useMemo, useState } from "react";
import MaterialReactTable from "material-react-table";
import { domainUrl, logOutHandler } from "../../utils/constants";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  Avatar,
  Box,
  Button,
  Container,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import ExportImg from "../../assets/img/excel-img.png";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Oval } from "react-loader-spinner";
import { AddCircleRounded } from "@mui/icons-material";
import ModalComponent from "../../Components/ModalComponent";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { InputNumberFormat } from "@react-input/number-format";

const AttorneyEscrowAdjustmentTable = () => {
  const [preview, setPreview] = useState(false);
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  const access_token = localStorage.getItem("access_token");
  const role = localStorage.getItem("role");
  const view_attorney_id = localStorage.getItem("view_attorney_id");
  const [totalCount, setTotalCount] = useState(2);
  const navigate = useNavigate();
  const [addChequeModal, setAddChequeModal] = useState(false);
  const [addNoteText, setAddNoteText] = useState("");
  const [addChequeLoading, setAddChequeLoading] = useState(false);
  const [refetchData, setRefetchData] = useState(false);
  const [dateValue, setDateValue] = useState("");
  const [amountValue, setAmountValue] = useState("");

  const [columnFilters, setColumnFilters] = useState([]);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  useEffect(() => {
    const fetchData = async () => {
      if (!data.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      const url = new URL("add-cheque", `${domainUrl}`);
      url.searchParams.set("id", `${view_attorney_id}`);

      url.searchParams.set(
        "offset",
        pagination.pageIndex * pagination.pageSize
      );
      url.searchParams.set("limit", `${pagination.pageSize}`);
      url.searchParams.set("offset", `${pagination.pageIndex}`);
      url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
      url.searchParams.set("search", search ?? "");
      url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
      try {
        const response = await fetch(url.href, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        });
        let json = await response.json();
        let data = json;

        if (data.code == "token_not_valid") {
          logOutHandler();
          navigate("/login");
          toast.error("Session Expired", { id: 1 });
          return;
        }
        if (data.data) setData(data.data);
        if (data.count) setRowCount(data.count);
      } catch (error) {
        setIsError(true);

        return;
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    };

    fetchData();
  }, [
    columnFilters,
    search,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    refetchData,
  ]);

  const columns = useMemo(
    () => [
      {
        id: "attorneys",
        header: "",
        columns: [
          {
            id: "date",
            header: "Date",
            accessorFn: (row) => `${formatDate(row.date)}`,
            accessorKey: "date",
            enableColumnActions: false,

            size: 30,
          },
          {
            accessorFn: (row) => `${formatUSD(row.amount)} `,
            id: "amount",
            header: "Amount",
            accessorKey: "amount",
            enableColumnActions: false,

            size: 30,
          },
          {
            id: "notes",
            header: "Notes",
            accessorFn: (row) => `${row.notes}`,
            accessorKey: "notes",
            enableColumnActions: false,
            size: 30,
          },
        ].filter(Boolean),
      },
    ],
    []
  );

  const formatDate = (dateString) => {
    if (dateString) {
      const date = dateString.split("-");

      return `${date[1]}/${date[2]}/${date[0]}`;
    }
    return dateString;
  };
  function formatUSCell(usPhoneNumber) {
    let digitsOnly = usPhoneNumber.replace(/\D/g, "");

    if (digitsOnly.length !== 10) {
      return "Invalid phone number format";
    }

    return "+1 " + digitsOnly.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  }
  function formatUSD(number) {
    if (typeof number !== "number" || isNaN(number)) {
      if (typeof number == "string") {
        number = parseFloat(number);
      } else {
        return "Invalid number";
      }
    }

    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    return formatter.format(number);
  }

  const addChequeActionHandler = async () => {
    setRefetchData(false);

    if (dateValue.length == 0) {
      toast.error("Date is required", { id: 1 });
      return;
    } else if (amountValue.length == 0) {
      toast.error("Amount is required", { id: 1 });
      return;
    } else if (addNoteText.length == 0) {
      toast.error("Note is required", { id: 1 });
      return;
    }

    try {
      setAddChequeLoading(true);
      const res = await axios.post(
        `${domainUrl}add-cheque?id=${view_attorney_id}`,
        {
          date: dateValue,
          amount: amountValue.replace(/[^0-9.-]+/g, ""),
          notes: addNoteText,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (res.data) {
        setAddChequeLoading(false);
        setAddChequeModal(false);
        setDateValue("");
        setAmountValue("");
        setAddNoteText("");
        toast.success(res.data.message, { id: 1 });
        setRefetchData(true);
      }
    } catch (error) {
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
      setAddChequeLoading(false);
      toast.error(error.response.data.detail, { id: 1 });
    }
  };
  const exportFileHandler = async (id) => {
    const url = new URL("add-cheque", `${domainUrl}`);
    url.searchParams.set("id", `${view_attorney_id}`);
    url.searchParams.set("offset", pagination.pageIndex * pagination.pageSize);
    url.searchParams.set("limit", `${pagination.pageSize}`);
    url.searchParams.set("offset", `${pagination.pageIndex}`);
    url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
    url.searchParams.set("search", search ?? "");
    url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
    url.searchParams.set("export", true);

    axios
      .get(url, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        if (response.headers.get("content-type") == "application/json") {
          response.data.text().then((text) => {
            toast.success(JSON.parse(text).scheduled, { autoClose: 10000 });
          });
        } else {
          let filename = `Escrow_Adjustments_by_Cheque_(Attorney)_${Date.now()}.xlsx`;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
        }
      });
  };

  return (
    <>
      <div className="w-full py-10">
        <div className="mx-5">
          <div className="flex items-center gap-5">
            <h2
              style={{
                color: "#1a3253",
              }}
            >
              Escrow Adjustments by Cheque
            </h2>
            <button
              onClick={() => {
                setAddChequeModal(true);
              }}
              type="button"
              className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              <AddCircleRounded
                className="-ml-0.5 h-5 w-5"
                aria-hidden="true"
              />
              Add Cheque
            </button>
          </div>
          <div className="p-1 border rounded-lg">
            <MaterialReactTable
              enableTopToolbar={true}
              renderTopToolbarCustomActions={({ table }) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      gap: "0.5rem",
                      alignItems: "center",
                    }}
                  >
                    {role == "admin" ? (
                      <Button
                        disabled={false}
                        startIcon={<Avatar src={ExportImg} />}
                        onClick={() => {
                          exportFileHandler();
                        }}
                        variant="contained"
                        sx={{
                          bgcolor: "#127c44",
                          "&:hover": {
                            bgcolor: "#127c44",
                          },
                          height: "25px",
                        }}
                      >
                        Export
                      </Button>
                    ) : (
                      ""
                    )}
                  </div>
                );
              }}
              enableStickyHeader
              muiTableContainerProps={{ sx: { maxHeight: "500px" } }}
              muiTableBodyProps={{
                sx: () => ({
                  "& tr:nth-child(odd)": {
                    backgroundColor: "#f8f9fa",
                  },
                  "& tr:nth-child(even)": {
                    backgroundColor: "#ffffff",
                  },
                }),
              }}
              muiTablePaperProps={{
                sx: {
                  maxWidth: "full",
                },
                elevation: 0,
              }}
              muiTableHeadCellProps={{
                sx: (theme) => ({
                  color: theme.palette.text.primary,
                }),
              }}
              enableFullScreenToggle={false}
              enableDensityToggle={false}
              enableHiding={false}
              enableColumnFilters={true}
              rowNumberMode="static"
              muiTablePaginationProps={{
                rowsPerPageOptions: [1, 2, 5, 10, 15, 20, 25],
                showFirstButton: false,
                showLastButton: false,
              }}
              columns={columns}
              data={data}
              muiTableBodyRowProps={({ row }) => ({
                onClick: () => {
                  if (data.length) {
                  }
                },

                sx: {
                  cursor: "pointer",
                },
              })}
              getRowId={(row) => row.id}
              initialState={{ showColumnFilters: false, density: "compact" }}
              manualPagination
              manualSorting
              muiToolbaralertBannerProps={
                isError
                  ? {
                      color: "error",
                      children: "Error loading data",
                    }
                  : undefined
              }
              onColumnFiltersChange={setColumnFilters}
              onGlobalFilterChange={setSearch}
              onPaginationChange={setPagination}
              onSortingChange={setSorting}
              rowCount={rowCount}
              state={{
                isLoading,
                pagination,
                showalertBanner: isError,
                showProgressBars: isRefetching,
                sorting,
              }}
            />
          </div>
        </div>
        <ModalComponent open={addChequeModal} handleClose={() => {}}>
          <div className="flex justify-center items-center">
            <div className="w-full  relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all  sm:max-w-lg">
              <div className="w-full flex justify-end">
                <span
                  className="border hover:cursor-pointer"
                  onClick={() => {
                    setAddChequeModal(false);
                  }}
                >
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      width="48"
                      height="48"
                      fill="white"
                      fill-opacity="0.01"
                    />
                    <path
                      d="M14 14L34 34"
                      stroke="#333"
                      stroke-width="1"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M14 34L34 14"
                      stroke="#333"
                      stroke-width="1"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </div>
              <>
                <div className="bg-white px-4 pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="my-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <div className="bg-white px-4 pb-4 pt-5 sm:p-2 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                            <InformationCircleIcon
                              className="h-6 w-6 bg-blue-100"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                            <div className="mt-1">
                              <p className="text-black text-lg font-bold mx-1">
                                Add Cheque Details
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                          Date:
                        </label>
                        <div className="my-2">
                          <input
                            type="date"
                            name="date"
                            value={dateValue}
                            onChange={(e) => {
                              setDateValue(e.target.value);
                            }}
                            className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none border-b-2 text-[#344767]"
                            placeholder="Select date"
                          />
                        </div>
                      </div>
                      <div className="p-1">
                        <div className="text-start">Amount</div>

                        <InputNumberFormat
                          placeholder="$0.00"
                          value={amountValue}
                          onChange={(e) => {
                            setAmountValue(e.target.value);
                          }}
                          currency="USD"
                          format="currency"
                          className="border border-gray-300 rounded-md pl-8 w-full px-2 text-2xl font-bold text-black outline-none md:px-6 py-4"
                        />
                      </div>
                      <div className="mt-2 w-full">
                        <div className="text-base">Notes</div>
                        <textarea
                          cols="50"
                          onChange={(e) => {
                            setAddNoteText(e.target.value);
                          }}
                          name="addNoteText"
                          id="addNoteText"
                          value={addNoteText}
                          placeholder="Type Text"
                          className="resize-y rounded-md block w-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 pl-2 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row justify-between sm:px-6">
                  <div className="flex justify-around gap-2 w-full">
                    <button
                      disabled={addChequeLoading}
                      type="button"
                      onClick={() => {
                        addChequeActionHandler();
                      }}
                      className="w-full inline-flex gap-3 justify-center items-center rounded-md bg-indigo-500 px-2.5 py-1.5 text-base font-semibold text-white border  shadow-md  hover:bg-indigo-300"
                    >
                      Submit
                      <div>
                        {addChequeLoading && (
                          <Oval
                            height={20}
                            width={20}
                            color="#ffff"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel="oval-loading"
                            secondaryColor="#ffff"
                            strokeWidth={2}
                            strokeWidthSecondary={2}
                          />
                        )}
                      </div>
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setAddChequeLoading(false);
                        setAddChequeModal(false);
                        setDateValue("");
                        setAmountValue("");
                        setAddNoteText("");
                      }}
                      className="w-full rounded-md bg-white px-2.5 py-1.5 text-base font-semibold text-black border  shadow-md  hover:bg-gray-300"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </>
            </div>
          </div>
        </ModalComponent>
      </div>
    </>
  );
};

export default AttorneyEscrowAdjustmentTable;
