import ApprovedClientsTable from "../Tables/ApprovedClientsTable";
import PendingClientsTable from "../Tables/PendingClientsTable";
import PendingInvitationsTable from "../Tables/PendingInvitationsTable";
import DelinquentPaymentsBar from "../../Components/Graphs/DelinquentPaymentsBar";
import ExpectedPaymentsBar from "../../Components/Graphs/ExpectedPaymentsBar";
import FundCirculationPie from "../../Components/Graphs/FundCirculationPie";
import LocAndContractLine from "../../Components/Graphs/LocAndContractLine";
import ContractPerformanceBar from "../../Components/Graphs/ContractPerformanceBar";
import CustomerManagementBar from "../../Components/Graphs/CustomerManagementBar";
import EscrowPerformanceBar from "../../Components/Graphs/EscrowPerformanceBar";
import ClientsChatTable from "../Tables/ClientsChatTable";
import UpcomingPaymentsTable from "../Tables/UpcomingPaymentsTable"
export default function AdminDashboard() {
  return (
    <>
      <div>
        <main className="py-10">
          <div className="w-full">
            <div className="mt-5 container m-auto grid grid-cols-1 place-items-start gap-x-2 gap-y-24 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3">
              <>
                <DelinquentPaymentsBar />
                <FundCirculationPie />
                <LocAndContractLine />
                <ExpectedPaymentsBar />
                <ContractPerformanceBar />
                <CustomerManagementBar />
                <EscrowPerformanceBar />
              </>
            </div>
          </div>
            <ClientsChatTable />
            <PendingInvitationsTable />
            <PendingClientsTable />
            <ApprovedClientsTable /> 
            <UpcomingPaymentsTable />
        </main>
      </div>
    </>
  );
}
