import React, { Fragment, useState } from "react";
import Breadcrumb from "../Components/Breadcrumb";
import { motion } from "framer-motion";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useEffect } from "react";
import axios from "axios";
import { domainUrl, logOutHandler } from "../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { postFNLNamePost } from "../utils/redux/slice/postFNLNameSlice";
import { Oval, TailSpin } from "react-loader-spinner";
import toast from "react-hot-toast";
import { addSingleAttorneyPost } from "../utils/redux/slice/addSingleAttorneySlice";
import { getFirmProfileGet } from "../utils/redux/slice/getFirmProfileSlice";
import { useNavigate } from "react-router-dom";
import { InputMask } from "@react-input/mask";

const AddAttorney = () => {
  const [firms, setFirms] = useState([]);
  const [loading, setLoading] = useState(false);

  const access_token = localStorage.getItem("access_token");
  const [selected, setSelected] = useState();
  const [query, setQuery] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const attorneyCodeLoader = useSelector(
    (state) => state.postFNLNameStorage.isLoading
  );
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [attorneyDetail, setAttorneyDetail] = useState({
    first_name: "",
    last_name: "",
    email: "",
    cell_phone_number: "",
    attorney_code: "",
  });
  const [submitLoader, setSubmitLoader] = useState(false);
  const [firmDetails, setFirmDetails] = useState({
    firm_code: "",
    firm_id: "",
  });
  const [errorFlag, setErrorFlag] = useState({
    first_name: "",
    last_name: "",
    email: "",
    cell_phone_number: "",
    attorney_code: "",
  });

  let errMsg = {
    first_name: "Please enter a valid first name (1-30 characters)",
    last_name: "Please enter a valid last name (1-30 characters)",
    email: "Enter a valid email",
    cell_phone_number: "Enter valid phone number",
  };

  const role = localStorage.getItem("role");
  const firm_id = localStorage.getItem("firm_id");

  const [defaultFirm, setDefaultFirm] = useState("");

  const [defaultFirmCheck, setDefaultFirmCheck] = useState(false);

  const autoFirmCheck = async () => {
    setLoading(true);
    const res = await dispatch(getFirmProfileGet(navigate));

    if (res.payload) {
      setFirmDetails({
        ...firmDetails,
        firm_code: res.payload.firm_code,
        firm_id: res.payload.id,
      });
      setLoading(false);
    }

    setDefaultFirmCheck(true);
    setDefaultFirm(`${res.payload.firm_name} : ${res.payload.firm_code}`);
    if (res.error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (role === "primaryattorney") {
      autoFirmCheck();
    }
    return () => {
      localStorage.removeItem("view_firm_detail");
    };
  }, []);
  const searchFirmList = async () => {
    try {
      const res = await axios.get(`${domainUrl}firms?search=${query}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      });
      if (res.data) {
        let list = [];
        res.data.data.forEach((element) => {
          list.push({
            id: element.id,
            name: element.firm_name + " - " + element.firm_code,
            firm_code: element.firm_code,
          });
        });

        setFirms(list);
      }
    } catch (error) {
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
    }
  };

  useEffect(() => {
    if (query !== "") {
      searchFirmList();
    }
  }, [query]);

  const saveSelectedFirm = async () => {
    setFirmDetails({
      ...firmDetails,
      firm_code: selected.firm_code,
      firm_id: selected.id,
    });
    if (attorneyDetail.last_name && attorneyDetail.first_name) {
      const res = await dispatch(
        postFNLNamePost(
          {
            firm_code: selected.firm_code,
            first_name: attorneyDetail.first_name,
            last_name: attorneyDetail.last_name,
          },
          navigate
        )
      );
      if (res.payload) {
        setAttorneyDetail((prevAttorneyDetail) => ({
          ...prevAttorneyDetail,
          attorney_code: res.payload.attorney_code,
        }));
        toast.success("Attorney code generated", { id: 1 });
      }
      if (res.error) {
        toast.error(res.error.message, { id: 1 });
      }
    }
  };

  useEffect(() => {
    if (selected) {
      saveSelectedFirm();
    }
  }, [selected]);

  const formatcell_phone_number = (value) => {
    let convertedNumber = value.replace(/\D/g, "");
    convertedNumber = convertedNumber.replace(/^1/, "");
    convertedNumber = convertedNumber.replace(
      /(\d{3})(\d{3})(\d{4})/,
      "$1 $2 $3"
    );

    return convertedNumber;
  };

  const formatAlphabetsWithSpace = (value) => {
    let formattedAlphabets;
    const { length } = value;

    const regex = () => value.replace(/[^a-zA-Z\s]+/g, "");

    if (length > 0) {
      formattedAlphabets = regex();
    }

    return formattedAlphabets;
  };
  const isEmailValid = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };
  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return email.length > 0 && regex.test(email);
  };

  const handleChange = async (e) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setAttorneyDetail({
      ...attorneyDetail,
      [e.target.name]:
        e.target.name == "cell_phone_number"
          ? e.target.value
          : e.target.name == "first_name"
          ? formatAlphabetsWithSpace(e.target.value)
          : e.target.name == "last_name"
          ? formatAlphabetsWithSpace(e.target.value)
          : e.target.value,
    });

    setTypingTimeout(setTimeout(async () => validateForm(e), 350));
  };

  const validateForm = async (e) => {
    if (e.target.name === "first_name" || e.target.name === "last_name") {
      getAttorneyCode(e);

      setErrorFlag({
        ...errorFlag,
        [e.target.name]:
          e.target.value > 30 || e.target.value < 1
            ? errMsg[e.target.name]
            : "",
      });
    } else if (e.target.name === "email") {
      setErrorFlag({
        ...errorFlag,
        [e.target.name]: !validateEmail(e.target.value)
          ? errMsg[e.target.name]
          : "",
      });
    } else if (e.target.name === "cell_phone_number") {
      setErrorFlag({
        ...errorFlag,
        [e.target.name]:
          formatcell_phone_number(e.target.value).length != 12
            ? errMsg[e.target.name]
            : "",
      });
    }
  };

  const getAttorneyCode = async (e) => {
    if (e.target.name == "first_name") {
      if (firmDetails.firm_code && e.target.value && attorneyDetail.last_name) {
        const res = await dispatch(
          postFNLNamePost(
            {
              firm_code: firmDetails.firm_code,
              first_name: e.target.value,
              last_name: attorneyDetail.last_name,
            },
            navigate
          )
        );
        if (res.payload) {
          setAttorneyDetail((prevAttorneyDetail) => ({
            ...prevAttorneyDetail,
            attorney_code: res.payload.attorney_code,
          }));
          toast.success("Attorney code generated", { id: 1 });
        }
        if (res.error) {
          toast.error(res.error.message, { id: 1 });
        }
      }
    } else {
      if (
        firmDetails.firm_code &&
        e.target.value &&
        attorneyDetail.first_name
      ) {
        const res = await dispatch(
          postFNLNamePost(
            {
              firm_code: firmDetails.firm_code,
              first_name: attorneyDetail.first_name,
              last_name: e.target.value,
            },
            navigate
          )
        );
        if (res.payload) {
          setAttorneyDetail((prevAttorneyDetail) => ({
            ...prevAttorneyDetail,
            attorney_code: res.payload.attorney_code,
          }));
          toast.success("Attorney code generated", { id: 1 });
        }
        if (res.error) {
          toast.error(res.error.message, { id: 1 });
        }
      }
    }
  };

  const isSubmitButtonDisabled = () => {
    const requiredFields = [
      attorneyDetail.attorney_code,
      attorneyDetail.first_name,
      attorneyDetail.last_name,
      attorneyDetail.email,
      attorneyDetail.cell_phone_number,
    ];

    for (const key in errorFlag) {
      if (attorneyDetail[key] == "") {
        setErrorFlag((prevErrorFlag) => ({
          ...prevErrorFlag,
          [key]: `*${key
            .replace("cell_phone_number", "primary phone")
            .replace(/_/g, " ")
            .replace(/\b\w/g, (match) => match.toUpperCase())} is required`,
        }));
      }
    }

    return requiredFields.some((field) => field === "");
  };

  const submitAttorneyHandler = async (e) => {
    e.preventDefault();
    const checkField = await isSubmitButtonDisabled();
    if (checkField === true) {
      toast.error("Complete the fields and submit", { id: 1 });
      return false;
    }
    setSubmitLoader(true);
    const res = await dispatch(
      addSingleAttorneyPost(
        {
          firm: firmDetails.firm_id,
          attorney_code: attorneyDetail.attorney_code,
          first_name: attorneyDetail.first_name,
          last_name: attorneyDetail.last_name,
          email: attorneyDetail.email,
          cell_phone_number: formatcell_phone_number(
            attorneyDetail.cell_phone_number
          ),
        },
        navigate
      )
    );
    if (res.payload) {
      setSubmitLoader(false);
      toast.success("Attorney Created");
      navigate("/list-attorneys");
    }
    if (res.error) {
      setSubmitLoader(false);
      const err = JSON.parse(res.error.message);

      if (err.email) {
        toast.error(err.email);
        setErrorFlag((prevState) => ({
          ...prevState,
          email: err.email,
        }));
      } else if (err.cell_phone_number) {
        toast.error(err.cell_phone_number);
        setErrorFlag((prevState) => ({
          ...prevState,
          cell_phone_number: err.cell_phone_number,
        }));
      } else if (err.first_name) {
        toast.error(err.first_name);
        setErrorFlag((prevState) => ({
          ...prevState,
          first_name: err.first_name,
        }));
      } else if (err.last_name) {
        toast.error(err.last_name);
        setErrorFlag((prevState) => ({
          ...prevState,
          last_name: err.last_name,
        }));
      } else {
        toast.error(err.username);
      }
    }
  };

  return (
    <div>
      
      {loading ? (
        <div className="w-full h-screen flex justify-center items-center">
          <Oval
            height={80}
            width={80}
            color="#1a3253"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#396181"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <motion.div
          initial={{ y: 100 }}
          animate={{ y: 0 }}
          transition={{ delay: 0.1, type: "spring", stiffness: 120 }}
        >
          <div className="mt-10 pt-5 border rounded-lg shadow-md">
            <div className="mx-5 ml-5">
              <div className="my-3">
                <div className="text-xl font-bold">ADD ATTORNEYS</div>
                <div className="text-sm text-[#9ca0b3]">
                  Add an attorney to the firm
                </div>
              </div>

              <div className="py-3">
                <form
                  onSubmit={(e) => {
                    submitAttorneyHandler(e);
                  }}
                >
                  <div className="space-y-12  lg:p-5">
                    <div className="border-gray-900/10 ">
                      {attorneyDetail.attorney_code == "" && (
                        <div className="font-bold">
                          Attorney Code: <span className="ml-2">--</span>
                        </div>
                      )}
                      {attorneyDetail.attorney_code !== "" && (
                        <>
                          <div className="flex gap-2">
                            <div className="font-bold">Attorney Code:</div>
                            <div>
                              {!attorneyCodeLoader ? (
                                <>{attorneyDetail.attorney_code}</>
                              ) : (
                                <span>
                                  <TailSpin
                                    height="20"
                                    width="20"
                                    color="black"
                                    ariaLabel="tail-spin-loading"
                                    radius="1"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                  />
                                </span>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                      <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-4">
                          <label className="block text-sm font-medium leading-6 text-gray-900">
                            Firm
                          </label>
                          {defaultFirmCheck ? (
                            <div>{defaultFirm}</div>
                          ) : (
                            <div className="">
                              <Combobox value={selected} onChange={setSelected}>
                                <div className="relative">
                                  <div className=" w-full h-10 cursor-default overflow-hidden rounded-lg bg-white text-left  focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                                    <Combobox.Input
                                      className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                                      displayValue={(firm) => firm.name}
                                      onChange={(event) =>
                                        setQuery(event.target.value)
                                      }
                                      placeholder=""
                                    />
                                  </div>
                                  <Transition
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                    afterLeave={() => setQuery("")}
                                  >
                                    <Combobox.Options className="absolute mt-1 max-h-60  overflow-auto rounded-md bg-white py-1 text-base  ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                      {firms.length === 0 && query !== "" ? (
                                        <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                          No match found.
                                        </div>
                                      ) : (
                                        firms &&
                                        firms.map((firm) => (
                                          <Combobox.Option
                                            key={firm.id}
                                            className={({ active }) =>
                                              `relative cursor-default select-none py-2 pl-10 pr-4  ${
                                                active
                                                  ? "bg-[#376080] text-white"
                                                  : "text-gray-900"
                                              }`
                                            }
                                            value={firm}
                                          >
                                            {({ selected, active }) => (
                                              <>
                                                <span
                                                  className={`block truncate ${
                                                    selected
                                                      ? "font-medium"
                                                      : "font-normal"
                                                  }`}
                                                >
                                                  {firm.name}
                                                </span>
                                                {selected ? (
                                                  <span
                                                    className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                                      active
                                                        ? "text-white"
                                                        : "text-[#376080]"
                                                    }`}
                                                  >
                                                    <CheckIcon
                                                      className="h-5 w-5"
                                                      aria-hidden="true"
                                                    />
                                                  </span>
                                                ) : null}
                                              </>
                                            )}
                                          </Combobox.Option>
                                        ))
                                      )}
                                    </Combobox.Options>
                                  </Transition>
                                </div>
                              </Combobox>
                            </div>
                          )}
                        </div>

                        <div className="sm:col-span-3">
                          <label className="block text-sm font-medium leading-6 text-gray-900">
                            First name
                          </label>
                          <div className="mt-2">
                            <input
                              className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                              id="first_name"
                              name="first_name"
                              onChange={(e) => handleChange(e)}
                              value={attorneyDetail.first_name}
                            />
                          </div>
                          <span className="text-red-500 text-xs">
                            {errorFlag.first_name}
                          </span>
                        </div>

                        <div className="sm:col-span-3">
                          <label className="block text-sm font-medium leading-6 text-gray-900">
                            Last name
                          </label>
                          <div className="mt-2">
                            <input
                              className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                              id="last_name"
                              name="last_name"
                              onChange={(e) => handleChange(e)}
                              value={attorneyDetail.last_name}
                            />
                          </div>
                          <span className="text-red-500 text-xs">
                            {errorFlag.last_name}
                          </span>
                        </div>

                        <div className="sm:col-span-3">
                          <label className="block text-sm font-medium leading-6 text-gray-900">
                            Email address
                          </label>
                          <div className="mt-2">
                            <input
                              className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                              id="email"
                              name="email"
                              value={attorneyDetail.email}
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                          <span className="text-red-500 text-xs">
                            {errorFlag.email}
                          </span>
                        </div>

                        <div className="sm:col-span-3">
                          <label className="block text-sm font-medium leading-6 text-gray-900">
                            Primary Phone
                          </label>
                          <div className="mt-2">
                            <InputMask
                              name="cell_phone_number"
                              value={attorneyDetail.cell_phone_number}
                              onChange={(e) => handleChange(e)}
                              className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                              id="cell_phone_number"
                              mask="+1 (___) ___-____"
                              replacement={{ _: /\d/ }}
                            />
                          </div>
                          <span className="text-red-500 text-xs">
                            {errorFlag.cell_phone_number}
                          </span>
                        </div>

                        {/* <div className="sm:col-span-3">
                      <label className="block text-sm font-medium leading-6 text-gray-900">
                        Unique Code
                      </label>
                      <div className="mt-2">
                        <input className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]" />
                      </div>
                    </div> */}
                      </div>
                    </div>
                    <div className="w-full flex justify-end">
                      <button
                        type="submit"
                        onClick={() => {}}
                        disabled={submitLoader}
                        className="rounded-md bg-[#2991e0] px-3 py-2 text-sm font-semibold text-white shadow-sm inline-flex gap-3 justify-center items-center hover:bg-[#1991e0] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        Submit
                        <div>
                          {submitLoader && (
                            <Oval
                              height={20}
                              width={20}
                              color="#ffff"
                              wrapperStyle={{}}
                              wrapperClass=""
                              visible={true}
                              ariaLabel="oval-loading"
                              secondaryColor="#ffff"
                              strokeWidth={2}
                              strokeWidthSecondary={2}
                            />
                          )}
                        </div>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default AddAttorney;
