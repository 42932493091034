import React from "react";
import { useNavigate } from "react-router-dom/dist";
import { useState } from "react";
import { useEffect } from "react";
import { domainUrl, logOutHandler } from "../../utils/constants";
import { useMemo } from "react";
import MaterialReactTable from "material-react-table";
import { Avatar, Button } from "@mui/material";
import ExportImg from "../../assets/img/excel-img.png";
import toast from "react-hot-toast";
import axios from "axios";

const ListFirmsTable = () => {
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  const access_token = localStorage.getItem("access_token");

  const [totalCount, setTotalCount] = useState(2);
  const navigate = useNavigate();

  const [columnFilters, setColumnFilters] = useState([]);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  function formatUSD(number) {
    if (typeof number !== "number" || isNaN(number)) {
      if (typeof number == "string") {
        number = parseFloat(number);
      } else {
        return "Invalid number";
      }
    }

    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    return formatter.format(number);
  }

  useEffect(() => {
    const fetchData = async () => {
      if (!data.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      const url = new URL("firms", `${domainUrl}`);
      url.searchParams.set(
        "offset",
        pagination.pageIndex * pagination.pageSize
      );
      url.searchParams.set("limit", `${pagination.pageSize}`);
      url.searchParams.set("offset", `${pagination.pageIndex}`);
      url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
      url.searchParams.set("search", search ?? "");
      url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
      try {
        const response = await fetch(url.href, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        });
        let json = await response.json();
        let data = json;

        if (data.code == "token_not_valid") {
          logOutHandler();
          navigate("/login");
          toast.error("Session Expired", { id: 1 });
          return;
        }
        setData(data.data);
        setRowCount(data.count);
      } catch (error) {
        setIsError(true);

        return;
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    };

    fetchData();
  }, [
    columnFilters,
    search,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
  ]);

  const columns = useMemo(
    () => [
      {
        id: "attorneys",
        header: "",
        columns: [
          {
            id: "firm_code",
            header: "Firm Code",
            accessorFn: (row) => `${row?.firm_code}`,
            accessorKey: "firm_code",
            enableColumnActions: false,
            size: 30,
          },
          {
            accessorFn: (row) => `${row?.firm_name} `,
            id: "firm_name",
            header: "Firm Name",
            accessorKey: "firm_name",
            enableColumnActions: false,

            size: 30,
          },

          {
            id: "email",
            header: "Email",
            accessorFn: (row) => `${row?.email}`,
            accessorKey: "email",
            enableColumnActions: false,

            size: 30,
          },
          {
            id: "cell_phone_number",
            header: "Primary Phone",
            accessorFn: (row) =>
              `${
                row?.cell_phone_number ? formatUSCell(row.cell_phone_number) : ""
              }`,
            accessorKey: "cell_phone_number",
            enableColumnActions: false,
            // enableSorting: false,
            size: 30,
          },
          {
            id: "created_time",
            header: "Created Date",
            accessorFn: (row) =>
              `${extractDateFromTimestamp(row?.created_time)}`,
            muiTableHeadCellFilterTextFieldProps: { placeholder: "YYYY-MM-DD" },
            enableColumnActions: false,

            accessorKey: "created_time",
            size: 30,
          },
          {
            id: "total_contract_value",
            header: "Contract Value",
            accessorFn: (row) => `${formatUSD(row?.total_contract_value)}`,
            enableColumnActions: false,
            enableSorting: false,
            enableColumnFilter: false,
            accessorKey: "total_contract_value",
            size: 30,
          },
          {
            id: "escrow_balance",
            header: "Escrow Balance",
            accessorFn: (row) => `${formatUSD(row?.escrow_balance)}`,
            enableColumnActions: false,
            enableSorting: false,
            enableColumnFilter: false,
            accessorKey: "escrow_balance",
            size: 30,
          },
          {
            id: "escrow_deficit",
            header: "Escrow Deficit",
            accessorFn: (row) => `${formatUSD(row?.escrow_deficit)}`,
            enableColumnActions: false,
            enableSorting: false,
            enableColumnFilter: false,
            accessorKey: "escrow_deficit",
            size: 30,
          },
          {
            id: "firm_contract__escrow_percentage",
            header: "Escrow Percentage",
            accessorFn: (row) => `${row.escrow_percentage}%`,
            accessorKey: "escrow_percentage",
            enableColumnActions: false,

            muiTableBodyCellProps: {
              align: "center",
            },
            size: 30,
          },
          {
            id: "attorney_count",
            header: "Attorney Count",
            accessorFn: (row) => `${row?.attorney_count}`,
            enableColumnActions: false,
            enableSorting: false,
            enableColumnFilter: false,
            accessorKey: "attorney_count",
            muiTableBodyCellProps: {
              align: "center",
            },
            size: 30,
          },
          {
            id: "client_count",
            header: "Client Count",
            accessorFn: (row) => `${row?.client_count}`,
            enableColumnActions: false,
            enableSorting: false,
            enableColumnFilter: false,
            accessorKey: "client_count",
            muiTableBodyCellProps: {
              align: "center",
            },
            size: 30,
          },
        ],
      },
    ],
    []
  );

  function extractDateFromTimestamp(timestamp) {
    const dateObject = new Date(timestamp);
    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
    const day = dateObject.getDate().toString().padStart(2, "0");
    const year = dateObject.getFullYear();
    return `${month}/${day}/${year}`;
  }

  const formatDate = (dateString) => {
    if (dateString) {
      const date = dateString.split("-");

      return `${date[1]}/${date[2]}/${date[0]}`;
    }
    return dateString;
  };
  function formatUSCell(usPhoneNumber) {
    // Remove any non-digit characters
    let digitsOnly = usPhoneNumber?.replace(/\D/g, "");
    // Check if the number has the expected length of 10 digits
    if (digitsOnly?.length !== 10) {
      return "Invalid phone number format";
    }
    // Format the number in the original format and add +1 in front
    return "+1 " + digitsOnly?.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  }

  const exportFileHandler = async (id) => {
    const url = new URL("firms", `${domainUrl}`);
    url.searchParams.set("offset", pagination.pageIndex * pagination.pageSize);
    url.searchParams.set("limit", `${pagination.pageSize}`);
    url.searchParams.set("offset", `${pagination.pageIndex}`);
    url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
    url.searchParams.set("search", search ?? "");
    url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
    url.searchParams.set("export", true);

    axios
      .get(url, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        if (response.headers.get("content-type") == "application/json") {
          response.data.text().then((text) => {
            toast.success(JSON.parse(text).scheduled, { autoClose: 10000 });
          });
        } else {
          let filename = `Firms_${Date.now()}.xlsx`;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
        }
      });
  };

  return (
    <div className="w-full">
      <div>
        <main className="py-10">
          <div className="flex justify-end px-5">
            <button
              type="button"
              onClick={() => {
                navigate("/add-firm");
              }}
              className="bg-transparent hover:bg-[#1a3253] text-[#1a3253] font-semibold hover:text-white transition ease-in-out py-2 px-4 border border-[#1a3253] hover:border-transparent rounded duration-100"
            >
              Add New Firm +
            </button>
          </div>

          <div className="px-5">
            <h2
              style={{
                color: "#1a3253",
              }}
            >
              All Firms
            </h2>
            <div className="text-base text-[#9ca0b3] my-2">
              List of All Firms
            </div>
            <div className="p-1 border rounded-lg">
              <MaterialReactTable
                enableTopToolbar={true}
                renderTopToolbarCustomActions={({ table }) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        gap: "0.5rem",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        disabled={false}
                        startIcon={<Avatar src={ExportImg} />}
                        onClick={() => {
                          exportFileHandler();
                        }}
                        variant="contained"
                        sx={{
                          bgcolor: "#127c44",
                          "&:hover": {
                            bgcolor: "#127c44",
                          },
                          height: "25px",
                        }}
                      >
                        Export
                      </Button>
                    </div>
                  );
                }}
                enableStickyHeader
                muiTableContainerProps={{ sx: { maxHeight: "500px" } }}
                muiTableBodyProps={{
                  sx: () => ({
                    "& tr:nth-child(odd)": {
                      backgroundColor: "#f8f9fa",
                    },
                    "& tr:nth-child(even)": {
                      backgroundColor: "#ffffff",
                    },
                  }),
                }}
                muiTablePaperProps={{
                  sx: {
                    maxWidth: "full",
                  },
                  elevation: 0,
                }}
                muiTableHeadCellProps={{
                  sx: (theme) => ({
                    color: theme.palette.text.primary,
                  }),
                }}
                enableFullScreenToggle={false}
                enableDensityToggle={false}
                enableHiding={false}
                enableColumnFilters={true}
                rowNumberMode="static"
                muiTablePaginationProps={{
                  rowsPerPageOptions: [1, 2, 5, 10, 15, 20, 25],
                  showFirstButton: false,
                  showLastButton: false,
                }}
                columns={columns}
                data={data}
                muiTableBodyRowProps={({ row }) => ({
                  onClick: () => {
                    if (data?.length) {
                      localStorage.setItem("firm_id", row.original.id);
                      localStorage.setItem("view_firm_id", row.original.id);

                      navigate(`/view-firm`);
                    }
                  },

                  sx: {
                    cursor: "pointer",
                  },
                })}
                getRowId={(row) => row.id}
                initialState={{
                  showColumnFilters: false,
                  density: "compact",
                }}
                manualPagination
                manualSorting
                muiToolbaralertBannerProps={
                  isError
                    ? {
                        color: "error",
                        children: "Error loading data",
                      }
                    : undefined
                }
                onColumnFiltersChange={setColumnFilters}
                onGlobalFilterChange={setSearch}
                onPaginationChange={setPagination}
                onSortingChange={setSorting}
                rowCount={rowCount}
                state={{
                  isLoading,
                  pagination,
                  showalertBanner: isError,
                  showProgressBars: isRefetching,
                  sorting,
                }}
              />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default ListFirmsTable;
