import { Fragment, useEffect, useState } from "react";
import { Dialog, Menu, Transition, Popover } from "@headlessui/react";
import {
  Bars3Icon,
  BellIcon,
  CalendarIcon,
  ChartPieIcon,
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
  ArrowLeftOnRectangleIcon,
  HomeModernIcon,
  AcademicCapIcon,
  UserGroupIcon,
  UserPlusIcon,
  DocumentChartBarIcon,
  PlusIcon,
  ReceiptRefundIcon,
  UserCircleIcon,
  DocumentTextIcon,
  DocumentIcon,
  DocumentMagnifyingGlassIcon,
  CalendarDaysIcon,
  ArrowTrendingDownIcon,
  PauseIcon,
  ExclamationTriangleIcon,
  CurrencyDollarIcon,
  ArrowPathIcon,
  ArchiveBoxArrowDownIcon,
  NoSymbolIcon,
  ClipboardDocumentCheckIcon,
  CreditCardIcon,
} from "@heroicons/react/24/outline";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import logo from "../assets/img/nationalaccess-logo.png";
import Breadcrumb from "../Components/Breadcrumb";
import { Line, Doughnut } from "react-chartjs-2";
import UserImg from "../Images/icons/user-icon.png";
import {
  Chart as ChartJS,
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import GlobalSearch from "./GlobalSearch";
import {
  Link,
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useSelector } from "react-redux";
import ModalComponent from "./ModalComponent";
import {
  domainUrl,
  logOutHandler,
  tokenExpiryValidate,
} from "../utils/constants";
import axios from "axios";
import toast from "react-hot-toast";
import WebformPageClient from "../Pages/WebformPageClient";
import NotificationComponent from "./NotificationComponent";
import { AppRegistration } from "@mui/icons-material";

ChartJS.register(
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  Tooltip,
  Legend
);

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Dashboard() {
  const role = localStorage.getItem("role");
  const access_token = localStorage.getItem("access_token");
  const userProf = JSON.parse(localStorage.getItem("userProf"));

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [openEulaModal, setOpenEulaModal] = useState(false);
  const [eulaPage, setEulaPage] = useState({
    default: true,
    exhibit_a: false,
    exhibit_b: false,
    exhibit_c: false,
  });

  const checkTokenValidity = async () => {
    try {
      const res = await axios.get(`${domainUrl}test-token`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      });
      if (res?.data) {
      }
    } catch (error) {
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
    }
  };

  const checkEulaSigned = async () => {
    try {
      const res = await axios.get(`${domainUrl}webform`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      });
      if (res?.data) {
        if (res.data.eula_accepted == false) {
          setOpenEulaModal(true);
        }
      }
    } catch (error) {
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
    }
  };

  const checkAttorneyEulaSigned = async () => {
    try {
      const res = await axios.get(`${domainUrl}check-eula`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      });
      if (res?.data) {
        if (res.data.eula_accepted == false) {
          setOpenEulaModal(true);
        }
      }
    } catch (error) {
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
    }
  };

  const acceptEulaSign = async () => {
    try {
      const res = await axios.post(
        `${domainUrl}accept-eula`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (res?.data) {
        toast.success(res.data.message, { id: 1 });
        setOpenEulaModal(false);
      }
    } catch (error) {
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
    }
  };

  useEffect(() => {
    if (role == "client") {
      checkEulaSigned();
    } else if (role == "attorney" || role == "primaryattorney") {
      checkAttorneyEulaSigned();
    }
  }, []);

  useEffect(() => {}, []);

  useEffect(() => {
    document.tidioIdentify = {
      distinct_id: userProf?.id,
      email: userProf?.email,
      name: `${userProf?.first_name} ${userProf?.last_name}`,
      phone: `+1 ${userProf?.cell_phone_number}`,
    };

    (function () {
      function onTidioChatApiReady() {}
      if (window.tidioChatApi) {
        window.tidioChatApi.on("ready", onTidioChatApiReady);
        window.tidioChatApi.on("messageFromVisitor", (e) => {});
      } else {
        document.addEventListener("tidioChat-ready", onTidioChatApiReady);
      }
    })();
  }, []);

  useEffect(() => {
    if (!role && !access_token) {
      navigate("/login");
    } else {
      if (role === "admin" && pathname === "/") {
        navigate("/admin-home");
      }
      if (role === "primaryattorney" && pathname === "/") {
        navigate("/firm-home");
      }
      if (role === "attorney" && pathname === "/") {
        navigate("/attorney-home");
      }
      if (role === "client" && pathname === "/") {
        navigate("/client-home");
      }
    }
  }, []);
  const navigation = {
    admin: [
      {
        title: "Home",
        src: "/admin-home",
        icon: HomeModernIcon,
      },
      {
        title: "Manage Firms",
        icon: HomeIcon,

        subMenus: [
          {
            title: "Firms Overview",
            src: "/firm-overview",
            icon: DocumentChartBarIcon,
          },
          {
            title: "Escrow Overview",
            src: "/escrow-overview",
            icon: CurrencyDollarIcon,
          },
          {
            title: "Add Firm",
            src: "/add-firm",
            icon: PlusIcon,
          },
          {
            title: "List Firm",
            src: "/list-firm",
            icon: HomeIcon,
          },
          {
            title: "Firm Payouts",
            src: "/firm-payouts",
            icon: CurrencyDollarIcon,
          },
          {
            title: "Payout History",
            src: "/payout-history",
            icon: CurrencyDollarIcon,
          },
        ],
        current: false,
      },
      {
        title: "Manage Attorneys",
        icon: AcademicCapIcon,
        subMenus: [
          {
            title: "Add Attorney",
            src: "/add-attorney",
            icon: UserPlusIcon,
          },
          {
            title: "List Attorney",
            src: "/list-attorneys",
            icon: UserGroupIcon,
          },
          {
            title: "Pending Invitations",
            src: "/attorney-pending-invitations",
            icon: UserGroupIcon,
          },
        ],
        current: false,
      },
      {
        title: "Manage Clients",
        icon: UserGroupIcon,

        subMenus: [
          {
            title: "Client Overview",
            src: "/client-overview",
            icon: DocumentChartBarIcon,
          },
          {
            title: "List Client",
            src: "/list-clients",
            icon: UserGroupIcon,
          },
          {
            title: "Pending Invitations",
            src: "/pending-invitations",
            icon: ReceiptRefundIcon,
          },
          {
            title: "Pending Approval",
            src: "/pending-clients",
            icon: ReceiptRefundIcon,
          },
          {
            title: "Approved Clients",
            src: "/approved-clients",
            icon: UserCircleIcon,
          },
          {
            title: "Rejected Clients",
            src: "/rejected-clients",
            icon: NoSymbolIcon,
          },
          {
            title: "Paused Clients",
            src: "/paused-clients",
            icon: PauseIcon,
          },
          {
            title: "Missed Payments",
            src: "/missed-payment-clients",
            icon: ArrowTrendingDownIcon,
          },
          {
            title: "Declined Payments",
            src: "/declined-payment-clients",
            icon: ArrowTrendingDownIcon,
          },
          {
            title: "Delinquent Clients",
            src: "/delinquent-clients",
            icon: ExclamationTriangleIcon,
          },
          {
            title: "Pre-Delinquent Clients",
            src: "/predelinquent-clients",
            icon: ExclamationTriangleIcon,
          },
          {
            title: "Past Delinquent Clients",
            src: "/pastdelinquent-clients",
            icon: ExclamationTriangleIcon,
          },
          {
            title: "Completed Clients",
            src: "/completed-clients",
            icon: UserCircleIcon,
          },
          {
            title: "Archived Clients",
            src: "/archived-clients",
            icon: ArchiveBoxArrowDownIcon,
          },
        ],
        current: false,
      },
      {
        title: "Manage Holidays",
        src: "/manage-holidays",
        icon: CalendarDaysIcon,
      },
      {
        title: "Reports",
        icon: ChartPieIcon,
        subMenus: [
          {
            title: "Escrow History",
            src: "/escrow-history",
            icon: CurrencyDollarIcon,
          },
          {
            title: "Transaction History",
            src: "/transaction-history",
            icon: CurrencyDollarIcon,
          },
          {
            title: "Delinquent Payments",
            src: "/delinquent-payments",
            icon: ExclamationTriangleIcon,
          },
          {
            title: "Expected Payments",
            src: "/expected-payments",
            icon: CurrencyDollarIcon,
          },
          {
            title: "Financial Reports",
            src: "/financial-reports",
            icon: CurrencyDollarIcon,
          },
          {
            title: "Account Receivable",
            src: "/account-receivable-aging",
            icon: CurrencyDollarIcon,
          },

          {
            title: "Audit Log",
            src: "/audit-log",
            icon: ClipboardDocumentCheckIcon,
          },
          {
            title: "Upcoming Payments",
            src: "/upcoming-payments",
            icon: CreditCardIcon,
          },
        ],
        current: false,
      },
    ],
    primaryattorney: [
      {
        title: "Home",
        src: "/firm-home",
        icon: HomeIcon,
      },
      {
        title: "Escrow Overview",
        src: "/escrow-overview",
        icon: CurrencyDollarIcon,
      },
      {
        title: "Add Attorney",
        src: "/add-attorney",
        icon: UserPlusIcon,
      },
      {
        title: "List Attorney",
        src: "/list-attorneys",
        icon: UsersIcon,
      },
      {
        title: "Manage Clients",
        icon: UserGroupIcon,
        current: false,
        subMenus: [
          {
            title: "Add New Client",
            src: "/add-clients",
            icon: UserPlusIcon,
          },
          {
            title: "List Client",
            src: "/list-clients",
            icon: UsersIcon,
          },
          {
            title: "Pending Invitation",
            src: "/pending-invitations",
            icon: ReceiptRefundIcon,
          },
          {
            title: "Pending Approval",
            src: "/pending-clients",
            icon: ReceiptRefundIcon,
          },
          {
            title: "Approved Clients",
            src: "/approved-clients",
            icon: UserCircleIcon,
          },
          {
            title: "Rejected Clients",
            src: "/rejected-clients",
            icon: UserCircleIcon,
          },
          {
            title: "Missed Payments",
            src: "/missed-payment-clients",
            icon: ArrowTrendingDownIcon,
          },
          {
            title: "Declined Payments",
            src: "/declined-payment-clients",
            icon: ArrowTrendingDownIcon,
          },
          {
            title: "Delinquent Clients",
            src: "/delinquent-clients",
            icon: ExclamationTriangleIcon,
          },
          {
            title: "Pre-Delinquent Clients",
            src: "/predelinquent-clients",
            icon: ExclamationTriangleIcon,
          },
          {
            title: "Delinquent Payments",
            src: "/delinquent-payments",
            icon: ExclamationTriangleIcon,
          },
          {
            title: "Completed Clients",
            src: "/completed-clients",
            icon: UserCircleIcon,
          },
        ],
      },
      {
        title: "Reports",
        icon: ChartPieIcon,
        subMenus: [
          {
            title: "Financial Reports",
            src: "/financial-reports",
            icon: CurrencyDollarIcon,
          },
        ],
        current: false,
      },
    ],

    attorney: [
      {
        title: "Home",
        src: "/attorney-home",
        icon: HomeIcon,
      },
      {
        title: "Manage Clients",
        icon: UserGroupIcon,
        current: false,
        subMenus: [
          {
            title: "Add New Client",
            src: "/add-clients",
            icon: UserPlusIcon,
          },
          {
            title: "List Client",
            src: "/list-clients",
            icon: UsersIcon,
          },
          {
            title: "Pending Invitation",
            src: "/pending-invitations",
            icon: ReceiptRefundIcon,
          },
          {
            title: "Pending Approval",
            src: "/pending-clients",
            icon: ReceiptRefundIcon,
          },
          {
            title: "Approved Clients",
            src: "/approved-clients",
            icon: UserCircleIcon,
          },
          {
            title: "Rejected Clients",
            src: "/rejected-clients",
            icon: UserCircleIcon,
          },
          {
            title: "Delinquent Clients",
            src: "/delinquent-clients",
            icon: ExclamationTriangleIcon,
          },
          {
            title: "Pre-Delinquent Clients",
            src: "/predelinquent-clients",
            icon: ExclamationTriangleIcon,
          },
        ],
      },
    ],

    client: [
      { title: "Home", src: "/client-home", icon: HomeIcon },
      {
        title: "Attorney & Contract",
        src: "/client-contracts-view",
        icon: DocumentTextIcon,
      },
    ],
  };

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [menu, setMenu] = useState(navigation);

  useEffect(() => {
    initalizeProfileData();
  }, []);

  const initalizeProfileData = () => {
    if (userProf) {
      if (userProf.last_name) {
        setProfUpdate((prevState) => ({
          ...prevState,
          first_name: userProf.first_name,
        }));
      }
      if (userProf.first_name) {
        setProfUpdate((prevState) => ({
          ...prevState,
          last_name: userProf.last_name,
        }));
      }
      if (userProf.cell_phone_number) {
        setProfUpdate((prevState) => ({
          ...prevState,
          cell_phone_number: userProf.cell_phone_number,
        }));
      }
      if (userProf.email) {
        setProfUpdate((prevState) => ({
          ...prevState,
          email: userProf.email,
        }));
      }
    } else {
      navigate("/login");
    }
  };
  const editProfileData = useSelector((state) => state.editProfileStorage.data);

  const [profUpdate, setProfUpdate] = useState({
    cell_phone_number: "",
    email: "",
    first_name: "",
    last_name: "",
  });

  const updateProfileData = () => {
    if (editProfileData.first_name) {
      userProf.first_name = editProfileData.first_name;
      setProfUpdate((prevState) => ({
        ...prevState,
        first_name: editProfileData.first_name,
      }));
      localStorage.setItem("userProf", JSON.stringify(userProf));
    }
    if (editProfileData.last_name) {
      userProf.last_name = editProfileData.last_name;
      setProfUpdate((prevState) => ({
        ...prevState,
        last_name: editProfileData.last_name,
      }));
      localStorage.setItem("userProf", JSON.stringify(userProf));
    }
    if (editProfileData.cell_phone_number) {
      userProf.cell_phone_number = editProfileData.cell_phone_number;
      setProfUpdate((prevState) => ({
        ...prevState,
        cell_phone_number: editProfileData.cell_phone_number,
      }));
      localStorage.setItem("userProf", JSON.stringify(userProf));
    }
    if (editProfileData.email) {
      userProf.email = editProfileData.email;
      setProfUpdate((prevState) => ({
        ...prevState,
        email: editProfileData.email,
      }));
      localStorage.setItem("userProf", JSON.stringify(userProf));
    }
  };

  useEffect(() => {
    if (
      editProfileData.first_name ||
      editProfileData.last_name ||
      editProfileData.cell_phone_number ||
      editProfileData.email
    ) {
      updateProfileData();
    }
  }, [editProfileData]);

  localStorage.setItem("pathname", pathname);

  const toggleSubmenu = (role, index) => {
    setMenu((prevMenu) => {
      const updatedMenu = { ...prevMenu };

      const menuItems = updatedMenu[role];

      menuItems.forEach((element, i) => {
        if (i == index) {
          return;
        }
        element.current = false;
      });
      if (menuItems && menuItems[index]) {
        menuItems[index].current = !menuItems[index].current;
      }

      return updatedMenu;
    });
  };

  return (
    <>
      <div className="">
        <div className="lg:pl-4 xl:pl-4">
          <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-50 lg:hidden"
              onClose={setSidebarOpen}
            >
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-900/80" />
              </Transition.Child>

              <div className="fixed inset-0 flex">
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                        <button
                          type="button"
                          className="-m-2.5 p-2.5"
                          onClick={() => setSidebarOpen(false)}
                        >
                          <span className="sr-only">Close sidebar</span>
                          <XMarkIcon
                            className="h-6 w-6 text-white"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </Transition.Child>
                    
                    <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-[#29292b] px-6 pb-4 ring-1 ring-white/10">
                      <div className="flex h-10 mt-4 shrink-0 justify-center items-center bg-[#b9b9bc]">
                        <img
                          className="h-8 w-auto bg-[#b9b9bc]"
                          src={logo}
                          alt="Natl"
                        />
                      </div>
                      <nav className="flex flex-1 flex-col">
                        <ul
                          role="list"
                          className="flex flex-1 flex-col gap-y-7"
                        >
                          <li>
                            <li className="flex justify-center align-center h-30 w-full border-t-2 border-[#9d9ca0] border-b-2 py-4 border-[#9d9ca0]">
                              
                              <div
                                className="relative hover:cursor-pointer"
                                onClick={() => {
                                  navigate("/profile-page");
                                  setSidebarOpen(false);
                                }}
                              >
                                <div className="-m-1.5 flex items-start gap-3 p-1.5">
                                  {/* <img
                                    className="h-12 w-12 rounded-full bg-gray-50"
                                    
                                    src="https:
                                    alt=""
                                  /> */}
                                  <span className="flex flex-col items-center justify-center">
                                    <span
                                      className="ml-4 text-sm font-semibold leading-6 text-white"
                                      aria-hidden="true"
                                    >
                                      {profUpdate ? (
                                        <>
                                          <span>{profUpdate.first_name}</span>
                                          <span className="ml-2">
                                            {profUpdate.last_name}
                                          </span>
                                        </>
                                      ) : (
                                        <>
                                          <span>
                                            {userProf && userProf?.first_name}
                                          </span>
                                          <span className="ml-2">
                                            {userProf && userProf?.last_name}
                                          </span>
                                        </>
                                      )}
                                    </span>
                                    {role === "primaryattorney" ? (
                                      <>
                                        <span className="text-[#e0e0e0] text-xs ml-2">
                                          PRIMARY ATTORNEY
                                        </span>
                                      </>
                                    ) : (
                                      <span className="text-[#e0e0e0] text-xs">
                                        {role?.toUpperCase()}
                                      </span>
                                    )}
                                  </span>
                                  <SettingsIcon className="h-20 w-20 text-gray-50" />
                                </div>
                              </div>
                            </li>
                          </li>
                          <li>
                            <ul className="pt-0 ease-in-out duration-300">
                              <li>
                                {role &&
                                  menu[role] &&
                                  menu[role].map((item, index) => (
                                    <>
                                      <li
                                        onClick={() => {
                                          if (!item.current) {
                                            navigate(item.src);
                                          }
                                          if (item.subMenus) {
                                            setSidebarOpen(true);
                                          } else {
                                            setSidebarOpen(false);
                                          }
                                          toggleSubmenu(role, index);
                                        }}
                                        key={index}
                                        className={`flex  rounded-md ease-in-out duration-200 p-2 cursor-pointer hover:bg-[#1a73e7] text-white text-sm items-center gap-x-4 ${
                                          item.gap ? "mt-9" : "mt-2"
                                        }`}
                                      >
                                        <item.icon
                                          className="h-6 w-6 shrink-0"
                                          aria-hidden="true"
                                        />
                                        <span className="flex-1">
                                          {item.title}
                                        </span>
                                        {item.subMenus && (
                                          <ChevronDownIcon
                                            className={`ml-2 h-5 w-5 text-white ease-in-out duration-300 ${
                                              item.current ? "rotate-180" : ""
                                            }`}
                                          />
                                        )}
                                      </li>
                                      {item.current && item.subMenus && (
                                        <>
                                          {item.subMenus.map(
                                            (subMenuItem, index) => (
                                              <ul className="ease-in-out duration-300 w-full flex justify-start pl-4 items-center">
                                                <subMenuItem.icon
                                                  className={`ease-in-out duration-300 shrink-0 text-white  ${
                                                    subMenuItem.src === pathname
                                                      ? "h-5 w-5"
                                                      : "h-3 w-3"
                                                  }`}
                                                  aria-hidden="true"
                                                />
                                                <Link to={subMenuItem.src}>
                                                  <li
                                                    onClick={() => {
                                                      setSidebarOpen(false);
                                                    }}
                                                    key={index}
                                                    className={`ease-in-out duration-300 flex justify-start items-center  px-5 py-3 cursor-pointer text-center   py-1  ${
                                                      subMenuItem.src ===
                                                      pathname
                                                        ? "font-bold text-blue-500 text-base"
                                                        : "text-sm text-gray-200"
                                                    }`}
                                                  >
                                                    {subMenuItem.title}
                                                  </li>
                                                </Link>
                                              </ul>
                                            )
                                          )}
                                        </>
                                      )}
                                    </>
                                  ))}
                              </li>
                              <li
                                className="mt-auto"
                                onClick={() => {
                                  logOutHandler();
                                  navigate("/login");
                                }}
                              >
                                <Link
                                  to=""
                                  className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-white hover:bg-gray-800 hover:text-white"
                                >
                                  <ArrowLeftOnRectangleIcon
                                    className="h-6 w-6 shrink-0"
                                    aria-hidden="true"
                                  />
                                  Log out
                                </Link>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>

          <div className="hidden  lg:fixed lg:inset-y-2 lg:z-50 lg:flex lg:w-72 lg:flex-col">
            <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-[#29292b] px-6 pb-4 rounded-md">
              <div className="flex h-10 mt-4 shrink-0 justify-center items-center bg-[#b9b9bc]">
                <img
                  className="h-8 w-auto bg-[#b9b9bc]"
                  src={logo}
                  alt="Natl"
                />
              </div>
              <nav className="flex flex-1 flex-col">
                <ul role="list" className="flex flex-1 flex-col gap-y-7 ">
                  <li className="flex justify-center align-center h-30 w-full border-t-2 border-[#9d9ca0] border-b-2 py-4 border-[#9d9ca0]">
                    
                    <div
                      className="relative hover:cursor-pointer"
                      onClick={() => {
                        navigate("/profile-page");
                      }}
                    >
                      <div className=" flex items-start gap-3 p-1.5">
                        {/* <img
                          className="h-12 w-12 rounded-full bg-gray-50"
                          
                          src="https:
                          alt=""
                        /> */}

                        <span className="hidden lg:flex flex-col lg:items-center justify-center w-full">
                          <span
                            className="ml-4 text-base font-semibold  text-white"
                            aria-hidden="true"
                          >
                            {profUpdate ? (
                              <>
                                <span>{profUpdate.first_name}</span>
                                <span className="ml-2">
                                  {profUpdate.last_name}
                                </span>
                              </>
                            ) : (
                              <>
                                <span>{userProf && userProf?.first_name}</span>
                                <span className="ml-2">
                                  {userProf && userProf?.last_name}
                                </span>
                              </>
                            )}
                          </span>
                          {role === "primaryattorney" ? (
                            <>
                              <span className="text-[#e0e0e0] text-xs">
                                PRIMARY ATTORNEY
                              </span>
                            </>
                          ) : (
                            <span className="text-[#e0e0e0] text-xs">
                              {role?.toUpperCase()}
                            </span>
                          )}
                        </span>
                        <SettingsIcon className="h-20 w-20 text-gray-50" />
                      </div>
                    </div>
                  </li>

                  <ul className="pt-0 ease-in-out">
                    <li>
                      {role &&
                        menu[role] &&
                        menu[role].map((item, index) => (
                          <>
                            <li
                              onClick={() => {
                                if (!item.current) {
                                  navigate(item.src);
                                }
                                toggleSubmenu(role, index);
                              }}
                              key={index}
                              className={`flex  rounded-md ease-in-out duration-200 p-2 cursor-pointer hover:bg-[#1a73e7] text-white text-sm items-center gap-x-4 ${
                                item.gap ? "mt-9" : "mt-2"
                              } ${item.current ? "bg-[#1a73e7]" : ""}`}
                            >
                              <item.icon
                                className="h-6 w-6 shrink-0"
                                aria-hidden="true"
                              />
                              <span className="flex-1">{item.title}</span>
                              {item.subMenus && (
                                <ChevronDownIcon
                                  className={`ml-2 h-5 w-5 text-white ease-in-out duration-300 ${
                                    item.current ? "rotate-180" : ""
                                  }`}
                                />
                              )}
                            </li>
                            {item.current && item.subMenus && (
                              <>
                                {item.subMenus.map((subMenuItem, idx) => (
                                  <ul className="ease-in-out duration-300 w-full flex justify-start pl-4 items-center  hover:translate-x-4  hover:duration-500">
                                    <subMenuItem.icon
                                      className={`ease-in-out duration-300 shrink-0 text-white   ${
                                        subMenuItem.src === pathname
                                          ? "h-5 w-5"
                                          : "h-3 w-3"
                                      }`}
                                      aria-hidden="true"
                                    />
                                    <Link to={subMenuItem.src}>
                                      <li
                                        onClick={() => {
                                          setSidebarOpen(false);
                                        }}
                                        key={index}
                                        className={`ease-in-out duration-300 flex justify-start items-center  px-2 py-3 cursor-pointer text-center hover:font-bold hover:text-blue-500   ${
                                          subMenuItem.src === pathname
                                            ? "font-bold text-blue-500  text-base"
                                            : "text-sm text-gray-200"
                                        }`}
                                      >
                                        {subMenuItem.title}
                                      </li>
                                    </Link>
                                  </ul>
                                ))}
                              </>
                            )}
                          </>
                        ))}
                    </li>
                    <li
                      className="mt-3"
                      onClick={() => {
                        logOutHandler();
                        navigate("/login");
                      }}
                    >
                      <Link
                        to=""
                        className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-white hover:bg-gray-800 hover:text-white"
                      >
                        <ArrowLeftOnRectangleIcon
                          className="h-6 w-6 shrink-0"
                          aria-hidden="true"
                        />
                        Logout
                      </Link>
                    </li>
                  </ul>
                </ul>
              </nav>
            </div>
          </div>

          <div className="lg:pl-72">
            <div className="sticky  top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
              <button
                type="button"
                className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
                onClick={() => setSidebarOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>

              <div
                className="h-6 w-px bg-gray-900/10 lg:hidden"
                aria-hidden="true"
              />

              <div className="flex py-3 flex-1 gap-x-4 self-stretch lg:gap-x-6">
                
              </div>
              <NotificationComponent />
            </div>
            <div className="m-5">
              <div className="border py-3 px-2 shadow-md rounded-md">
                <Breadcrumb />
              </div>
              <Outlet />
            </div>
          </div>
        </div>
      </div>

      <ModalComponent open={openEulaModal} handleClose={() => {}}>
        <div className="relative bg-white rounded-lg shadow overflow-y-auto min-h-96 sm:max-h-[450px] md:max-h-[600px] 2xl:max-h-[800px]">
          <div className="sticky top-0 flex flex-col items-center p-4 border-b rounded-t bg-white">
            <div className="text-xl font-semibold text-gray-900">
              National Access
            </div>
            <div className="text-xl font-semibold text-gray-900">
              User License Agreement
            </div>
          </div>

          {eulaPage.default ? (
            <div className="text-start p-6 space-y-6">
              <p className="text-base  leading-relaxed text-gray-500">
                The following “User License Agreement” governs your use of the
                software and services provided by National Access, LLC (and its
                subsidiaries) (“National Access”) also known as NA. This is a
                legal agreement between you and National Access and incorporates
                the Privacy Policy at https://natlaccess.com/privacy and the
                attached Exhibits. By registering your use of the Service (as
                defined below), you are accepting to be bound to the terms of
                this User License Agreement.
              </p>
              <div className="text-base font-bold underline">
                1. Definitions
              </div>
              <p className="text-base  leading-relaxed text-gray-500">
                (a) “Administrator” shall mean a Subscriber (as defined in
                Section 1(i)) with authority to designate additional Authorized
                Users and/or Administrators and commit the Subscriber to
                additional services from National Access.
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                (b) “Agreement” shall mean this entire User License Agreement
                and incorporates by reference the Privacy Policy located
                at https://natlaccess.com/privacy and the attached Exhibits.
              </p>
              <p className="w-fit">
                <ul className="mx-5">
                  <li
                    onClick={() => {
                      setEulaPage({
                        default: false,
                        exhibit_a: true,
                        exhibit_b: false,
                        exhibit_c: false,
                      });
                    }}
                    className="w-fit list-disc text-base  leading-relaxed text-blue-700 hover:cursor-pointer"
                  >
                    Exhibit A – National Access Service Level Commitments and
                    Support Services
                  </li>
                  <li
                    onClick={() => {
                      setEulaPage({
                        default: false,
                        exhibit_a: false,
                        exhibit_b: true,
                        exhibit_c: false,
                      });
                    }}
                    className="w-fit list-disc text-base  leading-relaxed text-blue-700 hover:cursor-pointer"
                  >
                    Exhibit B – National Access Data Protection Addendum for
                    GDPR Compliance
                  </li>
                  <li
                    onClick={() => {
                      setEulaPage({
                        default: false,
                        exhibit_a: false,
                        exhibit_b: false,
                        exhibit_c: true,
                      });
                    }}
                    className="w-fit list-disc text-base  leading-relaxed text-blue-700 hover:cursor-pointer"
                  >
                    Exhibit C – National Access Authorized Subprocessors
                  </li>
                  <li className="w-fit list-disc text-base  leading-relaxed text-blue-700 hover:cursor-pointer">
                    <a
                      href="https://plaid.com/legal/"
                      target="_blank"
                      className="text-blue-700"
                    >
                      {" "}
                      Exhibit D – Plaid Privacy Policy for Consumers and End
                      User Services Agreement
                    </a>
                  </li>
                </ul>
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                (c) “Authorized User” shall mean an individual subscriber or the
                partners, members, employees, temporary employees, and
                independent contractors of an organization with a subscription
                to the Service who have been added to the account as users.
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                (d) “Confidential Information” shall mean the Content (as
                defined in Section 1(e)) and any information, technical data, or
                know-how considered proprietary or confidential by either party
                to this Agreement including, but not limited to, either party’s
                research, services, inventions, processes, specifications,
                designs, drawings, diagrams, concepts, marketing, techniques,
                documentation, source code, customer information, personally
                identifiable information, pricing information, procedures, menu
                concepts, business and marketing plans or strategies, financial
                information, and business opportunities disclosed by either
                party before or after the Effective Date of this Agreement,
                either directly or indirectly in any form whatsoever, including
                in writing, orally, machine- readable form or through access to
                either party’s premises.
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                (e) “Content” shall mean any information you upload or post to
                the Service and any information provided by you to National
                Access in connection with the Service, including, without
                limitation, information about your Authorized Users or
                Registered Clients, as defined in Section 1(g).
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                (f) “Primary Subscriber” shall mean the Subscriber who initiated
                the Services offered by National Access and is assumed by
                National Access to have the sole authority to administer the
                subscription.
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                (g) “Registered Client” means an individual who has been invited
                to use the client-facing features of the Service in a limited
                capacity as a client of an Authorized User.
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                (h) “Service” shall mean any software or services provided by
                National Access, including but not limited to client financing
                and client intake software.
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                (i) “Subscriber” shall refer to the purchaser of the Services
                provided by National Access and shall also include any present
                or former agent, representative, independent contractor,
                employee, servant, attorney and any entity or person who had
                authority to act on your behalf.
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                (j) “Security Emergency” shall mean a violation by Subscriber of
                this Agreement that (a) could disrupt (i) National Access’s
                provision of the Service; (ii) the business of other subscribers
                to the Service; or (iii) the network or servers used to provide
                the Service; or (b) provides unauthorized third-party access to
                the Service.
              </p>
              <div className="text-base font-bold underline">
                2. Limited License &amp; Use of the Service
              </div>
              <p className="text-base  leading-relaxed text-gray-500">
                2.1 Subscriber is granted a non-exclusive, non-transferable,
                limited license to access and use the Service.
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                2.2 National Access does not review or pre-screen the Content
                and National Access claims no intellectual property rights with
                respect to the Content.
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                2.3 Authorized Users agree not to reproduce, duplicate, copy,
                sell, resell or exploit access to the Service, use of the
                Service, or any portion of the Service, including, but not
                limited to the HTML, Cascading Style Sheet (“CSS”) or any visual
                design elements without the express written permission from
                National Access.
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                2.4 Authorized Users agree not to modify, reverse engineer,
                adapt or otherwise tamper with the Service or modify another
                website so as to falsely imply that it is associated with the
                Service, National Access, or any other software or service
                provided by National Access.
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                2.5 Authorized Users agree that they will not knowingly use the
                Service in any manner which may infringe copyright or
                intellectual property rights or in any manner which is unlawful,
                offensive, threatening, libelous, defamatory, pornographic,
                obscene or in violation of the terms of this Agreement.
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                2.6 Authorized Users agree that they will not knowingly use the
                Service to upload, post, host, or transmit unsolicited bulk
                email “Spam”, short message service “SMS” messages, viruses,
                self-replicating computer programs “Worms” or any code of a
                destructive or malicious nature.
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                2.7 Except for the non-exclusive license granted pursuant to
                this Agreement, Subscriber acknowledges and agrees that all
                ownership, license, intellectual property and other rights and
                interests in and to the Service shall remain solely with
                National Access.
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                2.8 Authorized Users who configure the Service to share or make
                available certain Content to the public, are deemed to
                acknowledge and agree that everyone will have access to the
                Content (“Public Content”). It is the responsibility of the
                Authorized User to determine if the Service being shared is
                appropriate for each Registered User. National Access reserves
                the right, at any time, in its sole discretion, to take any
                action deemed necessary with respect to Public Content that
                violates the terms of this Agreement, including, but not limited
                to, removal of such Public Content.
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                2.9 National Access reserves the right at any time, and from
                time to time, to modify or discontinue, temporarily or
                permanently, any feature associated with the Service, with or
                without notice, except that National Access shall provide
                Subscriber with 30-days’ notice of any modification that
                materially reduces the functionality of the Service. Continued
                use of the Service following any modification constitutes
                Subscriber’s acceptance of the modification.
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                2.10 National Access reserves the right to temporarily suspend
                access to the Service for operational purposes, including, but
                not limited to, maintenance, repairs or installation of
                upgrades, and will endeavor to provide no less than two business
                days’ notice prior to any such suspension. Such notice shall be
                provided to you in advance through by way of notification within
                the Service, email or other notification method deemed
                appropriate by National Access. Further, National Access shall
                endeavor to confine planned operational suspensions with a best
                effort to minimize disruption to the Subscriber but reserves the
                ability to temporarily suspend operations without notice at any
                time to complete necessary repairs. In the event of a temporary
                suspension, National Access will use the same notification
                methods listed in this section to provide updates as to the
                nature and duration of any temporary suspension.
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                2.11 National Access stores all Content on redundant storage
                servers. The Subscriber may elect to, at a regular interval,
                replicate all Content associated with the subscription to a
                third-party storage service (“Escrow Agent”). The replicated
                Content (“Escrowed Data”) will be held under the terms of a
                separate agreement exclusively between the Subscriber and the
                Escrow Agent (“Escrow Agreement”). The Subscriber may also elect
                to replicate all Content associated with the subscription on its
                own storage device.
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                2.12 Subscriber grants to National Access a non-exclusive,
                royalty-free right during Subscriber’s use of the Service, to
                use the Confidential Information for the sole purpose of
                performing National Access’ obligations under the Agreement in
                accordance with the terms of the Agreement. Such rights shall
                include permission for National Access to generate and publish
                aggregate, anonymized reports on system usage and Content trends
                and type, provided they do not conflict with Section 4.1.
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                2.13 National Access uses one codebase for all jurisdictions.
                Subscriber is required, using settings available within the
                Service, to configure the Service for its own jurisdiction and
                to verify that the settings meet the Subscriber’s requirements.
                National Access will highlight known features that may require
                Subscriber review.
              </p>
              <div className="text-base font-bold underline">
                3. Access to the Service
              </div>
              <p className="text-base  leading-relaxed text-gray-500">
                3.1 Subscriber is only permitted to access and use the Service
                if he/she is an Authorized User or a Registered Client.
                Authorized Users are required to provide their full legal name,
                a valid email address, and any other information reasonably
                requested by the Service.
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                3.2 Each Authorized User will be provided with a unique
                identifier to access and use the Service (“Username”). The
                Username shall only be used by the Authorized User to whom it is
                assigned, and shall not be shared with, or used by any other
                person, including other Authorized Users.
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                3.3 The initial Administrator shall be the Primary Subscriber
                with authority to administer the subscription and designate
                additional Authorized Users and/or Administrators. Each
                subscription may designate multiple Authorized Users as
                Administrator. Any Administrator shall be deemed to have the
                authority to manage the subscription and any Authorized Users.
                The Administrator will deactivate an active Username if the
                Administrator wishes to terminate access to the Service for any
                Authorized User.
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                3.4 Administrators are responsible for all use of the Service by
                Authorized Users on the list of active Authorized Users
                associated with their subscription to the Service.
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                3.5 As between National Access and the Subscriber, any Content
                uploaded or posted to the Service remains the property of the
                Subscriber. Upon Cancellation or Termination of Service as
                discussed in Section 10 below, National Access shall only be
                responsible for the return of Content directly to the
                Administrator or a designated Authorized User in the event that
                the Administrator is unable to be reached.
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                3.6 All access to and use of the Service via mechanical,
                programmatic, robotic, scripted or any other automated means not
                provided as part of the Service is strictly prohibited.
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                3.7 Authorized Users are permitted to access and use the Service
                using an Application Program Interface (“API”) subject to the
                following conditions:
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                (a) any use of the Service using an API, including use of an API
                through a third-party product that accesses and uses the
                Service, is governed by these Terms of Service;
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                (b) National Access shall not be liable for any direct,
                indirect, incidental, special, consequential or exemplary
                damages, including but not limited to, damages for loss of
                profits, goodwill, use, data or other intangible losses (even if
                National Access has been advised of the possibility of such
                damages), resulting from any use of an API or third- party
                products that access and use the Service via an API;
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                (c) Excessive use of the Service using an API may result in
                temporary or permanent suspension of access to the Service via
                an API. National Access, in its sole discretion, will determine
                excessive use of the Service via an API, and will make a
                reasonable attempt to warn the Authorized User prior to
                suspension; and
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                (d) National Access reserves the right at any time to modify or
                discontinue, temporarily or permanently, access and use of the
                Service via an API, with or without notice.
              </p>
              <div className="text-base font-bold underline">
                4. Confidentiality
              </div>
              <p className="text-base  leading-relaxed text-gray-500">
                4.1 Each party agrees to treat all Confidential Information as
                confidential and not to use or disclose such Confidential
                Information except as necessary to perform its obligations under
                this Agreement.
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                4.2 National Access and any third-party vendors and hosting
                partners it utilizes to provide the Service shall hold Content
                in strict confidence and shall not use or disclose Content
                except (a) as required to perform their obligations under this
                Agreement; (b) in compliance with Section 7 of this Agreement,
                or (c) as otherwise authorized by you in writing.
              </p>
              <div className="text-base font-bold underline">
                5. Security and Access
              </div>
              <p className="text-base  leading-relaxed text-gray-500">
                5.1 National Access is responsible for providing a secure method
                of authentication and accessing its Service. National Access
                will provide mechanisms that:
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                (a) allow for user password management
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                (b) transmit passwords in a secure format
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                (c) protect passwords entered for purposes of gaining access to
                the Service by utilizing code that follows password management
                best practices.
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                5.2 Subscriber will be responsible for protecting the security
                of usernames and passwords, or any other codes associated to the
                Service, and for the accuracy and adequacy of personal
                information provided to the Service.
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                5.3 Subscriber will implement policies and procedures to prevent
                unauthorized use of usernames and passwords and will promptly
                notify National Access upon suspicion that a username and
                password has been lost, stolen, compromised, or misused.
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                5.4 At all times, National Access, and any third-party vendors
                and hosting partners it utilizes to provide the Service, will:
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                (a) use information security best practices for transmitting and
                storing your Content, adhering to industry standards;
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                (b) employ information security best practices with respect to
                network security techniques, including, but not limited to,
                firewalls, intrusion detection, and authentication protocols,
                vulnerability and patch management;
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                (c) ensure its host facilities maintain industry standards for
                security and privacy; and
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                (d) within thirty (30) days of a request by Subscriber, provide
                Subscriber with a (SOC2 or SOC3) audit report or industry
                standard successor report or a comparable description of its
                security measures in respect of the data center facilities used
                to host the Service and the Content. To obtain such a report,
                Subscriber must enter into an agreement with the third-party
                provider of the report.
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                5.5 National Access shall report to Subscriber, with all
                relevant details (except those which could prejudice the
                security of data uploaded by other customers), any event that
                National Access reasonably believes represents unauthorized
                access to, disclosure of, use of, or damage to Content (a
                “Security Breach”). National Access shall make such report
                within 72 hours after learning of the Security Breach.
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                5.6 In the event of a Security Breach, National Access shall (a)
                cooperate with Subscriber to identify the cause of the breach
                and to identify any affected Content; (b) assist and cooperate
                with Subscriber in investigating and preventing the recurrence
                of the Security Breach; (c) assist and cooperate with Subscriber
                in any litigation or investigation against third parties that
                Subscriber undertake to protect the security and integrity of
                Content; and (d) use commercially reasonable endeavors to
                mitigate any harmful effect of the Security Breach.
              </p>
              <div className="text-base font-bold underline">
                6. EU Data Protection
              </div>
              <p className="text-base  leading-relaxed text-gray-500">
                The parties agree to comply with the provisions of the Data
                Processing Addendum set out in Exhibit B.
              </p>
              <div className="text-base font-bold underline">
                7. Legal Compliance
              </div>
              <p className="text-base  leading-relaxed text-gray-500">
                7.1 National Access maintains that its primary duty is to
                protect the Content to the extent the law allows. National
                Access reserves the right to provide the Confidential
                Information to third parties as required and permitted by law
                (such as in response to a subpoena or court order), and to
                cooperate with law enforcement authorities in the investigation
                of any criminal or civil matter.
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                If National Access is required by law to make any disclosure of
                the Confidential Information that is prohibited or otherwise
                constrained by this Agreement, then National Access will provide
                Subscriber with prompt written notice (to the extent permitted
                by law) prior to such disclosure so that the Subscriber may seek
                a protective order or other appropriate relief. Subject to the
                foregoing sentence, National Access may furnish that portion
                (and only that portion) of the Confidential Information that it
                is legally compelled or otherwise legally required to disclose.
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                7.2 National Access will only accept legal requests for
                production of Content or other Confidential Information through
                the procedures listed on{" "}
                <a href="https://www.natlaccess.com/legal">
                  https://www.natlaccess.com/legal.
                </a>
              </p>
              <div className="text-base font-bold underline">
                8. Managed Backup and Archiving
              </div>
              <p className="text-base  leading-relaxed text-gray-500">
                8.1 National Access’s managed backup services must be designed
                to facilitate restoration of Content to the server or device
                from which the Content originated in the event the primary data
                is lost or corrupted. National Access shall ensure recovery of
                lost or corrupted Content at no cost to you. Following any
                cancellation or termination of Service for any reason,
                Subscriber shall have ninety days to retrieve any and all
                Content.
              </p>
              <div className="text-base font-bold underline">
                9. Payment, Refunds, and Subscription Changes
              </div>
              <p className="text-base  leading-relaxed text-gray-500">
                9.1 Subscribers with paid subscriptions will provide National
                Access with a valid credit card for payment of the applicable
                subscription fees. All subscription fees are exclusive of all
                federal, state, provincial, municipal, or other taxes which
                Subscribers agree to pay based on where the Subscriber is
                located. Invoices will include (i) subscription fees and (ii)
                all applicable sales taxes, as amended from time to time, for
                the jurisdiction in which the Subscriber is located. In the
                event of updated tax rates, National Access will apply the new
                tax rate without notice to the Subscriber. In addition to any
                fees, the Subscriber may still incur charges incidental to using
                the Service, for example, charges for Internet access, data
                roaming, and other data transmission charges.
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                9.2 Subscribers with monthly paying subscriptions will be
                charged upon the expiration of any applicable free trial period.
                Subscriptions cancelled prior to the expiration of any trial
                period, will not be charged. Monthly Subscribers will thereafter
                be charged in advance each 30 days. Annual Subscribers will
                thereafter be charged annually on the anniversary date of the
                initial subscription charge. All charges are final and non-
                refundable, including payments made by Annual Subscribers, setup
                fees, and other professional services charges.
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                Subscribers who purchased setup or professional services, like
                tailored live training, customized forms and documents, or
                migration services, must initiate those services within sixty
                (60) days (Service Window) following their purchase. Absent a
                separate invoice, the date of purchase for setup or professional
                services will be deemed to be the initial date of entry of a
                valid credit card for payment as required in §9.1. Failure of
                the Subscriber to initiate purchased setup or professional
                services within Service Window will result in those services no
                longer being available and no refund will be issued.
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                9.3 No refunds or credits will be issued for partial periods of
                service, upgrade/downgrade refunds, or refunds for periods
                unused with an active subscription, including, but not limited
                to, instances involving the removal of a Subscriber.
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                9.4 There are no charges for cancelling a subscription and
                paying subscriptions cancelled prior to the end of their current
                billing cycle will not be charged again in the following cycle.
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                9.5 The amount charged on the next billing cycle will be
                automatically updated to reflect any changes to the
                subscription, including upgrades or downgrades, and including
                the addition or removal of discounts included for the purchase
                of suite services. Adding Authorized User subscriptions or
                subscription upgrades will trigger prorated charges in the
                current billing cycle. Subscriber authorizes National Access to
                apply updated charge amounts. Subscription changes, including
                downgrades, may result in loss of access to Content, features,
                or an increase or reduction in the amount of available capacity
                for Content provided by the Service.
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                9.6 All prices are subject to change upon notice. Such notice
                may be provided by an e- mail message to the Administrator, or
                in the form of an announcement on the Service.
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                9.7 Subscriber is responsible for paying all taxes associated
                with the subscription to the Service. If National Access has the
                legal obligation to pay or collect taxes for which Subscriber is
                responsible under this section, the appropriate amount shall be
                charged to and paid by Subscriber, unless Subscriber provides
                National Access with a valid tax exemption certificate
                authorized by the appropriate taxing authority.
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                9.8 Any and all payments by or on account of the compensation
                payable under this Agreement shall be made free and clear of and
                without deduction or withholding for any taxes. If the
                Subscriber is required to deduct or withhold any taxes from such
                payments, then the sum payable shall be increased as necessary
                so that, after making all required deductions or withholdings,
                National Access receives an amount equal to the sum it would
                have received had no such deduction or withholding been made.
              </p>
              <div className="text-base font-bold underline">
                10. Cancellation and Termination
              </div>
              <p className="text-base  leading-relaxed text-gray-500">
                10.1 Administrators are solely responsible for canceling
                subscriptions. An Administrator may cancel their subscription at
                any time by accessing the Service and
                visiting https://natlaccess.com/support as applicable. For
                security reasons, cancellations shall only be performed by an
                Administrator using the account cancellation URL within the
                Service. The Administrator may be directed, within the Service,
                to call support to complete the cancellation. Cancellations
                shall not be accepted by any other means.
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                10.2 National Access in its sole discretion has the right to
                suspend or discontinue providing the Service to any Subscriber
                without notice for actions that are (a) in material violation of
                this Agreement and (b) create a Security Emergency.
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                10.3 If (i) Authorized Users use the Service to materially
                violate this Agreement in a way that does not create a Security
                Emergency; (ii) National Access provides Subscriber with
                commercially reasonable notice of this violation; (iii) National
                Access uses commercially reasonable efforts to discuss and
                resolve the violation with Subscriber; and (iv) despite the
                foregoing, the violation is not resolved to National Access’s
                reasonable satisfaction within thirty (30) days of such notice,
                then National Access reserves the right to suspend access to the
                Service.
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                10.4 As required by Section 8 above (“Managed Backup and
                Archiving”), upon cancellation or termination of a subscription,
                Content is made available to the Administrator or a designated
                Authorized User. Following a period of no less than ninety (90)
                days from the cancellation or termination of a subscription, all
                Content associated with such subscription will be irrevocably
                deleted from the Service. All Escrowed Data, if any, will
                continue to remain available for a period of six months upon
                cancellation or termination of a subscription in accordance with
                the terms of the Escrow Agreement.
              </p>
              <div className="text-base font-bold underline">
                11. Limitation of Liability
              </div>
              <p className="text-base  leading-relaxed text-gray-500">
                11.1 Except in the case of a violation by National Access of its
                obligations under Section 4 above (“Confidentiality”), Section 5
                above (“Security and Access”), and Section 8 above (“Managed
                Backup and Archiving”), and except as provided in Section 13.2
                below (“Indemnification”), National Access shall not be liable
                for and Subscriber waives the right to claim any loss, injury,
                claim, liability or damage of any kind resulting in any way from
                the Services provided to Subscriber by National Access.
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                11.2 Subscriber agrees that the liability of National Access
                arising out of any claim in any way connected with the service
                will not exceed the total amount you have paid for the service
                pursuant to the agreement within the six-month period before the
                date the claim arose. Subscriber further agrees that National
                Access is not and will not be liable for any special, indirect,
                incidental, or consequential damages of any kind whatsoever
                (including without limitation, attorney fees) relating to this
                agreement. These disclaimers apply regardless of the form of
                action, whether in contract, tort (including negligence), strict
                liability or otherwise, whether those damages are foreseeable
                and whether National Access has been advised of the possibility
                of those damages. These disclaimers are not applicable to the
                indemnification obligation set forth in section 13.2. Each
                provision of this agreement that provides for a limitation of
                liability, disclaimer of damages, or exclusion of damages is to
                allocate the risks of this agreement between the parties. This
                allocation is reflected in the pricing offered by National
                Access to subscriber and is an essential element of the basis of
                the bargain between the parties. Each of these provisions is
                severable from and independent of all other provisions of this
                agreement.
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                11.3 Subscriber will solely be responsible for any damage and/or
                loss of Content contained in Subscriber’s technology which
                occurs as a result of Subscriber’s electronic equipment and/or
                Subscriber’s computer system.
              </p>
              <div className="text-base font-bold underline">
                12. Disclaimer of Warranties
              </div>
              <p className="text-base  leading-relaxed text-gray-500">
                12.1 National Access hereby disclaims all warranties of any
                kind, whether express, implied or statutory, including, but not
                limited to the implied warranties of merchantability, fitness
                for a particular purpose, title and non-infringement of
                third-party rights with respect to any services provided by
                National Access. Nothing in this section 12.1 shall modify
                National Access’s obligation to indemnify subscriber as required
                by section 13.2(a) of this agreement ('Indemnification').
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                12.2 National Access makes no warranty that its services when
                provided to Subscriber in digital or electronic format will be
                compatible with Subscriber computer and/or other equipment, or
                that these Services will be secure or error free. Nor does
                National Access make any warranty as to any results that may be
                obtained from the use of the Service. Nothing in this Section
                12.2 shall modify National Access’s obligations under Section 4
                above (“Confidentiality”) or Section 5 above (“Security and
                Access”) or National Access’s obligation to indemnify you as
                required by Section 13.2(b) of this Agreement
                (“Indemnification”).
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                12.3 National Access hereby disclaims all warranties of any kind
                related to Subscriber’s hardware or software beyond the
                warranties provided by the manufacturer of Subscriber’s hardware
                or software.
              </p>
              <div className="text-base font-bold underline">
                13. Indemnification
              </div>
              <p className="text-base  leading-relaxed text-gray-500">
                13.1 Subscriber hereby agrees to indemnify and hold harmless
                National Access from and against any claim, action, proceeding,
                loss, liability, judgment, obligation, penalty, damage, cost or
                expense, including attorneys’ fees, which arise from or relate
                to the following:
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                a. Authorized Users’ breach of any obligation stated in this
                Agreement, and
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                b. Authorized Users’ negligent acts or omissions.
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                National Access will provide prompt notice to Subscriber of any
                indemnifiable event or loss. Subscriber will undertake, at
                Subscriber’s own cost, the defense of any claim, suit or
                proceeding with counsel reasonably acceptable to National
                Access. National Access reserves the right to participate in the
                defense of the claim, suit, or proceeding, at National Access’
                expense, with counsel of National Access’ choosing.
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                13.2 National Access shall defend, indemnify and hold Subscriber
                harmless against any loss, damage or costs (including reasonable
                attorneys’ fees) in connection with claims, demands, suits, or
                proceedings (“Claims”) made or brought against Subscriber by a
                third-party
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                a. alleging that the Service, or use of the Service as
                contemplated hereunder, infringes a copyright, a U.S. patent
                issued as of the date of final execution of this Agreement, or a
                trademark of a third party or involves the misappropriation of
                any trade secret of a third party; provided, however, that
                Subscriber:
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                (a) promptly gives written notice of the Claim to National
                Access (provided, however, that the failure to so notify shall
                not relieve National Access of its indemnification obligations
                unless National Access can show that it was materially
                prejudiced by such delay and then only to the extent of such
                prejudice); (b) gives National Access sole control of the
                defense and settlement of the Claim (provided that National
                Access may not settle any Claim unless it unconditionally
                releases Subscriber of all liability); and (c) provides to
                National Access, at National Access’s cost, all reasonable
                assistance. National Access shall not be required to indemnify
                Subscriber in the event of: (x) modification of the Service by
                Subscriber in conflict with Subscriber’s obligations or as a
                result of any prohibited activity as set forth herein to the
                extent that the infringement or misappropriation would not have
                occurred but for such modification; (y) use of the Service in
                combination with any other product or service not provided by
                National Access to the extent that the infringement or
                misappropriation would not have occurred but for such use; or
                (z) use of the Service in a manner not otherwise contemplated by
                this Agreement to the extent that the infringement or
                misappropriation would not have occurred but for such use; or
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                b. arising out of or related to a violation by National Access
                of its obligations under Section 4 above (“Confidentiality”) or
                Section 5 above (“Security and Access”).
              </p>
              <div className="text-base font-bold underline">
                14. NA Payments
              </div>
              <p className="text-base  leading-relaxed text-gray-500">
                14.1 National Access offers an optional product which allows you
                to process payments and other payment related services (“NA
                Payments”). In addition to Services related to NA Payments
                provided by National Access, the payment processing component of
                NA Payments is provided by the third-party payment processing
                provider Authorize.net, or its affiliates (“Payment
                Processor”). This payment processing is a Third Party Service
                (as defined below) and is subject to the 
                <a href="https://account.authorize.net/interfaces/Legal/Merchant/Agreements/GW/1.0/agreement-authnet.htm">
                  Authorize.net Payment Gateway Merchant Service Agreement
                </a>{" "}
                 including  agreements and other documents referred to in such
                agreement (collectively, the “Payment Processing Agreement”), as
                modified by the Payment Processor in accordance with the Payment
                Processing Agreement. By enrolling in and continuing to use NA
                Payments, Subscriber agrees to be bound by this Section 14 and
                the applicable terms of the Payment Processing Agreement.
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                14.2 NA Payments are subject to certain fees and surcharges
                communicated to Subscriber during the enrollment process. As a
                condition of National Access enabling NA Payments, Subscriber
                agrees to provide National Access with accurate and complete
                information related to Subscriber’s use of NA Payments and
                authorizes National Access to share such information and
                transaction information with the Payment Processor  pursuant to
                our Privacy Policy. Transaction information from payors will be
                collected for processing of transactions by the Payment
                Processor in accordance with the Payment Processor’s terms
                applicable to the payments. To the extent permitted by law,
                National Access may collect any payment obligations Subscriber
                owes under this Agreement by deducting the corresponding amounts
                from funds payable to Subscriber arising from the settlement of
                card transactions through NA Payments. Fees will be assessed at
                the time a transaction is processed and will be first deducted
                from the funds received for such transactions. If the settlement
                amounts are not sufficient to meet Subscriber’s obligations,
                National Access may charge or debit the bank account or credit
                card registered in Subscriber’s account for any amounts owed
                (and you agree to execute such additional directions in writing
                to permit us to do so, if required). In the event a payment
                chargeback or dispute occurs, Subscriber may be charged a
                dispute fee per occurrence by National Access. This Section does
                not permit National Access to debit a Subscriber trust account
                for any reason. In addition to the amount due, delinquent
                accounts may be charged fees that are incidental to the
                collection of delinquent accounts and chargebacks including, but
                not limited to, collection fees, convenience fees, legal fees
                and expenses, costs of any arbitration or court proceeding,
                collection agency fees, any applicable interest and third-party
                charges. Subscriber hereby explicitly agrees that all
                communication in relation to delinquent accounts will be made by
                electronic mail or by phone, at addresses and numbers provided
                to National Access. Such communication may be made by National
                Access or by anyone on its behalf, including, but not limited
                to, a third-party collection agent. Subscriber will comply with
                the terms and conditions of any applicable merchant agreements
                and all applicable card network rules, policies, laws and
                regulations, at all times while using NA Payments.  An
                Administrator may cancel the use of NA Payments at any time by
                visiting here.
              </p>
              <div className="text-base font-bold underline">
                15. Miscellaneous
              </div>
              <p className="text-base  leading-relaxed text-gray-500">
                15.1 Technical support and training are available to Authorized
                Users with active subscriptions, and is available by telephone,
                email or electronic support ticket, as defined at 
                https://natlaccess.com/support  and in Exhibit A.
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                15.2 Subscriber acknowledges and agrees that National Access may
                use third party vendors and hosting partners to provide the
                necessary hardware, software, networking, storage, and related
                technology required to run the Service.
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                15.3 The Services may allow you to access or use or integrate
                with third party providers of products and services (“Third
                Party Services”). Such Third-Party Services are not “Services”
                under this Agreement and are not subject to any terms related to
                Services, including related warranties, indemnities, service
                commitments or other obligations. The availability of any
                Third-Party Services through the Services does not imply
                National Access’s endorsement of or affiliation with the
                provider. Access to and use of any Third- Party Services are
                subject to the separate terms and conditions required by the
                providers of the Third-Party Services. National Access does not
                control the Third-Party Services and will have no liability to
                Subscriber in connection with any Third-Party Service. National
                Access has no obligation to monitor or maintain any Third-Party
                Service and may replace, disable or restrict access to any
                Third-Party Service or cancel related integrations at any time,
                without notice. The calculation of downtime pursuant to Exhibit
                A does not include the unavailability of any integration to a
                Third-Party Service.By using or enabling any third-party
                service, subscriber expressly acknowledges that any liability
                and remedies related to a third-party service are wholly
                governed by the applicable third-party agreement and National
                Access disclaims all liability related to such third-party
                service.
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                15.4 Subscriber acknowledges the risk that information and the
                Content stored and transmitted electronically through the
                Service may be intercepted by third parties. Subscriber agrees
                to accept that risk and will not hold National Access liable for
                any loss, damage, or injury resulting from the interception of
                information. The Content is stored securely and encrypted. Only
                National Access, with strict business reasons, may access and
                transfer the Content and only to provide Subscriber with the
                Service. National Access will make reasonable efforts to provide
                notice to Subscriber prior to such access and transfer. National
                Access’ actions will comply with its obligations under Sections
                4 and 5 of this Agreement.
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                15.5 The failure of either party to enforce any provision hereof
                shall not constitute or be construed as a waiver of such
                provision or of the right to enforce it at a later time.
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                15.6 This Agreement constitutes the entire agreement between
                Authorized Users and National Access and governs Authorized
                Users use of the Service, superseding any prior agreements
                between Authorized Users and National Access (including, but not
                limited to, any prior versions of this agreement).
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                15.7 National Access reserves the right to amend this Agreement.
                In the event of material changes to the Agreement, National
                Access will notify Subscribers, by email, or by other reasonable
                means of these changes prior to their enactment. Continued use
                of the Service by the Subscriber after reasonable notice will be
                considered acceptance of any new terms.
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                15.8 Neither party may assign any of its rights or obligations
                hereunder, whether by operation of law or otherwise, without the
                prior written consent of the other party (which consent shall
                not be unreasonably withheld). Notwithstanding the foregoing,
                either party may assign this Agreement in its entirety without
                consent of the other party in connection with a merger,
                acquisition, corporate reorganization, or sale of all or
                substantially all of its assets provided the assignee has agreed
                to be bound by all of the terms of this Agreement. Any attempt
                by a party to assign its rights or obligations under this
                Agreement in breach of this Section shall be void and of no
                effect.
              </p>
              <p className="text-base  leading-relaxed text-gray-500">
                15.9 Governing Law and Venue. This Agreement and your
                relationship with National Access shall be governed exclusively
                by, and will be enforced, construed, and interpreted exclusively
                in accordance with, the laws applicable in the State of Utah,
                and shall be considered to have been made and accepted in the
                State of Utah, without regard to its conflict of law provisions.
                All disputes under this Agreement will be resolved by the courts
                of Salt Lake City, Utah, and Subscribers consent to the
                jurisdiction of and venue in such courts and waive any objection
                as to inconvenient forum. In any action or proceeding to enforce
                rights under this Agreement, the prevailing party shall be
                entitled to recover costs and legal fees.
              </p>
            </div>
          ) : eulaPage.exhibit_a ? (
            <>
              <div className="text-start p-6 space-y-6">
                <div className="flex items-center gap-5">
                  <span
                    className="hover:cursor-pointer"
                    onClick={() => {
                      setEulaPage({
                        default: true,
                        exhibit_a: false,
                        exhibit_b: false,
                        exhibit_c: false,
                      });
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      fill="currentColor"
                      class="bi bi-arrow-left-circle"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
                      />
                    </svg>
                  </span>
                  <div className="text-xl text-center font-bold">Exhibit A</div>
                </div>
                <div className="text-base font-bold underline">
                  National Access Service Level Commitments and Support Services
                </div>

                <p className="text-base  leading-relaxed text-gray-500">
                  Commencing on the date the Service to the Subscriber commences
                  (the “Subscription Term”), National Access will provide
                  Service Level Commitments (“SLC”) Credits (defined in Section
                  3 below) and Support Services in accordance with the SLC and
                  Support Services Terms as defined herein. In the event of any
                  conflict between the Agreement and the Service Level
                  Commitment and Support Services Terms, the SLC and Support
                  Services Terms will prevail. The SLC and Support Services
                  incorporate the definitions set forth in Section 1 of the
                  National Access User License Agreement.
                </p>
                <div className="text-base font-bold">
                  1. Exhibit Definitions
                </div>

                <p className="text-base  leading-relaxed text-gray-500">
                  “Subscriber Core Group” means Subscriber’s employees who have
                  been trained on the Service and who are familiar with
                  Subscriber’s business practices.
                </p>
                <p className="text-base  leading-relaxed text-gray-500">
                  “Subscriber User Community” means all users who input, extract
                  or view data in the Service, including all Registered Clients.
                </p>
                <p className="text-base  leading-relaxed text-gray-500">
                  “Downtime” means any period, greater than ten minutes, within
                  the Scheduled Available Time during which the Subscriber is
                  unable to access or use the Service because of an Error (as
                  defined below), excluding (i) any such period that occurs
                  during any Scheduled Downtime and/or Recurring Downtime (as
                  defined below), or (ii) document preview, search, FTP or sync
                  functions of the Service.
                </p>
                <p className="text-base  leading-relaxed text-gray-500">
                  “Error(s)” means the material failure of the Service to
                  conform to its published functional specifications.
                </p>
                <p className="text-base  leading-relaxed text-gray-500">
                  “Procedural Issues” means those issues that are to be
                  addressed by Subscriber through adjustment of a specific
                  business process to accomplish work in the Service.
                </p>
                <p className="text-base  leading-relaxed text-gray-500">
                  “Recurring Downtime” means 4 hours per month on the third
                  Saturday of the month from 12:00 A.M. to 4:00 A.M. PST.
                </p>
                <p className="text-base  leading-relaxed text-gray-500">
                  “Request” means a modification to the Service outside of the
                  scope of the functional specifications.
                </p>
                <p className="text-base  leading-relaxed text-gray-500">
                  “Scheduled Available Time” means 24 hours a day, 7 days a
                  week.
                </p>
                <p className="text-base  leading-relaxed text-gray-500">
                  “Scheduled Downtime” means the time period identified by
                  National Access in which it intends to perform any planned
                  upgrades and/or maintenance on the Service or related systems
                  and any overrun beyond the planned completion time.
                </p>
                <p className="text-base  leading-relaxed text-gray-500">
                  “Uptime Percentage” means the total number of minutes of
                  Scheduled Available Time for a calendar month minus the number
                  of minutes of Downtime suffered in such calendar month,
                  divided by the total number of minutes of Scheduled Available
                  Time in such calendar month. Uptime Percentage will be
                  calculated by National Access solely using records and tools
                  available to National Access.
                </p>
                <p className="text-base  leading-relaxed text-gray-500">
                  “User Administration Support” means issues that impact the
                  usability of the Service and are addressable through the
                  adjustment of Registered Client’s access privileges, processes
                  or procedures.
                </p>
                <div className="text-base font-bold">
                  2. Scope of Service Level Commitments
                </div>

                <p className="text-base  leading-relaxed text-gray-500">
                  National Access’s obligations do not extend to Errors or other
                  issues caused by:
                </p>
                <p className="text-base  leading-relaxed text-gray-500">
                  1. any modification of the Service made by any person other
                  than National Access;
                </p>
                <p className="text-base  leading-relaxed text-gray-500">
                  2. any third-party hardware or software used by Subscriber or
                  any Registered Clients except as otherwise provided in the
                  then current Documentation;
                </p>
                <p className="text-base  leading-relaxed text-gray-500">
                  3. the improper operation of the Service by Subscriber or
                  Registered Clients;
                </p>
                <p className="text-base  leading-relaxed text-gray-500">
                  4. the accidental or deliberate damage to, or intrusion or
                  interference with the Service;
                </p>
                <p className="text-base  leading-relaxed text-gray-500">
                  5. the use of the Service other than in accordance with any
                  user Documentation or the reasonable instructions of National
                  Access;
                </p>
                <p className="text-base  leading-relaxed text-gray-500">
                  6. ongoing test or training instances of the Service provided
                  to Subscriber; or
                </p>
                <p className="text-base  leading-relaxed text-gray-500">
                  7. services, circumstances or events beyond the reasonable
                  control of National Access, including, without limitation, any
                  force majeure events, the performance and/or availability of
                  local ISPs employed by Subscriber, or any network beyond the
                  demarcation or control of National Access.
                </p>
                <div className="text-base font-bold">
                  3. Scheduled Downtime and Guaranteed Uptime
                </div>

                <p className="text-base  leading-relaxed text-gray-500">
                  National Access will use commercially reasonable efforts to
                  provide at least 24 hours’ prior notice before undertaking any
                  Scheduled Downtime. Commencing on the effective date of the
                  applicable Subscription Term, in the event the Service
                  experiences an Uptime Percentage of less than 99.9% in any
                  calendar month, National Access will provide to Subscriber a
                  credit (“SLC Credit”) equal to the credit percentage
                  identified in the table SLC Credits table below multiplied by
                  the Subscriber’s fees paid to National Access for the Service
                  that are attributable to such month (calculated on a straight
                  line pro-rated basis with respect to any fees paid in
                  advance). Subscriber will submit a written SLC Credit request
                  to National Access in writing within 30 days of such Downtime.
                  The SLC Credit is Subscriber’s sole and exclusive remedy for
                  any failure by National Access to meet any performance
                  obligations pertaining to the Service, including, without
                  limitation, any support obligations except as provided in the
                  User License Agreement.
                </p>
                <p className="text-base  leading-relaxed text-gray-500">
                  National Access reserves the right to temporarily suspend
                  Subscriber’s or a Registered Client’s access to the National
                  Access Service as set out in the User License Agreement. Any
                  such suspensions based on repairs, technical problems, outages
                  or maintenance services will be subject to the Service Level
                  Commitments.
                </p>
                <div className="text-base font-bold">SLC Credits Table</div>

                <table class="border-collapse border border-slate-400">
                  <thead>
                    <tr>
                      <th class="border border-slate-300 p-1">
                        Uptime Percentage
                      </th>
                      <th class="border border-slate-300 p-1">
                        Credit Percentage
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="border border-slate-300 p-1 text-center">
                        Equal to or greater than 98% but less than 99.9%
                      </td>
                      <td class="border border-slate-300 p-1 text-center">
                        10%
                      </td>
                    </tr>
                    <tr>
                      <td class="border border-slate-300 p-1 text-center">
                        Less than 98%
                      </td>
                      <td class="border border-slate-300 p-1 text-center">
                        25%
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="text-base font-bold">
                  4. Availability of SLC Credits
                </div>

                <p className="text-base  leading-relaxed text-gray-500">
                  Subscribers who are past due on any payments owed to National
                  Access are not eligible to receive SLC Credits. National
                  Access will issue SLC Credits, as determined in its sole
                  discretion, either on future billing cycles or as a refund
                  against annual fees paid. In order to receive any SLC Credit,
                  Subscriber must notify National Access within 30 days from the
                  time Subscriber becomes eligible to receive a SLC Credit.
                  Failure to comply with this requirement will forfeit
                  Subscriber’s right to receive a SLC Credit. In no event will
                  the total amount of SLC Credits if any, exceed the fees paid
                  by Subscriber for the corresponding month.
                </p>
                <div className="text-base font-bold">5. Support Services</div>

                <p className="text-base  leading-relaxed text-gray-500">
                  National Access will provide support services to assist
                  Subscriber in resolving Errors (“Support Services”). Support
                  Services do not include (a) physical installation or removal
                  of the API and any Documentation; (b) visits to Subscriber’s
                  site; (c) any electrical, mechanical or other work with
                  hardware, accessories or other devices associated with the use
                  of the Service; (d) any work with any third party equipment,
                  software or services; (e) any professional services
                  (“Professional Services”) associated with the Service,
                  including, without limitation, any custom development, or data
                  modeling.
                </p>
                <p className="text-base  leading-relaxed text-gray-500">
                  National Access will provide email and/or phone support as
                  specified at natlaccess.com/support, excluding National Access
                  corporate holidays and national Canadian, Irish, and U.S.
                  holidays except where noted.
                </p>
              </div>
            </>
          ) : eulaPage.exhibit_b ? (
            <>
              <div className="text-start p-6 space-y-6">
                <div className="flex items-center gap-5">
                  <span
                    className="hover:cursor-pointer"
                    onClick={() => {
                      setEulaPage({
                        default: true,
                        exhibit_a: false,
                        exhibit_b: false,
                        exhibit_c: false,
                      });
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      fill="currentColor"
                      class="bi bi-arrow-left-circle"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
                      />
                    </svg>
                  </span>
                  <div className="text-xl text-center font-bold">Exhibit B</div>
                </div>
                <div className="text-base font-bold underline">
                  National Access Data Protection Addendum for GDPR Compliance
                </div>
                <div className="text-base font-bold">
                  National Access Data Protection Addendum
                </div>

                <p className="text-base  leading-relaxed text-gray-500">
                  To the extent that National Access Processes any Subscriber
                  Personal Data (each as defined below) and (i) the Subscriber
                  Personal Data relates to individuals located in the EEA; or
                  (ii) Subscriber is established in the EEA or UK, the
                  provisions of this Data Processing Addendum (“DPA”) shall
                  apply to the processing of such Subscriber Personal Data. In
                  the event of any conflict between the remainder of the
                  Agreement and the DPA, the DPA will prevail.
                </p>
                <div className="text-base font-bold">1. Definitions</div>
                <p className="text-base  leading-relaxed text-gray-500">
                  1.1. The following capitalized terms used in this DPA shall be
                  defined as follows:
                </p>
                <p className="text-base  leading-relaxed text-gray-500">
                  (a) “Controller” has the meaning given in the GDPR.
                </p>
                <p className="text-base  leading-relaxed text-gray-500">
                  (b) “Data Protection Laws” means the EU General Data
                  Protection Regulation 2016/679 (“GDPR“) or the UK General Data
                  Protection Regulation (“UK GDPR”), tailored by the Data
                  Protection Act 2018, any applicable national implementing
                  legislation in each case as amended, replaced or superseded
                  from time to time, and all applicable legislation protecting
                  the fundamental rights and freedoms of persons and their right
                  to privacy with regard to the Processing of Subscriber
                  Personal Data.
                </p>
                <p className="text-base  leading-relaxed text-gray-500">
                  (c) “Data Subject” has the meaning given in the GDPR.
                </p>
                <p className="text-base  leading-relaxed text-gray-500">
                  (d) “European Economic Area” or “EEA” means the Member States
                  of the European Union together with Iceland, Norway, and
                  Liechtenstein.
                </p>
                <p className="text-base  leading-relaxed text-gray-500">
                  (e) “Processing” has the meaning given in the GDPR, and
                  “Process” will be interpreted accordingly.
                </p>
                <p className="text-base  leading-relaxed text-gray-500">
                  (f) “Processor” has the meaning given in the GDPR.
                </p>
                <p className="text-base  leading-relaxed text-gray-500">
                  (g) “Security Incident” means any confirmed accidental or
                  unlawful destruction, loss, alteration, unauthorised
                  disclosure of, or access to, any Subscriber Personal Data.
                </p>
                <p className="text-base  leading-relaxed text-gray-500">
                  (h) “Standard Contractual Clauses” means the Standard
                  Contractual Clauses (processors) approved by European
                  Commission Decision (EU) 2021/914 of 4 June 2021 or any
                  subsequent version thereof released by the European Commission
                  (which will automatically apply).
                </p>
                <p className="text-base  leading-relaxed text-gray-500">
                  The Standard Contractual Clauses are applicable to the extent
                  they reference Module Two (Controller-to-Processor).
                </p>
                <p className="text-base  leading-relaxed text-gray-500">
                  When (i) the Subscriber Personal Data relates to individuals
                  located in the UK; or (ii) Subscriber is established in the
                  UK, the parties agree to the Mandatory Clauses of the Approved
                  Addendum, being the template Addendum B.1.0 issued by the ICO
                  and laid before Parliament in accordance with s119A of the
                  Data Protection Act 2018 on 2 February 2022, as it is revised
                  under Section18 of those Mandatory Clauses.
                </p>
                <p className="text-base  leading-relaxed text-gray-500">
                  (i) “Subprocessor” means any Processor engaged by National
                  Access who agrees to receive from National Access Subscriber
                  Personal Data.
                </p>
                <p className="text-base  leading-relaxed text-gray-500">
                  (j) “Subscriber Personal Data” means the “personal data” (as
                  defined in the GDPR) described in the Annex and any other
                  personal data contained in the Content or that National Access
                  processes on Subscriber’s behalf in connection with the
                  provision of the Service.
                </p>
                <p className="text-base  leading-relaxed text-gray-500">
                  (k) “Supervisory Authority” has the meaning given in the GDPR.
                </p>
                <p className="text-base  leading-relaxed text-gray-500">
                  (l) “United Kingdom” or “UK” means the country of the United
                  Kingdom.
                </p>

                <div className="text-base font-bold">2. Data Processing</div>

                <p className="text-base  leading-relaxed text-gray-500">
                  2.1. The Parties acknowledge and agree that for the purpose of
                  the Data Protection Laws, the Subscriber is the Controller and
                  National Access is the Processor.
                </p>
                <p className="text-base  leading-relaxed text-gray-500">
                  2.2 Instructions for Data Processing. National Access will
                  only Process Subscriber Personal Data in accordance with
                  Subscriber’s written instructions. The parties acknowledge and
                  agree that the Agreement (subject to any changes to the
                  Service agreed between the parties) and this DPA shall be
                  Subscriber’s complete and final instructions to National
                  Access in relation to the processing of Subscriber Personal
                  Data.
                </p>
                <p className="text-base  leading-relaxed text-gray-500">
                  2.3. Processing outside the scope of this DPA or the Agreement
                  will require prior written agreement between Subscriber and
                  National Access on additional instructions for Processing.
                </p>
                <p className="text-base  leading-relaxed text-gray-500">
                  2.4. Required consents. Where required by applicable Data
                  Protection Laws, Subscriber will ensure that it has
                  obtained/will obtain all necessary consents and complies with
                  all applicable requirements under Data Protection Laws for the
                  Processing of Subscriber Personal Data by National Access in
                  accordance with the Agreement.
                </p>
                <div className="text-base font-bold">
                  3. Transfer of Personal Data
                </div>
                <p className="text-base  leading-relaxed text-gray-500">
                  3.1. Authorised Subprocessors. Subscriber agrees that National
                  Access may use Subprocessors listed to Process Subscriber
                  Personal Data. The current list of Subprocessors may be
                  accessed here: Exhibit C.
                </p>
                <p className="text-base  leading-relaxed text-gray-500">
                  3.2. As per Clause 9(a), Module 2, OPTION 2 of the Standard
                  Contractual Clauses, Subscriber agrees that National Access
                  may use subcontractors to fulfil its contractual obligations
                  under the Agreement. National Access shall notify Subscriber
                  from time to time of the identity of any Subprocessors
                  engaged. If Subscriber (acting reasonably) objects to a new
                  Subprocessor on grounds related to the protection of
                  Subscriber Personal Data only, then without prejudice to any
                  right to terminate the Agreement, Subscriber may request that
                  National Access move the Subscriber Personal Data to another
                  Subprocessor and National Access shall, within a reasonable
                  time following receipt of such request, use reasonable
                  endeavours to ensure that the original Subprocessor does not
                  Process any of the Subscriber Personal Data. If it is not
                  reasonably possible to use another Subprocessor, and
                  Subscriber continues to object for a legitimate reason, either
                  party may terminate the Agreement on thirty (30) days written
                  notice. If Subscriber does not object within thirty (30) days
                  of receipt of the notice, Subscriber is deemed to have
                  accepted the new Subprocessor.
                </p>
                <p className="text-base  leading-relaxed text-gray-500">
                  3.3. Save as set out in clauses 3.1 and 3.2, National Access
                  shall not permit, allow or otherwise facilitate Subprocessors
                  to Process Subscriber Personal Data without Subscriber’s prior
                  written consent and unless National Access: (a) enters into a
                  written agreement with the Subprocessor which imposes
                  equivalent obligations on the Subprocessor with regard to
                  their Processing of Subscriber Personal Data, as are imposed
                  on National Access under this DPA; and (b) shall at all times
                  remain responsible for compliance with its obligations under
                  the DPA and will be liable to Subscriber for the acts and
                  omissions of any Subprocessor as if they were National
                  Access’s acts and omissions.
                </p>
                <p className="text-base  leading-relaxed text-gray-500">
                  3.4. International Transfers of Subscriber Personal Data.
                  National Access commits to Processing Subscriber Personal Data
                  within the EEA. To the extent that the Processing of
                  Subscriber Personal Data by National Access involves the
                  export of such Subscriber Personal Data to a third party in a
                  country or territory outside the EEA, such export shall be:
                </p>
                <p className="text-base  leading-relaxed text-gray-500">
                  (a) to a country or territory ensuring an adequate level of
                  protection for the rights and freedoms of Data Subjects as
                  determined by the European Commission;
                </p>
                <p className="text-base  leading-relaxed text-gray-500">
                  (b) to a third party that is a member of a compliance scheme
                  recognised as offering adequate protection for the rights and
                  freedoms of Data Subjects as determined by the European
                  Commission; or
                </p>
                <p className="text-base  leading-relaxed text-gray-500">
                  (c) governed by the Standard Contractual Clauses between the
                  Subscriber as exporter and such third party as importer. For
                  this purpose, the Subscriber appoints National Access as its
                  agent with the authority to complete and enter into the
                  Standard Contractual Clauses as agent for the Subscriber on
                  its behalf.
                </p>
                <div className="text-base font-bold">
                  4. Data Security, Audits, and Security Notifications
                </div>
                <p className="text-base  leading-relaxed text-gray-500">
                  4.1 National Access Security Obligations. National Access will
                  implement and maintain appropriate technical and
                  organizational security measures to ensure a level of security
                  appropriate to the risk, including as appropriate, the
                  measures referred to in Article 32(1) of the GDPR.
                </p>
                <p className="text-base  leading-relaxed text-gray-500">
                  4.2 Upon Subscriber’s reasonable request, National Access will
                  make available all information reasonably necessary to
                  demonstrate compliance with this DPA.
                </p>
                <p className="text-base  leading-relaxed text-gray-500">
                  4.3 Security Incident Notification. If National Access becomes
                  aware of a Security Incident, National Access will (a) notify
                  Subscriber of the Security Incident within 72 hours, (b)
                  investigate the Security Incident and provide Subscriber (and
                  any law enforcement or regulatory official) with reasonable
                  assistance as required to investigate the Security Incident.
                </p>
                <p className="text-base  leading-relaxed text-gray-500">
                  4.4 National Access Employees and Personnel. National Access
                  will treat the Subscriber Personal Data as confidential, and
                  shall ensure that any employees or other personnel have agreed
                  in writing to protect the confidentiality and security of
                  Subscriber Personal Data.
                </p>
                <p className="text-base  leading-relaxed text-gray-500">
                  4.5 Audits. National Access will, upon Subscriber’s reasonable
                  request and at Subscriber’s expense, allow for and contribute
                  to audits, including inspections, conducted by Subscriber (or
                  a third party auditor on Subscriber’s behalf and mandated by
                  Subscriber) provided (i) such audits or inspections are not
                  conducted more than once per year (unless requested by a
                  Supervisory Authority); (ii) are conducted only during
                  business hours; (iii) are conducted in a manner that causes
                  minimal disruption to National Access’s operations and
                  business; and (iv) Following completion of the audit, upon
                  request, Subscriber will promptly provide National Access with
                  a complete copy of the results of that audit.
                </p>
                <div className="text-base font-bold">
                  5. Access Requests and Data Subject Rights
                </div>
                <p className="text-base  leading-relaxed text-gray-500">
                  5.1 Data Subject Rights. Where applicable, and taking into
                  account the nature of the Processing, National Access will use
                  reasonable endeavours to assist Subscriber by implementing
                  appropriate technical and organisational measures, insofar as
                  this is possible, for the fulfilment of Subscriber’s
                  obligation to respond to requests for exercising Data Subject
                  rights laid down in the Data Protection Laws.
                </p>
                <div className="text-base font-bold">
                  6. Data Protection Impact Assessment and Prior Consultation
                </div>
                <p className="text-base  leading-relaxed text-gray-500">
                  6.1 To the extent required under applicable Data Protection
                  Laws, National Access will provide Subscriber with reasonably
                  requested information regarding its Service to enable
                  Subscriber to carry out data protection impact assessments or
                  prior consultations with any Supervisory Authority, in each
                  case solely in relation to Processing of Subscriber Personal
                  Data and taking into account the nature of the Processing and
                  information available to National Access.
                </p>
                <div className="text-base font-bold">7. Termination</div>
                <p className="text-base  leading-relaxed text-gray-500">
                  7.1 Deletion or return of data. Subject to 7.2 below, National
                  Access will, at Subscriber’s election and within 90 (ninety)
                  days of the date of termination of the Agreement:
                </p>
                <p className="text-base  leading-relaxed text-gray-500">
                  (a) make available for retrieval all Subscriber Personal Data
                  Processed by National Access (and delete all other copies of
                  Subscriber Personal Data Processed by National Access
                  following such retrieval); or
                </p>
                <p className="text-base  leading-relaxed text-gray-500">
                  (b) delete the Subscriber Personal Data Processed by us.
                </p>
                <p className="text-base  leading-relaxed text-gray-500">
                  7.2 National Access and its Subprocessors may retain
                  Subscriber Personal Data to the extent required by applicable
                  laws and only to the extent and for such period as required by
                  applicable laws and always provided that National Access
                  ensures the confidentiality of all such Subscriber Personal
                  Data and shall ensure that such Subscriber Personal Data is
                  only Processed as necessary for the purpose(s) specified in
                  the applicable laws requiring its storage and for no other
                  purpose.
                </p>
                <div className="text-base font-bold">8. Governing law</div>
                <p className="text-base  leading-relaxed text-gray-500">
                  8.1 This DPA shall be governed by, and construed in accordance
                  with the laws of Ireland. Each of the parties irrevocably
                  submits for all purposes (including any non- contractual
                  disputes or claims) to the non-exclusive jurisdiction of the
                  courts in Ireland. For Standard Contractual Clauses Clause 17
                  OPTION 1 and Clause 18, the parties agree to the laws and
                  courts of Ireland.
                </p>
                <div className="text-base font-bold">Annex</div>
                <div className="text-base font-bold">
                  Details of the Processing of Subscriber Personal Data
                </div>
                <p className="text-base  leading-relaxed text-gray-500">
                  This Annex includes certain details of the processing of
                  Subscriber Personal Data as required by Article 28(3) of the
                  GDPR.
                </p>

                <div className="text-base font-bold">
                  Subject matter and duration of the Processing of Subscriber
                  Personal Data
                </div>
                <p className="text-base  leading-relaxed text-gray-500">
                  The subject matter and duration of the Processing of the
                  Subscriber Personal Data are set out in the Agreement and this
                  DPA.
                </p>
                <div className="text-base font-bold">
                  The nature and purpose of the Processing of Subscriber
                  Personal Data
                </div>
                <p className="text-base  leading-relaxed text-gray-500">
                  The Subscriber Personal Data will be subject to the following
                  basic processing activities: transmitting, collecting, storing
                  and analysing data in order to provide the Service to the
                  Subscriber, and any other activities related to the provision
                  of the Service or specified in the Agreement.
                </p>
                <div className="text-base font-bold">
                  The types of Subscriber Personal Data to be processed
                </div>
                <p className="text-base  leading-relaxed text-gray-500">
                  The Subscriber Personal Data concern the following categories
                  of data: names; email addresses; personal and professional
                  information; and any other personal data provided by the
                  Subscriber in connection with its use of the Service.
                </p>
                <div className="text-base font-bold">
                  The categories of data subject to whom the Subscriber Personal
                  Data relates
                </div>
                <p className="text-base  leading-relaxed text-gray-500">
                  Any categories of individuals whose data the Subscriber
                  extracts, transfers, and/or loads onto the Service, which may
                  include but is not limited to:
                </p>
                <p className="w-fit">
                  <ul className="mx-5">
                    <li className="w-fit list-disc text-base  leading-relaxed text-gray-500">
                      Registered Clients; and
                    </li>
                    <li className="w-fit list-disc text-base  leading-relaxed text-gray-500">
                      Past, present and prospective clients, business
                      relationship contacts, and outside counsel contacts of the
                      Subscriber.
                    </li>
                  </ul>
                </p>
                <div className="text-base font-bold">
                  The obligations and rights of the Subscriber
                </div>
                <p className="text-base  leading-relaxed text-gray-500">
                  The obligations and rights of the Subscriber are as set out in
                  this DPA.
                </p>
              </div>
            </>
          ) : eulaPage.exhibit_c ? (
            <>
              <div className="text-start p-6 space-y-6">
                <div className="flex items-center gap-5">
                  <span
                    className="hover:cursor-pointer"
                    onClick={() => {
                      setEulaPage({
                        default: true,
                        exhibit_a: false,
                        exhibit_b: false,
                        exhibit_c: false,
                      });
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      fill="currentColor"
                      class="bi bi-arrow-left-circle"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
                      />
                    </svg>
                  </span>
                  <div className="text-xl text-center font-bold">Exhibit C</div>
                </div>
                <div className="text-xl text-center font-bold underline">
                  Subprocessors
                </div>
                <p className="text-base  leading-relaxed text-gray-500">
                  As disclosed in §3.1 of National Access LLC Data Protection
                  Addendum for GDPR Compliance, Themis uses the authorized
                  subprocessors listed below to process personal information.
                </p>
                <p className="text-base  leading-relaxed text-gray-500">
                  All processing is done in accordance with Themis’ Terms of
                  Service and Privacy Policy respectively.
                </p>
                <div className="w-full flex justify-center">
                  <table class="border-collapse border border-slate-400">
                    <thead>
                      <tr>
                        <th class="border border-slate-300 p-1 text-center">
                          Subprocessor
                        </th>
                        <th class="border border-slate-300 p-1 text-center">
                          Purpose
                        </th>
                        <th class="border border-slate-300 p-1 text-center">
                          Controller of the Personal Information
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="border border-slate-300 p-1 text-center">
                          Microsoft Azure
                        </td>
                        <td class="border border-slate-300 p-1 text-center">
                          Hosting provider
                        </td>
                        <td class="border border-slate-300 p-1 text-center">
                          Subscriber
                        </td>
                      </tr>
                      <tr>
                        <td class="border border-slate-300 p-1 text-center">
                          Amazon Web Services
                        </td>
                        <td class="border border-slate-300 p-1 text-center">
                          Hosting provider
                        </td>
                        <td class="border border-slate-300 p-1 text-center">
                          Subscriber
                        </td>
                      </tr>
                      <tr>
                        <td class="border border-slate-300 p-1 text-center">
                          Stripe
                        </td>
                        <td class="border border-slate-300 p-1 text-center">
                          Payment Processor
                        </td>
                        <td class="border border-slate-300 p-1 text-center">
                          Subscriber
                        </td>
                      </tr>
                      <tr>
                        <td class="border border-slate-300 p-1 text-center">
                          Authorize.net
                        </td>
                        <td class="border border-slate-300 p-1 text-center">
                          Payment Processor
                        </td>
                        <td class="border border-slate-300 p-1 text-center">
                          Subscriber
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          <div className="sticky bottom-0 flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b bg-white">
            <button
              data-modal-hide="defaultModal"
              type="button"
              onClick={() => {
                acceptEulaSign();
              }}
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              I Accept
            </button>
            <button
              data-modal-hide="defaultModal"
              type="button"
              onClick={() => {
                logOutHandler();
                navigate("/login");
              }}
              className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
            >
              Decline
            </button>
          </div>
        </div>
      </ModalComponent>
    </>
  );
}
