import { useEffect, useMemo, useState } from "react";
import ExportImg from "../assets/img/excel-img.png";
import UserProf from "../assets/img/user_profile.png";
import {
  Chart as ChartJS,
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import MaterialReactTable from "material-react-table";
import { Hourglass, ThreeDots } from "react-loader-spinner";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import SmsIcon from "@mui/icons-material/Sms";
import { useNavigate } from "react-router-dom";
import { domainUrl, listedStates, logOutHandler } from "../utils/constants";
import axios from "axios";
import toast from "react-hot-toast";
import { Avatar, Button, IconButton } from "@mui/material";
ChartJS.register(
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  Tooltip,
  Legend
);

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function AccountReceivableAging() {
  const [data, setData] = useState([]);

  const [isError, setIsError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [isRefetching, setIsRefetching] = useState(false);

  const [rowCount, setRowCount] = useState(0);

  const role = localStorage.getItem("role");
  const access_token = localStorage.getItem("access_token");

  const navigate = useNavigate();

  const [columnFilters, setColumnFilters] = useState([]);
  const [accountTotal, setAccountTotal] = useState({});

  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState([]);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [invitationSmsLoaders, setInvitationSmsLoaders] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      if (!data.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      const url = new URL("payments-receivable", `${domainUrl}`);

      url.searchParams.set(
        "offset",
        pagination.pageIndex * pagination.pageSize
      );
      url.searchParams.set("limit", `${pagination.pageSize}`);
      url.searchParams.set("offset", `${pagination.pageIndex}`);
      url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
      url.searchParams.set("search", search ?? "");
      url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
      url.searchParams.set("table", `true`);
      try {
        const response = await fetch(url.href, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        });
        let json = await response.json();
        let data = json;

        if (data.code == "token_not_valid") {
          logOutHandler();
          navigate("/login");
          toast.error("Session Expired", { id: 1 });
          return;
        }
        setData(data.data);
        setRowCount(data.count);
        setAccountTotal(data.footer);
      } catch (error) {
        setIsError(true);
        return;
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    };

    fetchData();
  }, [
    columnFilters,
    search,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
  ]);

  const columns = useMemo(
    () => [
      {
        id: "firm_name",
        header: "",
        columns: [
          {
            accessorFn: (row) => `${row.firm_name} `,
            id: "firm_name",
            footer: "Total Outstanding Balance:",
            enableColumnActions: false,
            header: "Firm Name",
            accessorKey: "firm_name",
            size: 30,
          },
          {
            accessorFn: (row) => `${formatUSD(row.total)} `,
            id: "total",
            footer: accountTotal.total ? formatUSD(accountTotal.total) : "",
            enableColumnActions: false,
            header: "Total",
            accessorKey: "total",
            size: 30,
          },
          {
            accessorFn: (row) => `${formatUSD(row["0_30_days"])} `,
            id: "0_30_days",
            footer: accountTotal["0_30_days"]
              ? formatUSD(accountTotal["0_30_days"])
              : "",
            enableColumnActions: false,
            header: "0-30 days",
            accessorKey: "0_30_days",
            size: 30,
          },
          {
            accessorFn: (row) => `${formatUSD(row["30_60_days"])} `,
            id: "30_60_days",
            footer: accountTotal["30_60_days"]
              ? formatUSD(accountTotal["30_60_days"])
              : "",
            enableColumnActions: false,
            header: "30-60 days",
            accessorKey: "30_60_days",
            size: 30,
          },
          {
            accessorFn: (row) => `${formatUSD(row["60_90_days"])} `,
            id: "60_90_days",
            footer: accountTotal["60_90_days"]
              ? formatUSD(accountTotal["60_90_days"])
              : "",
            enableColumnActions: false,
            header: "60-90 days",
            accessorKey: "60_90_days",
            size: 30,
          },
          {
            accessorFn: (row) => `${formatUSD(row["90_180_days"])} `,
            id: "90_180_days",
            footer: accountTotal["90_180_days"]
              ? formatUSD(accountTotal["90_180_days"])
              : "",
            enableColumnActions: false,
            header: "90-180 days",
            accessorKey: "90_180_days",
            size: 30,
          },
          {
            accessorFn: (row) => `${formatUSD(row["180_plus_days"])} `,
            id: "180_plus_days",
            footer: accountTotal["180_plus_days"]
              ? formatUSD(accountTotal["180_plus_days"])
              : "",
            enableColumnActions: false,
            header: "180+ days",
            accessorKey: "180_plus_days",
            size: 30,
          },
        ].filter(Boolean),
      },
    ],

    []
  );
  const formatDate = (dateString) => {
    if (dateString) {
      const date = dateString.split("-");

      return `${date[1]}/${date[2]}/${date[0]}`;
    }
    return dateString;
  };
  function formatUSD(number) {
    if (typeof number !== "number" || isNaN(number)) {
      if (typeof number == "string") {
        number = parseFloat(number);
      } else {
        return "Invalid number";
      }
    }

    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    return formatter.format(number);
  }

  const exportFileHandler = async (id) => {
    const url = new URL("payments-receivable", `${domainUrl}`);

    url.searchParams.set("offset", pagination.pageIndex * pagination.pageSize);
    url.searchParams.set("limit", `${pagination.pageSize}`);
    url.searchParams.set("offset", `${pagination.pageIndex}`);
    url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
    url.searchParams.set("search", search ?? "");
    url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
    url.searchParams.set("export", true);

    axios
      .get(url, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        if (response.headers.get("content-type") == "application/json") {
          response.data.text().then((text) => {
            toast.success(JSON.parse(text).scheduled, { autoClose: 10000 });
          });
        } else {
          let filename = `Account_Receivable_Aging${Date.now()}.xlsx`;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
        }
      });
  };

  return (
    <>
      <div>
        <main className="py-10">
          <main className="w-full py-10 ">
            <div className="mx-5">
              <h2
                style={{
                  color: "#1a3253",
                }}
              >
                Account Receivable Aging
              </h2>

              <div className="p-1 border rounded-lg">
                <MaterialReactTable
                  enableTopToolbar={true}
                  enableStickyHeader
                  enableStickyFooter
                  renderTopToolbarCustomActions={() => {
                    if (role == "admin") {
                      return (
                        <div className="sm:flex justify-between gap-3">
                          <div className="mt-3 flex flex-col items-start gap-3 sm:flex-row sm:items-center sm:mt-0">
                            <Button
                              disabled={false}
                              startIcon={<Avatar src={ExportImg} />}
                              onClick={() => {
                                exportFileHandler();
                              }}
                              variant="contained"
                              sx={{
                                bgcolor: "#127c44",
                                "&:hover": {
                                  bgcolor: "#127c44",
                                },
                                height: "25px",
                              }}
                            >
                              Export
                            </Button>
                          </div>
                        </div>
                      );
                    }
                    return;
                  }}
                  muiTableContainerProps={{ sx: { maxHeight: "500px" } }}
                  muiTableBodyProps={{
                    sx: () => ({
                      "& tr:nth-child(odd)": {
                        backgroundColor: "#f8f9fa",
                      },
                      "& tr:nth-child(even)": {
                        backgroundColor: "#ffffff",
                      },
                    }),
                  }}
                  muiTablePaperProps={{
                    sx: {
                      maxWidth: "full",
                    },
                    elevation: 0,
                  }}
                  muiTableHeadCellProps={{
                    sx: (theme) => ({
                      color: theme.palette.text.primary,
                    }),
                  }}
                  enableFullScreenToggle={false}
                  enableDensityToggle={false}
                  enableHiding={false}
                  enableColumnFilters={true}
                  rowNumberMode="static"
                  renderDetailPanel={({ row }) => {
                    return (
                      <div className="bg-white">
                        <div className="relative mx-auto px-4 sm:px-6 lg:px-8">
                          <div className="my-5 px-4 sm:px-6 lg:px-8">
                            <div className="my-8 flow-root">
                              <div className="overflow-x-auto">
                                <div className="min-w-full py-2 border-2 border-black rounded-md">
                                  <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                      <tr>
                                        <th
                                          scope="col"
                                          className="py-3.5 pl-4 pr-3 text-left text-sm  text-gray-500 sm:pl-6"
                                        >
                                          Attroney Name
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-3 py-3.5 text-left text-sm  text-gray-500"
                                        >
                                          Total
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-3 py-3.5 text-left text-sm  text-gray-500"
                                        >
                                          0-30 days
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-3 py-3.5 text-left text-sm  text-gray-500"
                                        >
                                          30-60 days
                                        </th>{" "}
                                        <th
                                          scope="col"
                                          className="px-3 py-3.5 text-left text-sm  text-gray-500"
                                        >
                                          60-90 days
                                        </th>{" "}
                                        <th
                                          scope="col"
                                          className="px-3 py-3.5 text-left text-sm  text-gray-500"
                                        >
                                          90-180 days
                                        </th>{" "}
                                        <th
                                          scope="col"
                                          className="px-3 py-3.5 text-left text-sm  text-gray-500"
                                        >
                                          180+ days
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                      {row.original.attorneys.map(
                                        (item, index) => (
                                          <>
                                            <tr
                                              className="bg-white"
                                              key={index}
                                            >
                                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base font-bold text-gray-900 sm:pl-6">
                                                {item.attorney_name}
                                              </td>
                                              <td className="whitespace-nowrap px-3 py-4 text-sm font-semibold text-gray-900">
                                                {formatUSD(item.total)}
                                              </td>
                                              <td className="whitespace-nowrap px-3 py-4 text-sm font-semibold text-gray-900">
                                                {formatUSD(item["0_30_days"])}
                                              </td>
                                              <td className="whitespace-nowrap px-3 py-4 text-sm font-semibold text-gray-900">
                                                {formatUSD(item["30_60_days"])}
                                              </td>
                                              <td className="whitespace-nowrap px-3 py-4 text-sm font-semibold text-gray-900">
                                                {formatUSD(item["60_90_days"])}
                                              </td>
                                              <td className="whitespace-nowrap px-3 py-4 text-sm font-semibold text-gray-900">
                                                {formatUSD(item["90_180_days"])}
                                              </td>
                                              <td className="whitespace-nowrap px-3 py-4 text-sm font-semibold text-gray-900">
                                                {formatUSD(
                                                  item["180_plus_days"]
                                                )}
                                              </td>
                                            </tr>
                                            <div className="my-8 flow-root">
                                              <div className="overflow-x-auto">
                                                <div className="inline-block min-w-full py-2">
                                                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                                    <table className="min-w-full divide-y divide-gray-300">
                                                      <thead className="">
                                                        <tr>
                                                          <th
                                                            scope="col"
                                                            className="py-3.5 pl-4 pr-3 text-left text-sm  text-gray-500 sm:pl-6"
                                                          >
                                                            Client Name
                                                          </th>
                                                          <th
                                                            scope="col"
                                                            className="py-3.5 pl-4 pr-3 text-left text-sm  text-gray-500 sm:pl-6"
                                                          >
                                                            Total
                                                          </th>
                                                          <th
                                                            scope="col"
                                                            className="py-3.5 pl-4 pr-3 text-left text-sm  text-gray-500 sm:pl-6"
                                                          >
                                                            0-30 days
                                                          </th>
                                                          <th
                                                            scope="col"
                                                            className="py-3.5 pl-4 pr-3 text-left text-sm  text-gray-500 sm:pl-6"
                                                          >
                                                            30-60 days
                                                          </th>{" "}
                                                          <th
                                                            scope="col"
                                                            className="py-3.5 pl-4 pr-3 text-left text-sm  text-gray-500 sm:pl-6"
                                                          >
                                                            60-90 days
                                                          </th>{" "}
                                                          <th
                                                            scope="col"
                                                            className="py-3.5 pl-4 pr-3 text-left text-sm  text-gray-500 sm:pl-6"
                                                          >
                                                            90-180 days
                                                          </th>{" "}
                                                          <th
                                                            scope="col"
                                                            className="py-3.5 pl-4 pr-3 text-left text-sm  text-gray-500 sm:pl-6"
                                                          >
                                                            180+ days
                                                          </th>
                                                        </tr>
                                                      </thead>
                                                      <tbody className="divide-y divide-gray-200 bg-white">
                                                        {item.clients.map(
                                                          (itm, idx) => (
                                                            <tr
                                                              className="bg-white"
                                                              key={idx}
                                                            >
                                                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base font-bold text-gray-900 sm:pl-6">
                                                                {
                                                                  itm.client_name
                                                                }
                                                              </td>
                                                              <td className="whitespace-nowrap px-3 py-4 text-sm font-semibold text-gray-900">
                                                                {formatUSD(
                                                                  itm.total
                                                                )}
                                                              </td>
                                                              <td className="whitespace-nowrap px-3 py-4 text-sm font-semibold text-gray-900">
                                                                {formatUSD(
                                                                  itm[
                                                                    "0_30_days"
                                                                  ]
                                                                )}
                                                              </td>
                                                              <td className="whitespace-nowrap px-3 py-4 text-sm font-semibold text-gray-900">
                                                                {formatUSD(
                                                                  itm[
                                                                    "30_60_days"
                                                                  ]
                                                                )}
                                                              </td>
                                                              <td className="whitespace-nowrap px-3 py-4 text-sm font-semibold text-gray-900">
                                                                {formatUSD(
                                                                  itm[
                                                                    "60_90_days"
                                                                  ]
                                                                )}
                                                              </td>
                                                              <td className="whitespace-nowrap px-3 py-4 text-sm font-semibold text-gray-900">
                                                                {formatUSD(
                                                                  itm[
                                                                    "90_180_days"
                                                                  ]
                                                                )}
                                                              </td>
                                                              <td className="whitespace-nowrap px-3 py-4 text-sm font-bold text-gray-600">
                                                                {formatUSD(
                                                                  itm[
                                                                    "180_plus_days"
                                                                  ]
                                                                )}
                                                              </td>
                                                            </tr>
                                                          )
                                                        )}
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }}
                  muiTablePaginationProps={{
                    rowsPerPageOptions: [5, 10, 15, 20, 25],
                    showFirstButton: false,
                    showLastButton: false,
                  }}
                  columns={columns}
                  data={data}
                  positionActionsColumn="last"
                  displayColumnDefOptions={{
                    "mrt-row-actions": {
                      header: role == "admin" ? "Resend" : null,
                    },
                  }}
                  muiTableBodyRowProps={({ row }) => ({
                    onClick: () => {
                      if (data.length) {
                      }
                    },

                    sx: {
                      cursor: "pointer",
                    },
                  })}
                  getRowId={(row) => row.id}
                  initialState={{
                    showColumnFilters: false,
                    density: "spacious",
                  }}
                  manualPagination
                  manualSorting
                  muiToolbaralertBannerProps={
                    isError
                      ? {
                          color: "error",
                          children: "Error loading data",
                        }
                      : undefined
                  }
                  onColumnFiltersChange={setColumnFilters}
                  onGlobalFilterChange={setSearch}
                  onPaginationChange={setPagination}
                  onSortingChange={setSorting}
                  rowCount={rowCount}
                  state={{
                    isLoading,
                    pagination,
                    showalertBanner: isError,
                    showProgressBars: isRefetching,
                    sorting,
                  }}
                />
              </div>
            </div>
          </main>
        </main>
      </div>
    </>
  );
}
