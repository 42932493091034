import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { domainUrl, logOutHandler } from "../../constants";
import toast from "react-hot-toast";

export const addClientPost = createAsyncThunk("addClientPost", async (data,thunkAPI) => {
  const access_token = localStorage.getItem("access_token");
  try {
    const res = await axios.post(`${domainUrl}clients`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
    });
    return res.data;
  } catch (error) {
    if (error.response?.data.code == "token_not_valid") {
      logOutHandler();
      const navigate = thunkAPI.extra.navigateCallback;
      navigate("/login");
      toast.error("Session Expired", { id: 1 });
      return;
    } else {
      throw JSON.stringify(error.response.data.detail);
    }
  }
});

const addClientSlice = createSlice({
  name: "addClientStorage",
  initialState: { data: [], isLoading: false, error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addClientPost.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addClientPost.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(addClientPost.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      });
  },
});

export default addClientSlice.reducer;
