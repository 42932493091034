import React, { useState, useEffect } from "react";
import logo from "../assets/img/nationalaccess-logo.png";
import loginImg from "../assets/img/login-greet.jpg";
import { Switch } from "@headlessui/react";
import { Link, useNavigate, useParams } from "react-router-dom";
import OtpInput from "react-otp-input";
import "../assets/styles/ReactOtpInput.css";
import qrImg from "../assets/img/qr-img.png";
import { loginPost } from "../utils/redux/slice/loginSlice";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { otpPost } from "../utils/redux/slice/otpSlice";
import { ThreeDots } from "react-loader-spinner";
import { motion } from "framer-motion";
export default function Login() {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const [toggle, setToggle] = useState(false);
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();

  const [flagOtp, setFlagOtp] = useState(false);
  const dispatch = useDispatch();
  const { error } = useSelector((state) => state.loginStorage);

  const loadingLoginPage = useSelector((state) => state.loginStorage.isLoading);
  const dataLoginPage = useSelector((state) => state.loginStorage.data);
  const errorLoginPage = useSelector((state) => state.loginStorage.error);

  const loadingOtpPage = useSelector((state) => state.otpStorage.isLoading);
  const dataOtpPage = useSelector((state) => state.otpStorage.data);
  const errorOtpPage = useSelector((state) => state.otpStorage.error);

  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const [otpText, setOtpText] = useState(localStorage.getItem("otpText"));

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const temp_auth_token = localStorage.getItem("temp_auth_token");

  const submitEmailNPass = async (e) => {
    e.preventDefault();

    const res = await dispatch(loginPost(data, navigate));
    if (res.payload) {
      if (res.payload.access_token) {
        localStorage.setItem("role", res.payload.data.role.replace("-", ""));
        localStorage.setItem("access_token", res.payload.access_token);
        localStorage.setItem(
          "authorize_customer_id",
          res.payload.authorize_customer_id
        );
        localStorage.setItem("userProf", JSON.stringify(res.payload.data));
        localStorage.setItem("user2fa", JSON.stringify(res.payload.data));
        localStorage.setItem(
          "client_data",
          JSON.stringify(res.payload.client_data)
        );
        navigate("/client-home");

        window.location.reload();

        toast.success("Login Success");
      } else {
        localStorage.setItem("otpText", res.payload.preferred_2fa_method);
        localStorage.setItem("temp_auth_token", res.payload.temp_auth_token);
        setOtpText(res.payload.preferred_2fa_method);
        setFlagOtp(true);
        if (res.payload.preferred_2fa_method === "totp") {
          toast.success("Check Authenticator App");
        } else if (res.payload.preferred_2fa_method === "email") {
          toast.success(
            `A Sign-in code has Sent to  ${res.payload.identifier}`
          );
        } else {
          toast.success(`SMS Sign-in code sent to ${res.payload.identifier}`);
        }
      }
    }
    if (res.error) {
      toast.error(res.error.message, { id: 1 });
    }
  };

  const validateOtp = async (e) => {
    e.preventDefault();

    const res = await dispatch(otpPost({ otp, temp_auth_token }, navigate));
    if (res.payload) {
      setFlagOtp(false);
      localStorage.removeItem("otpText");
      localStorage.removeItem("temp_auth_token");
      localStorage.setItem("role", res.payload.data.role.replace("-", ""));
      localStorage.setItem("access_token", res.payload.access_token);
      localStorage.setItem("userProf", JSON.stringify(res.payload.data));
      localStorage.setItem("user2fa", JSON.stringify(res.payload.data));

      if (res.payload.data.role === "admin") {
        toast.success("Login Success");
        navigate("/admin-home");

        window.location.reload();
      } else if (res.payload.data.role === "primary-attorney") {
        toast.success("Login Success");
        localStorage.setItem("attorney_id", res.payload.attorney_id);
        localStorage.setItem("firm_id", res.payload.firm_id);
        navigate("/firm-home");

        window.location.reload();
      } else if (res.payload.data.role === "attorney") {
        toast.success("Login Success");
        navigate("/attorney-home");
        localStorage.setItem("attorney_id", res.payload.attorney_id);
        localStorage.setItem("firm_id", res.payload.firm_id);

        window.location.reload();
      } else {
        localStorage.setItem(
          "authorize_customer_id",
          res.payload.authorize_customer_id
        );
        localStorage.setItem(
          "client_data",
          JSON.stringify(res.payload.client_data)
        );

        navigate("/client-home");
        toast.success("Login Success");

        window.location.reload();
      }
    }
    if (res.error) {
      toast.error(res.error.message, { id: 1 });
    }
  };

  return (
    <>
      <html>
        <body>
          <div className="h-screen bg-white flex justify-center items-center">
            <div className="">
              <div className="w-full flex bg-[#F0F2F5] lg:w-full md:w-full">
                <div className="relative hidden h-[45.938rem] w-[28.25rem]  lg:flex-1 lg:block">
                  <img
                    className="absolute inset-y-0 right-0 h-full w-min object-cover"
                    src={loginImg}
                    alt="loginImg"
                  />
                </div>
                <div className="flex flex-1 flex-col justify-center w-screen px-4 py-12 sm:px-6  lg:flex-none lg:px-20 lg:w-auto  xl:px-24 ">
                  <div className="mx-auto w-full max-w-sm lg:w-96 ">
                    <div className="flex flex-col ">
                      <img
                        className="h-10 w-auto object-contain mb-5"
                        src={logo}
                        alt="Natl"
                      />

                      {!flagOtp ? (
                        <h2 className="mt-0 text-xl text-center font-bold leading-9 tracking-tight text-[#344767]">
                          Sign In
                        </h2>
                      ) : (
                        <h2 className="mt-0 text-xl text-center font-bold text-[#344767]">
                          {otpText === "totp" ? (
                            <>Enter TOTP</>
                          ) : (
                            <>Enter Sign-in code</>
                          )}
                        </h2>
                      )}

                      {!flagOtp ? (
                        <div className="mt-0 text-sm text-center   text-[#7b809a]">
                          Enter your email and password to Sign In
                        </div>
                      ) : (
                        <div className="mt-0 text-sm text-center  text-[#7b809a]">
                          {otpText === "totp" ? (
                            <>Please enter the TOP to verify your account</>
                          ) : (
                            <>
                              Please enter the One-Time Password to verify your
                              account
                            </>
                          )}
                        </div>
                      )}
                    </div>

                    <div className="mt-10">
                      {!flagOtp && (
                        <>
                          {!loadingLoginPage ? (
                            <div>
                              <form
                                onSubmit={(e) => {
                                  submitEmailNPass(e);
                                }}
                                className="space-y-6"
                              >
                                <div>
                                  <div className="mt-2">
                                    <input
                                      id="email"
                                      name="email"
                                      type="email"
                                      autoComplete="email"
                                      placeholder="Email"
                                      onChange={handleChange}
                                      required
                                      className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-[#9ca0b3] pl-4  focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                                    />
                                  </div>
                                </div>

                                <div>
                                  <div className="mt-2 relative w-full">
                                    <input
                                      id="password"
                                      name="password"
                                      type={
                                        isPasswordVisible ? "text" : "password"
                                      }
                                      autoComplete="current-password"
                                      placeholder="Password"
                                      onChange={handleChange}
                                      required
                                      className="block w-full rounded-md border py-1.5 shadow-sm  placeholder:text-[#9ca0b3] pl-4 focus:outline-none sm:text-sm sm:leading-6pl-4 focus:outline-none sm:text-sm sm:leading-6"
                                    />
                                    <button
                                      type="button"
                                      className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-600"
                                      onClick={() => togglePasswordVisibility()}
                                    >
                                      {isPasswordVisible ? (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          strokeWidth={1.5}
                                          stroke="currentColor"
                                          className="w-5 h-5"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                                          />
                                        </svg>
                                      ) : (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          strokeWidth={1.5}
                                          stroke="currentColor"
                                          className="w-5 h-5"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                          />
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                          />
                                        </svg>
                                      )}
                                    </button>
                                  </div>
                                </div>

                                <div className="flex items-center justify-end">
                                  {/* <div className="flex items-center">
                                    <Switch
                                      checked={toggle}
                                      onChange={setToggle}
                                      className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full "
                                    >
                                      <span
                                        aria-hidden="true"
                                        className="pointer-events-none absolute h-full w-full rounded-md bg-[#F0F2F5]"
                                      />
                                      <span
                                        aria-hidden="true"
                                        className={classNames(
                                          toggle
                                            ? "bg-[#1a3253]"
                                            : "bg-gray-200",
                                          "pointer-events-none absolute mx-auto h-4 w-9 rounded-full border border-gray-300 transition-colors duration-200 ease-in-out"
                                        )}
                                      />
                                      <span
                                        aria-hidden="true"
                                        className={classNames(
                                          toggle
                                            ? "translate-x-5"
                                            : "translate-x-0",
                                          "pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-black border border-gray-400 bg-white shadow ring-0 transition-transform duration-200 ease-in-out"
                                        )}
                                      />
                                    </Switch>

                                    <label className="ml-3 block text-sm leading-6 text-[#8a8ea6]">
                                      Remember me
                                    </label>
                                  </div> */}

                                  <div className="text-sm leading-6">
                                    <Link
                                      to="/forgot-pass"
                                      className="font-semibold text-[#344767] hover:text-[#6f7d94]"
                                    >
                                      Forgot password
                                    </Link>
                                  </div>
                                </div>

                                <div>
                                  <button
                                    type="submit"
                                    className="flex w-full justify-center rounded-md bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#4999e0] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                  >
                                    SIGN IN
                                  </button>
                                </div>
                              </form>
                            </div>
                          ) : (
                            <div className="w-full flex justify-center items-center">
                              <ThreeDots
                                height="80"
                                width="80"
                                radius="9"
                                color="#1a3253"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            </div>
                          )}
                        </>
                      )}
                      {flagOtp && (
                        <>
                          {!loadingOtpPage ? (
                            <div>
                              <form
                                onSubmit={(e) => {
                                  validateOtp(e);
                                }}
                              >
                                <div
                                  id="otpContainer"
                                  className=" h-28 flex justify-center align-center"
                                >
                                  <OtpInput
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={6}
                                    renderInput={(props) => (
                                      <input
                                        {...props}
                                        type="number"
                                        placeholder="-"
                                        style={{
                                          fontSize: "1.5rem",
                                          height: "3rem",
                                          width: "100%",
                                          margin: "0.2rem",
                                          padding: "0.2rem 0 0.2rem 0",
                                          textAlign: "center",
                                          borderBottom: "1px solid #376080",

                                          backgroundColor: "#f0f2f5",
                                        }}
                                      />
                                    )}
                                  />
                                </div>
                                <div className="flex justify-center ">
                                  <button
                                    type="submit"
                                    className="w-full rounded-md bg-gradient-to-r from-blue-400 via-blue-600 to-blue-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#1991e0] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                  >
                                    Submit
                                  </button>
                                </div>
                              </form>
                            </div>
                          ) : (
                            <div className="w-full flex justify-center items-center">
                              <ThreeDots
                                height="80"
                                width="80"
                                radius="9"
                                color="#1a3253"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </body>
      </html>
    </>
  );
}
