import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { domainUrl, logOutHandler } from '../../utils/constants';
import toast from 'react-hot-toast';
import { useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
  Cell,
} from 'recharts';

export default function OntimePaymentsBar() {
  const navigate = useNavigate();
  const access_token = localStorage.getItem('access_token');

  const [ontimeGraphData, setontimeGraphData] = useState({
    30: {},
    60: {},
    90: {},
    180: {},
    older: {},
  });

  const maxPaymentsTotal = Object.keys(ontimeGraphData).reduce(
    (maxTotal, key) => {
      const paymentsTotal = ontimeGraphData[key]?.payments_total || 0;
      return Math.max(maxTotal, paymentsTotal);
    },
    0
  );

  const getOntimePaymentGraphData = async (date_range) => {
    try {
      const res = await axios.get(
        `${domainUrl}ontime-payments?date_range=${date_range}&graph=true`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (res.data) {
        if (res.data.date_range == '0,30') {
          setontimeGraphData((prevData) => ({
            ...prevData,
            30: res.data,
          }));
        } else if (res.data.date_range == '30,60') {
          setontimeGraphData((prevData) => ({
            ...prevData,
            60: res.data,
          }));
        } else if (res.data.date_range == '60,90') {
          setontimeGraphData((prevData) => ({
            ...prevData,
            90: res.data,
          }));
        } else if (res.data.date_range == '90,180') {
          setontimeGraphData((prevData) => ({
            ...prevData,
            180: res.data,
          }));
        } else {
          setontimeGraphData((prevData) => ({
            ...prevData,
            older: res.data,
          }));
        }
      }
    } catch (error) {
      if (error.response?.data.code == 'token_not_valid') {
        logOutHandler();
        navigate('/login');
        toast.error('Session Expired', { id: 1 });
        return;
      }
    }
  };

  function formatUSD(number) {
    if (typeof number !== 'number' || isNaN(number)) {
      if (typeof number == 'string') {
        number = parseFloat(number);
      } else {
        return 'Invalid number';
      }
    }

    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    return formatter.format(number);
  }

  const data = [
    {
      name: '30',
      payment: ontimeGraphData[30]?.payments_ontime,
      expected_payment: ontimeGraphData[30]?.payments_total,
    },
    {
      name: '60',
      payment: ontimeGraphData[60]?.payments_ontime,
      expected_payment: ontimeGraphData[60]?.payments_total,
    },
    {
      name: '90',
      payment: ontimeGraphData[90]?.payments_ontime,
      expected_payment: ontimeGraphData[90]?.payments_total,
    },
    {
      name: '180',
      payment: ontimeGraphData[180]?.payments_ontime,
      expected_payment: ontimeGraphData[180]?.payments_total,
    },
    {
      name: 'Older',
      payment: ontimeGraphData['older']?.payments_ontime,
      expected_payment: ontimeGraphData['older']?.payments_total,
    },
  ];

  useEffect(() => {
    getOntimePaymentGraphData('0,30');
    getOntimePaymentGraphData('30,60');
    getOntimePaymentGraphData('60,90');
    getOntimePaymentGraphData('90,180');
    getOntimePaymentGraphData('180,365');
  }, []);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className='p-2 bg-white border border-black shadow-md flex flex-col gap-4 rounded-md'>
          <p className='text-medium text-sm'>{label} Days</p>
          <p className='text-lg font-semibold text-blue-600'>
            {/* Scheduled Payment:
            <span className='ml-2'>
              {formatUSD(payload[0].payload.expected_payment)}
            </span> */}
            <p className='text-lg font-semibold text-blue-600'>
              On Time Payment:
              <span className='ml-2'>
                {formatUSD(payload[0].payload.payment)}
              </span>
            </p>
          </p>
        </div>
      );
    }
  };

  const colors = ['#FFBF00', '#ee9a00', '#90EE90', '#ADD8E6', '#0054a8'];

  return (
    <>
      <div className='w-full'>
        <div className='w-full h-[350px] p-2 flex flex-col justify-center items-center border border-gray-200 rounded-xl shadow-md'>
          <div className='mb-3 text-base font-bold'>PAYMENT ON TIME</div>
          <div className='w-full h-60 sm:w-[27rem] lg:w-[27rem]'>
            <ResponsiveContainer width='100%' height='100%'>
              <BarChart
                width='100%'
                height='100%'
                data={data}
                onClick={(event, entry) => {
                  if (!event?.activePayload) {
                    return;
                  }

                  const routeMap = {
                    30: '0,30',
                    60: '30,60',
                    90: '60,90',
                    180: '90,180',
                    Older: '180,9999',
                  };
                  const route = routeMap[event?.activeLabel];
                  if (route) {
                    const [startOffset, endOffset] = route
                      .split(',')
                      .map(Number);

                    // Get today's date
                    const today = new Date();

                    // Calculate start_date and end_date based on offsets
                    const startDate = new Date();
                    startDate.setDate(today.getDate() - endOffset); // Start date goes back 'endOffset' days

                    const endDate = new Date();
                    endDate.setDate(today.getDate() - startOffset); // End date goes back 'startOffset' days

                    localStorage.setItem("query_param/on_time_clients", true);
                    let range = {
                      start_date: startDate.toISOString().split('T')[0], // Format YYYY-MM-DD
                      end_date: endDate.toISOString().split('T')[0],
                    };
                      localStorage.setItem(
                        "date_range/on_time_clients",
                        JSON.stringify(range)
                    );
                    navigate("/on-time-clients");
                  }
                }}
                margin={{
                  right: 35,
                  left: 35,
                }}
                padding={{
                  top: 20,
                  right: 20,
                  left: 20,
                  bottom: 20,
                }}
              >
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis dataKey='name' interval={0} tick={{ fontSize: 10 }} />
                <YAxis
                  tickFormatter={formatUSD}
                  domain={[0, maxPaymentsTotal + maxPaymentsTotal * 0.1]}
                  tickCount={8}
                  tick={{ fontSize: 10 }}
                />
                <Tooltip
                  wrapperStyle={{ zIndex: 2 }}
                  content={<CustomTooltip />}
                />

                <Bar
                  className='hover:cursor-pointer'
                  dataKey='payment'
                  barSize={50}
                >
                  {data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={colors[index % colors.length]}
                    />
                  ))}
                  <LabelList
                    dataKey='payment'
                    position={'top'}
                    angle={0}
                    textAnchor={'end'}
                    fill='black'
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
          <div className='my-3 flex flex-col justify-evenly items-start  gap-2'>
            <div className='flex  gap-3 justify-center items-start'>
              <div className='text-sm inline-flex items-center gap-1'>
                <span className='h-2 w-2 bg-[#FFBF00]'></span>30 days
              </div>
              <div className='text-sm inline-flex items-center gap-1'>
                <span className='h-2 w-2 bg-[#ee9a00]'></span>60 days
              </div>
            </div>
            <div className='flex  gap-3 justify-center items-start'>
              <div className='text-sm inline-flex items-center gap-1'>
                <span className='h-2 w-2 bg-[#90EE90]'></span>90 days
              </div>
              <div className='text-sm inline-flex items-center gap-1'>
                <span className='h-2 w-2 bg-[#ADD8E6]'></span>180 days
              </div>
            </div>
            <div className='flex  gap-3 justify-center items-start'>
              <div className='text-sm inline-flex items-center gap-1'>
                <span className='h-2 w-2 bg-[#0054a8]'></span>Older
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
