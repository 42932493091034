import { useState } from "react";
import {
  CalendarIcon,
  ChartPieIcon,
  CheckIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  PencilSquareIcon,
  PhotoIcon,
  PlusIcon,
  UserCircleIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { Line, Doughnut } from "react-chartjs-2";

import {
  Chart as ChartJS,
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { domainUrl, listedStates, logOutHandler } from "../utils/constants";
import { motion } from "framer-motion";
import MaterialReactTable from "material-react-table";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { getFirmProfileGet } from "../utils/redux/slice/getFirmProfileSlice";
import { Oval, TailSpin } from "react-loader-spinner";
import { Box, Button } from "@mui/material";
import ModalComponent from "../Components/ModalComponent";
import { addFirmEscrowAdjustmentPost } from "../utils/redux/slice/addFirmEscrowAdjustmentSlice";
import { Combobox, Switch, Transition } from "@headlessui/react";
import axios from "axios";
import { postFNLNamePost } from "../utils/redux/slice/postFNLNameSlice";
import { addSingleAttorneyPost } from "../utils/redux/slice/addSingleAttorneySlice";
import { Fragment } from "react";
import PendingClients from "./PendingClients";
import ApprovedClientsTable from "./Tables/ApprovedClientsTable";
import FirmDataVersionTable from "./Tables/FirmDataVersionTable";
import { InputMask } from "@react-input/mask";
import { InputNumberFormat } from "@react-input/number-format";
ChartJS.register(
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  Tooltip,
  Legend
);

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ViewFirm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const role = localStorage.getItem("role");
  const firm_id = localStorage.getItem("firm_id");
  const [refetchAttorney, setRefetchAttorney] = useState(false);
  const [firmData, setFirmData] = useState({});
  const [firmLoader, setFirmLoader] = useState(false);
  const [contractEditLoader, setContractEditLoader] = useState(false);
  const getStateByCode = (code) => {
    const state = listedStates.find((state) => state.code === code);
    return state ? state : null;
  };
  function formatUSD(number) {
    if (typeof number !== "number" || isNaN(number)) {
      if (typeof number == "string") {
        number = parseFloat(number);
      } else {
        return "Invalid number";
      }
    }

    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    return formatter.format(number);
  }

  const getFirmProfile = async () => {
    setFirmLoader(true);
    const res = await dispatch(getFirmProfileGet(navigate));
    if (res.payload) {
      setFirmData(res.payload);

      setEscrowAdjust({
        ...escrowAdjust,
        escrow_limit: res.payload.financial_details.escrow_limit
          ? formatUSD(res.payload.financial_details.escrow_limit)
          : "",
        escrow_percentage: res.payload.financial_details.escrow_percentage
          ? res.payload.financial_details.escrow_percentage
          : "",
        na_fee_percentage: res.payload.financial_details.na_fee_percentage
          ? res.payload.financial_details.na_fee_percentage
          : "",
        escrow_balance: res.payload.financial_details.escrow_actual
          ? formatUSD(res.payload.financial_details.escrow_actual)
          : "",
        loc: res.payload.financial_details.loc
          ? formatUSD(res.payload.financial_details.loc)
          : "",
        status: res.payload.financial_details.active,
        late_fees: res.payload.financial_details.late_fees
          ? formatUSD(res.payload.financial_details.late_fees)
          : "",
      });
      setFirmLoader(false);
    }
    if (res.error) {
      toast.error(res.error.message);
      setFirmLoader(false);
    }
  };

  useEffect(() => {
    if (firm_id) {
      localStorage.setItem("view_firm_id", firm_id);
      if (role === "admin" && firm_id) {
        getFirmProfile();
      } else {
        navigate(-1);
      }
    } else {
      navigate(-1);
    }
    return () => {};
  }, []);

  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  const [openEscrowModal, setOpenEscrowModal] = useState(false);
  const [openAddAttorneyModal, setOpenAddAttorneyModal] = useState(false);
  const [refreshData, setRefreshData] = useState(false);

  const handleClose = () => {
    setOpenEscrowModal(false);
    setOpenAddAttorneyModal(false);
  };

  const [escrowAdjust, setEscrowAdjust] = useState({
    escrow_limit: "",
    escrow_percentage: "",
    na_fee_percentage: "",
    loc: "",
    escrow_balance: "",
    status: false,
    late_fees: "",
  });
  const [escrowAdjustError, setEscrowAdjustError] = useState({
    escrow_limit: "",
    escrow_percentage: "",
    na_fee_percentage: "",
    loc: "",
    escrow_balance: "",
    status: "",
    late_fees: "",
  });

  const filterPositiveDecimal = (value) => {
    const validPositiveDecimalRegex = /^\d+(\.\d*)?$/;
    const parts = value.split(".");
    if (parts.length > 2) {
      value = parts.slice(0, 2).join(".") + parts.slice(2).join("");
    }
    const match = value.match(validPositiveDecimalRegex);
    return match ? match[0] : value.slice(0, -1);
  };

  const handleEscrowAdjustChange = (e) => {
    setEscrowAdjustError({
      ...escrowAdjustError,
      [e.target.name]: "",
    });

    if (
      e.target.name == "escrow_percentage" ||
      e.target.name == "na_fee_percentage"
    ) {
      if (parseFloat(e.target.value) > 100) {
        toast.error(
          `${e.target.name.replace(/_/g, " ").toUpperCase()} is out of range`,
          { id: 1 }
        );
        return;
      }
    }
    if (
      e.target.name == "escrow_limit" ||
      e.target.name == "loc" ||
      e.target.name == "escrow_balance" ||
      e.target.name == "late_fees"
    ) {
      setEscrowAdjust({
        ...escrowAdjust,
        [e.target.name]: e.target.value,
      });
    } else {
      setEscrowAdjust({
        ...escrowAdjust,
        [e.target.name]: filterPositiveDecimal(e.target.value),
      });
    }
  };

  const isSubmitFinancialButtonDisabled = () => {
    const requiredFields = [
      escrowAdjust.escrow_limit,
      escrowAdjust.escrow_percentage,
      escrowAdjust.na_fee_percentage,
      escrowAdjust.loc,
      escrowAdjust.escrow_balance,

      escrowAdjust.late_fees,
    ];

    for (const key in escrowAdjustError) {
      if (escrowAdjust[key] == "") {
        setEscrowAdjustError((prevErrorFlag) => ({
          ...prevErrorFlag,
          [key]: `*${key

            .replace(/_/g, " ")
            .replace(/\b\w/g, (match) => match.toUpperCase())} is required`,
        }));
      }
    }

    return (
      requiredFields.some((field) => field === "") ||
      escrowAdjustError.escrow_limit !== "" ||
      escrowAdjustError.escrow_percentage !== "" ||
      escrowAdjustError.na_fee_percentage !== "" ||
      escrowAdjustError.loc !== "" ||
      escrowAdjustError.escrow_balance !== "" ||
      escrowAdjustError.late_fees !== ""
    );
  };

  const handleEscrowAdjustSubmit = async (e) => {
    e.preventDefault();
    const checkField = await isSubmitFinancialButtonDisabled();
    if (checkField === true) {
      toast.error("Complete the fields and submit", { id: 1 });
      return false;
    }
    setContractEditLoader(true);
    const res = await dispatch(
      addFirmEscrowAdjustmentPost(
        {
          id: firm_id,
          escrow_limit:
            escrowAdjust.escrow_limit.length &&
            parseFloat(escrowAdjust.escrow_limit.replace(/[$,]/g, "")),
          escrow_percentage:
            escrowAdjust.escrow_percentage.length &&
            parseFloat(escrowAdjust.escrow_percentage),
          na_fee_percentage:
            escrowAdjust.na_fee_percentage.length &&
            parseFloat(escrowAdjust.na_fee_percentage.replace(/[$,]/g, "")),
          loc:
            escrowAdjust.loc.length &&
            parseFloat(escrowAdjust.loc.replace(/[$,]/g, "")),
          escrow_balance:
            escrowAdjust.escrow_balance.length &&
            parseFloat(escrowAdjust.escrow_balance.replace(/[$,]/g, "")),
          active: escrowAdjust.status,
          late_fees:
            escrowAdjust.late_fees.length &&
            parseFloat(escrowAdjust.late_fees.replace(/[$,]/g, "")),
        },
        navigate
      )
    );

    if (res.payload) {
      setContractEditLoader(false);

      setOpenEscrowModal(false);
      toast.success(res.payload.message);
      getFirmProfile();
    }
    if (res.error) {
      setContractEditLoader(false);

      toast.error(res.error.message, { id: 1 });
    }
  };

  const access_token = localStorage.getItem("access_token");
  const [totalCount, setTotalCount] = useState(2);

  const [columnFilters, setColumnFilters] = useState([]);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  useEffect(() => {
    const fetchData = async () => {
      if (!data.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      const url = new URL(`attorneys?id=${firm_id}`, `${domainUrl}`);
      url.searchParams.set(
        "offset",
        pagination.pageIndex * pagination.pageSize
      );
      url.searchParams.set("limit", `${pagination.pageSize}`);
      url.searchParams.set("offset", `${pagination.pageIndex}`);
      url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
      url.searchParams.set("search", search ?? "");
      url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
      try {
        const response = await fetch(url.href, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        });
        let json = await response.json();
        let data = json;

        if (data.code == "token_not_valid") {
          logOutHandler();
          navigate("/login");
          toast.error("Session Expired", { id: 1 });
          return;
        }
        setData(data.data);
        setRowCount(data.count);
      } catch (error) {
        setIsError(true);
        return;
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    };

    fetchData();
  }, [
    columnFilters,
    search,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    refetchAttorney,
  ]);

  const columns = useMemo(
    () => [
      {
        id: "attorneys",
        header: "",
        columns: [
          {
            accessorFn: (row) => `${row?.attorney_code} `,
            id: "attorney_code",
            header: "Attorney Code",
            accessorKey: "attorney_code",
            enableColumnActions: false,
            size: 30,
          },
          {
            accessorFn: (row) =>
              `${row?.user?.first_name} ${row?.user?.last_name} `,
            id: "user_model__first_name",
            header: "Name",
            accessorKey: "first_name",
            enableColumnActions: false,
            size: 30,
          },

          {
            id: "user_model__email",
            header: "Email",
            accessorFn: (row) => `${row?.user?.email}`,
            accessorKey: "email",
            enableColumnActions: false,
            size: 30,
          },
          {
            id: "user_model__cell_phone_number",
            header: "Primary Phone",
            accessorFn: (row) => `${formatUSCell(row?.user?.cell_phone_number)}`,
            accessorKey: "cell_phone_number",
            enableColumnActions: false,
            size: 30,
          },
          {
            id: "active_clients",
            header: "Active Clients",
            accessorFn: (row) => `${row?.active_clients}`,
            accessorKey: "active_clients",
            muiTableHeadCellProps: {
              align: "center",
            },
            muiTableBodyCellProps: {
              align: "center",
            },
            enableColumnActions: false,
            size: 30,
          },
          {
            id: "delinquent_clients",
            header: "Delinquent Clients",
            accessorFn: (row) => `${row.delinquent_clients}`,
            muiTableHeadCellProps: {
              align: "center",
            },
            muiTableBodyCellProps: {
              align: "center",
            },
            accessorKey: "delinquent_clients",
            enableColumnActions: false,
            size: 30,
          },
          {
            id: "user_model__received_invitations__accepted",
            header: "Status",
            accessorFn: (row) => `${row.accepted ? "ACCEPTED" : "PENDING"}`,
            accessorKey: "accepted",
            enableColumnActions: false,
            size: 30,
          },
        ],
      },
    ],
    []
  );

  const [firms, setFirms] = useState([]);
  const [selected, setSelected] = useState();
  const [query, setQuery] = useState("");

  const attorneyCodeLoader = useSelector(
    (state) => state.postFNLNameStorage.isLoading
  );
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [attorneyDetail, setAttorneyDetail] = useState({
    first_name: "",
    last_name: "",
    email: "",
    cell_phone_number: "",
    attorney_code: "",
  });

  const [firmDetails, setFirmDetails] = useState({
    firm_code: "",
    firm_id: "",
  });
  const [errorFlag, setErrorFlag] = useState({
    first_name: "",
    last_name: "",
    email: "",
    cell_phone_number: "",
    attorney_code: "",
  });

  let errMsg = {
    first_name: "Please enter a valid first name (1-30 characters)",
    last_name: "Please enter a valid last name (1-30 characters)",
    email: "Enter a valid email",
    cell_phone_number: "Enter valid phone number",
  };

  const [defaultFirm, setDefaultFirm] = useState("");

  const [defaultFirmCheck, setDefaultFirmCheck] = useState(false);

  const autoFirmCheck = async () => {
    const res = await dispatch(getFirmProfileGet(navigate));

    if (res.payload) {
      setFirmDetails({
        ...firmDetails,
        firm_code: res.payload.firm_code,
        firm_id: res.payload.id,
      });
    }

    setDefaultFirmCheck(true);
    setDefaultFirm(`${res.payload.firm_name} : ${res.payload.firm_code}`);
  };

  useEffect(() => {
    if (firm_id) {
      autoFirmCheck();
    }
    return () => {
      localStorage.removeItem("view_firm_id");
    };
  }, []);
  const searchFirmList = async () => {
    try {
      const res = await axios.get(`${domainUrl}firms?search=${query}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      });
      if (res.data) {
        let list = [];
        res.data.data.forEach((element) => {
          list.push({
            id: element.id,
            name: element.firm_name + " - " + element.firm_code,
            firm_code: element.firm_code,
          });
        });

        setFirms(list);
      }
    } catch (error) {
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
      toast.error(error.response.data.detail, { id: 1 });
    }
  };

  useEffect(() => {
    if (query !== "") {
      searchFirmList();
    }
  }, [query]);

  const saveSelectedFirm = () => {
    setFirmDetails({
      ...firmDetails,
      firm_code: selected.firm_code,
      firm_id: selected.id,
    });
  };

  useEffect(() => {
    if (selected) {
      saveSelectedFirm();
    }
  }, [selected]);

  const formatcell_phone_number = (value) => {
    let convertedNumber = value.replace(/\D/g, "");
    convertedNumber = convertedNumber.replace(/^1/, "");
    convertedNumber = convertedNumber.replace(
      /(\d{3})(\d{3})(\d{4})/,
      "$1 $2 $3"
    );

    return convertedNumber;
  };
  function formatUSCell(usPhoneNumber) {
    let digitsOnly = usPhoneNumber.replace(/\D/g, "");

    if (digitsOnly.length !== 10) {
      return "Invalid phone number format";
    }

    return "+1 " + digitsOnly.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  }
  const formatAlphabetsWithSpace = (value) => {
    let formattedAlphabets;
    const { length } = value;

    const regex = () => value.replace(/[^a-zA-Z\s]+/g, "");

    if (length > 0) {
      formattedAlphabets = regex();
    }

    return formattedAlphabets;
  };

  const handleChange = async (e) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setErrorFlag({
      ...errorFlag,
      first_name: "",
      last_name: "",
      email: "",
      cell_phone_number: "",
    });

    setAttorneyDetail({
      ...attorneyDetail,
      [e.target.name]:
        e.target.name == "cell_phone_number"
          ? e.target.value
          : e.target.name == "first_name"
          ? formatAlphabetsWithSpace(e.target.value)
          : e.target.name == "last_name"
          ? formatAlphabetsWithSpace(e.target.value)
          : e.target.value,
    });
    setTypingTimeout(setTimeout(async () => validateForm(e), 300));
  };

  const validateForm = async (e) => {
    if (e.target.name === "first_name" || e.target.name === "last_name") {
      getAttorneyCode(e);

      setErrorFlag({
        ...errorFlag,
        [e.target.name]:
          e.target.value > 30 || e.target.value < 1
            ? errMsg[e.target.name]
            : "",
      });
    } else if (e.target.name === "email") {
      const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

      setErrorFlag({
        ...errorFlag,
        [e.target.name]:
          e.target.value.length > 0 && regex.test(e.target.value)
            ? ""
            : errMsg[e.target.name],
      });
    } else if (e.target.name === "cell_phone_number") {
      setErrorFlag({
        ...errorFlag,
        [e.target.name]:
          formatcell_phone_number(e.target.value).length != 12
            ? errMsg[e.target.name]
            : "",
      });
    }
  };

  const getAttorneyCode = async (e) => {
    if (e.target.name == "first_name") {
      if (firmDetails.firm_code && e.target.value && attorneyDetail.last_name) {
        const res = await dispatch(
          postFNLNamePost(
            {
              firm_code: firmDetails.firm_code,
              first_name: e.target.value,
              last_name: attorneyDetail.last_name,
            },
            navigate
          )
        );
        if (res.payload) {
          setAttorneyDetail((prevAttorneyDetail) => ({
            ...prevAttorneyDetail,
            attorney_code: res.payload.attorney_code,
          }));
          toast.success("Attorney code generated", { id: 1 });
        }
        if (res.error) {
          toast.error(res.error.message, { id: 1 });
        }
      }
    } else {
      if (
        firmDetails.firm_code &&
        e.target.value &&
        attorneyDetail.first_name
      ) {
        const res = await dispatch(
          postFNLNamePost(
            {
              firm_code: firmDetails.firm_code,
              first_name: attorneyDetail.first_name,
              last_name: e.target.value,
            },
            navigate
          )
        );
        if (res.payload) {
          setAttorneyDetail((prevAttorneyDetail) => ({
            ...prevAttorneyDetail,
            attorney_code: res.payload.attorney_code,
          }));
          toast.success("Attorney code generated", { id: 1 });
        }
        if (res.error) {
          toast.error(res.error.message, { id: 1 });
        }
      }
    }
  };

  const isSubmitButtonDisabled = () => {
    const requiredFields = [
      attorneyDetail.attorney_code,
      attorneyDetail.first_name,
      attorneyDetail.last_name,
      attorneyDetail.email,
      attorneyDetail.cell_phone_number,
    ];

    for (const key in errorFlag) {
      if (attorneyDetail[key] == "") {
        setErrorFlag((prevErrorFlag) => ({
          ...prevErrorFlag,
          [key]: `*${key
            .replace("cell_phone_number", "primary phone")
            .replace(/_/g, " ")
            .replace(/\b\w/g, (match) => match.toUpperCase())} is required`,
        }));
      }
    }

    return (
      requiredFields.some((field) => field === "") ||
      errorFlag.first_name !== "" ||
      errorFlag.last_name !== "" ||
      errorFlag.email !== "" ||
      errorFlag.cell_phone_number !== ""
    );
  };

  const [submitLoader, setSubmitLoader] = useState(false);

  const submitAttorneyHandler = async (e) => {
    e.preventDefault();

    if (attorneyDetail) {
      const checkField = await isSubmitButtonDisabled();
      if (checkField === true) {
        toast.error("Complete the fields and submit", { id: 1 });
        return false;
      }
    }
    setSubmitLoader(true);
    const res = await dispatch(
      addSingleAttorneyPost(
        {
          firm: firmData.id,
          attorney_code: attorneyDetail.attorney_code,
          first_name: attorneyDetail.first_name,
          last_name: attorneyDetail.last_name,
          email: attorneyDetail.email,
          cell_phone_number: formatcell_phone_number(
            attorneyDetail.cell_phone_number
          ),
        },
        navigate
      )
    );
    if (res.payload) {
      setSubmitLoader(false);
      toast.success("Attorney Created");
      setOpenAddAttorneyModal(false);
      setAttorneyDetail({
        ...attorneyDetail,
        first_name: "",
        last_name: "",
        email: "",
        cell_phone_number: "",
        attorney_code: "",
      });
      setRefetchAttorney(!refetchAttorney);
    }
    if (res.error) {
      setSubmitLoader(false);

      let errorMsg = JSON.parse(res.error.message);
      if (errorMsg?.cell_phone_number || errorMsg?.email) {
        setErrorFlag({
          ...errorFlag,
          first_name: errorMsg?.first_name,
          last_name: errorMsg?.last_name,
          email: errorMsg?.email,
          cell_phone_number: errorMsg?.cell_phone_number,
        });
      }

      toast.error(res.error.message);
    }
  };

  return (
    <>
      <div className="w-full">
        {firmLoader ? (
          <div className="w-full h-screen flex justify-center items-center">
            <Oval
              height={80}
              width={80}
              color="#1a3253"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#396181"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        ) : (
          <div>
            <motion.div
              initial={{ y: 100 }}
              animate={{ y: 0 }}
              transition={{ delay: 0.1, type: "spring", stiffness: 120 }}
            >
              <main className="py-5">
                <div className="w-full flex flex-col justify-around xl:flex-col">
                  <div className="w-full my-5">
                    <div className="ml-2">
                      <div className="text-xl font-bold">
                        {firmData && firmData.firm_name}
                        {firmData && ` : (${firmData.firm_code})`}
                      </div>
                      <div className="text-sm text-[#9ca0b3]">
                        View Firm Details
                      </div>
                    </div>
                    <div className="w-full my-4">
                      <div className="w-full text-[#344767]  rounded-xl border shadow-lg">
                        <div className="flex justify-end">
                          <div
                            className="bg-[#1a3253] p-1 rounded-lg"
                            onClick={() => {
                              navigate("/update-firm");
                            }}
                          >
                            <PencilSquareIcon
                              className="h-8 w-8 text-white hover:cursor-pointer"
                              onClick={() => {
                                navigate("/update-firm");
                              }}
                            />
                          </div>
                        </div>
                        <div className="bg-white py-4">
                          <div className="mx-auto max-w-7xl px-6 lg:px-8">
                            <div className="border-b pb-1 mx-auto max-w-2xl lg:mx-0">
                              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                                {firmData && firmData.firm_name}
                              </h2>
                            </div>
                            <dl className="mx-auto mt-5 grid max-w-2xl grid-cols-1 gap-x-4 gap-y-4 text-base leading-7 sm:grid-cols-3 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                              <div>
                                <dt className="font-semibold text-gray-900">
                                  Primary Phone
                                </dt>
                                <dd className="mt-1 text-gray-600">
                                  {firmData && firmData?.cell_phone_number
                                    ? formatUSCell(firmData?.cell_phone_number)
                                    : firmData?.cell_phone_number}
                                </dd>
                              </div>
                              <div>
                                <dt className="font-semibold text-gray-900">
                                  Email
                                </dt>
                                <dd className="mt-1 text-gray-600">
                                  {firmData && firmData.email}
                                </dd>
                              </div>
                              <div>
                                <dt className="font-semibold text-gray-900">
                                  Address
                                </dt>
                                <dd className="mt-1 text-gray-600">
                                  {firmData &&
                                    ` ${firmData.address_line1},  ${
                                      firmData.address_line2
                                    },${firmData.city}  ${
                                      firmData?.state &&
                                      getStateByCode(firmData?.state).name
                                    } ${firmData.zip_code}`}
                                </dd>
                              </div>
                              <div>
                                <dt className="font-semibold text-gray-900">
                                  UNIQUE CODE
                                </dt>
                                <dd className="mt-1 text-gray-600">
                                  {firmData && firmData.firm_code}
                                </dd>
                              </div>
                              <div>
                                <dt className="font-semibold text-gray-900">
                                  Escrow Percentage
                                </dt>
                                <dd className="mt-1 text-gray-600">
                                  {firmData.financial_details &&
                                    ` ${firmData.financial_details.escrow_percentage} %`}
                                </dd>
                              </div>
                              <div>
                                <dt className="font-semibold text-gray-900">
                                  Escrow Limit
                                </dt>
                                <dd className="mt-1 text-gray-600">
                                  {firmData.financial_details &&
                                    `${formatUSD(
                                      firmData.financial_details.escrow_limit
                                    )} `}
                                </dd>
                              </div>
                              <div>
                                <dt className="font-semibold text-gray-900">
                                  NA Service Fee
                                </dt>
                                <dd className="mt-1 text-gray-600">
                                  {firmData.financial_details &&
                                    ` ${firmData.financial_details.na_fee_percentage} %`}
                                </dd>
                              </div>
                              <div>
                                <dt className="font-semibold text-gray-900">
                                  LOC
                                </dt>
                                <dd className="mt-1  text-gray-600">
                                  {firmData.financial_details &&
                                    ` ${formatUSD(
                                      firmData?.financial_details.loc
                                    )}`}
                                </dd>
                              </div>
                              <div>
                                <dt className="font-semibold text-gray-900">
                                  Escrow balance
                                </dt>
                                <dd className="mt-1  text-gray-600">
                                  {firmData.financial_details &&
                                    ` ${formatUSD(
                                      firmData?.financial_details?.escrow_actual
                                    )}`}
                                </dd>
                              </div>
                              <div>
                                <dt className="font-semibold text-gray-900">
                                  Escrow Withheld
                                </dt>
                                <dd className="mt-1  text-gray-600">
                                  {firmData.financial_details &&
                                    ` ${formatUSD(
                                      firmData?.financial_details
                                        ?.escrow_withheld
                                    )}`}
                                </dd>
                              </div>
                              {/* <div>
                                <dt className="font-semibold text-gray-900">
                                  Firm Status
                                </dt>
                                <dd>
                                  <div className="inline-flex items-center gap-x-1  py-1 font-bold text-[#415271]">
                                    {firmData.financial_details &&
                                    firmData?.financial_details?.active ? (
                                      <>
                                        <svg
                                          className="h-1.5 w-1.5 fill-green-500"
                                          viewBox="0 0 6 6"
                                          aria-hidden="true"
                                        >
                                          <circle cx={3} cy={3} r={3} />
                                        </svg>
                                        Active
                                      </>
                                    ) : (
                                      <>
                                        <svg
                                          className="h-1.5 w-1.5 fill-yellow-500"
                                          viewBox="0 0 6 6"
                                          aria-hidden="true"
                                        >
                                          <circle cx={3} cy={3} r={3} />
                                        </svg>
                                        Disabled
                                      </>
                                    )}
                                  </div>
                                </dd>
                              </div> */}
                              <div>
                                <dt className="font-semibold text-gray-900">
                                  Late Fees
                                </dt>
                                <dd className="mt-1  text-gray-600">
                                  {firmData.financial_details &&
                                    ` ${formatUSD(
                                      firmData?.financial_details?.late_fees
                                    )}`}
                                </dd>
                              </div>
                            </dl>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-full flex justify-end items-center p-5">
                      <button
                        onClick={() => {
                          setOpenEscrowModal(true);
                        }}
                        className="bg-transparent hover:bg-[#1a3253] text-[#1a3253] font-semibold hover:text-white transition ease-in-out py-2 px-4 border border-[#1a3253] hover:border-transparent rounded duration-100"
                      >
                        Financial Adjustments
                      </button>
                    </div>
                  </div>
                  <div className="w-full overflow-hidden rounded-xl my-5">
                    <div className="px-2 py-2">
                      <div className="ml-2">
                        <div className="text-md font-bold">Attorneys</div>
                        <div className="text-sm text-[#9ca0b3]">
                          List of Active Attorneys
                        </div>
                      </div>
                      <div className="p-1 border rounded-lg">
                        <MaterialReactTable
                          muiTableProps={{
                            elevation: 0,

                            sx: {
                              borderRadius: "10px",
                            },
                          }}
                          renderTopToolbarCustomActions={({ table }) => (
                            <Box
                              sx={{ display: "flex", gap: "1rem", p: "2px" }}
                            >
                              <button
                                onClick={() => {
                                  setOpenAddAttorneyModal(true);
                                }}
                                type="button"
                                className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-50 px-2.5 py-1.5 text-sm font-semibold text-[#376080] shadow-sm hover:bg-indigo-100"
                              >
                                <PlusIcon
                                  className="-ml-0.5 h-5 w-5"
                                  aria-hidden="true"
                                />
                                Add Attorney
                              </button>
                            </Box>
                          )}
                          enableTopToolbar={true}
                          enableStickyHeader
                          muiTableContainerProps={{
                            sx: { maxHeight: "500px" },
                          }}
                          muiTableBodyProps={{
                            sx: () => ({
                              "& tr:nth-child(odd)": {
                                backgroundColor: "#f8f9fa",
                              },
                              "& tr:nth-child(even)": {
                                backgroundColor: "#ffffff",
                              },
                            }),
                          }}
                          muiTablePaperProps={{
                            sx: {
                              maxWidth: "full",
                            },
                            elevation: 0,
                          }}
                          muiTableHeadCellProps={{
                            sx: (theme) => ({
                              color: theme.palette.text.primary,
                            }),
                          }}
                          enableFullScreenToggle={false}
                          enableDensityToggle={false}
                          enableHiding={false}
                          enableColumnFilters={true}
                          rowNumberMode="static"
                          muiTablePaginationProps={{
                            rowsPerPageOptions: [1, 2, 5, 10, 15, 20, 25],
                            showFirstButton: false,
                            showLastButton: false,
                          }}
                          columns={columns}
                          data={data}
                          muiTableBodyRowProps={({ row }) => ({
                            onClick: () => {
                              if (data.length) {
                                localStorage.setItem(
                                  "view_attorney_id",
                                  data[row.id].attorney_id
                                );
                                navigate(`/attorney-profile`);
                              }
                            },

                            sx: {
                              cursor: "pointer",
                            },
                          })}
                          getRowId={(row) => row.id}
                          initialState={{
                            showColumnFilters: false,
                            density: "compact",
                          }}
                          manualPagination
                          manualSorting
                          muiToolbaralertBannerProps={
                            isError
                              ? {
                                  color: "error",
                                  children: "Error loading data",
                                }
                              : undefined
                          }
                          onColumnFiltersChange={setColumnFilters}
                          onGlobalFilterChange={setSearch}
                          onPaginationChange={setPagination}
                          onSortingChange={setSorting}
                          rowCount={rowCount}
                          state={{
                            isLoading,
                            pagination,
                            showalertBanner: isError,
                            showProgressBars: isRefetching,
                            sorting,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <PendingClients />
                <ApprovedClientsTable />
                {role == "admin" && firmData ? (
                  <FirmDataVersionTable
                    firmData={firmData}
                    refetchVersion={refreshData}
                  />
                ) : (
                  ""
                )}
              </main>
            </motion.div>
          </div>
        )}
      </div>

      <ModalComponent open={openEscrowModal} handleClose={() => {}}>
        <>
          <div className="flex justify-center items-center">
            <div className="w-full  relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all  sm:max-w-lg">
              <div className="w-full rounded-lg p-5">
                <div className="flex flex-col items-center justify-between px-2 py-4 rounded-t sm:flex-row">
                  <h3 className="text-xl font-semibold text-gray-900">
                    Financial Adjustments
                  </h3>
                  {/* <div className="self-end inline-flex gap-2">
                    Firm Status:
                    <Switch
                      checked={escrowAdjust.status}
                      onChange={(e) => {
                        
                        setEscrowAdjust({
                          ...escrowAdjust,
                          status: e,
                        });
                      }}
                      className={classNames(
                        escrowAdjust.status ? "bg-indigo-600" : "bg-gray-200",
                        "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none"
                      )}
                    >
                      <span
                        aria-hidden="true"
                        className={classNames(
                          escrowAdjust.status
                            ? "translate-x-5"
                            : "translate-x-0",
                          "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                        )}
                      />
                    </Switch>
                  </div> */}
                </div>

                <div className="w-full">
                  <form
                    onSubmit={(e) => {
                      handleEscrowAdjustSubmit(e);
                    }}
                  >
                    <div className="w-full  border-gray-900/10 mt-5">
                      <div className="w-full mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="w-full sm:col-span-3">
                          <label className="block text-sm font-medium leading-6 text-gray-900">
                            Escrow Percentage
                          </label>
                          <div className="w-full mt-2">
                            <input
                              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                              id="escrowPercentage"
                              type="text"
                              name="escrow_percentage"
                              placeholder="Enter Escrow Percentage"
                              value={escrowAdjust.escrow_percentage}
                              onChange={(e) => {
                                handleEscrowAdjustChange(e);
                              }}
                            />
                          </div>
                          <span className="text-red-500 text-xs">
                            {escrowAdjustError.escrow_percentage}
                          </span>
                        </div>

                        <div className="w-full sm:col-span-3">
                          <label className="block text-sm font-medium leading-6 text-gray-900">
                            Escrow Limit
                          </label>
                          <div className="w-full mt-2">
                            <InputNumberFormat
                              name="escrow_limit"
                              id="escrow_limit"
                              placeholder="Enter Escrow Limit"
                              value={escrowAdjust.escrow_limit}
                              onChange={(e) => {
                                handleEscrowAdjustChange(e);
                              }}
                              currency="USD"
                              format="currency"
                              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />
                          </div>
                          <span className="text-red-500 text-xs">
                            {escrowAdjustError.escrow_limit}
                          </span>
                        </div>

                        <div className="w-full sm:col-span-3">
                          <label className="block text-sm font-medium leading-6 text-gray-900">
                            NA Fee Percentage
                          </label>
                          <div className="w-full mt-2">
                            <input
                              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                              id="feePercentage"
                              type="text"
                              name="na_fee_percentage"
                              placeholder="Enter Fee Percentage"
                              value={escrowAdjust.na_fee_percentage}
                              onChange={(e) => {
                                handleEscrowAdjustChange(e);
                              }}
                            />
                          </div>
                          <span className="text-red-500 text-xs">
                            {escrowAdjustError.na_fee_percentage}
                          </span>
                        </div>

                        <div className="w-full sm:col-span-3">
                          <label className="block text-sm font-medium leading-6 text-gray-900">
                            LOC
                          </label>
                          <div className="w-full mt-2">
                            <InputNumberFormat
                              name="loc"
                              id="loc"
                              placeholder="Enter LOC"
                              value={escrowAdjust.loc}
                              onChange={(e) => {
                                handleEscrowAdjustChange(e);
                              }}
                              currency="USD"
                              format="currency"
                              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />
                          </div>
                          <span className="text-red-500 text-xs">
                            {escrowAdjustError.loc}
                          </span>
                        </div>

                        <div className="w-full sm:col-span-3">
                          <label className="block text-sm font-medium leading-6 text-gray-900">
                            Escrow Balance
                          </label>
                          <div className="w-full mt-2">
                            <InputNumberFormat
                              name="escrow_balance"
                              id="escrow_balance"
                              disabled={true}
                              placeholder="Enter Escrow Balance"
                              value={escrowAdjust.escrow_balance}
                              onChange={(e) => {
                                handleEscrowAdjustChange(e);
                              }}
                              currency="USD"
                              format="currency"
                              className="appearance-none border-b rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />
                          </div>
                          <span className="text-red-500 text-xs">
                            {escrowAdjustError.escrow_balance}
                          </span>
                        </div>
                        <div className="w-full sm:col-span-3">
                          <label className="block text-sm font-medium leading-6 text-gray-900">
                            Late Fee
                          </label>
                          <div className="w-full mt-2">
                            <InputNumberFormat
                              name="late_fees"
                              id="late_fees"
                              placeholder="Enter Late Fee"
                              value={escrowAdjust.late_fees}
                              onChange={(e) => {
                                handleEscrowAdjustChange(e);
                              }}
                              currency="USD"
                              format="currency"
                              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />
                          </div>
                          <span className="text-red-500 text-xs">
                            {escrowAdjustError.late_fees}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="w-full flex justify-end gap-2">
                      <button
                        type="submit"
                        disabled={contractEditLoader}
                        className="inline-flex gap-3 justify-center items-center mt-5 rounded-md bg-[#2991e0] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#1991e0] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        Submit
                        <div>
                          {contractEditLoader && (
                            <Oval
                              height={20}
                              width={20}
                              color="#ffff"
                              wrapperStyle={{}}
                              wrapperClass=""
                              visible={true}
                              ariaLabel="oval-loading"
                              secondaryColor="#ffff"
                              strokeWidth={2}
                              strokeWidthSecondary={2}
                            />
                          )}
                        </div>
                      </button>

                      <button
                        type="button"
                        onClick={() => {
                          setEscrowAdjustError({
                            ...escrowAdjustError,
                            escrow_limit: "",
                            escrow_percentage: "",
                            na_fee_percentage: "",
                            loc: "",
                            escrow_balance: "",
                            status: "",
                            late_fees: "",
                          });
                          handleClose();
                          setEscrowAdjust({
                            ...escrowAdjust,
                            escrow_limit: firmData.financial_details
                              .escrow_limit
                              ? formatUSD(
                                  firmData.financial_details.escrow_limit
                                )
                              : "",
                            escrow_percentage: firmData.financial_details
                              .escrow_percentage
                              ? firmData.financial_details.escrow_percentage
                              : "",
                            na_fee_percentage: firmData.financial_details
                              .na_fee_percentage
                              ? firmData.financial_details.na_fee_percentage
                              : "",
                            escrow_balance: firmData.financial_details
                              .escrow_actual
                              ? formatUSD(
                                  firmData.financial_details.escrow_actual
                                )
                              : "",
                            loc: firmData.financial_details.loc
                              ? formatUSD(firmData.financial_details.loc)
                              : "",
                            status: firmData.financial_details.active,
                            late_fees: firmData.financial_details.late_fees
                              ? formatUSD(firmData.financial_details.late_fees)
                              : "",
                          });
                        }}
                        className="mt-5 rounded-md bg-[#2991e0] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#1991e0] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      </ModalComponent>
      <ModalComponent open={openAddAttorneyModal} handleClose={() => {}}>
        <div className="flex justify-center items-center">
          <div className="w-full  relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all  sm:max-w-lg">
            <div className="w-full p-5">
              <div className="w-full rounded-lg ">
                <div className="w-full">
                  <div className="w-full">
                    <div className="text-xl font-bold">ADD ATTORNEYS</div>
                  </div>

                  <div className="w-full">
                    <form
                      onSubmit={(e) => {
                        submitAttorneyHandler(e);
                      }}
                    >
                      <div className="w-full">
                        <div className="w-full  border-gray-900/10 mt-5 ">
                          {attorneyDetail.attorney_code == "" && (
                            <div className="font-bold">
                              Attorney Code: <span className="ml-2">--</span>
                            </div>
                          )}
                          {attorneyDetail.attorney_code !== "" && (
                            <>
                              <div className="flex gap-2">
                                <div className="font-bold">Attorney Code:</div>
                                <div>
                                  {!attorneyCodeLoader ? (
                                    <>{attorneyDetail.attorney_code}</>
                                  ) : (
                                    <span>
                                      <TailSpin
                                        height="20"
                                        width="20"
                                        color="black"
                                        ariaLabel="tail-spin-loading"
                                        radius="1"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                      />
                                    </span>
                                  )}
                                </div>
                              </div>
                            </>
                          )}
                          <div className="w-full mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="w-full sm:col-span-4">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Firm
                              </label>
                              <div>{`${firmData.firm_name} : ${firmData.firm_code}`}</div>
                            </div>

                            <div className="w-full sm:col-span-3">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                First name
                              </label>
                              <div className="w-full mt-2">
                                <input
                                  className="w-full mt-2 border-b-1 focus:border-[#344767] block  outline-none  border-b-2 text-[#344767] "
                                  id="first_name"
                                  name="first_name"
                                  onChange={(e) => handleChange(e)}
                                  value={attorneyDetail.first_name}
                                />
                              </div>
                              <span className="text-red-500 text-xs">
                                {errorFlag.first_name}
                              </span>
                            </div>

                            <div className="w-full sm:col-span-3">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Last name
                              </label>
                              <div className="w-full mt-2">
                                <input
                                  className="w-full mt-2 border-b-1 focus:border-[#344767] block  outline-none  border-b-2 text-[#344767] "
                                  id="last_name"
                                  name="last_name"
                                  onChange={(e) => handleChange(e)}
                                  value={attorneyDetail.last_name}
                                />
                              </div>
                              <span className="text-red-500 text-xs">
                                {errorFlag.last_name}
                              </span>
                            </div>

                            <div className="w-full sm:col-span-3">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Email address
                              </label>
                              <div className="w-full mt-2">
                                <input
                                  className="w-full mt-2 border-b-1 focus:border-[#344767] block  outline-none  border-b-2 text-[#344767] "
                                  id="email"
                                  name="email"
                                  onChange={(e) => handleChange(e)}
                                  value={attorneyDetail.email}
                                />
                              </div>
                              <span className="text-red-500 text-xs">
                                {errorFlag.email}
                              </span>
                            </div>

                            <div className="w-full sm:col-span-3">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Primary Phone
                              </label>
                              <div className="w-full mt-2">
                                <InputMask
                                  name="cell_phone_number"
                                  value={attorneyDetail.cell_phone_number}
                                  onChange={(e) => handleChange(e)}
                                  className="w-full mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767] "
                                  mask="+1 (___) ___-____"
                                  replacement={{ _: /\d/ }}
                                />
                              </div>
                              <span className="text-red-500 text-xs">
                                {errorFlag.cell_phone_number}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="w-full flex justify-end gap-2">
                          <button
                            type="submit"
                            disabled={submitLoader}
                            className="mt-5 rounded-md bg-[#2991e0] px-3 py-2 text-sm font-semibold text-white shadow-sm inline-flex gap-3 justify-center items-center hover:bg-[#1991e0] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          >
                            Submit
                            <div>
                              {submitLoader && (
                                <Oval
                                  height={20}
                                  width={20}
                                  color="#ffff"
                                  wrapperStyle={{}}
                                  wrapperClass=""
                                  visible={true}
                                  ariaLabel="oval-loading"
                                  secondaryColor="#ffff"
                                  strokeWidth={2}
                                  strokeWidthSecondary={2}
                                />
                              )}
                            </div>
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              setOpenAddAttorneyModal(false);
                              setAttorneyDetail({
                                ...attorneyDetail,
                                first_name: "",
                                last_name: "",
                                email: "",
                                cell_phone_number: "",
                                attorney_code: "",
                              });
                              setErrorFlag({
                                first_name: "",
                                last_name: "",
                                email: "",
                                cell_phone_number: "",
                                attorney_code: "",
                              });
                            }}
                            className="mt-5 rounded-md bg-[#2991e0] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#1991e0] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalComponent>
    </>
  );
}
