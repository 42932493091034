import { useEffect, useMemo, useState } from "react";

import {
  Chart as ChartJS,
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import MaterialReactTable from "material-react-table";
import { Hourglass, ThreeDots } from "react-loader-spinner";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import SmsIcon from "@mui/icons-material/Sms";
import {
  Avatar,
  Button,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import ExportImg from "../../assets/img/excel-img.png";
import { useNavigate } from "react-router-dom";
import { domainUrl, logOutHandler } from "../../utils/constants";
import axios from "axios";
import toast from "react-hot-toast";
import FormControl from "@mui/material/FormControl";
ChartJS.register(
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  Tooltip,
  Legend
);

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function MissedPaymentClientsTable() {
  const [data, setData] = useState([]);

  const [isError, setIsError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [isRefetching, setIsRefetching] = useState(false);

  const [rowCount, setRowCount] = useState(0);

  const role = localStorage.getItem("role");
  const access_token = localStorage.getItem("access_token");

  const [totalCount, setTotalCount] = useState(2);
  const navigate = useNavigate();

  const [columnFilters, setColumnFilters] = useState([]);

  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState([]);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [exportData, setExportdata] = useState();
  const [filterValue, setFilterValue] = useState("all");

  const exportFileHandler = async (id) => {
    const url = new URL("clients", `${domainUrl}`);
    url.searchParams.set("status", `missed-payments`);
    url.searchParams.set("offset", pagination.pageIndex * pagination.pageSize);
    url.searchParams.set("limit", `${pagination.pageSize}`);
    url.searchParams.set("offset", `${pagination.pageIndex}`);
    url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
    url.searchParams.set("search", search ?? "");
    url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
    url.searchParams.set("export", true);

    axios
      .get(url, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        if (response.headers.get("content-type") == "application/json") {
          response.data.text().then((text) => {
            toast.success(JSON.parse(text).scheduled, { autoClose: 10000 });
          });
        } else {
          let filename = `Missed Payment Clients_${Date.now()}.xlsx`;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
        }
      });
  };

  const formatDate = (dateString) => {
    if (dateString) {
      const date = dateString.split("-");
      return `${date[1]}/${date[2]}/${date[0]}`;
    }
    return dateString;
  };

  function formatUSD(number) {
    if (typeof number !== "number" || isNaN(number)) {
      if (typeof number == "string") {
        number = parseFloat(number);
      } else {
        return "Invalid number";
      }
    }

    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    return formatter.format(number);
  }

  const [notifyEmailLoaders, setNotifyEmailLoaders] = useState({});
  const [notifySmsLoaders, setNotifySmsLoaders] = useState({});
  const [tableRefetch, setTableRefetch] = useState(false);

  function extractDateFromTimestamp(timestamp) {
    const dateObject = new Date(timestamp);
    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
    const day = dateObject.getDate().toString().padStart(2, "0");
    const year = dateObject.getFullYear();
    return `${month}/${day}/${year}`;
  }

  function extractTimeFromTimestamp(timestamp) {
    const dateObject = new Date(timestamp);
    const hour = dateObject.getHours().toString().padStart(2, "0");
    const minute = dateObject.getMinutes().toString().padStart(2, "0");
    return `${hour}:${minute}`;
  }

  const notifyEmailInvitation = async (id) => {
    try {
      setNotifyEmailLoaders({ ...notifyEmailLoaders, [id]: true });
      const res = await axios.post(
        `${domainUrl}missed-payments-reminder?id=${id}&type=email`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (res.data) {
        setTableRefetch(!tableRefetch);
        setNotifyEmailLoaders({ ...notifyEmailLoaders, [id]: false });
        toast.success(res.data.message, { id: 1 });
      }
    } catch (error) {
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
      setNotifyEmailLoaders({ ...notifyEmailLoaders, [id]: false });
      toast.error("Something went wrong", { id: 1 });
    }
  };
  const notifySmsInvitation = async (id) => {
    try {
      setNotifySmsLoaders({ ...notifySmsLoaders, [id]: true });
      const res = await axios.post(
        `${domainUrl}missed-payments-reminder?id=${id}&type=sms`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (res.data) {
        setTableRefetch(!tableRefetch);
        setNotifySmsLoaders({ ...notifySmsLoaders, [id]: false });
        toast.success(res.data.message, { id: 1 });
      }
    } catch (error) {
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
      setNotifySmsLoaders({ ...notifySmsLoaders, [id]: false });
      toast.error("Something went wrong", { id: 1 });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!data.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      const url = new URL("clients", `${domainUrl}`);
      url.searchParams.set("status", `missed-payments`);
      url.searchParams.set(
        "date_range",
        filterValue == "all" ? "" : filterValue
      );
      url.searchParams.set(
        "offset",
        pagination.pageIndex * pagination.pageSize
      );
      url.searchParams.set("limit", `${pagination.pageSize}`);
      url.searchParams.set("offset", `${pagination.pageIndex}`);
      url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
      url.searchParams.set("search", search ?? "");
      url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
      url.searchParams.set("table", `true`);
      try {
        const response = await fetch(url.href, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        });
        let json = await response.json();
        let data = json;

        if (data.code == "token_not_valid") {
          logOutHandler();
          navigate("/login");
          toast.error("Session Expired", { id: 1 });
          return;
        }
        setData(data.data);
        setRowCount(data.count);
      } catch (error) {
        setIsError(true);

        return;
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    };

    fetchData();
  }, [
    columnFilters,
    search,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    filterValue,
  ]);

  const columns = useMemo(
    () => [
      {
        id: "clients",
        header: "",
        columns: [
          role == "admin"
            ? {
                accessorFn: (row) =>
                  `${row?.contracts?.[0]?.attorney?.firm.firm_name} `,
                id: "contracts__attorney__firm__firm_name",
                header: "Firm Name",
                accessorKey: "firm_name",
                enableColumnActions: false,
                size: 30,
              }
            : null,
          {
            accessorFn: (row) =>
              `${row?.user?.first_name} ${row?.user?.last_name} `,
            id: "user_model__first_name",
            header: "Client Name",
            accessorKey: "first_name",
            enableColumnActions: false,
            size: 30,
          },
          {
            id: "user_model__email",
            header: "Email",
            accessorFn: (row) => `${row?.user?.email}`,
            accessorKey: "email",
            enableColumnActions: false,
            size: 30,
          },
          {
            id: "contracts__case_type",
            header: "Case Type",
            accessorFn: (row) => `${row?.contracts?.[0].case_type}`,
            accessorKey: "case_type",
            enableColumnActions: false,

            size: 30,
          },
          {
            id: "contracts__contract_amount",
            header: "Total Contract Amount",
            accessorFn: (row) =>
              `${formatUSD(row?.contracts?.[0].contract_amount)}`,
            enableColumnActions: false,
            accessorKey: "contract_amount",
          },
          {
            id: "contracts__amount_due",
            header: "Amount Due",
            accessorFn: (row) => `${formatUSD(row?.contracts?.[0].amount_due)}`,
            enableColumnActions: false,

            accessorKey: "amount_due",
            size: 30,
          },
          {
            id: "missed_payments",
            header: "Missed Payments",
            accessorFn: (row) => `${row?.contracts?.[0].missed_payments}`,
            enableColumnActions: false,
            accessorKey: "missed_payments",
          },
          {
            id: "last_missed_date",
            header: "Last Missed Date",
            accessorFn: (row) =>
              row?.contracts?.[0]?.last_missed_date
                ? formatDate(row.contracts[0].last_missed_date)
                : "N/A",
            accessorKey: "last_missed_date",
            muiTableHeadCellFilterTextFieldProps: { placeholder: "YYYY-MM-DD" },
            enableColumnActions: false,
            size: 30,
          },
        ].filter(Boolean),
      },
    ],

    []
  );

  const [rowSelection, setRowSelection] = useState({});
  const [bulkEmailStatus, setBulkEmailStatus] = useState(false);
  const [bulkSmsStatus, setBulkSmsStatus] = useState(false);
  const [bulkEmailNotifyLoaders, setBulkEmailNotifyLoaders] = useState(false);
  const [bulkSmsNotifyLoaders, setBulkSmsNotifyLoaders] = useState(false);

  useEffect(() => {
    const length = Object.keys(rowSelection).length;

    if (length >= 1) {
      setBulkEmailStatus(true);
      setBulkSmsStatus(true);
    } else {
      setBulkEmailStatus(false);
      setBulkSmsStatus(false);
    }
  }, [rowSelection]);

  const sendBulk = async (type) => {
    const choosenRows = Object.keys(rowSelection).filter(
      (value) => rowSelection[value]
    );

    const idsToSend = choosenRows.map((index) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].id == index) {
          return data[i].id;
        }
      }
    });

    let url = "";
    if (idsToSend.length > 1) {
      url = `${domainUrl}missed-payments-reminder?ids=${idsToSend}&type=${type}`;
    } else {
      url = `${domainUrl}missed-payments-reminder?id=${idsToSend}&type=${type}`;
    }
    if (type == "sms") {
      try {
        setBulkSmsNotifyLoaders(true);
        const res = await axios.post(
          url,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${access_token}`,
            },
          }
        );
        if (res.data) {
          setTableRefetch(!tableRefetch);
          setBulkSmsNotifyLoaders(false);
          toast.success(res.data.message, { id: 1 });
          setRowSelection({});
        }
      } catch (error) {
        if (error.response?.data.code == "token_not_valid") {
          logOutHandler();
          navigate("/login");
          toast.error("Session Expired", { id: 1 });
          return;
        }
        setBulkSmsNotifyLoaders(false);
      }
    } else {
      try {
        setBulkEmailNotifyLoaders(true);
        const res = await axios.post(
          url,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${access_token}`,
            },
          }
        );
        if (res.data) {
          setTableRefetch(!tableRefetch);
          setBulkEmailNotifyLoaders(false);
          toast.success(res.data.message, { id: 1 });
          setRowSelection({});
        }
      } catch (error) {
        if (error.response?.data.code == "token_not_valid") {
          logOutHandler();
          navigate("/login");
          toast.error("Session Expired", { id: 1 });
          return;
        }
        setBulkEmailNotifyLoaders(false);
      }
    }
  };

  return (
    <>
      <div>
        <main className="py-10">
          <main className="w-full py-10 ">
            <div className="mx-5">
              <h2
                style={{
                  color: "#1a3253",
                }}
              >
                Missed Payment Clients
              </h2>
              <div className="text-base text-[#9ca0b3] my-2">
                List of Clients who missed payments
              </div>
              {/* {range?.start_date ? (
                <div className="my-2 flex items-center gap-3">
                  <span>From:</span>
                  <span className="font-semibold">{formatDate(range?.start_date)}</span>
                  <span>To</span>
                  <span className="font-semibold">{formatDate(range?.end_date)}</span>
                </div>
              ) : null} */}

              <div className="p-1 border rounded-lg">
                <MaterialReactTable
                  enableTopToolbar={true}
                  enableRowSelection={
                    role == "admin" || role == "primaryattorney" ? true : false
                  }
                  renderTopToolbarCustomActions={
                    role == "admin" || role == "primaryattorney"
                      ? ({ table }) => (
                          <>
                            <div className="sm:flex justify-between gap-3">
                              <div className="flex flex-col items-start sm:flex-row sm:items-center gap-2">
                                <div>
                                  {bulkSmsNotifyLoaders ? (
                                    <button
                                      className={`rounded text-white p-2 text-sm bg-blue-800`}
                                    >
                                      <div className="w-full flex  items-center gap-2">
                                        <div className="inline-flex items-center gap-2">
                                          Notify via SMS
                                          <ThreeDots
                                            height={20}
                                            width={20}
                                            radius="9"
                                            color="#ffff"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                          />
                                        </div>
                                      </div>
                                    </button>
                                  ) : (
                                    <button
                                      onClick={() => {
                                        sendBulk("sms");
                                      }}
                                      disabled={!bulkSmsStatus}
                                      className={`rounded text-white p-2 text-sm ${
                                        bulkSmsStatus
                                          ? "bg-blue-600 hover:bg-blue-800"
                                          : "bg-blue-300"
                                      }`}
                                    >
                                      <div className="w-full flex  items-center gap-2">
                                        <div className="inline-flex items-center gap-2">
                                          Notify via SMS <SmsIcon />
                                        </div>
                                      </div>
                                    </button>
                                  )}
                                </div>
                                <div>
                                  {bulkEmailNotifyLoaders ? (
                                    <button
                                      className={`rounded text-white p-2 text-sm bg-blue-800`}
                                    >
                                      <div className="w-full flex  items-center gap-2">
                                        <div className="inline-flex items-center gap-2">
                                          Notify via Email
                                          <ThreeDots
                                            height={20}
                                            width={20}
                                            radius="9"
                                            color="#ffff"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                          />
                                        </div>
                                      </div>
                                    </button>
                                  ) : (
                                    <button
                                      onClick={() => {
                                        sendBulk("email");
                                      }}
                                      disabled={!bulkEmailStatus}
                                      className={`rounded text-white p-2 text-sm ${
                                        bulkEmailStatus
                                          ? "bg-blue-600 hover:bg-blue-800"
                                          : "bg-blue-300"
                                      }`}
                                    >
                                      <div className="w-full flex  items-center gap-2">
                                        <div className="inline-flex items-center gap-2">
                                          Notify via Email{" "}
                                          <ForwardToInboxIcon />
                                        </div>
                                      </div>
                                    </button>
                                  )}
                                </div>
                              </div>
                              {role == "admin" || role == "primaryattorney" ? (
                                <div className="mt-3 flex flex-col items-start gap-3 sm:flex-row sm:items-center sm:mt-0">
                                  <Button
                                    disabled={false}
                                    startIcon={<Avatar src={ExportImg} />}
                                    onClick={() => {
                                      exportFileHandler();
                                    }}
                                    variant="contained"
                                    sx={{
                                      bgcolor: "#127c44",
                                      "&:hover": {
                                        bgcolor: "#127c44",
                                      },
                                      height: "25px",
                                    }}
                                  >
                                    Export
                                  </Button>

                                  <FormControl
                                    sx={{ m: 1, minWidth: 120 }}
                                    size="small"
                                  >
                                    <InputLabel id="demo-select-small-label">
                                      Filter
                                    </InputLabel>
                                    <Select
                                      labelId="demo-select-small-label"
                                      id="demo-select-small"
                                      value={filterValue}
                                      label="Filter"
                                      onChange={(event) => {
                                        setFilterValue(event.target.value);
                                      }}
                                    >
                                      <MenuItem value={"all"}>All</MenuItem>
                                      <MenuItem value={7}>7 days ago</MenuItem>
                                      <MenuItem value={10}>
                                        10 days ago
                                      </MenuItem>
                                      <MenuItem value={30}>
                                        30 days ago
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </>
                        )
                      : null
                  }
                  onRowSelectionChange={setRowSelection}
                  enableStickyHeader
                  muiTableContainerProps={{ sx: { maxHeight: "500px" } }}
                  muiTableBodyProps={{
                    sx: () => ({
                      "& tr:nth-child(odd)": {
                        backgroundColor: "#f8f9fa",
                      },
                      "& tr:nth-child(even)": {
                        backgroundColor: "#ffffff",
                      },
                    }),
                  }}
                  muiTablePaperProps={{
                    sx: {
                      maxWidth: "full",
                    },
                    elevation: 0,
                  }}
                  muiTableHeadCellProps={{
                    sx: (theme) => ({
                      color: theme.palette.text.primary,
                    }),
                  }}
                  enableFullScreenToggle={false}
                  enableDensityToggle={false}
                  enableHiding={false}
                  enableColumnFilters={true}
                  rowNumberMode="static"
                  muiTablePaginationProps={{
                    rowsPerPageOptions: [5, 10, 15, 20, 25],
                    showFirstButton: false,
                    showLastButton: false,
                  }}
                  columns={columns}
                  data={data}
                  enableRowActions
                  positionActionsColumn="last"
                  displayColumnDefOptions={{
                    "mrt-row-actions": {
                      header:
                        role == "admin" || role == "primaryattorney"
                          ? "Notify"
                          : null,
                    },
                  }}
                  renderRowActions={({ row }) => {
                    return (
                      <>
                        {(role == "admin" || role == "primaryattorney") && (
                          <div className="flex items-center">
                            <div>
                              {notifySmsLoaders[row.original.id] ? (
                                <IconButton>
                                  <Hourglass
                                    visible={true}
                                    height="25"
                                    width="25"
                                    ariaLabel="hourglass-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    colors={["#306cce", "#72a1ed"]}
                                  />
                                </IconButton>
                              ) : (
                                <IconButton
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    notifySmsInvitation(row.original.id);
                                  }}
                                >
                                  <SmsIcon
                                    sx={{
                                      color: "black",
                                    }}
                                  />
                                </IconButton>
                              )}
                            </div>
                            <div>
                              {notifyEmailLoaders[row.original.id] ? (
                                <IconButton>
                                  <Hourglass
                                    visible={true}
                                    height="25"
                                    width="25"
                                    ariaLabel="hourglass-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    colors={["#306cce", "#72a1ed"]}
                                  />
                                </IconButton>
                              ) : (
                                <IconButton
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    notifyEmailInvitation(row.original.id);
                                  }}
                                >
                                  <ForwardToInboxIcon
                                    sx={{
                                      color: "black",
                                    }}
                                  />
                                </IconButton>
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    );
                  }}
                  muiTableBodyRowProps={({ row }) => ({
                    onClick: () => {
                      localStorage.setItem("view_client_id", row.original.id);
                      navigate(`/client-view`);
                    },
                    sx: {
                      cursor: "pointer",
                    },
                  })}
                  getRowId={(row) => row.id}
                  initialState={{
                    showColumnFilters: false,
                    density: "compact",
                  }}
                  manualPagination
                  manualSorting
                  muiToolbaralertBannerProps={
                    isError
                      ? {
                          color: "error",
                          children: "Error loading data",
                        }
                      : undefined
                  }
                  onColumnFiltersChange={setColumnFilters}
                  onGlobalFilterChange={setSearch}
                  onPaginationChange={setPagination}
                  onSortingChange={setSorting}
                  rowCount={rowCount}
                  state={{
                    rowSelection,
                    isLoading,
                    pagination,
                    showalertBanner: isError,
                    showProgressBars: isRefetching,
                    sorting,
                  }}
                />
              </div>
            </div>
          </main>
        </main>
      </div>
    </>
  );
}
