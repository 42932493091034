import { useEffect, useMemo, useState } from "react";

import MaterialReactTable from "material-react-table";
import { useNavigate } from "react-router-dom";
import { domainUrl, logOutHandler } from "../../utils/constants";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import axios from "axios";
import { Avatar, Button } from "@mui/material";
import ExportImg from "../../assets/img/excel-img.png";


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function UpcomingPaymentsTable() {
  const [data, setData] = useState([]);

  const [isError, setIsError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [isRefetching, setIsRefetching] = useState(false);

  const [rowCount, setRowCount] = useState(0);

  const access_token = localStorage.getItem("access_token");
  const role = localStorage.getItem("role");

  const navigate = useNavigate();
  const dispatch = useDispatch();


  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState([{ id: "date", desc: true }]);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [refetchData, setRefetchData] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      if (!data?.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      const url = new URL("upcoming-payments", `${domainUrl}`);
      url.searchParams.set("offset", pagination.pageIndex);
      url.searchParams.set("limit", `${pagination.pageSize}`);
      url.searchParams.set("search", search ?? "");
      url.searchParams.set("sorting", JSON.stringify(sorting ?? []));

      try {
        const response = await axios.get(url.href, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        });
        let scheduleData = response;
        if (scheduleData.code == "token_not_valid") {
          logOutHandler();
          navigate("/login");
          toast.error("Session Expired", { id: 1 });
          return;
        }
        setData(scheduleData.data.data);
        setTotalAmount(scheduleData.data.total_amount || 0);
        setRowCount(scheduleData.data.count);
      } catch (error) {
        setIsError(true);
        return;
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    };

    fetchData();
  }, [
    search,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    refetchData,
  ]);

  const columns = useMemo(
    () => [
      {
        id: "payments",
        header: "",
        columns: [
          {
            id: "date",
            header: "Payment Date",
            accessorFn: (row) => formatDate(row.date),
            enableColumnActions: false,
            size: 30,
          },
          {
            id: "client",
            header: "Client",
            enableColumnActions: false,
            size: 30,
            Cell: ({ row }) => (
              <span
                onClick={() => {
                  localStorage.setItem("view_client_id", row.original.client.id);
                  navigate("/client-view");
                }}
                className="text-blue-600 hover:text-blue-800 cursor-pointer underline"
              >
                {`${row.original.client.name} (${row.original.client.client_code})`}
              </span>
            ),
          },
          {
            id: "amount",
            header: "Amount",
            enableColumnActions: false,
            size: 30,
            Cell: ({ row }) => (
              <span>
                ${parseFloat(row.original.amount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
              </span>
            ),
          },
          {
            id: "attorney",
            header: "Attorney",
            enableColumnActions: false,
            size: 30,
            Cell: ({ row }) => (
              <span
                onClick={() => {
                  localStorage.setItem("view_attorney_id", row.original.attorney.id);
                  navigate("/attorney-profile");
                }}
                className="text-blue-600 hover:text-blue-800 cursor-pointer underline"
              >
                {`${row.original.attorney.name} (${row.original.attorney.firm_name})`}
              </span>
            ),
          },

        ],
      },
    ],
    []
  );
  const formatDate = (dateString) => {
    if (dateString) {
      const date = dateString.split("-");
      return `${date[1]}/${date[2]}/${date[0]}`;
    }
    return dateString;
  };

  const exportFileHandler = async () => {
    const url = new URL("upcoming-payments", `${domainUrl}`);
    url.searchParams.set("offset", pagination.pageIndex);
    url.searchParams.set("limit", `${pagination.pageSize}`);
    url.searchParams.set("search", search ?? "");
    url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
    url.searchParams.set("export", true);

    axios
      .get(url.href, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        if (response.headers.get("content-type") == "application/json") {
          response.data.text().then((text) => {
            toast.success(JSON.parse(text).scheduled, { autoClose: 10000 });
          });
        } else {
          let filename = `Upcoming_Payments_${new Date().toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }).replace(/\//g, '')}.xlsx`;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
        }
      });
  };


  
  return (
    <>
      <div>
        <main className="py-10">
          <main className="w-full py-10 ">
            <div className="mx-5">
              <h2
                style={{
                  color: "#1a3253",
                }}
              >
                Upcoming Payments
              </h2>
              <div className="text-base text-[#9ca0b3] my-2"></div>

              <div className="p-1 border rounded-lg">
                <MaterialReactTable
                  enableTopToolbar={true}
                  renderTopToolbarCustomActions={({ table }) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          gap: "0.5rem",
                          alignItems: "center",
                        }}
                      >
                        {role == "admin" ? (
                          <Button
                            disabled={false}
                            startIcon={<Avatar src={ExportImg} />}
                            onClick={() => {
                              exportFileHandler();
                            }}
                            variant="contained"
                            sx={{
                              bgcolor: "#127c44",
                              "&:hover": {
                                bgcolor: "#127c44",
                              },
                              height: "25px",
                            }}
                          >
                            Export
                          </Button>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  }}
                  enableColumnFilters={false}
                  enableGlobalFilter={true}
                  enableToolbarInternalActions={true}
                  positionGlobalFilter="right"
                  enableStickyHeader
                  muiTableContainerProps={{ sx: { maxHeight: "500px" } }}
                  muiTableBodyProps={{
                    sx: () => ({
                      "& tr:nth-child(odd)": {
                        backgroundColor: "#f8f9fa",
                      },
                      "& tr:nth-child(even)": {
                        backgroundColor: "#ffffff",
                      },
                    }),
                  }}
                  muiTablePaperProps={{
                    sx: {
                      maxWidth: "full",
                    },
                    elevation: 0,
                  }}
                  muiTableHeadCellProps={{
                    sx: (theme) => ({
                      color: theme.palette.text.primary,
                    }),
                  }}
                  enableFullScreenToggle={false}
                  enableDensityToggle={false}
                  enableHiding={false}
                  rowNumberMode="static"
                  muiTablePaginationProps={{
                    rowsPerPageOptions: [1, 2, 5, 10, 15, 20, 25],
                    showFirstButton: false,
                    showLastButton: false,
                  }}
                  columns={columns}
                  data={data}
                  getRowId={(row) => row.id}
                  initialState={{
                    showColumnFilters: false,
                    density: "compact",
                  }}
                  manualPagination
                  manualSorting
                  muiToolbaralertBannerProps={
                    isError
                      ? {
                          color: "error",
                          children: "Error loading data",
                        }
                      : undefined
                  }
                  onGlobalFilterChange={setSearch}
                  onPaginationChange={setPagination}
                  onSortingChange={setSorting}
                  rowCount={rowCount}
                  renderBottomToolbarCustomActions={() => (
                    <div style={{ 
                      padding: '16px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '50%',
                      gap: '8px',
                      alignItems: 'center'
                    }}>
                      <span style={{ 
                        color: "#1a3253", 
                        fontSize: "16px"
                      }}>
                        Total Amount:
                      </span>
                      <span style={{ 
                        color: "#1a3253", 
                        fontWeight: "bold",
                        fontSize: "16px"
                      }}>
                        ${totalAmount?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || '0.00'}
                      </span>
                    </div>
                  )}
                  state={{
                    isLoading,
                    pagination,
                    showalertBanner: isError,
                    showProgressBars: isRefetching,
                    sorting,
                  }}
                />
              </div>
            </div>
          </main>
        </main>
      </div>
    </>
  );
}
