import { useEffect, useMemo, useState } from 'react';
import {
  CalendarIcon,
  ChartPieIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';
import { Line, Doughnut } from 'react-chartjs-2';

import {
  Chart as ChartJS,
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';

import MaterialReactTable from 'material-react-table';
import { Replay, ExitToApp } from '@mui/icons-material';
import { Hourglass } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import { domainUrl, logOutHandler } from '../../utils/constants';
import ExportImg from '../../assets/img/excel-img.png';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Avatar, Button, IconButton } from '@mui/material';
import { pauseClientPut } from '../../utils/redux/slice/pauseClientSlice';
import { useDispatch } from 'react-redux';
import { putDelinquentClientPut } from '../../utils/redux/slice/putDelinquentClientSlice';
ChartJS.register(
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  Tooltip,
  Legend
);

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function DelinquentClientsTable() {
  const [data, setData] = useState([]);

  const [isError, setIsError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [isRefetching, setIsRefetching] = useState(false);

  const [rowCount, setRowCount] = useState(0);

  const [refetchData, setRefetchData] = useState(false);

  const role = localStorage.getItem('role');
  const access_token = localStorage.getItem('access_token');

  const [totalCount, setTotalCount] = useState(2);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [columnFilters, setColumnFilters] = useState([]);

  const [search, setSearch] = useState('');
  const [sorting, setSorting] = useState([]);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [resetLoaders, setResetLoaders] = useState({});

  const resetClient = async (id) => {
    setResetLoaders({ ...resetLoaders, [id]: true });

    const res = await dispatch(
      putDelinquentClientPut({ status: 'non-delinquent', id: id }, navigate)
    );
    if (res.payload) {
      setResetLoaders({ ...resetLoaders, [id]: false });
      setRefetchData(!refetchData);
      toast.success(res.payload.message, { id: 1 });
    }
    if (res.error) {
      setResetLoaders({ ...resetLoaders, [id]: false });
      toast.error(res.error.message, { id: 1 });
    }
  };

  const formatDate = (dateString) => {
    if (dateString) {
      const date = dateString.split('-');
      return `${date[1]}/${date[2]}/${date[0]}`;
    }
    return dateString;
  };
  function formatUSD(number) {
    if (typeof number !== 'number' || isNaN(number)) {
      if (typeof number == 'string') {
        number = parseFloat(number);
      } else {
        return 'Invalid number';
      }
    }

    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    return formatter.format(number);
  }
  function formatUSCell(usPhoneNumber) {
    // Remove any non-digit characters
    let digitsOnly = usPhoneNumber?.replace(/\D/g, '');
    // Check if the number has the expected length of 10 digits
    if (digitsOnly?.length !== 10) {
      return 'Invalid phone number format';
    }
    // Format the number in the original format and add +1 in front
    return '+1 ' + digitsOnly?.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  }

  useEffect(() => {
    const fetchData = async () => {
      if (!data?.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      const url = new URL('clients', `${domainUrl}`);
      url.searchParams.set('status', `delinquent`);
      url.searchParams.set(
        'offset',
        pagination.pageIndex * pagination.pageSize
      );
      url.searchParams.set('limit', `${pagination.pageSize}`);
      url.searchParams.set('offset', `${pagination.pageIndex}`);
      url.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
      url.searchParams.set('search', search ?? '');
      url.searchParams.set('sorting', JSON.stringify(sorting ?? []));
      url.searchParams.set('table', `true`);
      try {
        const response = await fetch(url.href, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${access_token}`,
          },
        });
        let json = await response.json();
        let data = json;

        if (data.code == 'token_not_valid') {
          logOutHandler();
          navigate('/login');
          toast.error('Session Expired', { id: 1 });
          return;
        }
        setData(data.data);
        setRowCount(data.count);
      } catch (error) {
        setIsError(true);

        return;
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    };

    fetchData();
  }, [
    columnFilters,
    search,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    refetchData,
  ]);

  const columns = useMemo(
    () => [
      {
        id: 'clients',
        header: '',
        columns: [
          role == 'admin'
            ? {
                accessorFn: (row) =>
                  `${row?.contracts?.[0].attorney.firm.firm_name} `,
                id: 'contracts__attorney__firm__firm_name',
                header: 'Firm Name',
                accessorKey: 'firm_name',
                enableColumnActions: false,
                size: 30,
              }
            : null,
          role == 'admin' || role == 'primaryattorney'
            ? {
                accessorFn: (row) =>
                  `${row?.contracts?.[0].attorney?.user?.first_name} ${row?.contracts?.[0].attorney.user.last_name}`,
                id: 'contracts__attorney__user_model__first_name',
                header: 'Attorney Name',
                accessorKey: 'first_name',
                enableColumnActions: false,
                size: 30,
              }
            : null,
          {
            accessorFn: (row) =>
              `${row?.user?.first_name} ${row?.user?.last_name} `,
            id: 'user_model__first_name',
            header: 'Client Name',
            enableColumnActions: false,
            accessorKey: 'name',
            size: 30,
          },
          {
            id: 'user_model__email',
            header: 'Email',
            accessorFn: (row) => `${row?.user?.email}`,
            accessorKey: 'email',
            enableColumnActions: false,
            size: 30,
          },
          {
            id: 'user_model__cell_phone_number',
            header: 'Primary Phone',
            accessorFn: (row) =>
              `${formatUSCell(row?.user?.cell_phone_number)}`,
            accessorKey: 'cell_phone_number',
            enableColumnActions: false,
            size: 30,
          },
          {
            id: 'contracts__case_type',
            header: 'Case Type',
            accessorFn: (row) => `${row?.contracts?.[0].case_type}`,
            accessorKey: 'case_type',
            enableColumnActions: false,
            size: 30,
          },
          {
            id: 'contracts__contract_amount',
            header: 'Total Contract Amount',
            accessorFn: (row) =>
              `${formatUSD(row?.contracts?.[0].contract_amount)}`,
            enableColumnActions: false,
            accessorKey: 'contract_amount',
          },
          {
            id: 'contracts__total_paid_amount',
            header: 'Amount Paid',
            accessorFn: (row) =>
              `${formatUSD(row?.contracts?.[0]?.total_paid_amount)}`,
            enableColumnActions: false,
            accessorKey: 'total_paid_amount',
          },
          {
            id: 'contracts__balance_amount',
            header: 'Remaining Amount',
            accessorFn: (row) =>
              `${formatUSD(row?.contracts?.[0]?.balance_amount)}`,
            enableColumnActions: false,
            enableSorting: false,
            enableColumnFilter: false,
            accessorKey: 'balance_amount',
          },

          {
            id: 'contracts__amount_due',
            header: 'Amount Due',
            accessorFn: (row) => `${formatUSD(row?.contracts?.[0].amount_due)}`,
            enableColumnActions: false,
            accessorKey: 'amount_due',
            size: 30,
          },
          {
            id: 'contracts__start_date',
            header: 'Start Date',
            accessorFn: (row) =>
              `${formatDate(row?.contracts?.[0].start_date)}`,
            enableColumnActions: false,
            accessorKey: 'start_date',
            size: 30,
          },
          {
            id: 'contracts__end_date',
            header: 'End Date',
            accessorFn: (row) => `${formatDate(row?.contracts?.[0].end_date)}`,
            enableColumnActions: false,
            accessorKey: 'end_date',
            size: 30,
          },
          {
            id: 'delinquency_duration',
            header: 'Delinquency Duration',
            accessorFn: (row) => `${row?.delinquency_duration} days`,
            enableColumnActions: false,

            enableColumnFilter: false,
            accessorKey: 'delinquency_duration',
          },
          {
            id: 'missed_duration',
            header: 'Missed Duration',
            accessorFn: (row) => `${row?.missed_duration} days`,
            enableColumnActions: false,

            enableColumnFilter: false,
            accessorKey: 'missed_duration',
          },
        ].filter(Boolean),
      },
    ],

    []
  );

  const exportFileHandler = async (id) => {
    const url = new URL('clients', `${domainUrl}`);
    url.searchParams.set('status', 'delinquent');
    url.searchParams.set('offset', pagination.pageIndex * pagination.pageSize);
    url.searchParams.set('limit', `${pagination.pageSize}`);
    url.searchParams.set('offset', `${pagination.pageIndex}`);
    url.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
    url.searchParams.set('search', search ?? '');
    url.searchParams.set('sorting', JSON.stringify(sorting ?? []));
    url.searchParams.set('export', true);

    axios
      .get(url, {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        if (response.headers.get('content-type') == 'application/json') {
          response.data.text().then((text) => {
            toast.success(JSON.parse(text).scheduled, { autoClose: 10000 });
          });
        } else {
          let filename = `Delinquent Clients_${Date.now()}.xlsx`;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        }
      });
  };

  return (
    <>
      <div>
        <main className='py-10'>
          <main className='w-full py-10 '>
            <div className='mx-5'>
              <h2
                style={{
                  color: '#1a3253',
                }}
              >
                Delinquent Clients
              </h2>
              <div className='text-base text-[#9ca0b3] my-2'>
                Clients marked as delinquent by NA Admin
              </div>
              {/* {range?.start_date ? (
                <div className="my-2 flex items-center gap-3">
                  <span>From:</span>
                  <span className="font-semibold">{formatDate(range?.start_date)}</span>
                  <span>To</span>
                  <span className="font-semibold">{formatDate(range?.end_date)}</span>
                </div>
              ) : null} */}

              <div className='p-1 border rounded-lg'>
                <MaterialReactTable
                  enableTopToolbar={true}
                  renderTopToolbarCustomActions={({ table }) => {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          gap: '0.5rem',
                          alignItems: 'center',
                        }}
                      >
                        {role == 'admin' ||
                        role == 'primaryattorney' ||
                        role == 'attorney' ? (
                          <Button
                            disabled={false}
                            startIcon={<Avatar src={ExportImg} />}
                            onClick={() => {
                              exportFileHandler();
                            }}
                            variant='contained'
                            sx={{
                              bgcolor: '#127c44',
                              '&:hover': {
                                bgcolor: '#127c44',
                              },
                              height: '25px',
                            }}
                          >
                            Export
                          </Button>
                        ) : (
                          ''
                        )}
                      </div>
                    );
                  }}
                  enableStickyHeader
                  muiTableContainerProps={{ sx: { maxHeight: '500px' } }}
                  muiTableBodyProps={{
                    sx: () => ({
                      '& tr:nth-child(odd)': {
                        backgroundColor: '#f8f9fa',
                      },
                      '& tr:nth-child(even)': {
                        backgroundColor: '#ffffff',
                      },
                    }),
                  }}
                  muiTablePaperProps={{
                    sx: {
                      maxWidth: 'full',
                    },
                    elevation: 0,
                  }}
                  muiTableHeadCellProps={{
                    sx: (theme) => ({
                      textAlign: 'center',

                      color: theme.palette.text.primary,
                    }),
                  }}
                  enableFullScreenToggle={false}
                  enableDensityToggle={false}
                  enableHiding={false}
                  enableColumnFilters={true}
                  rowNumberMode='static'
                  muiTablePaginationProps={{
                    rowsPerPageOptions: [5, 10, 15, 20, 25],
                    showFirstButton: false,
                    showLastButton: false,
                  }}
                  columns={columns}
                  data={data}
                  enableRowActions={role == 'admin' ? true : false}
                  positionActionsColumn='last'
                  displayColumnDefOptions={{
                    'mrt-row-actions': {
                      header: 'Reset',
                    },
                  }}
                  renderRowActions={({ row }) => (
                    <>
                      {resetLoaders[row.original.id] ? (
                        <IconButton>
                          <Hourglass
                            visible={true}
                            height='25'
                            width='25'
                            ariaLabel='hourglass-loading'
                            wrapperStyle={{}}
                            wrapperClass=''
                            colors={['#306cce', '#72a1ed']}
                          />
                        </IconButton>
                      ) : (
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            resetClient(row.original.id);
                          }}
                        >
                          <ExitToApp
                            sx={{
                              color: 'black',
                            }}
                          />
                        </IconButton>
                      )}
                    </>
                  )}
                  muiTableBodyRowProps={({ row }) => ({
                    onClick: () => {
                      if (data.length) {
                        localStorage.setItem('view_client_id', row.original.id);
                        navigate(`/client-view`);
                      }
                    },

                    sx: {
                      cursor: 'pointer',
                    },
                  })}
                  getRowId={(row) => row.id}
                  initialState={{
                    showColumnFilters: false,
                    density: 'compact',
                  }}
                  manualPagination
                  manualSorting
                  muiToolbaralertBannerProps={
                    isError
                      ? {
                          color: 'error',
                          children: 'Error loading data',
                        }
                      : undefined
                  }
                  onColumnFiltersChange={setColumnFilters}
                  onGlobalFilterChange={setSearch}
                  onPaginationChange={setPagination}
                  onSortingChange={setSorting}
                  rowCount={rowCount}
                  state={{
                    isLoading,
                    pagination,
                    showalertBanner: isError,
                    showProgressBars: isRefetching,
                    sorting,
                  }}
                />
              </div>
            </div>
          </main>
        </main>
      </div>
    </>
  );
}
