import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { domainUrl, logOutHandler } from "../../constants";
import toast from "react-hot-toast";

export const editClientProfilePut = createAsyncThunk(
  "editClientProfilePut",
  async ({data,id},thunkAPI) => {
    try {
      const access_token = localStorage.getItem("access_token");


      const res = await axios.put(
        `${domainUrl}edit-profile?id=${id}`,
        {
          cell_phone_number: data.cell_phone_number,
          email: data.email,
          first_name: data.first_name,
          last_name: data.last_name,
          address_line1: data.address_line1,
          address_line2: data.address_line2,
          state: data.state,
          city: data.city,
          zip_code: data.zip_code,
          case_type: data.case_type,
          case_number: data.case_number,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      return res.data;
    } catch (error) {
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        const navigate = thunkAPI.extra.navigateCallback;
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      } else {
        throw JSON.stringify(error.response.data.detail);
      }
    }
  }
);

const editClientProfileSlice = createSlice({
  name: "editClientProfileStorage",
  initialState: { data: [], isLoading: false, error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(editClientProfilePut.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(editClientProfilePut.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(editClientProfilePut.rejected, (state, action) => {
        
        state.isLoading = false;
        state.error = action.error;
        
      });
  },
});

export default editClientProfileSlice.reducer;
