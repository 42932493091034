import { useEffect, useMemo, useState } from "react";
import {
  CalendarIcon,
  ChartPieIcon,
  ClipboardDocumentCheckIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  PlusIcon,
  TrashIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { Line, Doughnut } from "react-chartjs-2";

import {
  Chart as ChartJS,
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import MaterialReactTable from "material-react-table";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useNavigate } from "react-router-dom";
import { domainUrl, logOutHandler } from "../utils/constants";
import axios from "axios";
import toast from "react-hot-toast";
import ModalComponent from "../Components/ModalComponent";
import { IconButton } from "@mui/material";
import { Oval } from "react-loader-spinner";
ChartJS.register(
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  Tooltip,
  Legend
);

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ManageHolidays() {
  const [data, setData] = useState([]);

  const [isError, setIsError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [isRefetching, setIsRefetching] = useState(false);

  const [rowCount, setRowCount] = useState(0);

  const [importDoc, setImportDoc] = useState([]);
  const [openAddHolidayModal, setOpenAddHolidayModal] = useState(false);

  const [formData, setFormData] = useState({
    title: "",
    date: "",
  });
  const [errors, setErrors] = useState({});

  const role = localStorage.getItem("role");
  const access_token = localStorage.getItem("access_token");

  const [totalCount, setTotalCount] = useState(2);
  const navigate = useNavigate();

  const [columnFilters, setColumnFilters] = useState([]);

  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState([]);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const formatDate = (dateString) => {
    if (dateString) {
      const date = dateString.split("-");

      return `${date[1]}/${date[2]}/${date[0]}`;
    }
    return dateString;
  };

  const fetchData = async () => {
    if (!data.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }
    const url = new URL("holidays", `${domainUrl}`);
    url.searchParams.set("offset", pagination.pageIndex * pagination.pageSize);
    url.searchParams.set("limit", `${pagination.pageSize}`);
    url.searchParams.set("offset", `${pagination.pageIndex}`);

    url.searchParams.set("search", search ?? "");
    url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
    try {
      const response = await fetch(url.href, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      });
      let json = await response.json();
      let data = json;

      if (data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
      setData(data.data);
      setRowCount(data.count);
    } catch (error) {
      setIsError(true);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  };
  useEffect(() => {
    fetchData();
  }, [
    columnFilters,
    search,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
  ]);

  const columns = useMemo(
    () => [
      {
        id: "id",
        header: "",
        columns: [
          {
            id: "id",
            enableColumnActions: false,
            header: "No",
            accessorFn: (row) => `${row.serial_id ? row.serial_id : ""}`,
            accessorKey: "serial_id",
            enableSorting: false,
          },
          {
            id: "title",
            header: "Event",
            accessorFn: (row) => `${row.title}`,
            enableColumnActions: false,
            accessorKey: "title",
          },
          {
            id: "date",
            header: "Date",
            accessorFn: (row) => `${formatDate(row.date)}`,
            enableColumnActions: false,
            accessorKey: "date",
          },
        ],
      },
    ],

    []
  );

  const deleteHoliday = async (id) => {
    try {
      setIsLoading(true);
      const res = await axios.delete(`${domainUrl}holidays?id=${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      });
      if (res.data) {
        setIsLoading(false);
        fetchData();
        toast.success("Deleted Holiday", { id: 1 });
      }
    } catch (error) {
      setIsLoading(false);

      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
      if (error.response.data.detail?.title) {
        toast.error(error.response.data.detail.title, { id: 1 });
      }
    }
  };

  const handleImportDocChange = (event) => {
    const files = event.target.files;
    let newFiles = [];

    const allowedFileTypes = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/csv",
      "text/csv",
    ];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (allowedFileTypes.includes(file.type)) {
        let maxsize = 5 * 1024 * 1024;
        if (file.size < maxsize) {
          newFiles = [...newFiles, file];
        } else {
          toast.error("Maximum allowed size is 5 MB.");
        }
      } else {
        toast.error(
          "Invalid File Type! Please upload a file of type: .csv, .xlsx, or .xls."
        );
      }
    }
    setImportDoc((prevFiles) => [...prevFiles, ...newFiles]);
  };
  useEffect(() => {
    if (importDoc.length) {
      postImportedFile();
    }
  }, [importDoc]);

  const handleImportDocRemove = (index) => {
    setImportDoc((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(index, 1);
      return updatedFiles;
    });
  };

  const postImportedFile = async () => {
    const importPromises = importDoc.map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (event) => {
          const base64Data = event.target.result.split(",")[1];
          resolve({ name: file.name, file: base64Data });
        };

        reader.onerror = (error) => {
          reject(error);
        };

        reader.readAsDataURL(file);
      });
    });

    const base64FilesImport = await Promise.all(importPromises);

    try {
      const res = await axios.post(
        `${domainUrl}holidays`,
        { file: base64FilesImport[base64FilesImport.length - 1].file },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (res.data) {
        fetchData();
        setImportDoc([]);
        toast.success("Imported Holidays", { id: 1 });
      }
    } catch (error) {
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
      if (error.response.data.detail) {
        toast.error(`${error.response.data.detail}`, { id: 1 });
      }
      if (error.response.data.detail?.title) {
        toast.error(
          `Row ${error.response.data?.row} : ${error.response.data.detail.title}`,
          { id: 1 }
        );
      }
      if (error.response.data.detail?.date) {
        toast.error(
          `Row ${error.response.data?.row} : ${error.response.data.detail.date}`,
          { id: 1 }
        );
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "date") {
      const today = new Date();
      const selectedDate = new Date(value);
      if (selectedDate <= today) {
        setErrors({ ...errors, [name]: "Past days are not accepted" });
      } else {
        setErrors({ ...errors, [name]: "" });
      }
    }
  };
  useEffect(() => {
    if (formData.date) {
    }
    if (formData.title) {
      setErrors({ ...errors, ["title"]: "" });
    }
  }, [formData]);

  const [submitLoader, setSubmitLoader] = useState(false);

  const submitAddHolidayHandler = async (e) => {
    e.preventDefault();
    const newErrors = {};

    if (!formData.title) {
      newErrors.title = "Title is required";
      toast.error("Complete the fields", { id: 1 });
    } else {
      newErrors.title = "";
    }

    if (!formData.date) {
      newErrors.date = "Date is required";
      toast.error("Complete the fields", { id: 1 });
    } else {
      newErrors.date = "";
    }

    setErrors(newErrors);

    if (!newErrors.title && !newErrors.date) {
      try {
        setSubmitLoader(true);
        const res = await axios.put(
          `${domainUrl}holidays`,
          { title: formData.title, date: formData.date },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${access_token}`,
            },
          }
        );
        if (res.data) {
          setSubmitLoader(false);

          setOpenAddHolidayModal(false);
          fetchData();
          setFormData({ title: "", date: "" });
          toast.success("Added Holiday", { id: 1 });
        }
      } catch (error) {
        setSubmitLoader(false);

        if (error.response?.data.code == "token_not_valid") {
          logOutHandler();
          navigate("/login");
          toast.error("Session Expired", { id: 1 });
          return;
        }
        if (error.response.data.detail?.title) {
          toast.error(error.response.data.detail.title, { id: 1 });
        }
        if (error.response.data.detail?.date) {
          toast.error(error.response.data.detail.date, { id: 1 });
        }
      }
    }
  };

  return (
    <>
      <div>
        <main className="py-10">
          <main className="w-full py-10 ">
            <div className="mx-5">
              <h2
                style={{
                  color: "#1a3253",
                }}
              >
                Manage Holidays
              </h2>

              <div className="py-2">
                <div className="w-full py-2 flex justify-end items-center gap-2">
                  <button
                    type="button"
                    onClick={() => {
                      setOpenAddHolidayModal(true);
                    }}
                    className="inline-flex items-center gap-x-1.5 rounded-md bg-[#28282a] px-2.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#717175] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#717175]"
                  >
                    <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                    Add a new holiday
                  </button>

                  <label className="flex gap-x-1.5 rounded-md bg-[#70d23f] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#90e366] hover:cursor-pointer">
                    <ClipboardDocumentCheckIcon
                      className="-ml-0.5 h-5 w-5"
                      aria-hidden="true"
                    />
                    <input
                      type="file"
                      onChange={handleImportDocChange}
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    />
                    <div className="hover:cursor-pointer">Import</div>
                  </label>
                </div>

                <div className="p-1 border rounded-lg">
                  <MaterialReactTable
                    enableTopToolbar={false}
                    enableStickyHeader
                    muiTableContainerProps={{ sx: { maxHeight: "500px" } }}
                    muiTablePaperProps={{
                      sx: {
                        maxWidth: "full",
                      },
                      elevation: 0,
                    }}
                    muiTableHeadCellProps={{
                      sx: (theme) => ({
                        color: theme.palette.text.primary,
                      }),
                    }}
                    enableFullScreenToggle={false}
                    enableDensityToggle={false}
                    enableHiding={false}
                    enableColumnFilters={false}
                    rowNumberMode="static"
                    muiTablePaginationProps={{
                      rowsPerPageOptions: [5, 10, 15, 20, 25],
                      showFirstButton: false,
                      showLastButton: false,
                    }}
                    columns={columns}
                    data={data}
                    enableRowActions
                    positionActionsColumn="last"
                    displayColumnDefOptions={{
                      "mrt-row-actions": {
                        header: "Remove",
                      },
                    }}
                    renderRowActions={({ row }) => (
                      <>
                        <IconButton
                          onClick={() => {
                            deleteHoliday(row.original.id);
                          }}
                        >
                          <TrashIcon className="h-6 w-6" />
                        </IconButton>
                      </>
                    )}
                    muiTableBodyRowProps={({ row }) => ({
                      onClick: () => {
                        if (data.length) {
                        }
                      },

                      sx: {
                        cursor: "pointer",
                      },
                    })}
                    getRowId={(row) => row.id}
                    initialState={{
                      showColumnFilters: false,
                      density: "compact",
                    }}
                    manualPagination
                    muiToolbaralertBannerProps={
                      isError
                        ? {
                            color: "error",
                            children: "Error loading data",
                          }
                        : undefined
                    }
                    onColumnFiltersChange={setColumnFilters}
                    onGlobalFilterChange={setSearch}
                    onPaginationChange={setPagination}
                    onSortingChange={setSorting}
                    rowCount={rowCount}
                    state={{
                      isLoading,
                      pagination,
                      showalertBanner: isError,
                      showProgressBars: isRefetching,
                      sorting,
                    }}
                  />
                </div>
              </div>
            </div>
          </main>
        </main>
        <ModalComponent open={openAddHolidayModal} handleClose={() => {}}>
          <div className="md:flex justify-center items-center">
            <div className="bg-white p-5 rounded-lg">
              <form
                onSubmit={(e) => {
                  submitAddHolidayHandler(e);
                }}
              >
                <div className="w-full  border-gray-900/10 pb-12">
                  <h2 className="text-base font-semibold leading-7 text-gray-900">
                    Add a new holiday
                  </h2>

                  <div className="mt-10 space-y-10">
                    {/* <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                      <div className="sm:col-span-3">
                        <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                          Event/Title:
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="title"
                            id="title"
                            className="mt-2 border-b-1  focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-3">
                        <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                          Date:
                        </label>
                        <div className="mt-2">
                          <input
                            type="date"
                            className="mt-2 border-b-1  focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                            placeholder="Select date"
                          />
                        </div>
                      </div>
                    </div> */}
                    <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                      <div className="sm:col-span-3">
                        <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                          Event/Title:
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="title"
                            id="title"
                            value={formData.title}
                            onChange={handleInputChange}
                            className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none border-b-2 text-[#344767]"
                          />
                          {errors.title && (
                            <span className="text-sm text-red-600">
                              {errors.title}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="sm:col-span-3">
                        <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                          Date:
                        </label>
                        <div className="mt-2">
                          <input
                            type="date"
                            name="date"
                            value={formData.date}
                            onChange={handleInputChange}
                            className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none border-b-2 text-[#344767]"
                            placeholder="Select date"
                          />
                          {errors.date && (
                            <span className="text-sm text-red-600">
                              {errors.date}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-end gap-3 items-center">
                  <button
                    disabled={
                      errors?.title || errors?.date || submitLoader
                        ? true
                        : false
                    }
                    type="submit"
                    className="rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm inline-flex gap-3 justify-center items-center hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Add Holiday
                    <div>
                      {submitLoader && (
                        <Oval
                          height={20}
                          width={20}
                          color="#ffff"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                          ariaLabel="oval-loading"
                          secondaryColor="#ffff"
                          strokeWidth={2}
                          strokeWidthSecondary={2}
                        />
                      )}
                    </div>
                  </button>

                  <button
                    type="button"
                    className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    onClick={() => {
                      setOpenAddHolidayModal(false);
                      setFormData({ title: "", date: "" });
                      setErrors({});
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </ModalComponent>
      </div>
    </>
  );
}
