import React, { PureComponent, useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { DateRangePicker } from "rsuite";
import subDays from "date-fns/subDays";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import subMonths from "date-fns/subMonths";
import subYears from "date-fns/subYears";
import { domainUrl, logOutHandler } from "../../utils/constants";
import toast from "react-hot-toast";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const LocAndContractLine = () => {
  const access_token = localStorage.getItem("access_token");
  const navigate = useNavigate();
  const [locContractGraphData, setLocContractGraphData] = useState({
    contract: [0],
    loc: [0],
    weeks: [],
  });
  const [data, setData] = useState([]);

  const [dateFilter, setDateFilter] = useState({
    start_date: "",
    end_date: "",
  });
  const predefinedRanges = [
    {
      label: "Past week",
      closeOverlay: false,
      value: [subDays(new Date(), 6), new Date()],
      placement: "left",
    },
    {
      label: "Past month",
      closeOverlay: false,
      value: [
        startOfMonth(subMonths(new Date(), 1)),
        endOfMonth(subMonths(new Date(), 1)),
      ],
      placement: "left",
    },
    {
      label: "Past 3 months",
      closeOverlay: false,
      value: [startOfMonth(subMonths(new Date(), 3)), new Date()],
      placement: "left",
    },
    {
      label: "Past 6 months",
      closeOverlay: false,
      value: [startOfMonth(subMonths(new Date(), 6)), new Date()],
      placement: "left",
    },
    {
      label: "Past year",
      closeOverlay: false,
      value: [startOfMonth(subYears(new Date(), 1)), new Date()],
      placement: "left",
    },
    {
      label: "Past 2 years",
      closeOverlay: false,
      value: [startOfMonth(subYears(new Date(), 2)), new Date()],
      placement: "left",
    },
  ];

  const handleDateFilter = (e) => {
    if (e == null) {
      setDateFilter({
        start_date: "",
        end_date: "",
      });
    }
    if (e && e.length === 2) {
      const startDate = new Date(e[0]);
      const endDate = new Date(e[1]);

      var timezoneOffsetInMinutes = new Date().getTimezoneOffset();
      startDate.setHours(
        startDate.getHours() + startDate.getTimezoneOffset() / 60
      );
      endDate.setHours(endDate.getHours() + endDate.getTimezoneOffset() / 60);

      const formattedStartDate = startDate.toISOString().split("T")[0];
      const formattedEndDate = endDate.toISOString().split("T")[0];

      setDateFilter({
        start_date: formattedStartDate,
        end_date: formattedEndDate,
      });
    }
  };
  const formatDate = (dateString) => {
    if (dateString) {
      const date = dateString.split("-");

      return `${date[1]}/${date[2]}/${date[0]}`;
    }
    return dateString;
  };
  function formatUSD(number) {
    if (typeof number !== "number" || isNaN(number)) {
      if (typeof number == "string") {
        number = parseFloat(number);
      } else {
        return "Invalid number";
      }
    }

    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    return formatter.format(number);
  }

  const getLocContractGraphData = async () => {
    try {
      const res = await axios.get(
        `${domainUrl}loc-graph?start_date=${dateFilter.start_date}&end_date=${dateFilter.end_date}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (res.data) {
        setLocContractGraphData(res.data);

        const { weeks, loc, contract } = res.data;

        const graphData = weeks.map((week, index) => ({
          name: week,
          loc: loc[index],
          contract: contract[index],
          amt: 0,
        }));
        setData(graphData);
      }
    } catch (error) {
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
    }
  };

  useEffect(() => {
    getLocContractGraphData();
  }, [dateFilter]);
  const CustomizedLabel = ({ x, y, stroke, value }) => (
    <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
      {value}
    </text>
  );

  const CustomizedAxisTick = ({ x, y, stroke, payload }) => (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        transform="rotate(-35)"
      >
        {payload.value}
      </text>
    </g>
  );

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="p-2  bg-white border border-black shadow-md flex flex-col gap-1 rounded-md">
          <p className="text-sm">
            Date:
            <span className="ml-1 text-xs">
              {formatDate(payload[0].payload?.name)}
            </span>
          </p>
          <p className="text-sm font-semibold text-[#4472c4]">
            Loc:
            <span className="ml-2">{formatUSD(payload[0].payload?.loc)}</span>
          </p>
          <p className="text-sm font-semibold text-[#ed7d31]">
            New Contracts:
            <span className="ml-2">
              {formatUSD(payload[0].payload?.contract)}
            </span>
          </p>
        </div>
      );
    }
  };

  return (
    <div className="w-full">
      <div className="w-full h-[350px] p-2 flex flex-col justify-center items-center border border-gray-200 rounded-xl shadow-md">
        <div className="mb-8 text-base font-bold">LOC & NEW CONTRACTS</div>
        <div className="w-full h-60 sm:w-[28rem] lg:w-[28rem]">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              width={500}
              height={400}
              data={data}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 10,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="name"
                height={60}
                tickFormatter={formatDate}
                tick={((<CustomizedAxisTick />), { fontSize: 10 })}
              />
              <YAxis yAxisId="left" tick={{ fontSize: 10 }} />
              <YAxis
                yAxisId="right"
                orientation="right"
                tick={{ fontSize: 10 }}
              />
              <Tooltip
                wrapperStyle={{ zIndex: 2 }}
                content={<CustomTooltip />}
              />
              
              <Line
                type="monotone"
                dataKey="contract"
                yAxisId="left"
                stroke="#ed7d31"
                label={<CustomizedLabel />}
                domain={[
                  0,
                  locContractGraphData?.contract
                    ? Math.max(...locContractGraphData.contract)
                    : 0,
                ]}
                onClick={() => {
                  navigate("/approved-clients");
                }}
              />
              <Line
                type="monotone"
                dataKey="loc"
                yAxisId="right"
                stroke="#4472c4"
                domain={[
                  0,
                  Math.max(
                    ...locContractGraphData.contract,
                    ...locContractGraphData.loc
                  ),
                ]}
                onClick={() => {
                  navigate("/firm-payouts");
                }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div className="my-3 flex flex-col justify-evenly items-center  gap-2">
          <div className="flex  gap-3 justify-center items-start">
            <div className="text-sm inline-flex items-center gap-1">
              <span className="h-2 w-2 bg-[#4472c4]"></span>Loc
            </div>
            <div className="text-sm inline-flex items-center gap-1">
              <span className="h-2 w-2 bg-[#ed7d31]"></span>New Contracts
            </div>
          </div>
        </div>
        <DateRangePicker
          ranges={predefinedRanges}
          onChange={(e) => {
            handleDateFilter(e);
          }}
          showOneCalendar
          size="xs"
          placeholder="Select Date"
          format="MM/dd/yyyy"
          character=" – "
          className="hover:cursor-pointer"
          preventOverflow={true}
        />
      </div>
    </div>
  );
};

export default LocAndContractLine;
