import React, { useState } from "react";
import { Avatar, Button, InputLabel, MenuItem, Select } from "@mui/material";
import ExportImg from "../assets/img/excel-img.png";
import DocImg from "../assets/img/document-img.png";
import { domainUrl } from "../utils/constants";
import axios from "axios";
import toast from "react-hot-toast";
import FormControl from "@mui/material/FormControl";

const FinancialReports = () => {
  const access_token = localStorage.getItem("access_token");
  const role = localStorage.getItem("role");
  const currentYear = new Date().getFullYear();
  const [yearValue, setYearValue] = useState(currentYear);
  const yearList = Array.from(
    { length: 10 },
    (_, index) => currentYear - index
  );

  const exportAttorneyBalanceReport = async (id) => {
    const url = new URL("attorney-balance-report", `${domainUrl}`);
    url.searchParams.set("year", yearValue);
    url.searchParams.set("export", true);

    axios
      .get(url, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        if (response.headers.get("content-type") == "application/json") {
          response.data.text().then((text) => {
            toast.success("Generating Attorney Balance Report");
          });
        } else {
          let filename = `Attorney Balance Report_${Date.now()}.xlsx`;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
          toast.success("Generating Attorney Balance Report");
        }
      });
  };
  const exportClientPaymentsReport = async (id) => {
    const url = new URL("client-payments-report", `${domainUrl}`);
    url.searchParams.set("export", true);

    axios
      .get(url, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        if (response.headers.get("content-type") == "application/json") {
          response.data.text().then((text) => {
            toast.success("Generating Client Payments Report");
          });
        } else {
          let filename = `Client Payments Report_${Date.now()}.xlsx`;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
          toast.success("Generating Client Payments Report");
        }
      });
  };
  const exportFinancialReport = async (id) => {
    const url = new URL("financial-report", `${domainUrl}`);
    url.searchParams.set("export", true);

    axios
      .get(url, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        if (response.headers.get("content-type") == "application/json") {
          response.data.text().then((text) => {
            toast.success("Generating Financial Report");
          });
        } else {
          let filename = `Financial Reports_${Date.now()}.xlsx`;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
          toast.success("Generating Financial Report");
        }
      });
  };
  return (
    <div>
      <main className="py-10">
        <main className="w-full py-10 ">
          <div className="mx-5">
            <h2
              style={{
                color: "#1a3253",
              }}
            >
              Financial Reports
            </h2>
            <div className="text-base text-[#9ca0b3] my-2"></div>
            <div className="w-full">
              <ul role="list" className="divide-y divide-gray-100">
                <li className="flex items-center justify-between gap-x-6 py-5">
                  <div className="flex min-w-0 gap-x-4">
                    <img
                      className="h-14 w-14 flex-none bg-gray-50 p-1"
                      src={DocImg}
                      alt=""
                    />
                    <div className="min-w-0 flex-auto">
                      <p className="text-lg font-semibold leading-6 text-gray-900">
                        Attorney Balance Report
                      </p>
                      <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                        Generate attorney balance report by clicking export
                      </p>
                    </div>
                    {/* <div className="mt-3 flex flex-col items-start gap-3 sm:flex-row sm:items-center sm:mt-0">
                      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel
                          id="demo-select-small-label"
                          
                        >
                          Year
                        </InputLabel>
                        <Select
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          
                          label="Year"
                          onChange={(event) => {
                            setYearValue(event.target.value); 
                          }}
                        >
                          {yearList.map((year) => (
                            <MenuItem key={year} value={year}>
                              {year}
                            </MenuItem>
                          ))}
                          <MenuItem value={"2024"}>2024</MenuItem>
                        </Select>
                      </FormControl>
                    </div> */}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "0.5rem",
                      alignItems: "center",
                    }}
                  >
                    {role == "admin" || role == "primaryattorney" ? (
                      <Button
                        disabled={false}
                        startIcon={<Avatar src={ExportImg} />}
                        onClick={() => {
                          exportAttorneyBalanceReport();
                        }}
                        variant="contained"
                        sx={{
                          bgcolor: "#127c44",
                          "&:hover": {
                            bgcolor: "#127c44",
                          },
                          height: "25px",
                        }}
                      >
                        Export
                      </Button>
                    ) : (
                      ""
                    )}
                  </div>
                </li>
                <li className="flex items-center justify-between gap-x-6 py-5">
                  <div className="flex min-w-0 gap-x-4">
                    <img
                      className="h-14 w-14 flex-none bg-gray-50 p-1"
                      src={DocImg}
                      alt=""
                    />
                    <div className="min-w-0 flex-auto">
                      <p className="text-lg font-semibold leading-6 text-gray-900">
                        Client Payments Report
                      </p>
                      <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                        Generate client payments report by clicking export
                      </p>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "0.5rem",
                      alignItems: "center",
                    }}
                  >
                    {role == "admin" || role == "primaryattorney" ? (
                      <Button
                        disabled={false}
                        startIcon={<Avatar src={ExportImg} />}
                        onClick={() => {
                          exportClientPaymentsReport();
                        }}
                        variant="contained"
                        sx={{
                          bgcolor: "#127c44",
                          "&:hover": {
                            bgcolor: "#127c44",
                          },
                          height: "25px",
                        }}
                      >
                        Export
                      </Button>
                    ) : (
                      ""
                    )}
                  </div>
                </li>
                <li className="flex items-center justify-between gap-x-6 py-5">
                  <div className="flex min-w-0 gap-x-4">
                    <img
                      className="h-14 w-14 flex-none bg-gray-50 p-1"
                      src={DocImg}
                      alt=""
                    />
                    <div className="min-w-0 flex-auto">
                      <p className="text-lg font-semibold leading-6 text-gray-900">
                        Financial Report
                      </p>
                      <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                        Generate financial report by clicking export
                      </p>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "0.5rem",
                      alignItems: "center",
                    }}
                  >
                    {role == "admin" || role == "primaryattorney" ? (
                      <Button
                        disabled={false}
                        startIcon={<Avatar src={ExportImg} />}
                        onClick={() => {
                          exportFinancialReport();
                        }}
                        variant="contained"
                        sx={{
                          bgcolor: "#127c44",
                          "&:hover": {
                            bgcolor: "#127c44",
                          },
                          height: "25px",
                        }}
                      >
                        Export
                      </Button>
                    ) : (
                      ""
                    )}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </main>
      </main>
    </div>
  );
};

export default FinancialReports;
