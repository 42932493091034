import { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import "./App.css";
import Router from "./utils/router";
import "rsuite/Button/styles/index.css";

function App() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register("/service-worker.js")
      .then((registration) => {
        
      })
      .catch((error) => {
        
      });
  }
  let deferredPrompt;
  window.addEventListener("beforeinstallprompt", (e) => {
    e.preventDefault();
    deferredPrompt = e;
  });

  return (
    <>
      <Router />
      <Toaster position="top-right" />
    </>
  );
}

export default App;
