import { useEffect, useMemo, useState } from "react";
import {
  Chart as ChartJS,
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import ExportImg from "../../assets/img/excel-img.png";
import MaterialReactTable from "material-react-table";
import { useNavigate } from "react-router-dom";
import { domainUrl, logOutHandler } from "../../utils/constants";
import toast from "react-hot-toast";
import axios from "axios";
import { Avatar, Button } from "@mui/material";
ChartJS.register(
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  Tooltip,
  Legend
);

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function AttorneyTotalClientsTable() {
  const [data, setData] = useState([]);

  const [isError, setIsError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [isRefetching, setIsRefetching] = useState(false);

  const [rowCount, setRowCount] = useState(0);

  const access_token = localStorage.getItem("access_token");
  const role = localStorage.getItem("role");
  const query_param = localStorage.getItem("query_param/total_clients");

  useEffect(() => {
    if (!query_param) {
      navigate(-1);
    }

    return () => {
      localStorage.removeItem("query_param/total_clients");
    };
  }, []);

  const [totalCount, setTotalCount] = useState(2);
  const navigate = useNavigate();

  const [columnFilters, setColumnFilters] = useState([]);

  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState([]);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  function extractDateFromTimestamp(timestamp) {
    const dateObject = new Date(timestamp);
    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
    const day = dateObject.getDate().toString().padStart(2, "0");
    const year = dateObject.getFullYear();
    return `${month}/${day}/${year}`;
  }

  function formatUSD(number) {
    if (typeof number !== "number" || isNaN(number)) {
      if (typeof number == "string") {
        number = parseFloat(number);
      } else {
        return "Invalid number";
      }
    }

    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    return formatter.format(number);
  }
  const formatDate = (dateString) => {
    if (dateString) {
      const date = dateString.split("-");

      return `${date[1]}/${date[2]}/${date[0]}`;
    }
    return dateString;
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!data.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      const url = new URL("clients", `${domainUrl}`);

      url.searchParams.set(
        "offset",
        pagination.pageIndex * pagination.pageSize
      );
      url.searchParams.set("limit", `${pagination.pageSize}`);
      url.searchParams.set("offset", `${pagination.pageIndex}`);
      url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
      url.searchParams.set("search", search ?? "");
      url.searchParams.set("sorting", JSON.stringify(sorting ?? []));

      try {
        const response = await fetch(url.href, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        });
        let json = await response.json();
        let data = json;

        if (data.code == "token_not_valid") {
          logOutHandler();
          navigate("/login");
          toast.error("Session Expired", { id: 1 });
          return;
        }
        setData(data.data);
        setRowCount(data.count);
      } catch (error) {
        setIsError(true);

        return;
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    };

    fetchData();
  }, [
    columnFilters,
    search,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
  ]);

  const columns = useMemo(
    () => [
      {
        id: "clients",
        header: "",
        columns: [
          {
            accessorFn: (row) => `${row.client_code} `,
            id: "client_code",
            header: "Client Code",
            accessorKey: "client_code",
            enableColumnActions: false,

            size: 30,
          },
          {
            accessorFn: (row) =>
              `${row.user.first_name} ${row.user.last_name} `,
            id: "user_model__first_name",
            header: "Client Name",
            accessorKey: "first_name",
            enableColumnActions: false,

            size: 30,
          },
          {
            id: "user_model__email",
            header: "Email",
            accessorFn: (row) => `${row.user.email}`,
            accessorKey: "email",
            enableColumnActions: false,
            size: 30,
          },
          {
            id: "user_model__cell_phone_number",
            header: "Primary Phone",
            accessorFn: (row) => `${formatUSCell(row.user.cell_phone_number)}`,
            accessorKey: "cell_phone_number",
            enableColumnActions: false,
            size: 30,
          },
          {
            id: "status",
            header: "Client Status",
            accessorFn: (row) => `${row.status.toUpperCase()}`,
            accessorKey: "status",
            enableColumnActions: false,
            size: 30,
          },
          {
            id: "delinquency",
            header: "Contract Status",
            accessorFn: (row) => `${row.delinquency.toUpperCase()}`,
            accessorKey: "delinquency",
            enableColumnActions: false,
            size: 30,
          },
          {
            id: "contract_amount",
            header: "Contract Amount",
            accessorFn: (row) =>
              `${formatUSD(row.contracts[0].contract_amount)}`,
            accessorKey: "contract_amount",
            enableColumnActions: false,
            size: 30,
          },
          {
            id: "start_date",
            header: "Contract Start date",
            accessorFn: (row) => `${formatDate(row.contracts[0].start_date)}`,
            accessorKey: "start_date",
            enableColumnActions: false,
            size: 30,
          },
          {
            id: "end_date",
            header: "Contract End date",
            accessorFn: (row) => `${formatDate(row.contracts[0].end_date)}`,
            accessorKey: "end_date",
            enableColumnActions: false,
            size: 30,
          },
          {
            id: "payment_schedule_count",
            header: "Payment Progress",
            accessorFn: (row) =>
              `${row.contracts[0].paid_count} of ${row.contracts[0].payment_schedule_count}`,
            accessorKey: "payment_schedule_count",
            enableColumnActions: false,
            size: 30,
          },
        ],
      },
    ],
    []
  );

  function formatUSCell(usPhoneNumber) {
    let digitsOnly = usPhoneNumber.replace(/\D/g, "");

    if (digitsOnly.length !== 10) {
      return "Invalid phone number format";
    }

    return "+1 " + digitsOnly.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  }
  const exportFileHandler = async (id) => {
    const url = new URL("clients", `${domainUrl}`);
    url.searchParams.set("offset", pagination.pageIndex * pagination.pageSize);
    url.searchParams.set("limit", `${pagination.pageSize}`);
    url.searchParams.set("offset", `${pagination.pageIndex}`);
    url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
    url.searchParams.set("search", search ?? "");
    url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
    url.searchParams.set("export", true);

    axios
      .get(url, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        if (response.headers.get("content-type") == "application/json") {
          response.data.text().then((text) => {
            toast.success(JSON.parse(text).scheduled, { autoClose: 10000 });
          });
        } else {
          let filename = `Clients_${Date.now()}.xlsx`;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
        }
      });
  };

  return (
    <>
      <div>
        <main className="py-10">
          <main className="w-full py-10 ">
            <div className="mx-5">
              <h2
                style={{
                  color: "#1a3253",
                }}
              >
                Total Clients
              </h2>
              <div className="text-base text-[#9ca0b3] my-2">
                List of total clients
              </div>

              <div className="p-1 border rounded-lg">
                <MaterialReactTable
                  enableTopToolbar={true}
                  enableStickyHeader
                  muiTableContainerProps={{ sx: { maxHeight: "500px" } }}
                  muiTableBodyProps={{
                    sx: () => ({
                      "& tr:nth-child(odd)": {
                        backgroundColor: "#f8f9fa",
                      },
                      "& tr:nth-child(even)": {
                        backgroundColor: "#ffffff",
                      },
                    }),
                  }}
                  muiTablePaperProps={{
                    sx: {
                      maxWidth: "full",
                    },
                    elevation: 0,
                  }}
                  muiTableHeadCellProps={{
                    sx: (theme) => ({
                      color: theme.palette.text.primary,
                    }),
                  }}
                  enableFullScreenToggle={false}
                  enableDensityToggle={false}
                  enableHiding={false}
                  enableColumnFilters={true}
                  rowNumberMode="static"
                  muiTablePaginationProps={{
                    rowsPerPageOptions: [1, 2, 5, 10, 15, 20, 25],
                    showFirstButton: false,
                    showLastButton: false,
                  }}
                  columns={columns}
                  data={data}
                  muiTableBodyRowProps={({ row }) => ({
                    onClick: () => {
                      if (data.length) {
                        if (
                          row.original.status == "approved" ||
                          row.original.status == "archived"
                        ) {
                          localStorage.setItem(
                            "view_client_id",
                            row.original.id
                          );
                          navigate(`/client-view`);
                        } else {
                          localStorage.setItem(
                            "view_client_id",
                            row.original.id
                          );
                          navigate(`/view-pending-client`);
                        }
                      }
                    },

                    sx: {
                      cursor: "pointer",
                    },
                  })}
                  getRowId={(row) => row.id}
                  initialState={{
                    showColumnFilters: false,
                    density: "compact",
                  }}
                  manualPagination
                  manualSorting
                  muiToolbaralertBannerProps={
                    isError
                      ? {
                          color: "error",
                          children: "Error loading data",
                        }
                      : undefined
                  }
                  onColumnFiltersChange={setColumnFilters}
                  onGlobalFilterChange={setSearch}
                  onPaginationChange={setPagination}
                  onSortingChange={setSorting}
                  rowCount={rowCount}
                  state={{
                    isLoading,
                    pagination,
                    showalertBanner: isError,
                    showProgressBars: isRefetching,
                    sorting,
                  }}
                />
              </div>
            </div>
          </main>
        </main>
      </div>
    </>
  );
}
