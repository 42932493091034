import { useEffect, useMemo, useState } from "react";
import {
  CalendarIcon,
  ChartPieIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { Line, Doughnut } from "react-chartjs-2";

import {
  Chart as ChartJS,
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";

import MaterialReactTable from "material-react-table";
import { Replay } from "@mui/icons-material";
import { Hourglass } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { domainUrl, logOutHandler } from "../../utils/constants";
import ExportImg from "../../assets/img/excel-img.png";
import axios from "axios";
import toast from "react-hot-toast";
import { Avatar, Button, IconButton } from "@mui/material";
import { pauseClientPut } from "../../utils/redux/slice/pauseClientSlice";
import { useDispatch } from "react-redux";
ChartJS.register(
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  Tooltip,
  Legend
);

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function PausedClientsTable() {
  const [data, setData] = useState([]);

  const [isError, setIsError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [isRefetching, setIsRefetching] = useState(false);

  const [rowCount, setRowCount] = useState(0);

  const [refetchData, setRefetchData] = useState(false);

  const role = localStorage.getItem("role");
  const access_token = localStorage.getItem("access_token");

  const [totalCount, setTotalCount] = useState(2);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [columnFilters, setColumnFilters] = useState([]);

  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState([]);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [resumeLoaders, setResumeLoaders] = useState({});

  const resumeClient = async (id) => {
    setResumeLoaders({ ...resumeLoaders, [id]: true });

    const res = await dispatch(
      pauseClientPut({ status: "resume", id: id }, navigate)
    );
    if (res.payload) {
      setResumeLoaders({ ...resumeLoaders, [id]: false });
      setRefetchData(!refetchData);
      toast.success(res.payload.message, { id: 1 });
    }
    if (res.error) {
      setResumeLoaders({ ...resumeLoaders, [id]: false });
      toast.error(res.error.message, { id: 1 });
    }
  };

  const formatDate = (dateString) => {
    if (dateString) {
      const date = dateString.split("-");
      return `${date[1]}/${date[2]}/${date?.[0]}`;
    }
    return dateString;
  };

  function formatUSD(number) {
    if (typeof number !== "number" || isNaN(number)) {
      if (typeof number == "string") {
        number = parseFloat(number);
      } else {
        return "Invalid number";
      }
    }

    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    return formatter.format(number);
  }
  useEffect(() => {
    const fetchData = async () => {
      if (!data.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      const url = new URL("clients", `${domainUrl}`);
      url.searchParams.set("status", `paused`);
      url.searchParams.set(
        "offset",
        pagination.pageIndex * pagination.pageSize
      );
      url.searchParams.set("limit", `${pagination.pageSize}`);
      url.searchParams.set("offset", `${pagination.pageIndex}`);
      url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
      url.searchParams.set("search", search ?? "");
      url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
      url.searchParams.set("table", `true`);
      try {
        const response = await fetch(url.href, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        });
        let json = await response.json();
        let data = json;

        if (data.code == "token_not_valid") {
          logOutHandler();
          navigate("/login");
          toast.error("Session Expired", { id: 1 });
          return;
        }
        setData(data.data);
        setRowCount(data.count);
      } catch (error) {
        setIsError(true);

        return;
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    };

    fetchData();
  }, [
    columnFilters,
    search,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    refetchData,
  ]);

  const columns = useMemo(
    () => [
      {
        id: "clients",
        header: "",
        columns: [
          {
            accessorFn: (row) => `${row.contracts?.[0].attorney.firm.firm_name} `,
            id: "contracts__attorney__firm__firm_name",
            header: "Firm Name",
            accessorKey: "firm_name",
            enableColumnActions: false,
            size: 30,
          },
          {
            accessorFn: (row) =>
              `${row?.user?.first_name} ${row?.user?.last_name} `,
            id: "user_model__first_name",
            header: "Client Name",
            enableColumnActions: false,
            accessorKey: "first_name",
            size: 30,
          },
          {
            id: "user_model__email",
            header: "Email",
            accessorFn: (row) => `${row?.user?.email}`,
            accessorKey: "email",
            enableColumnActions: false,
            size: 30,
          },
          {
            id: "contracts__case_number",
            header: "Case Number",
            accessorFn: (row) => `${row?.contracts?.[0].case_number}`,
            accessorKey: "case_number",
            enableColumnActions: false,
            size: 30,
          },
          {
            id: "contracts__case_type",
            header: "Case Type",
            accessorFn: (row) => `${row?.contracts?.[0].case_type}`,
            accessorKey: "case_type",
            enableColumnActions: false,
            size: 30,
          },
          {
            id: "contracts__contract_amount",
            header: "Total Contract Amount",
            accessorFn: (row) =>
              `${formatUSD(row?.contracts?.[0].contract_amount)}`,
            enableColumnActions: false,
            accessorKey: "contract_amount",
          },
          {
            id: "contracts__amount_due",
            header: "Amount Due",
            accessorFn: (row) => `${formatUSD(row?.contracts?.[0].amount_due)}`,
            enableColumnActions: false,
            accessorKey: "amount_due",
            size: 30,
          },
          {
            id: "paused_date",
            header: "Paused Date",
            accessorFn: (row) => `${formatDate(row?.paused_date)}`,
            enableColumnActions: false,
            accessorKey: "paused_date",
            size: 30,
          },
        ],
      },
    ],

    []
  );
  const exportFileHandler = async (id) => {
    const url = new URL("clients", `${domainUrl}`);
    url.searchParams.set("status", "paused");
    url.searchParams.set("offset", pagination.pageIndex * pagination.pageSize);
    url.searchParams.set("limit", `${pagination.pageSize}`);
    url.searchParams.set("offset", `${pagination.pageIndex}`);
    url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
    url.searchParams.set("search", search ?? "");
    url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
    url.searchParams.set("export", true);

    axios
      .get(url, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        if (response.headers.get("content-type") == "application/json") {
          response.data.text().then((text) => {
            toast.success(JSON.parse(text).scheduled, { autoClose: 10000 });
          });
        } else {
          let filename = `Paused Clients_${Date.now()}.xlsx`;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
        }
      });
  };

  return (
    <>
      <div>
        <main className="py-10">
          <main className="w-full py-10 ">
            <div className="mx-5">
              <h2
                style={{
                  color: "#1a3253",
                }}
              >
                Paused Clients
              </h2>
              <div className="text-base text-[#9ca0b3] my-2">
                List of Clients paused by NA Admin
              </div>

              <div className="p-1 border rounded-lg">
                <MaterialReactTable
                  enableTopToolbar={true}
                  renderTopToolbarCustomActions={({ table }) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          gap: "0.5rem",
                          alignItems: "center",
                        }}
                      >
                        {role == "admin" ? (
                          <Button
                            disabled={false}
                            startIcon={<Avatar src={ExportImg} />}
                            onClick={() => {
                              exportFileHandler();
                            }}
                            variant="contained"
                            sx={{
                              bgcolor: "#127c44",
                              "&:hover": {
                                bgcolor: "#127c44",
                              },
                              height: "25px",
                            }}
                          >
                            Export
                          </Button>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  }}
                  enableStickyHeader
                  muiTableContainerProps={{ sx: { maxHeight: "500px" } }}
                  muiTableBodyProps={{
                    sx: () => ({
                      "& tr:nth-child(odd)": {
                        backgroundColor: "#f8f9fa",
                      },
                      "& tr:nth-child(even)": {
                        backgroundColor: "#ffffff",
                      },
                    }),
                  }}
                  muiTablePaperProps={{
                    sx: {
                      maxWidth: "full",
                    },
                    elevation: 0,
                  }}
                  muiTableHeadCellProps={{
                    sx: (theme) => ({
                      textAlign: "center",

                      color: theme.palette.text.primary,
                    }),
                  }}
                  enableFullScreenToggle={false}
                  enableDensityToggle={false}
                  enableHiding={false}
                  enableColumnFilters={true}
                  rowNumberMode="static"
                  muiTablePaginationProps={{
                    rowsPerPageOptions: [5, 10, 15, 20, 25],
                    showFirstButton: false,
                    showLastButton: false,
                  }}
                  columns={columns}
                  data={data}
                  enableRowActions
                  positionActionsColumn="last"
                  displayColumnDefOptions={{
                    "mrt-row-actions": {
                      header: "Resume",
                    },
                  }}
                  renderRowActions={({ row }) => (
                    <>
                      {resumeLoaders[row.original.id] ? (
                        <IconButton>
                          <Hourglass
                            visible={true}
                            height="25"
                            width="25"
                            ariaLabel="hourglass-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            colors={["#306cce", "#72a1ed"]}
                          />
                        </IconButton>
                      ) : (
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            resumeClient(row.original.id);
                          }}
                        >
                          <Replay
                            sx={{
                              color: "black",
                            }}
                          />
                        </IconButton>
                      )}
                    </>
                  )}
                  muiTableBodyRowProps={({ row }) => ({
                    onClick: () => {
                      if (data.length) {
                        localStorage.setItem("view_client_id", row.original.id);
                        navigate(`/client-view`);
                      }
                    },

                    sx: {
                      cursor: "pointer",
                    },
                  })}
                  getRowId={(row) => row.id}
                  initialState={{
                    showColumnFilters: false,
                    density: "compact",
                  }}
                  manualPagination
                  manualSorting
                  muiToolbaralertBannerProps={
                    isError
                      ? {
                          color: "error",
                          children: "Error loading data",
                        }
                      : undefined
                  }
                  onColumnFiltersChange={setColumnFilters}
                  onGlobalFilterChange={setSearch}
                  onPaginationChange={setPagination}
                  onSortingChange={setSorting}
                  rowCount={rowCount}
                  state={{
                    isLoading,
                    pagination,
                    showalertBanner: isError,
                    showProgressBars: isRefetching,
                    sorting,
                  }}
                />
              </div>
            </div>
          </main>
        </main>
      </div>
    </>
  );
}
