import { useEffect, useMemo, useState } from "react";
import {
  CalendarIcon,
  ChartPieIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { Line, Doughnut } from "react-chartjs-2";
import ExportImg from "../../assets/img/excel-img.png";

import {
  Chart as ChartJS,
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import MaterialReactTable from "material-react-table";
import { Hourglass, ThreeDots } from "react-loader-spinner";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import SmsIcon from "@mui/icons-material/Sms";
import { useNavigate } from "react-router-dom";
import { domainUrl, listedStates, logOutHandler } from "../../utils/constants";
import axios from "axios";
import toast from "react-hot-toast";
import { Avatar, Button, IconButton } from "@mui/material";
ChartJS.register(
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  Tooltip,
  Legend
);

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function TotalEscrowBalanceTable() {
  const [data, setData] = useState([]);

  const [isError, setIsError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [isRefetching, setIsRefetching] = useState(false);

  const [rowCount, setRowCount] = useState(0);

  const role = localStorage.getItem("role");
  const access_token = localStorage.getItem("access_token");

  const navigate = useNavigate();

  const [columnFilters, setColumnFilters] = useState([]);

  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState([]);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  function extractDateFromTimestamp(timestamp) {
    const dateObject = new Date(timestamp);
    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
    const day = dateObject.getDate().toString().padStart(2, "0");
    const year = dateObject.getFullYear();
    return `${month}/${day}/${year}`;
  }

  function extractTimeFromTimestamp(timestamp) {
    const dateObject = new Date(timestamp);
    const hour = dateObject.getHours().toString().padStart(2, "0");
    const minute = dateObject.getMinutes().toString().padStart(2, "0");
    return `${hour}:${minute}`;
  }

  useEffect(() => {
    const fetchData = async () => {
      if (!data.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      const url = new URL("firm-escrows", `${domainUrl}`);
      url.searchParams.set(
        "offset",
        pagination.pageIndex * pagination.pageSize
      );
      url.searchParams.set("limit", `${pagination.pageSize}`);
      url.searchParams.set("offset", `${pagination.pageIndex}`);
      url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
      url.searchParams.set("search", search ?? "");
      url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
      try {
        const response = await fetch(url.href, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        });
        let json = await response.json();
        let data = json;

        if (data.code == "token_not_valid") {
          logOutHandler();
          navigate("/login");
          toast.error("Session Expired", { id: 1 });
          return;
        }
        setData(data.data);
        setRowCount(data.count);
      } catch (error) {
        setIsError(true);

        return;
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    };

    fetchData();
  }, [
    columnFilters,
    search,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
  ]);

  const columns = useMemo(
    () => [
      {
        id: "firm_name",
        header: "",
        columns: [
          {
            id: "firm_name",
            header: "Firm Name",
            accessorFn: (row) => `${row?.firm_name}`,
            accessorKey: "firm_name",
            enableColumnActions: false,
            size: 30,
          },
          {
            id: "firm_code",
            header: "Firm Code",
            accessorFn: (row) => `${row?.firm_code}`,
            accessorKey: "firm_code",
            enableColumnActions: false,
            size: 30,
          },
          {
            accessorFn: (row) => `${formatUSD(row?.escrow_balance)} `,
            id: "escrow_balance",
            enableColumnActions: false,
            header: "Escrow Balance",
            accessorKey: "escrow_balance",
            size: 30,
          },
        ],
      },
    ],

    []
  );
  const formatDate = (dateString) => {
    if (dateString) {
      const date = dateString.split("-");

      return `${date[1]}/${date[2]}/${date[0]}`;
    }
    return dateString;
  };
  const getStateByCode = (code) => {
    const state = listedStates.find((state) => state.code === code);
    return state ? state : null;
  };
  function formatUSD(number) {
    if (typeof number !== "number" || isNaN(number)) {
      if (typeof number == "string") {
        number = parseFloat(number);
      } else {
        return "Invalid number";
      }
    }

    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    return formatter.format(number);
  }
  function formatUSCell(usPhoneNumber) {
    let digitsOnly = usPhoneNumber.replace(/\D/g, "");

    if (digitsOnly.length !== 10) {
      return "Invalid phone number format";
    }

    return "+1 " + digitsOnly.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  }

  return (
    <>
      <div>
        <main className="py-10">
          <main className="w-full py-10 ">
            <div className="mx-5">
              <h2
                style={{
                  color: "#1a3253",
                }}
              >
                Total Escrow Balance
              </h2>
              <div className="text-base text-[#9ca0b3] my-2">
                Firm aggregated escrow
              </div>

              <div className="p-1 border rounded-lg">
                <MaterialReactTable
                  enableTopToolbar={false}
                  enableStickyHeader
                  muiTableContainerProps={{ sx: { maxHeight: "500px" } }}
                  muiTableBodyProps={{
                    sx: () => ({
                      "& tr:nth-child(odd)": {
                        backgroundColor: "#f8f9fa",
                      },
                      "& tr:nth-child(even)": {
                        backgroundColor: "#ffffff",
                      },
                    }),
                  }}
                  muiTablePaperProps={{
                    sx: {
                      maxWidth: "full",
                    },
                    elevation: 0,
                  }}
                  muiTableHeadCellProps={{
                    sx: (theme) => ({
                      color: theme.palette.text.primary,
                    }),
                  }}
                  enableFullScreenToggle={false}
                  enableDensityToggle={false}
                  enableHiding={false}
                  enableColumnFilters={false}
                  rowNumberMode="static"
                  renderDetailPanel={({ row }) => {
                    return (
                      <div className="bg-white">
                        <div className="relative mx-auto  max-w-7xl px-4 py-8 sm:px-6 lg:px-8">
                          <div className="px-4 sm:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                              <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                  <tr>
                                    <th
                                      scope="col"
                                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                    >
                                      Attorney Name
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                    >
                                      Attorney Code
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                    >
                                      Escrow Balance
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                  {row.original.attorneys.map((item, index) => (
                                    <tr className="bg-white">
                                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                        {item.attorney_name}
                                      </td>
                                      <td className="whitespace-nowrap px-3 py-4 text-sm font-bold text-gray-700">
                                        {item.attorney_code}
                                      </td>
                                      <td className="whitespace-nowrap px-3 py-4 text-sm font-bold text-gray-700">
                                        {formatUSD(item.escrow_balance)}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }}
                  muiTablePaginationProps={{
                    rowsPerPageOptions: [5, 10, 15, 20, 25],
                    showFirstButton: false,
                    showLastButton: false,
                  }}
                  columns={columns}
                  data={data}
                  enableRowActions={false}
                  positionActionsColumn="last"
                  muiTableBodyRowProps={({ row }) => ({
                    onClick: () => {
                      if (data.length) {
                      }
                    },

                    sx: {
                      cursor: "pointer",
                    },
                  })}
                  getRowId={(row) => row.id}
                  initialState={{
                    showColumnFilters: false,
                    density: "compact",
                  }}
                  manualPagination
                  manualSorting
                  muiToolbaralertBannerProps={
                    isError
                      ? {
                          color: "error",
                          children: "Error loading data",
                        }
                      : undefined
                  }
                  onColumnFiltersChange={setColumnFilters}
                  onGlobalFilterChange={setSearch}
                  onPaginationChange={setPagination}
                  onSortingChange={setSorting}
                  rowCount={rowCount}
                  state={{
                    isLoading,
                    pagination,
                    showalertBanner: isError,
                    showProgressBars: isRefetching,
                    sorting,
                  }}
                />
              </div>
            </div>
          </main>
        </main>
      </div>
    </>
  );
}
