import { useEffect, useMemo, useState } from "react";
import {
  Chart as ChartJS,
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import MaterialReactTable from "material-react-table";
import { useNavigate } from "react-router-dom";
import { domainUrl, logOutHandler } from "../../utils/constants";
import toast from "react-hot-toast";
import axios from 'axios';
ChartJS.register(
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  Tooltip,
  Legend
);


export default function ClientsChatTable() {
  const [data, setData] = useState([]);

  const [isError, setIsError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [isRefetching, setIsRefetching] = useState(false);

  const [rowCount, setRowCount] = useState(0);

  const role = localStorage.getItem("role");
  const access_token = localStorage.getItem("access_token");

  const navigate = useNavigate();

  const [columnFilters, setColumnFilters] = useState([]);

  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState([]);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });


  useEffect(() => {
   
    const fetchData = async () => {
      if (!data?.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }
      const url = new URL("chat/list/", `${domainUrl}`);
      url.searchParams.set("limit", `${pagination.pageSize}`);
      url.searchParams.set("offset", `${pagination.pageIndex}`);
      url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
      url.searchParams.set("search", search ?? "");
      url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
      url.searchParams.set("table", `true`);
      try {
        const response = await fetch(url.href, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        });
        let json = await response.json();
        let data = json;

        if (data.code == "token_not_valid") {
          logOutHandler();
          navigate("/login");
          toast.error("Session Expired", { id: 1 });
          return;
        }
        setData(data.data);
        setRowCount(data.count);
      } catch (error) {
        setIsError(true);

        return;
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    };
    fetchData();
  }, [
    columnFilters,
    search,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
  ]);

  
  const columns = useMemo(
    () => [
      {
        id: "clients",
        header: "",
        columns: [
          {
            accessorFn: (row) => row?.receiver?.name,
            id: "client_name",
            enableColumnActions: false,
            header: "Client Name",
            size: 30,
            Cell: ({ row }) => (
              <span
                onClick={() => {
                  localStorage.setItem("view_client_id", row?.original?.client_details.client_id);
                  console.log('row.original.client_details.client_id' ,row?.original?.client_details?.client_id)
                  navigate("/client-view");
                }}
                className="text-blue-600 hover:text-blue-800 cursor-pointer underline"
              >
                {row?.original?.receiver?.name}
              </span>
            ),
          },
          {
            accessorFn: (row) => row?.client_details?.client_code,
            id: "client_code",
            enableColumnActions: false,
            header: "Client Code",
            size: 30,
          },
          {
            accessorFn: (row) => row?.receiver?.phone_number,
            id: "phone_number",
            enableColumnActions: false,
            header: "Phone Number",
            size: 30,
            Cell: ({ row }) => (
              <span>{row?.original?.receiver_phone}</span>
            ),
          },
          {
            accessorFn: (row) => row?.client_details?.firm.name,
            id: "firm_name",
            enableColumnActions: false,
            header: "Firm",
            size: 30,
            Cell: ({ row }) => (
              <span
                onClick={() => {
                  localStorage.setItem("firm_id", row?.original.client_details?.firm.id);
                  navigate("/view-firm");
                }}
                className="text-blue-600 hover:text-blue-800 cursor-pointer underline"
              >
                {row.original.client_details.firm.name}
              </span>
            ),
          },
          {
            accessorFn: (row) => row?.client_details?.attorney.name,
            id: "attorney_name",
            enableColumnActions: false,
            header: "Attorney",
            size: 30,
            Cell: ({ row }) => (
              <span
                onClick={() => {
                  localStorage.setItem("view_attorney_id", row?.original?.client_details?.attorney.id);
                  navigate("/attorney-profile");
                }}
                className="text-blue-600 hover:text-blue-800 cursor-pointer underline"
              >
                {row?.original?.client_details?.attorney?.name}
              </span>
            ),
          },
          {
            accessorFn: (row) => row?.last_message_time,
            id: "last_message_time",
            enableColumnActions: false,
            header: "Last Message",
            size: 30,
            Cell: ({ row }) => (
              <span>
                {new Date(row?.original?.last_message_time)?.toLocaleString()}
              </span>
            ),
          }
        ],
      },
    ],
    []
  );



  return (
    <>
      <div>
        <main className="py-10">
          <main className="w-full py-10 ">
            <div className="mx-5">
              <h2
                style={{
                  color: "#1a3253",
                }}
              >
                Clients Chat
              </h2>

              <div className="p-1 border rounded-lg">
                <MaterialReactTable
                  enableTopToolbar={true}
                  enableStickyHeader
                  muiTableContainerProps={{ sx: { maxHeight: "500px" } }}
                  muiTableBodyProps={{
                    sx: () => ({
                      "& tr:nth-child(odd)": {
                        backgroundColor: "#f8f9fa",
                      },
                      "& tr:nth-child(even)": {
                        backgroundColor: "#ffffff",
                      },
                    }),
                  }}
                  muiTablePaperProps={{
                    sx: {
                      maxWidth: "full",
                    },
                    elevation: 0,
                  }}
                  muiTableHeadCellProps={{
                    sx: (theme) => ({
                      color: theme.palette.text.primary,
                    }),
                  }}
                  enableFullScreenToggle={false}
                  enableDensityToggle={false}
                  enableHiding={false}
                  enableColumnFilters={true}
                  rowNumberMode="static"
                  muiTablePaginationProps={{
                    rowsPerPageOptions: [5, 10, 15, 20, 25],
                    showFirstButton: false,
                    showLastButton: false,
                  }}
                  columns={columns}
                  data={data || []}
                  getRowId={(row) => row.id}
                  initialState={{
                    showColumnFilters: false,
                    density: "compact",
                  }}
                  manualPagination
                  manualSorting
                  muiToolbaralertBannerProps={
                    isError
                      ? {
                          color: "error",
                          children: "Error loading data",
                        }
                      : undefined
                  }
                  onColumnFiltersChange={setColumnFilters}
                  onGlobalFilterChange={setSearch}
                  onPaginationChange={setPagination}
                  onSortingChange={setSorting}
                  rowCount={rowCount}
                  state={{
                    isLoading,
                    pagination,
                    showalertBanner: isError,
                    showProgressBars: isRefetching,
                    sorting,
                  }}
                />
              </div>
            </div>
          </main>
        </main>
      </div>
    </>
  );
}
