import { useNavigate } from "react-router-dom";
import axios from "axios";
import { domainUrl, logOutHandler } from "../../utils/constants";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
import { DateRangePicker } from "rsuite";
import subDays from "date-fns/subDays";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import subMonths from "date-fns/subMonths";
import subYears from "date-fns/subYears";
export default function AttorneyContractStatusPie() {
  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");
  const [contractData, setContractData] = useState({});
  const [data, setData] = useState([]);
  const [dateFilter, setDateFilter] = useState({
    start_date: "",
    end_date: "",
  });

  const predefinedRanges = [
    {
      label: "Past week",
      closeOverlay: false,
      value: [subDays(new Date(), 6), new Date()],
      placement: "left",
    },
    {
      label: "Past month",
      closeOverlay: false,
      value: [
        startOfMonth(subMonths(new Date(), 1)),
        endOfMonth(subMonths(new Date(), 1)),
      ],
      placement: "left",
    },
    {
      label: "Past 3 months",
      closeOverlay: false,
      value: [startOfMonth(subMonths(new Date(), 3)), new Date()],
      placement: "left",
    },
    {
      label: "Past 6 months",
      closeOverlay: false,
      value: [startOfMonth(subMonths(new Date(), 6)), new Date()],
      placement: "left",
    },
    {
      label: "Past year",
      closeOverlay: false,
      value: [startOfMonth(subYears(new Date(), 1)), new Date()],
      placement: "left",
    },
    {
      label: "Past 2 years",
      closeOverlay: false,
      value: [startOfMonth(subYears(new Date(), 2)), new Date()],
      placement: "left",
    },
  ];

  function formatUSD(number) {
    if (typeof number !== "number" || isNaN(number)) {
      if (typeof number == "string") {
        number = parseFloat(number);
      } else {
        return "Invalid number";
      }
    }

    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    return formatter.format(number);
  }

  const handleDateFilter = (e) => {
    if (e == null) {
      setDateFilter({
        start_date: "",
        end_date: "",
      });
    }
    if (e && e.length === 2) {
      const startDate = new Date(e[0]);
      const endDate = new Date(e[1]);

      var timezoneOffsetInMinutes = new Date().getTimezoneOffset();
      startDate.setHours(
        startDate.getHours() + startDate.getTimezoneOffset() / 60
      );
      endDate.setHours(endDate.getHours() + endDate.getTimezoneOffset() / 60);

      const formattedStartDate = startDate.toISOString().split("T")[0];
      const formattedEndDate = endDate.toISOString().split("T")[0];

      setDateFilter({
        start_date: formattedStartDate,
        end_date: formattedEndDate,
      });
    }
  };

  const getContractStatusGraphData = async () => {
    try {
      const res = await axios.get(
        `${domainUrl}contract-graph?start_date=${dateFilter.start_date}&end_date=${dateFilter.end_date}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (res.data) {
        setContractData(res.data);
        const temp = [
          {
            name: "Active",
            value: res.data.active,
            count: res.data.active,
          },
          {
            name: "Delinquent",
            value: res.data.delinquent,
            count: res.data.delinquent,
          },
          {
            name: "Completed",
            value: res.data.completed,
            count: res.data.completed,
          },
        ];
        setData(temp);
      }
    } catch (error) {
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
    }
  };

  useEffect(() => {
    getContractStatusGraphData();
  }, [dateFilter]);

  const COLORS = ["#4ab516", "#ff0000", "#1976D2"];
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="p-2  bg-white border border-black shadow-md flex flex-col gap-4 rounded-md">
          <p className="text-medium text-sm">{payload[0].payload?.name}</p>
          <p className="text-base font-semibold text-blue-600">
            Contracts:
            <span className="ml-2">{payload[0].payload?.count}</span>
          </p>
        </div>
      );
    }
  };

  const navigateToCorrespondingRoute = (entry) => {
    if (entry.name === "Active") {
      localStorage.setItem("query_param/active", true);
      let range = {
        start_date: dateFilter.start_date,
        end_date: dateFilter.end_date,
      };

      navigate("/attorney-client-contracts");
    } else if (entry.name === "Delinquent") {
      localStorage.setItem("query_param/active", true);
      let range = {
        start_date: dateFilter.start_date,
        end_date: dateFilter.end_date,
      };

      navigate("/attorney-client-contracts");
    } else if (entry.name === "Completed") {
      localStorage.setItem("query_param/active", true);
      let range = {
        start_date: dateFilter.start_date,
        end_date: dateFilter.end_date,
      };

      navigate("/attorney-client-contracts");
    }
  };

  return (
    <>
      <div className="w-full">
        <div className="w-full h-[350px] p-2 flex flex-col justify-center items-center border border-gray-200 rounded-xl shadow-md">
          <div className="mb-3 text-base text-gray-700 font-semibold">
            CONTRACT STATUS
          </div>
          <div className="flex justify-evenly items-center gap-8">
            <div>
              <div className="text-xs inline-flex items-center gap-1">
                <span className="h-2 w-2 bg-[#4ab516]"></span>Active
              </div>
              <div className="font-bold text-sm text-center">
                {contractData.active ? `${contractData.active}` : "0"}
              </div>
            </div>
            <div>
              <div className="text-xs inline-flex items-center gap-1">
                <span className="h-2 w-2 bg-[#ff0000]"></span>Delinquent
              </div>
              <div className="font-bold text-sm text-center">
                {contractData.delinquent ? `${contractData.delinquent}` : "0"}
              </div>
            </div>
            <div>
              <div className="text-xs inline-flex items-center gap-1">
                <span className="h-2 w-2 bg-[#1976D2]"></span>Completed
              </div>
              <div className="font-bold text-sm text-center">
                {contractData.completed ? `${contractData.completed}` : "0"}
              </div>
            </div>
          </div>
          <div className="w-full h-60 flex justify-center items-center sm:w-[27rem] lg:w-[27rem]">
            <PieChart width={235} height={380}>
              <Pie
                className="hover:cursor-pointer"
                data={data}
                onClick={(e) => {
                  navigateToCorrespondingRoute(e);
                }}
                fill="#8884d8"
                dataKey="value"
                labelLine={false}
                label={({
                  cx,
                  cy,
                  midAngle,
                  innerRadius,
                  outerRadius,
                  percent,
                }) => {
                  const radius = outerRadius * 1.16;
                  const x = cx + radius * Math.cos(-midAngle * (Math.PI / 180));
                  const y = cy + radius * Math.sin(-midAngle * (Math.PI / 180));

                  return (
                    <text
                      x={x}
                      y={y}
                      fill="black"
                      textAnchor="middle"
                      dominantBaseline="central"
                      fontSize={10}
                    >
                      {`${(percent * 100).toFixed(2)}%`}
                    </text>
                  );
                }}
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip
                wrapperStyle={{ zIndex: 2 }}
                content={<CustomTooltip />}
              />
            </PieChart>
          </div>
          {/* <DateRangePicker
            ranges={predefinedRanges}
            onChange={(e) => {
              handleDateFilter(e);
            }}
            size="xs"
            showOneCalendar
            placeholder="Select Date"
            format="MM/dd/yyyy"
            character=" – "
            className="z-0 hover:cursor-pointer"
            preventOverflow={true}
          /> */}
        </div>
      </div>
    </>
  );
}
