import React, { Fragment, useEffect, useRef, useState } from "react";

import {
  CalendarIcon,
  ChartPieIcon,
  CheckCircleIcon,
  ClipboardDocumentCheckIcon,
  DocumentDuplicateIcon,
  ExclamationTriangleIcon,
  FolderIcon,
  HomeIcon,
  PencilIcon,
  PlusIcon,
  TrashIcon,
  UsersIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { PhotoIcon, UserCircleIcon } from "@heroicons/react/24/solid";

import { useNavigate } from "react-router-dom/dist";
import StepperComponent from "../Components/StepperComponent";
import Breadcrumb from "../Components/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import {
  postFirmNamePost,
  postFirmNameStorage,
} from "../utils/redux/slice/postFirmNameSlice";
import toast from "react-hot-toast";
import { Oval, TailSpin, ThreeDots } from "react-loader-spinner";
import { Combobox, Dialog, Transition } from "@headlessui/react";
import { CheckIcon, MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { postFNLNamePost } from "../utils/redux/slice/postFNLNameSlice";
import { postMultipleFNLNamePost } from "../utils/redux/slice/postMultipleFNLNameSlice";
import { addFirmPost } from "../utils/redux/slice/addFirmSlice";
import { getAddFirmDraftGet } from "../utils/redux/slice/getAddFirmDraftSlice";
import ModalComponent from "../Components/ModalComponent";
import { deleteAddFirmDraft } from "../utils/redux/slice/deleteAddFirmDraftSlice";
import axios from "axios";
import { domainUrl, listedStates, logOutHandler } from "../utils/constants";
import { deleteSingleMultipleAttorney } from "../utils/redux/slice/deleteSingleMultipleAttorneySlice";
import { ErrorSharp } from "@mui/icons-material";
import { InputMask } from "@react-input/mask";
import { InputNumberFormat } from "@react-input/number-format";
import { DocumentArrowUpIcon } from "@heroicons/react/24/outline";

const AddFirms = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const access_token = localStorage.getItem("access_token");
  const [pageSection, setPageSection] = useState({
    FIRM: true,
    ATTORNEYS: false,
    CONTRACT: false,
    PAYMENT: false,
  });
  const [stepperStatus, setStepperStatus] = useState(true);

  const [skipFlag, setSkipFlag] = useState(false);
  const [overRideFlag, setOverRideFlag] = useState(false);

  const [firmDetail, setFirmDetail] = useState({
    firm_name: "",
    email: "",
    address_line1: "",
    address_line2: "",
    city: "",
    state: "",
    zip_code: "",
    cell_phone_number: "",
    secondary_cell_phone_number: "",
    firm_code: "",
    firm_url: "",
    logo_url: "",
  });
  const [primaryAttorneyDetail, setPrimaryAttorneyDetail] = useState({
    first_name: "",
    last_name: "",
    email: "",
    cell_phone_number: "",
    attorney_code: "",
  });

  const initialField = {
    first_name: "",
    last_name: "",
    email: "",
    cell_phone_number: "",
    attorney_code: "",
  };

  const [contract, setContract] = useState({
    na_fee_percentage: 25,
    escrow_limit: 5000,
    escrow_percentage: 10,
  });
  const cardFields = {
    cardholder: "",
    card_number: "",
    expiration_date: "",
    cvv: "",
    is_primary: false,
  };

  const [initialAttorneysData, setInitialAttorneysData] = useState({});
  const [cardData, setCardData] = useState(cardFields);

  const [fields, setFields] = useState([initialField]);

  const [cards, setCards] = useState([]);
  const [directDeposit, setDirectDeposit] = useState({
    bank_routing_number: "",
    bank_account_number: "",
  });

  const getStateByCode = (code) => {
    return listedStates.find((state) => state.code === code);
  };

  const getAddFirmDraftLoader = useSelector(
    (state) => state.getAddFirmDraftStorage.isLoading
  );

  const [openEnableDraft, setOpenEnableDraft] = useState(false);
  const [openSoftErrorModal, setOpenSoftErrorModal] = useState(false);
  const [openHardErrorModal, setOpenHardErrorModal] = useState(false);
  const [hardErrorData, setHardErrorData] = useState({
    firm_id: "",
    firm_name: "",
    firm_code: "",
  });

  const allowedFileTypes = [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "image/webp",
    "application/pdf",
  ];

  const handleClose = () => {
    setOpenEnableDraft(false);
    setOpenSoftErrorModal(false);
    setOpenHardErrorModal(false);
  };

  const checkDraftHandler = async () => {
    const res = await dispatch(getAddFirmDraftGet(navigate));
    if (res.payload) {
      if (res.payload.detail === "No saved drafts") {
        return false;
      }
      setOpenEnableDraft(true);

      toast.success("Retained Draft", { id: 1 });
      if (res.payload.firm) {
        setFirmDetail(res.payload.firm);
        setFirmDetail((prevState) => ({
          ...prevState,
          cell_phone_number: formatcell_phone_number(
            res.payload.firm.cell_phone_number
          ),
          secondary_cell_phone_number: formatcell_phone_number(
            res.payload.firm.secondary_cell_phone_number
          ),
        }));
        setSelectedState(getStateByCode(res.payload.firm.state));
      }
      if (res.payload.primary_attorney) {
        setPrimaryAttorneyDetail(res.payload.primary_attorney);
        setPrimaryAttorneyDetail((prevState) => ({
          ...prevState,
          cell_phone_number: formatcell_phone_number(
            res.payload.primary_attorney.cell_phone_number
          ),
        }));
      }
      if (res.payload.attorney) {
        res.payload.attorney.length > 0
          ? setFields([...res.payload.attorney, initialField])
          : setFields([initialField]);

        let errAttorneys = [];
        res.payload.attorney.forEach((element) => {
          errAttorneys.push({
            first_name: "",
            last_name: "",
            email: "",
            cell_phone_number: "",
          });
        });

        const updatedAttorneys = res.payload.attorney.map((attorneyData) => ({
          first_name: "",
          last_name: "",
          email: "",
          cell_phone_number: "",
        }));

        updatedAttorneys.push({
          first_name: "",
          last_name: "",
          email: "",
          cell_phone_number: "",
        });
        setErrorFlag({
          ...errorFlag,
          attorney: updatedAttorneys,
        });
      }
      if (res.payload.direct_deposit) {
        setDirectDeposit(res.payload.bank_account_number);
        setDirectDeposit((prevState) => ({
          ...prevState,
          bank_account_number: res.payload.direct_deposit.bank_account_number
            ? res.payload.direct_deposit.bank_account_number
            : "",
          bank_routing_number: res.payload.direct_deposit.bank_routing_number
            ? res.payload.direct_deposit.bank_routing_number
            : "",
        }));
      }
      if (res.payload.contract) {
        if (res.payload.contract) {
          setContract((prevState) => ({
            ...prevState,
            escrow_limit: res.payload.contract.escrow_limit
              ? res.payload.contract.escrow_limit
              : 5000,
            escrow_percentage: res.payload.contract.escrow_percentage
              ? res.payload.contract.escrow_percentage
              : 10,
            na_fee_percentage: res.payload.contract.na_fee_percentage
              ? res.payload.contract.na_fee_percentage
              : 25,
          }));
        }
      }
    }
    if (res.error) {
    }
  };

  useEffect(() => {
    checkDraftHandler();
  }, []);

  const deleteDraft = async () => {
    const res = await dispatch(deleteAddFirmDraft(navigate));
    if (res.payload) {
      handleClose();
      setFirmDetail({
        firm_name: "",
        email: "",
        address_line1: "",
        address_line2: "",
        city: "",
        state: "",
        zip_code: "",
        cell_phone_number: "",
        secondary_cell_phone_number: "",
        firm_code: "",
      });
      setSelectedState({});
      setPrimaryAttorneyDetail({
        first_name: "",
        last_name: "",
        email: "",
        cell_phone_number: "",
        attorney_code: "",
      });
      setFields([initialField]);
      setDirectDeposit({
        bank_routing_number: "",
        bank_account_number: "",
      });
      setContract({
        na_fee_percentage: "25",
        escrow_limit: "5000",
        escrow_percentage: "10",
      });
      setSkipFlag(true);
    }
    if (res.error) {
      toast.error(res.error.message);
    }
  };

  const [typingTimeout, setTypingTimeout] = useState(0);

  const firmCodeLoader = useSelector(
    (state) => state.postFirmNameStorage.isLoading
  );

  const attorneyCodeLoader = useSelector(
    (state) => state.postFNLNameStorage.isLoading
  );

  const activeAttorneysCodeLoader = useSelector(
    (state) => state.postMultipleFNLNameStorage.isLoading
  );
  const [firmDetailFlag, setFirmDetailFlag] = useState({
    firm_name: "",
    email: "",
    address_line1: "",
    address_line2: "",
    city: "",
    state: "",
    zip_code: "",
    cell_phone_number: "",
    secondary_cell_phone_number: "",
    firm_code: "",
  });
  const errMsg = {
    email_duplicate: "Email duplication in the same form",
    cell_phone_number_duplicate: "Phone number duplication in the same form",
    email: "Enter a valid email.",
    cell_phone_number: "Phone number must be 10 digit",
    secondary_cell_phone_number: "Secondary Phone number must be 10 digit",
    zip_code: "zipcode should be 5 digit",
    first_name: "Please enter a valid first name (1-30 characters)",
    last_name: "Please enter a valid last name (1-30 characters)",
    escrow_limit: "Please enter correct escrow limit",
    escrow_percentage: "Exceeds the percentage limit for Escrow",
    na_fee_percentage: "Exceeds the percentage limit for NA Fee",
    bank_routing_number: "Bank Routing number should be of length 9 digits.",
    bank_account_number:
      "Bank account number needs to be between 12 and 17 digits.",
    cvv: "CVV should be 3 or 4 digits long. ",
    card_number: "Card number should be 16 digits long.",
    firm_url: "Enter a valid URL",
    logo_url: "Img uploading failed",
  };

  const [errorFlag, setErrorFlag] = useState({
    firm: {
      firm_name: "",
      email: "",
      address_line1: "",
      address_line2: "",
      city: "",
      state: "",
      zip_code: "",
      cell_phone_number: "",
      secondary_cell_phone_number: "",
      firm_code: "",
      firm_url: "",
      logo_url: "",
    },
    primary_attorney: {
      first_name: "",
      last_name: "",
      email: "",
      cell_phone_number: "",
      attorney_code: "",
    },

    attorney: [
      {
        first_name: "",
        last_name: "",
        email: "",
        cell_phone_number: "",
      },
    ],
    contract: {
      na_fee_percentage: "",
      escrow_limit: "",
      escrow_percentage: "",
    },
    direct_deposit: {
      bank_routing_number: "",
      bank_account_number: "",
    },
  });
  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return email.length > 0 && regex.test(email);
  };
  const validateFirmUrl = (url) => {
    const firmUrlRegex =
      /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+\/?$/;
    return url.length > 0 && firmUrlRegex.test(url);
  };
  const validateLogoUrl = (url) => {
    return url?.length > 0;
  };
  const formatAlphabetsWithSpace = (value) => {
    let formattedAlphabets;
    const { length } = value;

    const regex = () => value.replace(/[^a-zA-Z\s]+/g, "");

    if (length > 0) {
      formattedAlphabets = regex();
    }

    return formattedAlphabets ? formattedAlphabets : "";
  };

  const length_validate = {
    zip_code: 5,
    cvv: { min: 3, max: 4 },
    card_number: 16,
    bank_routing_number: 9,
    bank_account_number: 17,
    escrow_percentage: 2,
    na_fee_percentage: 2,
  };

  const formatcell_phone_number = (value) => {
    let convertedNumber = value.replace(/\D/g, "");
    convertedNumber = convertedNumber.replace(/^1/, "");
    convertedNumber = convertedNumber.replace(
      /(\d{3})(\d{3})(\d{4})/,
      "$1 $2 $3"
    );

    return convertedNumber;
  };
  function formatUSCell(usPhoneNumber) {
    let digitsOnly = usPhoneNumber.replace(/\D/g, "");

    if (digitsOnly.length !== 10) {
      return "Invalid phone number format";
    }

    return "+1 " + digitsOnly.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  }
  const [selectedState, setSelectedState] = useState();
  const [selectedCity, setSelectedCity] = useState();

  const [query, setQuery] = useState("");
  const [queryCity, setQueryCity] = useState("");
  const [city, setCity] = useState([]);
  const filteredcity =
    queryCity === ""
      ? city
      : city.filter((city) =>
          city.name
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(queryCity.toLowerCase().replace(/\s+/g, ""))
        );

  const filteredstates =
    query === ""
      ? listedStates
      : listedStates.filter((listedStates) =>
          listedStates.code
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  const formHandler = async (e, form_type, index = -1) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    if (form_type !== "attorney") {
      setErrorFlag((prevErrorFlag) => ({
        ...prevErrorFlag,
        [form_type]: {
          ...prevErrorFlag[form_type],
          [e.target.name]: "",
        },
      }));
    }

    if (form_type == "firm") {
      if (e.target.name == "firm_name") {
        setFirmDetail({
          ...firmDetail,
          firm_name: formatAlphabetsWithSpace(e.target.value),
        });
      } else if (
        ["cell_phone_number", "secondary_cell_phone_number"].includes(
          e.target.name
        )
      ) {
        setFirmDetail({
          ...firmDetail,
          [e.target.name]: e.target.value,
        });
      } else if (["state", "city"].includes(e.target.name)) {
        setFirmDetail({
          ...firmDetail,
          [e.target.name]: formatAlphabetsWithSpace(e.target.value),
        });
      } else if (e.target.name == "zip_code") {
        setFirmDetail({
          ...firmDetail,
          [e.target.name]: filterNumberLength(
            e.target.value,
            length_validate[e.target.name]
          ),
        });
      } else if (e.target.name == "logo_url") {
        const file = e.target.files[0];

        if (allowedFileTypes.includes(file.type)) {
          let maxSize = 500 * 1024;
          if (file.size > maxSize) {
            toast.error("Maximum allowed size is 500KB.");
            return;
          }

          const filePromise = new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = (event) => {
              const base64Data = event.target.result.split(",")[1];
              resolve(base64Data); // Returning only the base64 string
            };

            reader.onerror = (error) => {
              reject(error);
            };

            reader.readAsDataURL(file);
          });

          const base64File = await filePromise;

          setFirmDetail((prevValue) => ({
            ...prevValue,
            logo_url: base64File, // Storing only the base64 string
          }));

          // setErrorFlag((prevErrorFlag) => ({
          //   ...prevErrorFlag,
          //   firm: {
          //     ...prevErrorFlag[form_type],
          //     [e.target.name]: !validateLogoUrl(base64File)
          //       ? errMsg[e.target.name]
          //       : "",
          //   },
          // }));
        } else {
          toast.error(
            "Invalid File Type! Please upload a file of type: [Supported File Types, e.g., .jpg, .png, .jpeg]."
          );
        }
      } else {
        setFirmDetail({
          ...firmDetail,
          [e.target.name]: e.target.value,
        });
      }
    } else if (form_type == "primary_attorney") {
      setPrimaryAttorneyDetail({
        ...primaryAttorneyDetail,
        [e.target.name]:
          e.target.name == "cell_phone_number"
            ? e.target.value
            : e.target.name == "first_name"
            ? formatAlphabetsWithSpace(e.target.value)
            : e.target.name == "last_name"
            ? formatAlphabetsWithSpace(e.target.value)
            : e.target.value,
      });
    } else if (form_type == "attorney") {
      const { name, value } = e.target;

      const updatedFields = [...fields];

      const updatedAttorney = { ...updatedFields[index] };

      updatedAttorney[name] =
        name === "cell_phone_number"
          ? value
          : name === "first_name"
          ? formatAlphabetsWithSpace(value)
          : name === "last_name"
          ? formatAlphabetsWithSpace(value)
          : value;

      if (updatedAttorney.first_name == "" || updatedAttorney.last_name == "") {
        updatedAttorney.attorney_code = "";
      }
      updatedFields[index] = updatedAttorney;

      setFields(updatedFields);
    } else if (form_type == "contract") {
      if (e.target.name == "escrow_limit") {
        setContract({
          ...contract,
          [e.target.name]: e.target.value,
        });
      } else {
        setContract({
          ...contract,
          [e.target.name]: filterPositiveDecimal(e.target.value),
        });
      }
    } else {
      if (["card_number", "cvv"].includes(e.target.name)) {
      } else if (
        ["bank_routing_number", "bank_account_number"].includes(e.target.name)
      ) {
        setDirectDeposit({
          ...directDeposit,
          [e.target.name]: filterNumberLength(
            e.target.value,
            length_validate[e.target.name]
          ),
        });
      }

      {
        setDirectDeposit({
          ...directDeposit,
          [e.target.name]: filterNumberLength(
            e.target.value,
            length_validate[e.target.name]
          ),
        });
      }
    }

    if (["first_name", "last_name", "firm_name"].includes(e.target.name)) {
      setTypingTimeout(
        setTimeout(async () => validateFormFields(e, form_type, index), 300)
      );
    } else {
      setTypingTimeout(
        setTimeout(async () => validateFormFields(e, form_type, index), 300)
      );
    }
  };
  const checkEmptyFields = (index) => {
    return Object.values(fields[index]).every((value) => !value);
  };
  const validateFormFields = async (e, form_type, index = -1) => {
    if (form_type == "firm") {
      if (e.target.name == "firm_name") {
        if (e.target.value.length > 0) {
          setErrorFlag((prevErrorFlag) => ({
            ...prevErrorFlag,
            firm: {
              ...prevErrorFlag[form_type],
              ["firm_name"]: "",
            },
          }));
          const res = await dispatch(
            postFirmNamePost({ firm_name: e.target.value }, navigate)
          );
          if (res.payload) {
            toast.success("Firm Code Generated", { id: 1 });
            setFirmDetail((prev) => ({
              ...prev,
              firm_code: res.payload.firm_code,
            }));
          }
          if (res.error) {
          }
        } else {
          setFirmDetail((prev) => ({
            ...prev,
            firm_code: "",
          }));
        }
        return;
      } else if (e.target.name == "email") {
        setErrorFlag((prevErrorFlag) => ({
          ...prevErrorFlag,
          firm: {
            ...prevErrorFlag[form_type],
            [e.target.name]: !validateEmail(e.target.value)
              ? errMsg[e.target.name]
              : "",
          },
        }));
      } else if (e.target.name == "firm_url") {
        setErrorFlag((prevErrorFlag) => ({
          ...prevErrorFlag,
          firm: {
            ...prevErrorFlag[form_type],
            [e.target.name]: !validateFirmUrl(e.target.value)
              ? errMsg[e.target.name]
              : "",
          },
        }));
      } else if (e.target.name == "zip_code") {
        setErrorFlag((prevErrorFlag) => ({
          ...prevErrorFlag,
          firm: {
            ...prevErrorFlag[form_type],
            [e.target.name]:
              e.target.value.length != 5 ? errMsg[e.target.name] : "",
          },
        }));
      } else if (
        e.target.name == "cell_phone_number" ||
        e.target.name == "secondary_cell_phone_number"
      ) {
        if (e.target.value.length !== 0) {
          setErrorFlag((prevErrorFlag) => ({
            ...prevErrorFlag,
            firm: {
              ...prevErrorFlag[form_type],
              [e.target.name]:
                formatcell_phone_number(e.target.value).length < 12
                  ? errMsg[e.target.name]
                  : "",
            },
          }));
        }
      } else {
        setErrorFlag((prevErrorFlag) => ({
          ...prevErrorFlag,
          firm: {
            ...prevErrorFlag[form_type],
            [e.target.name]: "",
          },
        }));
      }
      if (e.target.name == "cell_phone_number" || e.target.name == "email") {
        fields.map((data, idx) => {
          if (data[e.target.name] == e.target.value) {
            if (e.target.value.length !== 0) {
              setErrorFlag((prevErrorFlag) => ({
                ...prevErrorFlag,
                firm: {
                  ...prevErrorFlag[form_type],
                  [e.target.name]: errMsg[`${e.target.name}_duplicate`],
                },
              }));
            }
          }
        });
      }
      if (e.target.name == "secondary_cell_phone_number") {
        fields.map((data, idx) => {
          if (data.cell_phone_number == e.target.value) {
            if (e.target.value.length !== 0) {
              setErrorFlag((prevErrorFlag) => ({
                ...prevErrorFlag,
                firm: {
                  ...prevErrorFlag[form_type],
                  [e.target.name]: errMsg[`cell_phone_number_duplicate`],
                },
              }));
            }
          }
        });
      }
    } else if (form_type == "primary_attorney") {
      if (["first_name", "last_name"].includes(e.target.name)) {
        setErrorFlag((prevErrorFlag) => ({
          ...prevErrorFlag,
          primary_attorney: {
            ...prevErrorFlag[form_type],
            [e.target.name]:
              e.target.value.length > 30 ? errMsg[e.target.name] : "",
          },
        }));

        if (e.target.name == "first_name") {
          if (
            firmDetail.firm_code &&
            e.target.value &&
            primaryAttorneyDetail.last_name
          ) {
            if (e.target.value.length > 0 && e.target.value.length <= 30) {
              const res = await dispatch(
                postFNLNamePost(
                  {
                    firm_code: firmDetail.firm_code,
                    first_name: e.target.value,
                    last_name: primaryAttorneyDetail.last_name,
                  },
                  navigate
                )
              );
              if (res.payload) {
                toast.success("Attorney Code Generated", { id: 1 });
                setPrimaryAttorneyDetail((prev) => ({
                  ...prev,
                  attorney_code: res.payload.attorney_code,
                }));
              }
              if (res.error) {
              }
            }
          } else {
            setPrimaryAttorneyDetail((prev) => ({
              ...prev,
              attorney_code: "",
            }));
          }
        } else {
          if (
            firmDetail.firm_code &&
            e.target.value &&
            primaryAttorneyDetail.first_name
          ) {
            if (e.target.value.length > 0 && e.target.value.length <= 30) {
              const res = await dispatch(
                postFNLNamePost(
                  {
                    firm_code: firmDetail.firm_code,
                    first_name: primaryAttorneyDetail.first_name,
                    last_name: e.target.value,
                  },
                  navigate
                )
              );
              if (res.payload) {
                toast.success("Attorney Code Generated", { id: 1 });
                setPrimaryAttorneyDetail((prev) => ({
                  ...prev,
                  attorney_code: res.payload.attorney_code,
                }));
              }
              if (res.error) {
              }
            }
          } else {
            setPrimaryAttorneyDetail((prev) => ({
              ...prev,
              attorney_code: "",
            }));
          }
        }
      } else if (e.target.name == "cell_phone_number") {
        if (e.target.value.length !== 0) {
          setErrorFlag((prevErrorFlag) => ({
            ...prevErrorFlag,
            primary_attorney: {
              ...prevErrorFlag[form_type],
              [e.target.name]:
                formatcell_phone_number(e.target.value).length < 12
                  ? errMsg[e.target.name]
                  : "",
            },
          }));
        }
      } else if (e.target.name == "email") {
        if (e.target.value.length !== 0) {
          setErrorFlag((prevErrorFlag) => ({
            ...prevErrorFlag,
            primary_attorney: {
              ...prevErrorFlag[form_type],
              [e.target.name]: !validateEmail(e.target.value)
                ? errMsg[e.target.name]
                : "",
            },
          }));
        }
      }
      if (e.target.name == "cell_phone_number" || e.target.name == "email") {
        fields.map((data, idx) => {
          if (data[e.target.name] == e.target.value) {
            if (e.target.value.length !== 0) {
              setErrorFlag((prevErrorFlag) => ({
                ...prevErrorFlag,
                primary_attorney: {
                  ...prevErrorFlag[form_type],
                  [e.target.name]: errMsg[`${e.target.name}_duplicate`],
                },
              }));
            }
          }
        });
      }
    } else if (form_type == "attorney") {
      if (e.target.name == "cell_phone_number") {
        setErrorFlag((prevState) => {
          const updatedAttorneyArray = [...prevState[form_type]];

          updatedAttorneyArray[index] = {
            ...updatedAttorneyArray[index],
            [e.target.name]: e.target.value
              ? formatcell_phone_number(e.target.value).length != 12
                ? errMsg[e.target.name]
                : ""
              : "",
          };
          return {
            ...prevState,
            [form_type]: updatedAttorneyArray,
          };
        });
      } else if (["first_name", "last_name"].includes(e.target.name)) {
        if (
          firmDetail.firm_code &&
          fields[index].first_name &&
          fields[index].last_name
        ) {
          if (e.target.value.length > 0 && e.target.value.length <= 30) {
            localStorage.setItem("activeAttorneysCodeLoader", index);
            const res = await dispatch(
              postMultipleFNLNamePost(
                {
                  firm_code: firmDetail.firm_code,
                  first_name: fields[index].first_name,
                  last_name: fields[index].last_name,
                },
                navigate
              )
            );
            if (res.payload) {
              toast.success("Attorney Code Generated", { id: 1 });

              const updatedFields = [...fields];
              updatedFields[index] = {
                ...updatedFields[index],
                attorney_code: res.payload.attorney_code,
              };

              setFields((prevFields) => {
                const updatedFields = [...prevFields];
                updatedFields[index] = {
                  ...updatedFields[index],
                  attorney_code: res.payload.attorney_code,
                };
                return updatedFields;
              });
            }
            if (res.error) {
            }
          }
        }

        setErrorFlag((prevState) => {
          const updatedAttorneyArray = [...prevState[form_type]];

          updatedAttorneyArray[index] = {
            ...updatedAttorneyArray[index],
            [e.target.name]:
              e.target.value.length > 30 ? errMsg[e.target.name] : "",
          };
          return {
            ...prevState,
            [form_type]: updatedAttorneyArray,
          };
        });
      } else {
        setErrorFlag((prevState) => {
          const updatedAttorneyArray = [...prevState[form_type]];

          updatedAttorneyArray[index] = {
            ...updatedAttorneyArray[index],
            [e.target.name]: e.target.value
              ? !validateEmail(e.target.value)
                ? errMsg[e.target.name]
                : ""
              : "",
          };

          return {
            ...prevState,
            [form_type]: updatedAttorneyArray,
          };
        });
      }

      if (e.target.name == "cell_phone_number" || e.target.name == "email") {
        if (
          firmDetail[e.target.name] == e.target.value ||
          primaryAttorneyDetail[e.target.name] == e.target.value ||
          firmDetail.secondary_cell_phone_number == e.target.value
        ) {
          setErrorFlag((prevState) => {
            const updatedAttorneyArray = [...prevState[form_type]];

            updatedAttorneyArray[index] = {
              ...updatedAttorneyArray[index],
              [e.target.name]: errMsg[`${e.target.name}_duplicate`],
            };
            return {
              ...prevState,
              [form_type]: updatedAttorneyArray,
            };
          });
        }
        fields.map((data, idx) => {
          if (idx !== index) {
            if (data[e.target.name] == e.target.value) {
              setErrorFlag((prevState) => {
                const updatedAttorneyArray = [...prevState[form_type]];

                updatedAttorneyArray[index] = {
                  ...updatedAttorneyArray[index],
                  [e.target.name]: errMsg[`${e.target.name}_duplicate`],
                };
                return {
                  ...prevState,
                  [form_type]: updatedAttorneyArray,
                };
              });
            }
          }
        });
      }
    } else if (form_type == "contract") {
      if (
        e.target.name == "escrow_percentage" ||
        e.target.name == "na_fee_percentage"
      ) {
        setErrorFlag((prevErrorFlag) => ({
          ...prevErrorFlag,
          contract: {
            ...prevErrorFlag[form_type],
            [e.target.name]:
              parseFloat(e.target.value) > 99.99 ? errMsg[e.target.name] : "",
          },
        }));
      } else if (e.target.name == "escrow_limit") {
        setErrorFlag((prevErrorFlag) => ({
          ...prevErrorFlag,
          contract: {
            ...prevErrorFlag[form_type],
            [e.target.name]:
              parseFloat(e.target.value) < 5000 ? errMsg[e.target.name] : "",
          },
        }));
      }
    } else if (form_type == "direct_deposit") {
      if (e.target.name == "bank_routing_number") {
        setErrorFlag((prevErrorFlag) => ({
          ...prevErrorFlag,
          direct_deposit: {
            ...prevErrorFlag[form_type],
            [e.target.name]:
              e.target.value.length != 9 ? errMsg[e.target.name] : "",
          },
        }));
      } else if (e.target.name == "bank_account_number") {
        setErrorFlag((prevErrorFlag) => ({
          ...prevErrorFlag,
          direct_deposit: {
            ...prevErrorFlag[form_type],
            [e.target.name]:
              e.target.value.length > 17 || e.target.value.length < 12
                ? errMsg[e.target.name]
                : "",
          },
        }));
      }
    } else {
      return null;
    }
  };

  const [openEditAttorneyModal, setEditAttorneyModal] = useState(false);

  const totalSteps = 4;
  const steps = [
    {
      label: "FIRM",
      step: 1,
      done: true,
    },
    {
      label: "ATTORNEYS",
      step: 2,
      done: true,
    },
    {
      label: "CONTRACT",
      step: 3,
      done: true,
    },
    {
      label: "PAYMENT",
      step: 4,
      done: true,
    },
  ];

  const [activeStep, setActiveStep] = useState(1);
  const nextStep = () => {
    if (pageSection.FIRM) {
      setPageSection({
        ...pageSection,
        FIRM: false,
        ATTORNEYS: true,
      });
      setActiveStep(activeStep + 1);
    }
    if (pageSection.ATTORNEYS) {
      setPageSection({
        ...pageSection,
        ATTORNEYS: false,
        CONTRACT: true,
      });
      setActiveStep(activeStep + 1);
    }
    if (pageSection.CONTRACT) {
      setPageSection({
        ...pageSection,
        CONTRACT: false,
        PAYMENT: true,
      });
      setActiveStep(activeStep + 1);
    }
    if (pageSection.PAYMENT) {
      setPageSection({
        ...pageSection,
        FIRM: false,
      });
      setActiveStep(activeStep + 1);
    }
  };

  const prevStep = () => {
    if (pageSection.FIRM) {
      setPageSection({
        ...pageSection,
        FIRM: false,
      });
      setActiveStep(activeStep - 1);
    }

    if (pageSection.ATTORNEYS) {
      setPageSection({
        ...pageSection,
        ATTORNEYS: false,
        FIRM: true,
      });
      setActiveStep(activeStep - 1);
    }
    if (pageSection.CONTRACT) {
      setPageSection({
        ...pageSection,
        CONTRACT: false,
        ATTORNEYS: true,
      });
      setActiveStep(activeStep - 1);
    }
    if (pageSection.PAYMENT) {
      setPageSection({
        ...pageSection,
        PAYMENT: false,
        CONTRACT: true,
      });
      setActiveStep(activeStep - 1);
    }
  };

  const [editAttorneyIndexFlag, setEditAttorneyIndexFlag] = useState(-1);
  const handleAttorneyDetailChange = (e, index) => {
    const { name, value } = e.target;
    const updatedFields = [...fields];
    updatedFields[index][name] = value;
    setFields(updatedFields);
  };

  const filterNonNumbers = (value) => {
    return value.replace(/[^0-9]/g, "");
  };
  const filterNumberLength = (value, max) => {
    const numericValue = value.replace(/[^0-9]/g, "");

    if (numericValue.length > max) {
      return numericValue.slice(0, max);
    }
    return numericValue;
  };
  const filterCardExpiry = (value) => {
    value = value.replace("/", "");

    value = `${value.slice(0, 2)}/${value.slice(2, 4)}`;

    return value;
  };

  let previousValidValue = "";

  const filterPositiveDecimal = (value) => {
    const numericValue = value.replace(/[^0-9.]/g, "");

    const validPositiveDecimalRegex = /^\d*\.?\d*$/;

    if (validPositiveDecimalRegex.test(numericValue)) {
      const parsedValue = parseFloat(numericValue);

      if (!isNaN(parsedValue) && isFinite(parsedValue) && parsedValue >= 0) {
        previousValidValue = numericValue;

        return parsedValue.toString();
      }
    }

    return previousValidValue;
  };

  const handleAddField = () => {
    setFields([...fields, initialField]);
    setErrorFlag((prevState) => {
      const updatedAttorneyArray = [...prevState["attorney"]];
      updatedAttorneyArray.push({
        first_name: "",
        last_name: "",
        email: "",
        cell_phone_number: "",
        attorney_code: "",
      });

      return {
        ...prevState,
        ["attorney"]: updatedAttorneyArray,
      };
    });
    return;
  };

  const validateFields = () => {
    const isValid = fields.every((field) => {
      return (
        field.first_name.trim() !== "" &&
        field.last_name.trim() !== "" &&
        field.email.trim() !== "" &&
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(field.email) &&
        field.cell_phone_number.trim() !== ""
      );
    });
    return isValid;
  };

  const allAttorneyValidated = () => {
    return fields.every((field) => {
      return field.is_valid;
    });
  };

  function isFirmDataValid(errorFlag) {
    const firmSelf = errorFlag.firm;

    const isFirmNameValid =
      typeof firmSelf.first_name === "string" &&
      firmSelf.first_name.trim() !== "";
    const isAddressLine1Valid =
      typeof firmSelf.address_line1 === "string" &&
      firmSelf.address_line1.trim() !== "";
    const isAddressLine2Valid =
      typeof firmSelf.address_line2 === "string" &&
      firmSelf.address_line2.trim() !== "";

    return isFirmNameValid && isAddressLine1Valid && isAddressLine2Valid;
  }

  const [softErrorMsg, setSoftErrorMsg] = useState("");
  const [hardErrorMsg, setHardErrorMsg] = useState("");

  const isButtonFirmSecDisabled = () => {
    const requiredFields = [
      firmDetail.firm_name,
      firmDetail.email,
      firmDetail.state,
      firmDetail.city,
      firmDetail.cell_phone_number,
      // firmDetail.firm_url,
      // firmDetail.logo_url,

      firmDetail.zip_code,
      firmDetail.address_line1,

      primaryAttorneyDetail.first_name,
      primaryAttorneyDetail.last_name,
      primaryAttorneyDetail.email,
      primaryAttorneyDetail.cell_phone_number,
      primaryAttorneyDetail.attorney_code,
    ];
    for (const key in errorFlag.firm) {
      if (firmDetail[key] == "") {
        if (
          key == "secondary_cell_phone_number" ||
          key == "address_line2" ||
          key == "firm_url" ||
          key == "logo_url"
        ) {
          continue;
        }

        setErrorFlag((prevErrorFlag) => ({
          ...prevErrorFlag,
          firm: {
            ...prevErrorFlag.firm,
            [key]: `*${key
              .replace("secondary_cell_phone_number", "secondary phone")
              .replace("cell_phone_number", "primary phone")
              .replace(/_/g, " ")
              .replace(/\b\w/g, (match) => match.toUpperCase())} is required`,
          },
        }));
      }
    }
    for (const key in errorFlag.primary_attorney) {
      if (primaryAttorneyDetail[key] == "") {
        setErrorFlag((prevErrorFlag) => ({
          ...prevErrorFlag,
          primary_attorney: {
            ...prevErrorFlag.primary_attorney,
            [key]: `*${key
              .replace(/_/g, " ")
              .replace(/\b\w/g, (match) => match.toUpperCase())} is required`,
          },
        }));
      }
    }

    return requiredFields.some((field) => field === "");
  };

  const checkFirmSecError = () => {
    let requiredCheckFields = [
      errorFlag.firm.email,
      errorFlag.firm.cell_phone_number,
      errorFlag.firm.secondary_cell_phone_number,
      // errorFlag.firm.firm_url,
      // errorFlag.firm.logo_url,
      errorFlag.primary_attorney.email,
      errorFlag.primary_attorney.cell_phone_number,
    ];

    for (let requiredField of requiredCheckFields) {
      if (requiredField && requiredField != "") {
        return false;
      }
    }
    return true;
  };

  const submitFirmSection = async () => {
    if (!checkFirmSecError()) {
      toast.error("Complete the fields", { id: 1 });
      return false;
    }

    if (firmDetail) {
      const checkField = isButtonFirmSecDisabled();
      if (checkField == true) {
        toast.error("Complete the fields", { id: 1 });
        return false;
      }
    }
    let submitFirmData = firmDetail;
    let submitPrimaryData = primaryAttorneyDetail;
    submitFirmData.cell_phone_number = formatcell_phone_number(
      submitFirmData.cell_phone_number
    );
    submitFirmData.secondary_cell_phone_number = formatcell_phone_number(
      submitFirmData.secondary_cell_phone_number
    );
    submitPrimaryData.cell_phone_number = formatcell_phone_number(
      submitPrimaryData.cell_phone_number
    );
    const res = await dispatch(
      addFirmPost(
        {
          override: overRideFlag,
          skip: skipFlag,
          firm: submitFirmData,
          primary_attorney: submitPrimaryData,
          form_type: "firm",
        },
        navigate
      )
    );
    if (res.payload) {
      const updatedAttorneys = res.payload.attorney.map((attorneyData) => ({
        first_name: "",
        last_name: "",
        email: "",
        cell_phone_number: "",
      }));

      updatedAttorneys.push({
        first_name: "",
        last_name: "",
        email: "",
        cell_phone_number: "",
      });
      setErrorFlag({
        ...errorFlag,
        firm: {
          firm_name: "",
          email: "",
          address_line1: "",
          address_line2: "",
          city: "",
          state: "",
          zip_code: "",
          cell_phone_number: "",
          secondary_cell_phone_number: "",
          firm_code: "",
        },
        primary_attorney: {
          first_name: "",
          last_name: "",
          email: "",
          cell_phone_number: "",
          attorney_code: "",
        },
        attorney: updatedAttorneys,
        contract: {
          na_fee_percentage: "",
          escrow_limit: "",
          escrow_percentage: "",
        },
        direct_deposit: {
          bank_routing_number: "",
          bank_account_number: "",
        },
      });

      nextStep();
      setSkipFlag(false);
    }
    if (res.error) {
      if (res.error.message == '"Something went wrong."') {
        toast.error("Something went wrong", { id: 1 });
      } else {
        toast.error("Error", { id: 1 });
      }
      let err = JSON.parse(res.error.message);

      if (res.error.message)
        if (err.firm) {
          if (err.firm.firm_name && err.firm.address_line1) {
            setSoftErrorMsg("Firm Name and Address line1 Already Exist");
            setOpenSoftErrorModal(true);
          }
          if (err.firm.firm_name && err.firm.address_line2) {
            setSoftErrorMsg("Firm Name and Address line2 Already Exist");
            setOpenSoftErrorModal(true);
          }
          if (
            err.firm.firm_name &&
            err.firm.cell_phone_number &&
            err.firm.secondary_cell_phone_number
          ) {
            setHardErrorMsg("Firm Name and Primary Phones Already Exist");
            gotoPageRoute(
              formatcell_phone_number(firmDetail.cell_phone_number)
            );
          } else if (err.firm.firm_name && err.firm.cell_phone_number) {
            setHardErrorMsg("Firm Name and Primary Number Already Exist");
            gotoPageRoute(
              formatcell_phone_number(firmDetail.cell_phone_number)
            );
          } else if (
            err.firm.firm_name &&
            err.firm.secondary_cell_phone_number
          ) {
            setHardErrorMsg("Firm Name and Secondary Number Already Exist");
            gotoPageRoute(
              formatcell_phone_number(firmDetail.secondary_cell_phone_number)
            );
          }
        }

      setErrorFlag(JSON.parse(res.error.message));
    }
  };
  const gotoPageRoute = async (num) => {
    try {
      const res = await axios.get(`${domainUrl}firm?phone=${num}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      });
      if (res.data) {
        setHardErrorData({
          ...hardErrorData,

          firm_id: parseInt(res.data.id),
          firm_code: res.data.firm_code,
          firm_name: res.data.firm_name,
        });

        localStorage.setItem("firm_id", parseInt(res.data.id));

        setOpenHardErrorModal(true);
      }
    } catch (error) {
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
    }
  };

  const checkAttorneyError = (index) => {
    let keys = Object.keys(errorFlag.attorney[index]);

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      if (errorFlag.attorney[index][key] !== "") {
        return false;
      }
    }

    return true;
  };

  const submitAttorneySection = async (index, from = "", type) => {
    if (index !== "") {
      if (!checkAttorneyError(index)) {
        return false;
      }
    } else {
      if (!checkAttorneyError(fields.length - 1)) {
        return false;
      }
    }
    if (from === "next") {
      const isButtonAttorneySecDisabled = () => {
        const requiredFields = [
          fields[fields.length - 1].first_name &&
            fields[fields.length - 1].last_name &&
            fields[fields.length - 1].email &&
            fields[fields.length - 1].cell_phone_number,
        ];

        return requiredFields.some((field) => field === "");
      };

      const allFieldsEmpty = Object.values(fields[fields.length - 1]).every(
        (value) => value === ""
      );
      const allFieldsNotEmpty = Object.values(fields[fields.length - 1]).every(
        (value) => value && value.trim() !== ""
      );
      if (allFieldsNotEmpty) {
        let submitNextAttorney = fields[fields.length - 1];
        submitNextAttorney.cell_phone_number = formatcell_phone_number(
          fields[fields.length - 1].cell_phone_number
        );
        setSkipFlag(false);
        const res = await dispatch(
          addFirmPost(
            {
              attorney: submitNextAttorney,
              form_type: "attorney",
              index: fields.length - 1,
            },
            navigate
          )
        );
        if (res.payload) {
          handleAddField();
          nextStep();
        }
        if (res.error) {
          const error = JSON.parse(res.error.message);
          toast.error("Error occured", { id: 1 });

          let updatedAttorney = {
            first_name: "",
            last_name: "",
            email: "",
            cell_phone_number: "",
          };
          updatedAttorney = { ...updatedAttorney, ...error.attorney };

          setErrorFlag((prevState) => {
            const updatedAttorneys = [...prevState.attorney];
            updatedAttorneys[fields.length - 1] = updatedAttorney;

            return {
              ...prevState,
              attorney: updatedAttorneys,
            };
          });
        }
        return;
      }
      if (allFieldsEmpty) {
        setStepperStatus(false);
        nextStep();
        return false;
      }
      toast.error("Complete the previous form", { id: 1 });
      return;
    } else if (from === "add-next") {
      const isButtonAttorneySecDisabled = () => {
        const requiredFields = [
          fields[index].first_name &&
            fields[index].last_name &&
            fields[index].email &&
            fields[index].cell_phone_number,
        ];

        return requiredFields.some((field) => field === "");
      };
      const allFieldsEmpty = Object.values(fields[index]).every(
        (value) => value === ""
      );
      const allFieldsNotEmpty = Object.values(fields[index]).every(
        (value) => value && value.trim() !== ""
      );

      if (allFieldsNotEmpty) {
        setSkipFlag(false);
        let addMultiAttorney = fields[index];
        addMultiAttorney.cell_phone_number = formatcell_phone_number(
          addMultiAttorney.cell_phone_number
        );
        const res = await dispatch(
          addFirmPost(
            {
              attorney: addMultiAttorney,
              form_type: "attorney",
              index: index,
            },
            navigate
          )
        );
        if (res.payload) {
          handleAddField();
        }
        if (res.error) {
          const error = JSON.parse(res.error.message);
          toast.error("Error occured", { id: 1 });

          let updatedAttorney = {
            first_name: "",
            last_name: "",
            email: "",
            cell_phone_number: "",
          };
          updatedAttorney = { ...updatedAttorney, ...error.attorney };

          setErrorFlag((prevState) => {
            const updatedAttorneys = [...prevState.attorney];
            updatedAttorneys[index] = updatedAttorney;

            return {
              ...prevState,
              attorney: updatedAttorneys,
            };
          });
        }

        return;
      }
      if (allFieldsEmpty) {
        toast.error("Fill the previous form", { id: 1 });
        return;
      }

      toast.error("Complete the previous form", { id: 1 });
      return;
    } else if (type == "attorney") {
      setSkipFlag(false);

      const res = await dispatch(
        deleteSingleMultipleAttorney(
          { type: "attorney", index: index },
          navigate
        )
      );
      if (res.payload) {
        toast.success("Removed", { id: 1 });

        let trimval = [...fields];
        trimval.splice(index, 1);
        setFields(trimval);
      }
      if (res.error) {
        const error = JSON.parse(res.error.message);
        toast.error("Error occured", { id: 1 });

        let updatedAttorney = {
          first_name: "",
          last_name: "",
          email: "",
          cell_phone_number: "",
        };
        updatedAttorney = { ...updatedAttorney, ...error.attorney };

        setErrorFlag((prevState) => {
          const updatedAttorneys = [...prevState.attorney];
          updatedAttorneys[index] = updatedAttorney;

          return {
            ...prevState,
            attorney: updatedAttorneys,
          };
        });
      }
    } else {
      setSkipFlag(false);

      let submitUpdateAttorney = fields[index];
      submitUpdateAttorney.cell_phone_number = formatcell_phone_number(
        submitUpdateAttorney.cell_phone_number
      );
      const res = await dispatch(
        addFirmPost(
          {
            attorney: submitUpdateAttorney,
            form_type: "attorney",
            index: index,
            update: true,
          },
          navigate
        )
      );
      if (res.payload) {
        setEditAttorneyIndexFlag(-1);
      }
      if (res.error) {
        const error = JSON.parse(res.error.message);
        toast.error("Error occured", { id: 1 });

        let updatedAttorney = {
          first_name: "",
          last_name: "",
          email: "",
          cell_phone_number: "",
        };
        updatedAttorney = { ...updatedAttorney, ...error.attorney };

        setErrorFlag((prevState) => {
          const updatedAttorneys = [...prevState.attorney];
          updatedAttorneys[index] = updatedAttorney;

          return {
            ...prevState,
            attorney: updatedAttorneys,
          };
        });
      }
    }
  };

  const isButtonContractSecDisabled = () => {
    const requiredFields = [
      contract.na_fee_percentage,
      contract.escrow_limit,
      contract.escrow_percentage,
    ];

    return requiredFields.some((field) => field === "");
  };

  const submitContractSection = async (e) => {
    if (contract) {
      const checkField = await isButtonContractSecDisabled();
      if (checkField === true) {
        toast.error("Complete the fields", { id: 1 });
        return false;
      }
    }
    setSkipFlag(false);

    let submitData = contract;
    submitData.escrow_limit = parseFloat(
      String(submitData.escrow_limit).replace(/[$,]/g, "")
    );
    const res = await dispatch(
      addFirmPost(
        {
          contract: submitData,
          form_type: "contract",
        },
        navigate
      )
    );
    if (res.payload) {
      nextStep();
    }
    if (res.error) {
      setErrorFlag(JSON.parse(res.error.message));
    }
  };

  const isButtonPaymentSecDisabled = () => {
    const requiredFields = [
      directDeposit.bank_account_number,
      directDeposit.bank_routing_number,
    ];

    return (
      requiredFields.some((field) => field === "") ||
      errorFlag.direct_deposit.bank_account_number !== "" ||
      errorFlag.direct_deposit.bank_routing_number !== ""
    );
  };
  const [submitLoader, setSubmitLoader] = useState(false);
  const submitPaymentSection = async () => {
    setSkipFlag(false);

    if (directDeposit) {
      const checkField = await isButtonPaymentSecDisabled();
      if (checkField === true) {
        toast.error("Complete the fields", { id: 1 });
        return false;
      }
    }
    setSubmitLoader(true);
    const res = await dispatch(
      addFirmPost(
        {
          direct_deposit: directDeposit,
          form_type: "direct_deposit",
        },
        navigate
      )
    );
    if (res.payload) {
      setSubmitLoader(false);

      navigate("/list-firm");
      toast.success("Created Firm Successfully", { id: 1 });
    }
    if (res.error) {
      setSubmitLoader(false);

      if (typeof JSON.parse(res.error.message) == "string") {
        toast.error(JSON.parse(res.error.message), { id: 1 });
      } else {
        setErrorFlag(JSON.parse(res.error.message));
        toast.error("Error Occured", { id: 1 });
      }
    }
  };

  return (
    <div className="pb-40">
      {getAddFirmDraftLoader ? (
        <div>
          <div className="w-full h-screen flex justify-center items-center">
            <Oval
              height={80}
              width={80}
              color="#1a3253"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#396181"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        </div>
      ) : (
        <main className="my-4 border rounded-lg shadow-md">
          <div className="flex justify-end px-5">
            <StepperComponent
              nextStep={nextStep}
              prevStep={prevStep}
              activeStep={activeStep}
              steps={steps}
              done={stepperStatus}
            />
          </div>

          {pageSection.FIRM && (
            <>
              <div className="mx-5 my-2 ml-5">
                <div className="">
                  <div className="text-xl font-bold">ADD FIRM</div>
                  <div className="text-sm text-[#9ca0b3]">Firm Details</div>
                </div>

                <form>
                  <div className="mt-5">
                    <div className="space-y-12  lg:p-5">
                      <div className="border-b border-gray-900/10 pb-12">
                        {firmDetail.firm_code == "" && (
                          <div className="font-bold">
                            Firm Unique Code: <span className="ml-2">--</span>
                          </div>
                        )}
                        {firmDetail.firm_code !== "" && (
                          <>
                            <div className="flex gap-2">
                              <div className="font-bold">Firm Unique Code:</div>
                              <div>
                                {!firmCodeLoader ? (
                                  <>{firmDetail.firm_code}</>
                                ) : (
                                  <span>
                                    <TailSpin
                                      height="20"
                                      width="20"
                                      color="black"
                                      ariaLabel="tail-spin-loading"
                                      radius="1"
                                      wrapperStyle={{}}
                                      wrapperClass=""
                                      visible={true}
                                    />
                                  </span>
                                )}
                              </div>
                            </div>
                          </>
                        )}

                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                          <div className="col-span-full">
                            <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                              Firm Name
                            </label>
                            <div className="mt-2">
                              <input
                                name="firm_name"
                                value={firmDetail.firm_name}
                                onChange={(e) => formHandler(e, "firm")}
                                className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                              />
                              <span className="text-red-500 text-xs">
                                {errorFlag.firm &&
                                  errorFlag.firm &&
                                  errorFlag.firm.firm_name}
                              </span>
                            </div>
                          </div>

                          <div className="sm:col-span-full">
                            <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                              Email address
                            </label>
                            <div className="mt-2">
                              <input
                                name="email"
                                onBlur={() => {
                                  setFirmDetailFlag((prev) => ({
                                    ...prev,
                                    email: false,
                                  }));
                                }}
                                value={firmDetail.email}
                                onChange={(e) => {
                                  formHandler(e, "firm");
                                }}
                                className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                              />
                              <span className="text-red-500 text-xs">
                                {errorFlag.firm && errorFlag.firm.email}
                              </span>
                            </div>
                          </div>

                          <div className="col-span-full">
                            <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                              Website URL
                            </label>
                            <div className="mt-2">
                              <input
                                name="firm_url"
                                type="url"
                                value={firmDetail.firm_url}
                                onChange={(e) => formHandler(e, "firm")}
                                className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                              />
                              <span className="text-red-500 text-xs">
                                {errorFlag.firm && errorFlag.firm.firm_url}{" "}
                              </span>
                            </div>
                          </div>

                          <div className="col-span-full">
                            <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                              Address 1
                            </label>
                            <div className="mt-2">
                              <input
                                name="address_line1"
                                value={firmDetail.address_line1}
                                onChange={(e) => formHandler(e, "firm")}
                                className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                              />
                              <span className="text-red-500 text-xs">
                                {errorFlag.firm && errorFlag.firm.address_line1}
                              </span>
                            </div>
                          </div>
                          <div className="col-span-full">
                            <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                              Address 2
                            </label>
                            <div className="mt-2">
                              <input
                                name="address_line2"
                                value={firmDetail.address_line2}
                                onChange={(e) => formHandler(e, "firm")}
                                className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                              />
                              <span className="text-red-500 text-xs">
                                {errorFlag.firm && errorFlag.firm.address_line2}
                              </span>
                            </div>
                          </div>

                          <div className="sm:col-span-2 ">
                            <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                              City
                            </label>
                            <div className="mt-2">
                              <input
                                name="city"
                                value={firmDetail.city}
                                onChange={(e) => formHandler(e, "firm")}
                                className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                              />
                            </div>
                            <span className="text-red-500 text-xs">
                              {errorFlag.firm && errorFlag.firm.city}
                            </span>
                          </div>

                          <div className="sm:col-span-2">
                            <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                              State
                            </label>

                            <div className="mt-1 w-full h-full flex items-center">
                              <div className="grow h-full">
                                <Combobox
                                  value={selectedState}
                                  onChange={(value) => {
                                    setSelectedState(value);

                                    setErrorFlag((prevErrorFlag) => ({
                                      ...prevErrorFlag,
                                      firm: {
                                        ...prevErrorFlag["firm"],
                                        ["state"]: "",
                                      },
                                    }));

                                    setFirmDetail({
                                      ...firmDetail,
                                      state: value.code,
                                    });
                                  }}
                                >
                                  <div className="relative">
                                    <div className="relative w-full h-10 cursor-default overflow-hidden rounded-lg bg-white text-left  focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                                      <Combobox.Input
                                        className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                                        displayValue={(listedStates) =>
                                          listedStates.code
                                        }
                                        onChange={(event) => {
                                          setQuery(event.target.value);
                                        }}
                                        placeholder=""
                                      />
                                    </div>
                                    <span className="text-red-500 text-xs">
                                      {errorFlag.firm && errorFlag.firm.state}
                                    </span>
                                    <Transition
                                      as={Fragment}
                                      leave="transition ease-in duration-100"
                                      leaveFrom="opacity-100"
                                      leaveTo="opacity-0"
                                      afterLeave={() => setQuery("")}
                                    >
                                      <Combobox.Options className="absolute  max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                        {filteredstates.length === 0 &&
                                        query !== "" ? (
                                          <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                            No match found.
                                          </div>
                                        ) : (
                                          filteredstates.map((listedStates) => (
                                            <Combobox.Option
                                              key={listedStates.id}
                                              className={({ active }) =>
                                                `relative z-10 cursor-default select-none py-2 pl-10 pr-4  ${
                                                  active
                                                    ? "bg-[#376080] text-white"
                                                    : "bg-white text-gray-900"
                                                }`
                                              }
                                              value={listedStates}
                                              disabled={
                                                listedStates.unavailable
                                              }
                                            >
                                              {({ selected, active }) => (
                                                <>
                                                  <span
                                                    className={`block truncate ${
                                                      selected
                                                        ? "font-medium"
                                                        : "font-normal"
                                                    }`}
                                                  >
                                                    {listedStates.code}
                                                  </span>
                                                  {selected ? (
                                                    <span
                                                      className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                                        active
                                                          ? "text-white"
                                                          : "text-[#376080]"
                                                      }`}
                                                    >
                                                      <CheckIcon
                                                        className="h-5 w-5"
                                                        aria-hidden="true"
                                                      />
                                                    </span>
                                                  ) : null}
                                                </>
                                              )}
                                            </Combobox.Option>
                                          ))
                                        )}
                                      </Combobox.Options>
                                    </Transition>
                                  </div>
                                </Combobox>
                              </div>
                            </div>
                          </div>

                          <div className="sm:col-span-2">
                            <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                              Zip code
                            </label>
                            <div className="mt-2">
                              <input
                                name="zip_code"
                                value={firmDetail.zip_code}
                                onChange={(e) => formHandler(e, "firm")}
                                className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                              />
                            </div>

                            <span className="text-red-500 text-xs">
                              {errorFlag.firm && errorFlag.firm.zip_code}
                            </span>
                          </div>

                          <div className="sm:col-span-2">
                            <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                              Primary Phone
                            </label>
                            <div className="mt-2">
                              <InputMask
                                name="cell_phone_number"
                                value={firmDetail.cell_phone_number}
                                onChange={(e) => formHandler(e, "firm")}
                                className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                                mask="+1 (___) ___-____"
                                replacement={{ _: /\d/ }}
                              />
                              <span className="text-red-500 text-xs">
                                {errorFlag.firm &&
                                  errorFlag.firm.cell_phone_number}
                              </span>
                            </div>
                          </div>
                          <div className="sm:col-span-2">
                            <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                              Secondary Phone
                            </label>
                            <div className="mt-2">
                              <InputMask
                                name="secondary_cell_phone_number"
                                value={firmDetail.secondary_cell_phone_number}
                                onChange={(e) => formHandler(e, "firm")}
                                className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                                mask="+1 (___) ___-____"
                                replacement={{ _: /\d/ }}
                              />
                              <span className="text-red-500 text-xs">
                                {errorFlag.firm &&
                                  errorFlag.firm.secondary_cell_phone_number}
                              </span>
                            </div>
                          </div>

                          <div className="sm:col-span-2">
                            <div>
                              <label className="flex gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 hover:cursor-pointer">
                                <DocumentArrowUpIcon
                                  className="-ml-0.5 h-5 w-5"
                                  aria-hidden="true"
                                />
                                <input
                                  name="logo_url"
                                  type="file"
                                  onChange={(e) => {
                                    formHandler(e, "firm");
                                  }}
                                  accept=".jpg, .jpeg, .png, .wep"
                                />
                                <div className="hover:cursor-pointer">
                                  Upload Firm Logo
                                </div>
                              </label>
                              <span className="text-red-500 text-xs">
                                {errorFlag.firm && errorFlag.firm.logo_url}{" "}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-10 sm:mx-5">
                    <div className="py-5">
                      <div className="text-lg font-bold">
                        ADD PRIMARY ATTORNEY
                      </div>
                      <div className="text-xs text-[#9ca0b3]"></div>
                    </div>

                    <form>
                      <div className="space-y-12">
                        <div className="pb-12">
                          {primaryAttorneyDetail.attorney_code == "" && (
                            <div className="font-bold">
                              Attorney Code: <span className="ml-2">--</span>
                            </div>
                          )}
                          {primaryAttorneyDetail.attorney_code !== "" && (
                            <>
                              <div className="flex gap-2">
                                <div className="font-bold">Attorney Code:</div>
                                <div>
                                  {!attorneyCodeLoader ? (
                                    <>{primaryAttorneyDetail.attorney_code}</>
                                  ) : (
                                    <span>
                                      <TailSpin
                                        height="20"
                                        width="20"
                                        color="black"
                                        ariaLabel="tail-spin-loading"
                                        radius="1"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                      />
                                    </span>
                                  )}
                                </div>
                              </div>
                            </>
                          )}
                          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-3">
                              <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                                First name
                              </label>
                              <div className="mt-2">
                                <input
                                  name="first_name"
                                  value={primaryAttorneyDetail.first_name}
                                  onChange={(e) => {
                                    formHandler(e, "primary_attorney");
                                  }}
                                  className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                                />
                                <span className="text-red-500 text-xs">
                                  {errorFlag.primary_attorney &&
                                    errorFlag.primary_attorney.first_name}
                                </span>
                              </div>
                            </div>

                            <div className="sm:col-span-3">
                              <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                                Last name
                              </label>
                              <div className="mt-2">
                                <input
                                  name="last_name"
                                  value={primaryAttorneyDetail.last_name}
                                  onChange={(e) => {
                                    formHandler(e, "primary_attorney");
                                  }}
                                  className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                                />
                                <span className="text-red-500 text-xs">
                                  {errorFlag.primary_attorney &&
                                    errorFlag.primary_attorney.last_name}
                                </span>
                              </div>
                            </div>

                            <div className="sm:col-span-3">
                              <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                                Email address
                              </label>
                              <div className="mt-2">
                                <input
                                  name="email"
                                  value={primaryAttorneyDetail.email}
                                  onChange={(e) => {
                                    formHandler(e, "primary_attorney");
                                  }}
                                  className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                                />
                                <span className="text-red-500 text-xs">
                                  {errorFlag.primary_attorney &&
                                    errorFlag.primary_attorney.email}
                                </span>
                              </div>
                            </div>

                            <div className="sm:col-span-3">
                              <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                                Primary Phone
                              </label>
                              <div className="mt-2">
                                <InputMask
                                  name="cell_phone_number"
                                  value={
                                    primaryAttorneyDetail.cell_phone_number
                                  }
                                  onChange={(e) => {
                                    formHandler(e, "primary_attorney");
                                  }}
                                  className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                                  mask="+1 (___) ___-____"
                                  replacement={{ _: /\d/ }}
                                />
                                <span className="text-red-500 text-xs">
                                  {errorFlag.primary_attorney &&
                                    errorFlag.primary_attorney
                                      .cell_phone_number}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </form>
                <div className="mt-6 flex items-center justify-end gap-x-6 mb-5">
                  {/* <button
                  type="button"
                  className="text-sm font-semibold leading-6 text-gray-900"
                  onClick={prevStep}
                  disabled={activeStep === 1}
                >
                  Previous
                </button> */}
                  <button
                    submitFirmSectionButton
                    type="button"
                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => {
                      submitFirmSection();
                    }}
                    disabled={activeStep === totalSteps}
                  >
                    Next
                  </button>
                </div>
              </div>
            </>
          )}
          {pageSection.ATTORNEYS && (
            <>
              <div className="mx-5 ml-5">
                <div className="">
                  <div className="text-xl font-bold">
                    ADDITIONAL ATTORNEY (OPTIONAL)
                  </div>
                  <div className="text-sm text-[#9ca0b3]">
                    Add at least one or more attorneys
                  </div>
                </div>

                <div className="mt-1">
                  {fields.map((field, index) => (
                    <>
                      {index != fields.length - 1 &&
                      !checkEmptyFields(index) ? (
                        <>
                          <div className="mt-6" key={index}>
                            <div className="flex justify-end gap-2">
                              {editAttorneyIndexFlag != index &&
                                index != fields.length - 1 &&
                                !checkEmptyFields(index) && (
                                  <div>
                                    <PencilIcon
                                      onClick={() => {
                                        setInitialAttorneysData(fields[index]);
                                        setEditAttorneyIndexFlag(index);
                                      }}
                                      className="h-5 w-5 hover:cursor-pointer"
                                    />
                                  </div>
                                )}
                              <div>
                                {index != fields.length - 1 &&
                                  !checkEmptyFields(index) && (
                                    <div>
                                      <TrashIcon
                                        onClick={() => {
                                          submitAttorneySection(
                                            index,
                                            "",
                                            "attorney"
                                          );
                                        }}
                                        className="h-5 w-5 hover:cursor-pointer"
                                      />
                                    </div>
                                  )}
                              </div>
                            </div>

                            <div className="">
                              {fields[index].attorney_code == "" && (
                                <div className="font-bold">
                                  Attorney Code:{" "}
                                  <span className="ml-2">--</span>
                                </div>
                              )}
                              {fields[index].attorney_code !== "" && (
                                <>
                                  <div className="flex gap-2">
                                    <div className="font-bold">
                                      Attorney Code:
                                    </div>
                                    <div>
                                      {activeAttorneysCodeLoader != index ? (
                                        <>{fields[index].attorney_code}</>
                                      ) : (
                                        <span>
                                          <TailSpin
                                            height="20"
                                            width="20"
                                            color="black"
                                            ariaLabel="tail-spin-loading"
                                            radius="1"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                            visible={true}
                                          />
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </>
                              )}

                              <div className="mt-8  grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                <div className="sm:col-span-3">
                                  <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                                    First name
                                  </label>

                                  <div className="mt-2 block w-full  border-b-2 text-[#c7ccd0]">
                                    {field.first_name}
                                  </div>
                                </div>

                                <div className="sm:col-span-3">
                                  <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                                    Last name
                                  </label>

                                  <div className="mt-2 block w-full  border-b-2 text-[#c7ccd0]">
                                    {field.last_name}
                                  </div>
                                </div>

                                <div className="sm:col-span-3">
                                  <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                                    Email address
                                  </label>

                                  <div className="mt-2 block w-full  border-b-2 text-[#c7ccd0]">
                                    {field.email}
                                  </div>
                                </div>

                                <div className="sm:col-span-3">
                                  <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                                    Primary Phone
                                  </label>

                                  <div className="mt-2 block w-full  border-b-2 text-[#c7ccd0]">
                                    {formatUSCell(field.cell_phone_number)}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <ModalComponent
                            open={editAttorneyIndexFlag === index}
                            handleClose={() => {
                              let updatedFields = [...fields];
                              updatedFields[index] = initialAttorneysData;
                              setFields(updatedFields);
                              setEditAttorneyIndexFlag(-1);
                            }}
                          >
                            <div className="w-full px-5 mt-5 sm:max-w-4xl">
                              <div className="bg-white space-y-12 border rounded-lg my-3 p-5">
                                <div className="flex justify-end gap-2">
                                  {editAttorneyIndexFlag != index && (
                                    <div>
                                      <PencilIcon
                                        onClick={() => {
                                          setInitialAttorneysData(
                                            fields[index]
                                          );
                                          setEditAttorneyIndexFlag(index);
                                        }}
                                        className="h-5 w-5 hover:cursor-pointer"
                                      />
                                    </div>
                                  )}
                                  <div>
                                    {index > -1 && (
                                      <div>
                                        <TrashIcon
                                          onClick={() => {
                                            submitAttorneySection(
                                              index,
                                              "",
                                              "attorney"
                                            );
                                          }}
                                          className="h-5 w-5 hover:cursor-pointer"
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="border-gray-900/10 pb-12">
                                  {fields[index].attorney_code == "" && (
                                    <div className="font-bold">
                                      Attorney Code:{" "}
                                      <span className="ml-2">--</span>
                                    </div>
                                  )}
                                  {fields[index].attorney_code !== "" && (
                                    <>
                                      <div className="flex gap-2">
                                        <div className="font-bold">
                                          Attorney Code:
                                        </div>
                                        <div>
                                          {activeAttorneysCodeLoader !=
                                          index ? (
                                            <>{fields[index].attorney_code}</>
                                          ) : (
                                            <span>
                                              <TailSpin
                                                height="20"
                                                width="20"
                                                color="black"
                                                ariaLabel="tail-spin-loading"
                                                radius="1"
                                                wrapperStyle={{}}
                                                wrapperClass=""
                                                visible={true}
                                              />
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                    </>
                                  )}
                                  <div className="mt-8 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                    <div className="text-start sm:col-span-3">
                                      <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                                        First name
                                      </label>

                                      {editAttorneyIndexFlag !== index ? (
                                        <div className="mt-2 block w-full  border-b-2 text-[#c7ccd0]">
                                          {field.first_name}
                                        </div>
                                      ) : (
                                        <div className="mt-2">
                                          <input
                                            className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                                            type="text"
                                            name="first_name"
                                            value={field.first_name}
                                            onChange={(e) => {
                                              formHandler(e, "attorney", index);
                                            }}
                                          />
                                          <span className="text-red-500 text-xs">
                                            {
                                              errorFlag.attorney[index]
                                                .first_name
                                            }
                                          </span>
                                        </div>
                                      )}
                                    </div>

                                    <div className="text-start sm:col-span-3">
                                      <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                                        Last name
                                      </label>

                                      {editAttorneyIndexFlag !== index ? (
                                        <div className="mt-2 block w-full  border-b-2 text-[#c7ccd0]">
                                          {field.last_name}
                                        </div>
                                      ) : (
                                        <div className="mt-2">
                                          <input
                                            className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                                            type="text"
                                            name="last_name"
                                            value={field.last_name}
                                            onChange={(e) =>
                                              formHandler(e, "attorney", index)
                                            }
                                          />
                                          <span className="text-red-500 text-xs">
                                            {
                                              errorFlag.attorney[index]
                                                .last_name
                                            }
                                          </span>
                                        </div>
                                      )}
                                    </div>

                                    <div className="text-start sm:col-span-3">
                                      <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                                        Email address
                                      </label>

                                      {editAttorneyIndexFlag !== index ? (
                                        <div className="mt-2 block w-full  border-b-2 text-[#c7ccd0]">
                                          {field.email}
                                        </div>
                                      ) : (
                                        <div className="mt-2">
                                          <input
                                            className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                                            type="email"
                                            name="email"
                                            value={field.email}
                                            onChange={(e) =>
                                              formHandler(e, "attorney", index)
                                            }
                                          />
                                          <span className="text-red-500 text-xs">
                                            {errorFlag.attorney[index].email}
                                          </span>
                                        </div>
                                      )}
                                    </div>

                                    <div className="text-start sm:col-span-3">
                                      <label className="block text-md  font-medium leading-6 text-[#9ca0b3]">
                                        Primary Phone
                                      </label>

                                      {editAttorneyIndexFlag !== index ? (
                                        <div className="mt-2 block w-full  border-b-2 text-[#c7ccd0]">
                                          {field.cell_phone_number}
                                        </div>
                                      ) : (
                                        <div className="mt-2">
                                          <InputMask
                                            className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                                            type="text"
                                            name="cell_phone_number"
                                            value={field.cell_phone_number}
                                            onChange={(e) =>
                                              formHandler(e, "attorney", index)
                                            }
                                            mask="+1 (___) ___-____"
                                            replacement={{ _: /\d/ }}
                                          />
                                          <span className="text-red-500 text-xs">
                                            {
                                              errorFlag.attorney[index]
                                                .cell_phone_number
                                            }
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                {editAttorneyIndexFlag == index && (
                                  <div className="w-full flex justify-end gap-5">
                                    <button
                                      type="button"
                                      onClick={() => {
                                        submitAttorneySection(index, "", "");
                                      }}
                                      className={` text-white px-4 py-2 rounded-lg  ${"bg-[#272729] hover:bg-[#172729] focus:outline-none focus:bg-[#272729]"}`}
                                    >
                                      Save
                                    </button>
                                    <button
                                      onClick={() => {
                                        let updatedFields = [...fields];
                                        updatedFields[index] =
                                          initialAttorneysData;
                                        setFields(updatedFields);
                                        setEditAttorneyIndexFlag(-1);

                                        let updatedAttorney = {
                                          first_name: "",
                                          last_name: "",
                                          email: "",
                                          cell_phone_number: "",
                                        };

                                        setErrorFlag((prevState) => {
                                          const updatedAttorneys = [
                                            ...prevState.attorney,
                                          ];
                                          updatedAttorneys[index] =
                                            updatedAttorney;

                                          return {
                                            ...prevState,
                                            attorney: updatedAttorneys,
                                          };
                                        });
                                      }}
                                      type="button"
                                      className="bg-[#272729] text-white px-4 py-2 rounded-lg hover:bg-[#172729] focus:outline-none focus:bg-[#272729]"
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                          </ModalComponent>
                        </>
                      ) : (
                        <>
                          <div className="bg-white space-y-12 border rounded-lg my-3 p-2 sm:p-5">
                            {/* <div className="flex justify-end gap-2">
                              {editAttorneyIndexFlag != index && (
                                <div>
                                  <PencilIcon
                                    onClick={() => {
                                      setInitialAttorneysData(fields[index]);
                                      setEditAttorneyIndexFlag(index);
                                    }}
                                    className="h-5 w-5 hover:cursor-pointer"
                                  />
                                </div>
                              )}
                              <div>
                                {index > -1 && (
                                  <div>
                                    <TrashIcon
                                      onClick={() => {
                                        submitAttorneySection(
                                          index,
                                          "",
                                          "attorney"
                                        );
                                      }}
                                      className="h-5 w-5 hover:cursor-pointer"
                                    />
                                  </div>
                                )}
                              </div>
                            </div> */}
                            <div className="border-gray-900/10 py-6">
                              {fields[index].attorney_code == "" && (
                                <div className="font-bold">
                                  Attorney Code:{" "}
                                  <span className="ml-2">--</span>
                                </div>
                              )}
                              {fields[index].attorney_code !== "" && (
                                <>
                                  <div className="flex gap-2">
                                    <div className="font-bold">
                                      Attorney Code:
                                    </div>
                                    <div>
                                      {activeAttorneysCodeLoader != index ? (
                                        <>{fields[index].attorney_code}</>
                                      ) : (
                                        <span>
                                          <TailSpin
                                            height="20"
                                            width="20"
                                            color="black"
                                            ariaLabel="tail-spin-loading"
                                            radius="1"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                            visible={true}
                                          />
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </>
                              )}
                              <div className="mt-8 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                <div className="sm:col-span-3">
                                  <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                                    First name
                                  </label>

                                  <div className="mt-2">
                                    <input
                                      className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                                      type="text"
                                      name="first_name"
                                      value={field.first_name}
                                      onChange={(e) => {
                                        formHandler(e, "attorney", index);
                                      }}
                                    />
                                    <span className="text-red-500 text-xs">
                                      {errorFlag.attorney[index].first_name}
                                    </span>
                                  </div>
                                </div>

                                <div className="sm:col-span-3">
                                  <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                                    Last name
                                  </label>

                                  <div className="mt-2">
                                    <input
                                      className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                                      type="text"
                                      name="last_name"
                                      value={field.last_name}
                                      onChange={(e) =>
                                        formHandler(e, "attorney", index)
                                      }
                                    />
                                    <span className="text-red-500 text-xs">
                                      {errorFlag.attorney[index].last_name}
                                    </span>
                                  </div>
                                </div>

                                <div className="sm:col-span-3">
                                  <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                                    Email address
                                  </label>

                                  <div className="mt-2">
                                    <input
                                      className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                                      type="email"
                                      name="email"
                                      value={field.email}
                                      onChange={(e) =>
                                        formHandler(e, "attorney", index)
                                      }
                                    />
                                    <span className="text-red-500 text-xs">
                                      {errorFlag.attorney[index].email}
                                    </span>
                                  </div>
                                </div>

                                <div className="sm:col-span-3">
                                  <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                                    Primary Phone
                                  </label>

                                  <div className="mt-2">
                                    <InputMask
                                      name="cell_phone_number"
                                      value={field.cell_phone_number}
                                      onChange={(e) =>
                                        formHandler(e, "attorney", index)
                                      }
                                      className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                                      mask="+1 (___) ___-____"
                                      replacement={{ _: /\d/ }}
                                    />
                                    <span className="text-red-500 text-xs">
                                      {
                                        errorFlag.attorney[index]
                                          .cell_phone_number
                                      }
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  ))}

                  {/* <div className="mt-4">
                  <button
                    
                    
                    
                    onClick={() => {
                      submitAttorneySection();
                    }}
                    
                    
                    
                    
                  >
                    Submit
                  </button>
                </div> */}
                </div>
                <button
                  type="button"
                  onClick={(e) =>
                    submitAttorneySection(fields.length - 1, "add-next", "")
                  }
                  className="flex justify-center items-center  text-sm font-bold px-2 py-2 bg-indigo-600 rounded-lg text-white hover:bg-indigo-400"
                >
                  <div>
                    <PlusIcon className="h-4 w-4" />
                  </div>
                  <div className="text-sm font-bold">Add Attorney</div>
                </button>
              </div>
              <div className="mt-6 flex items-center justify-end gap-x-6 px-8 mb-5 ">
                <button
                  type="button"
                  className="text-sm font-semibold leading-6 text-gray-900"
                  onClick={prevStep}
                  disabled={activeStep === 1}
                >
                  Previous
                </button>
                <button
                  type="button"
                  className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  onClick={() => submitAttorneySection("", "next", "")}
                  disabled={activeStep === totalSteps}
                >
                  Next
                </button>
              </div>
            </>
          )}
          {pageSection.CONTRACT && (
            <>
              <div className="mx-5 ml-5 ">
                <div className="">
                  <div className="text-xl font-bold">CONTRACT DETAILS</div>
                  <div className="text-sm text-[#9ca0b3]">
                    Overwrite default values as needed
                  </div>
                </div>

                <div className="mt-5 sm:px-5">
                  <form>
                    <div className="space-y-12 ">
                      <div className="border-gray-900/10 pb-12">
                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                          <div className="sm:col-span-3">
                            <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                              Maximum Escrow Value ($)
                            </label>
                            <div className="mt-2">
                              <InputNumberFormat
                                name="escrow_limit"
                                id="escrow_limit"
                                placeholder="Enter Escrow Limit"
                                value={contract.escrow_limit}
                                onChange={(e) => formHandler(e, "contract")}
                                currency="USD"
                                format="currency"
                                className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                              />
                            </div>
                            <span className="text-red-500 text-xs">
                              {errorFlag.contract.escrow_limit}
                            </span>
                          </div>

                          <div className="sm:col-span-3">
                            <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                              Escrow %
                            </label>
                            <div className="mt-2">
                              <input
                                className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                                type="text"
                                name="escrow_percentage"
                                value={contract.escrow_percentage}
                                onChange={(e) => formHandler(e, "contract")}
                              />
                            </div>
                            <span className="text-red-500 text-xs">
                              {errorFlag.contract.escrow_percentage}
                            </span>
                          </div>

                          <div className="sm:col-span-3">
                            <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                              NA Service Fee %
                            </label>
                            <div className="mt-2">
                              <input
                                className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                                type="text"
                                name="na_fee_percentage"
                                value={contract.na_fee_percentage}
                                onChange={(e) => formHandler(e, "contract")}
                              />
                            </div>

                            <span className="text-red-500 text-xs">
                              {errorFlag.contract.na_fee_percentage}
                            </span>
                          </div>

                          {/* <div className="sm:col-span-3">
                          <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                            Delinquent Collection Rate
                          </label>
                          <div className="mt-2">
                            <input
                              className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                              type="text"
                              name="deliquency_collection_rate"
                              value={contract.deliquency_collection_rate}
                              onChange={(e) => formHandler(e, "contract")}
                            />
                          </div>
                        </div> */}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="mt-6 flex items-center justify-end gap-x-6 px-8 mb-5 ">
                <button
                  type="button"
                  className="text-sm font-semibold leading-6 text-gray-900"
                  onClick={prevStep}
                  disabled={activeStep === 1}
                >
                  Previous
                </button>
                <button
                  type="button"
                  className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  onClick={() => {
                    submitContractSection();
                  }}
                  disabled={activeStep === totalSteps}
                >
                  Next
                </button>
              </div>
            </>
          )}
          {pageSection.PAYMENT && (
            <>
              <div className="mx-5 ml-5 ">
                <div className="">
                  <div className="text-xl font-bold">PAYMENT DETAILS</div>
                  <div className="text-sm text-[#9ca0b3]">
                    Payment Details for Monthly Services
                  </div>
                </div>

                <div className="mt-5 ">
                  <form>
                    <div className="pt-5">
                      <div className="text-md font-bold">
                        Direct Deposit Information
                      </div>
                      <div className="text-sm text-[#9ca0b3]">
                        Enter account details for direct deposit
                      </div>
                    </div>
                    <div className="border-gray-900/10 pb-12 sm:px-5">
                      <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-3">
                          <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                            Bank Routing Number
                          </label>
                          <div className="mt-2">
                            <input
                              className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                              type="text"
                              name="bank_routing_number"
                              value={directDeposit.bank_routing_number}
                              onChange={(e) => formHandler(e, "direct_deposit")}
                            />
                          </div>

                          <span className="text-red-500 text-xs">
                            {errorFlag.direct_deposit.bank_routing_number}
                          </span>
                        </div>

                        <div className="sm:col-span-3">
                          <label className="block text-md font-medium leading-6 text-[#9ca0b3]">
                            Bank Account Number
                          </label>
                          <div className="mt-2">
                            <input
                              className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                              type="text"
                              name="bank_account_number"
                              value={directDeposit.bank_account_number}
                              onChange={(e) => formHandler(e, "direct_deposit")}
                            />
                          </div>

                          <span className="text-red-500 text-xs">
                            {errorFlag.direct_deposit.bank_account_number}
                          </span>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {/* <div className="container mx-auto p-4">
              <h1 className="text-xl font-semibold mb-4">
                Credit Card Validation
              </h1>
              <div className="my-4 py-4 w-full flex flex-col gap-2 justify-around items-center md:flex-row shadow">
                {cards &&
                  cards.map((card, index) => (
                    <div
                      class="w-96 h-60 m-auto bg-red-100 rounded-xl relative text-white shadow-2xl transition-transform transform hover:scale-110"
                      key={index}
                    >
                      <img
                        class="relative object-cover w-full h-full rounded-xl"
                        src="https://i.imgur.com/Zi6v09P.png"
                      />

                      <div class="w-full px-8 absolute top-8">
                        <div class="flex justify-between">
                          <div class="">
                            <p class="font-light">Name</p>
                            <p class="font-medium tracking-widest">
                              {card.cardholder}
                            </p>
                          </div>
                          <img
                            class="w-14 h-14"
                            src="https://i.imgur.com/bbPHJVe.png"
                          />
                        </div>
                        <div class="pt-1">
                          <p class="font-light">Card Number</p>
                          <p class="font-medium tracking-more-wider">
                            {card.card_number}
                          </p>
                        </div>
                        <div class="pt-6 pr-6">
                          <div class="flex justify-between">
                            <div class="">
                              <p class="font-light text-xs text-xs">Expiry</p>
                              <p class="font-medium tracking-wider text-sm">
                                {card.expiration_date}
                              </p>
                            </div>

                            <div class="">
                              <p class="font-light text-xs">CVV</p>
                              <p class="font-bold tracking-more-wider text-sm">
                                {card.expiration_date}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>

              <form className="space-y-4">
                <div className="flex flex-col">
                  <label
                    htmlFor="cardHolderName"
                    className="mb-2 block text-md font-medium leading-6 text-[#9ca0b3]"
                  >
                    Card Holder Name:
                  </label>
                  <input
                    type="text"
                    id="cardholder"
                    name="cardholder"
                    value={cardData.cardholder}
                    className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                    onChange={(e) => formHandler(e, "payment")}
                  />
                  <span className="text-red-500 text-xs">
                    {errorFlag.payment.cardholder}
                  </span>
                </div>
                <div className="flex flex-col">
                  <label
                    htmlFor="cardNumber"
                    className="mb-2 block text-md font-medium leading-6 text-[#9ca0b3]"
                  >
                    Card Number:
                  </label>
                  <input
                    type="text"
                    id="card_number"
                    name="card_number"
                    value={cardData.card_number}
                    className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                    onChange={(e) => formHandler(e, "payment")}
                  />
                  <span className="text-red-500 text-xs">
                    {errorFlag.payment.card_number}
                  </span>
                </div>
                <div className="flex flex-col">
                  <label
                    htmlFor="expiryDate"
                    className="mb-2 block text-md font-medium leading-6 text-[#9ca0b3]"
                  >
                    Expiry Date (MM/DD):
                  </label>
                  <input
                    type="text"
                    id="expiration_date"
                    name="expiration_date"
                    value={cardData.expiration_date}
                    className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                    onChange={(e) => formHandler(e, "payment")}
                  />
                  <span className="text-red-500 text-xs">
                    {errorFlag.payment.expiration_date}
                  </span>
                </div>
                <div className="flex flex-col">
                  <label
                    htmlFor="cvv"
                    className="mb-2 block text-md font-medium leading-6 text-[#9ca0b3]"
                  >
                    CVV:
                  </label>
                  <input
                    type="text"
                    id="cvv"
                    name="cvv"
                    value={cardData.cvv}
                    className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                    onChange={(e) => formHandler(e, "payment")}
                  />

                  <span className="text-red-500 text-xs">
                    {errorFlag.payment.cvv}
                  </span>
                </div>

                <button
                  type="button"
                  className="bg-blue-500 text-white py-2 px-4 rounded-md"
                  onClick={handleAddCards}
                >
                  + Add
                </button>
              </form>
            </div> */}
              <div className="mt-6 flex items-center justify-end gap-x-6 px-8 mb-5 ">
                <button
                  type="button"
                  className="text-sm font-semibold leading-6 text-gray-900"
                  onClick={prevStep}
                  disabled={activeStep === 1}
                >
                  Previous
                </button>
                <button
                  type="button"
                  className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm inline-flex gap-3 justify-center items-center hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  onClick={() => {
                    submitPaymentSection();
                  }}
                  disabled={submitLoader}
                >
                  Finish
                  <div>
                    {submitLoader && (
                      <Oval
                        height={20}
                        width={20}
                        color="#ffff"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="#ffff"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                      />
                    )}
                  </div>
                </button>
              </div>
            </>
          )}
        </main>
      )}

      {/* <ModalComponent open={openEnableDraft} handleClose={() => { }}>
        <div className="flex items-center justify-center bg-white p-5">
          <div className="bg-white">
            <div className="relative z-10">
              <div className="overflow-hidden rounded-lg bg-white ">
                <div className="mt-0 pt-8">
                  <div className="text-2xl text-center font-bold text-[#3a6281]">
                    DRAFT
                  </div>
                  {firmDetail.firm_name && (
                    <div className="text-md font-light mt-2">
                      FIRM: {firmDetail.firm_name}
                    </div>
                  )}
                </div>
                <div>
                  <div className="mt-1 pt-5 flex  flex-col gap-3 lg:flex-row">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500  sm:col-start-2"
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      Resume
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                      onClick={() => {
                        deleteDraft();
                      }}
                    >
                      Skip
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalComponent> */}

      <ModalComponent open={openEnableDraft} handleClose={() => {}}>
        <div className="flex justify-center items-center">
          <div className="w-full  relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all  sm:max-w-lg">
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ClipboardDocumentCheckIcon
                    className="h-6 w-6 text-green-600"
                    aria-hidden="true"
                  />
                </div>
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <h3 className="text-base font-semibold leading-6 text-gray-900">
                    Disclaimer: Incomplete Form [{`${firmDetail.firm_code}`}]
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      There's an incomplete form in the National Access Network.
                      Please review and fill in missing details for smooth
                      processing.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
              <button
                type="button"
                className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 sm:ml-3 sm:w-auto outline-none"
                onClick={() => {
                  handleClose();
                }}
              >
                Resume
              </button>
              <button
                type="button"
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                onClick={() => {
                  deleteDraft();
                }}
              >
                Skip
              </button>
            </div>
          </div>
        </div>
      </ModalComponent>

      <ModalComponent open={openSoftErrorModal} handleClose={() => {}}>
        <div className="flex justify-center items-center">
          <div className="w-full  relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all  sm:max-w-lg">
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationTriangleIcon
                    className="h-6 w-6 text-red-600"
                    aria-hidden="true"
                  />
                </div>
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <h3 className="text-base font-semibold leading-6 text-gray-900">
                    Disclaimer:
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Firm with similar information (Name & Address) available
                      in National Access Network
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
              <button
                type="button"
                className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto outline-none"
                onClick={() => {
                  setOverRideFlag(true);
                  handleClose();
                  submitFirmSection();
                }}
              >
                Proceed
              </button>
              <button
                type="button"
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                onClick={() => {
                  handleClose();
                }}
              >
                Revise
              </button>
            </div>
          </div>
        </div>
      </ModalComponent>

      <ModalComponent open={openHardErrorModal} handleClose={() => {}}>
        <div className="flex justify-center items-center">
          <div className="w-full  relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all  sm:max-w-lg">
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <DocumentDuplicateIcon
                    className="h-6 w-6 text-red-600"
                    aria-hidden="true"
                  />
                </div>
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <h3 className="text-base font-semibold leading-6 text-gray-900">
                    Duplicate Information
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Firm with similar information available in National Access
                      Network | Firm Code:
                      <span className="text-black font-bold">
                        {hardErrorData.firm_code}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
              <button
                type="button"
                className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto outline-none"
                onClick={() => {
                  handleClose();
                  navigate("/view-firm");
                }}
              >
                View-Firm
              </button>
              <button
                type="button"
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                onClick={() => {
                  handleClose();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </ModalComponent>
    </div>
  );
};

export default AddFirms;
