import { useEffect, useMemo, useState } from "react";
import {
  CalendarIcon,
  ChartPieIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { Line, Doughnut } from "react-chartjs-2";

import {
  Chart as ChartJS,
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import ExportImg from "../../assets/img/excel-img.png";
import MaterialReactTable from "material-react-table";
import { useNavigate } from "react-router-dom";
import { domainUrl, logOutHandler } from "../../utils/constants";
import { Avatar, Button } from "@mui/material";
import toast from "react-hot-toast";
import axios from "axios";
ChartJS.register(
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  Tooltip,
  Legend
);

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function TransactionHistoryTable() {
  const [data, setData] = useState([]);

  const [isError, setIsError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [isRefetching, setIsRefetching] = useState(false);

  const [rowCount, setRowCount] = useState(0);

  const access_token = localStorage.getItem("access_token");
  const role = localStorage.getItem("role");

  const [totalCount, setTotalCount] = useState(2);
  const navigate = useNavigate();
  const [columnFilters, setColumnFilters] = useState([]);

  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState([]);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  function extractDateFromTimestamp(timestamp) {
    const dateObject = new Date(timestamp);
    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
    const day = dateObject.getDate().toString().padStart(2, "0");
    const year = dateObject.getFullYear();
    return `${month}/${day}/${year}`;
  }

  function formatUSD(number) {
    if (typeof number !== "number" || isNaN(number)) {
      if (typeof number == "string") {
        number = parseFloat(number);
      } else {
        return "Invalid number";
      }
    }

    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    return formatter.format(number);
  }
  const formatDate = (dateString) => {
    if (dateString) {
      const date = dateString.split("-");

      return `${date[1]}/${date[2]}/${date[0]}`;
    }
    return dateString;
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!data.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      const url = new URL("transactions", `${domainUrl}`);

      url.searchParams.set(
        "offset",
        pagination.pageIndex * pagination.pageSize
      );
      url.searchParams.set("limit", `${pagination.pageSize}`);
      url.searchParams.set("offset", `${pagination.pageIndex}`);
      url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
      url.searchParams.set("search", search ?? "");
      url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
      try {
        const response = await fetch(url.href, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        });
        let json = await response.json();
        let data = json;

        if (data.code == "token_not_valid") {
          logOutHandler();
          navigate("/login");
          toast.error("Session Expired", { id: 1 });
          return;
        }
        setData(data.data);
        setRowCount(data.count);
      } catch (error) {
        setIsError(true);

        return;
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    };

    fetchData();
  }, [
    columnFilters,
    search,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
  ]);

  const columns = useMemo(
    () => [
      {
        id: "transactions",
        header: "",
        columns: [
          {
            accessorFn: (row) =>
              `${row.transaction_id ? row.transaction_id : "--"} `,
            id: "transaction_id",
            header: "Transaction Id",
            accessorKey: "transaction_id",
            enableColumnActions: false,
            muiTableHeadCellProps: {
              align: "center",
            },
            muiTableBodyCellProps: {
              align: "center",
            },
            size: 25,
          },
          {
            id: "payment_schedule__contract__client__user_model__first_name",
            header: "Client",
            accessorFn: (row) =>
              `${row?.client?.first_name} ${row?.client?.last_name}`,
            accessorKey: "first_name",
            enableColumnActions: false,
            size: 30,
          },
          role == "admin" || role == "primaryadmin"
            ? {
                id: "payment_schedule__contract__attorney__user_model__first_name",
                header: "Attorney",
                accessorFn: (row) =>
                  `${row?.attorney?.first_name} ${row?.attorney?.last_name}`,
                accessorKey: "attorney",
                enableColumnActions: false,
                size: 30,
              }
            : null,
          role == "admin"
            ? {
                id: "payment_schedule__contract__attorney__firm__firm_name",
                header: "Firm",
                accessorFn: (row) => `${row?.attorney?.firm_name}`,
                accessorKey: "firm_name",
                enableColumnActions: false,
                size: 30,
              }
            : null,
          {
            id: "type",
            header: "Type",
            accessorFn: (row) => `${row?.type?.toUpperCase()}`,
            accessorKey: "type",
            enableColumnActions: false,
            size: 30,
          },
          {
            id: "amount_paid",
            header: "Amount",
            accessorFn: (row) => `${formatUSD(row.amount_paid)}`,
            accessorKey: "amount_paid",
            enableColumnActions: false,
            size: 30,
          },
          {
            id: "paid_at",
            header: "Date",
            accessorFn: (row) => `${extractDateFromTimestamp(row.paid_at)}`,
            muiTableHeadCellFilterTextFieldProps: { placeholder: "YYYY-MM-DD" },
            accessorKey: "paid_at",
            enableColumnActions: false,
            size: 30,
          },
          {
            id: "status",
            header: "Status",
            accessorFn: (row) => `${row?.status?.toUpperCase()}`,
            accessorKey: "status",
            enableColumnActions: false,
            size: 30,
          },
        ].filter(Boolean),
      },
    ],
    []
  );

  const exportFileHandler = async (id) => {
    const url = new URL("transactions", `${domainUrl}`);
    url.searchParams.set("offset", pagination.pageIndex * pagination.pageSize);
    url.searchParams.set("limit", `${pagination.pageSize}`);
    url.searchParams.set("offset", `${pagination.pageIndex}`);
    url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
    url.searchParams.set("search", search ?? "");
    url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
    url.searchParams.set("export", true);

    axios
      .get(url, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        if (response.headers.get("content-type") == "application/json") {
          response.data.text().then((text) => {
            toast.success(JSON.parse(text).scheduled, { autoClose: 10000 });
          });
        } else {
          let filename = `Transactions_${Date.now()}.xlsx`;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
        }
      });
  };

  return (
    <>
      <div>
        <main className="py-10">
          <main className="w-full py-10 ">
            <div className="mx-5">
              <h2
                style={{
                  color: "#1a3253",
                }}
              >
                Transaction History
              </h2>
              <div className="text-base text-[#9ca0b3] my-2">
                List of recent transactions
              </div>

              <div className="p-1 border rounded-lg">
                <MaterialReactTable
                  enableTopToolbar={true}
                  renderTopToolbarCustomActions={
                    role == "admin"
                      ? ({ table }) => {
                          <div
                            style={{
                              display: "flex",
                              gap: "0.5rem",
                              alignItems: "center",
                            }}
                          >
                            <Button
                              disabled={false}
                              startIcon={<Avatar src={ExportImg} />}
                              onClick={() => {
                                exportFileHandler();
                              }}
                              variant="contained"
                              sx={{
                                bgcolor: "#127c44",
                                "&:hover": {
                                  bgcolor: "#127c44",
                                },
                                height: "25px",
                              }}
                            >
                              Export
                            </Button>
                          </div>;
                        }
                      : null
                  }
                  enableStickyHeader
                  muiTableContainerProps={{ sx: { maxHeight: "500px" } }}
                  muiTableBodyProps={{
                    sx: () => ({
                      "& tr:nth-child(odd)": {
                        backgroundColor: "#f8f9fa",
                      },
                      "& tr:nth-child(even)": {
                        backgroundColor: "#ffffff",
                      },
                    }),
                  }}
                  muiTablePaperProps={{
                    sx: {
                      maxWidth: "full",
                    },
                    elevation: 0,
                  }}
                  muiTableHeadCellProps={{
                    sx: (theme) => ({
                      color: theme.palette.text.primary,
                    }),
                  }}
                  enableFullScreenToggle={false}
                  enableDensityToggle={false}
                  enableHiding={false}
                  enableColumnFilters={true}
                  rowNumberMode="static"
                  muiTablePaginationProps={{
                    rowsPerPageOptions: [1, 2, 5, 10, 15, 20, 25],
                    showFirstButton: false,
                    showLastButton: false,
                  }}
                  columns={columns}
                  data={data}
                  muiTableBodyRowProps={({ row }) => ({
                    onClick: () => {
                      if (data.length) {
                      }
                    },

                    sx: {
                      cursor: "pointer",
                    },
                  })}
                  getRowId={(row) => row.id}
                  initialState={{
                    showColumnFilters: false,
                    density: "compact",
                  }}
                  manualPagination
                  manualSorting
                  muiToolbaralertBannerProps={
                    isError
                      ? {
                          color: "error",
                          children: "Error loading data",
                        }
                      : undefined
                  }
                  onColumnFiltersChange={setColumnFilters}
                  onGlobalFilterChange={setSearch}
                  onPaginationChange={setPagination}
                  onSortingChange={setSorting}
                  rowCount={rowCount}
                  state={{
                    isLoading,
                    pagination,
                    showalertBanner: isError,
                    showProgressBars: isRefetching,
                    sorting,
                  }}
                />
              </div>
            </div>
          </main>
        </main>
      </div>
    </>
  );
}
