import { useNavigate } from "react-router-dom";
import axios from "axios";
import { domainUrl, logOutHandler } from "../../utils/constants";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
  Cell,
} from "recharts";

export default function ExpectedPaymentsBar() {
  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");
  const [expectedGraphData, setExpectedGraphData] = useState({
    1: {},
    2: {},
    3: {},
    4: {},
    5: {},
    6: {},
    7: {},
    8: {},
    9: {},
    10: {},
    11: {},
    12: {},
  });

  const maxPaymentsTotal = Object.keys(expectedGraphData).reduce(
    (maxTotal, key) => {
      const paymentsTotal = expectedGraphData[key]?.payments_total || 0;
      return Math.max(maxTotal, paymentsTotal);
    },
    0
  );

  const getExpectedPaymentGraphData = async (month) => {
    try {
      const res = await axios.get(
        `${domainUrl}expected-payments?month=${month}&graph=true`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (res.data) {
        setExpectedGraphData((prevData) => ({
          ...prevData,
          [res.data?.month]: res.data,
        }));
      }
    } catch (error) {
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
    }
  };

  function formatUSD(number) {
    if (typeof number !== "number" || isNaN(number)) {
      if (typeof number == "string") {
        number = parseFloat(number);
      } else {
        return "Invalid number";
      }
    }

    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    return formatter.format(number);
  }

  const monthValue = new Date().getMonth() + 1;
  const data = Array.from({ length: 5 }, (_, index) => {
    const month = (monthValue + index) % 12 || 12;
    return {
      name: month,
      payment: expectedGraphData[month]?.expected_payments || 0,
    };
  });

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  data.forEach((item) => {
    item.name = monthNames[item.name - 1];
  });
  useEffect(() => {
    for (let month = 1; month <= 12; month++) {
      getExpectedPaymentGraphData(month.toString());
    }
  }, []);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="p-2 bg-white border border-black shadow-md flex flex-col gap-4 rounded-md">
          <p className="text-medium text-sm">{label}</p>
          <p className="text-lg font-semibold text-blue-600">
            Payment:
            <span className="ml-2">
              {formatUSD(payload[0].payload.payment)}
            </span>
          </p>
        </div>
      );
    }
  };

  const colors = ["#FFBF00", "#ee9a00", "#90EE90", "#ADD8E6", "#0054a8"];

  return (
    <>
      <div className="w-full">
        <div className="w-full h-[350px] p-2 flex flex-col justify-center items-center border border-gray-200 rounded-xl shadow-md">
          <div className="mb-3 text-base font-bold">EXPECTED PAYMENTS</div>
          <div className="w-full h-60 sm:w-[27rem] lg:w-[27rem]">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                width="100%"
                height="100%"
                data={data}
                onClick={(event, entry) => {
                  if (!event?.activePayload) {
                    return;
                  }
                  const monthRoute = {
                    January: "1",
                    February: "2",
                    March: "3",
                    April: "4",
                    May: "5",
                    June: "6",
                    July: "7",
                    August: "8",
                    September: "9",
                    October: "10",
                    November: "11",
                    December: "12",
                  };
                  const route = monthRoute[event?.activeLabel];
                  if (route) {
                    navigate("/expected-payments");
                  }
                }}
                margin={{
                  right: 38,
                  left: 38,
                }}
                padding={{
                  top: 20,
                  right: 20,
                  left: 20,
                  bottom: 20,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" interval={0} tick={{ fontSize: 10 }} />
                <YAxis
                  tickFormatter={formatUSD}
                  domain={[0, maxPaymentsTotal + maxPaymentsTotal * 0.1]}
                  tickCount={8}
                  tick={{ fontSize: 10 }}
                />
                <Tooltip
                  wrapperStyle={{ zIndex: 2 }}
                  content={<CustomTooltip />}
                />
                
                <Bar
                  className="hover:cursor-pointer"
                  dataKey="payment"
                  barSize={50}
                >
                  {data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={colors[index % colors.length]}
                    />
                  ))}
                  {/* <LabelList
                          dataKey="payment"
                          position={"top"}
                          angle={0}
                          textAnchor={"end"}
                          
                          fill="black"
                        /> */}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
          <div className="my-3 flex flex-col justify-evenly items-start  gap-2">
            <div className="flex  gap-3 justify-center items-start">
              <div className="text-sm inline-flex items-center gap-1">
                <span className="h-2 w-2 bg-[#FFBF00]"></span>
                {data ? data[0].name : ""}
              </div>
              <div className="text-sm inline-flex items-center gap-1">
                <span className="h-2 w-2 bg-[#ee9a00]"></span>
                {data ? data[1].name : ""}
              </div>
            </div>
            <div className="flex  gap-3 justify-center items-start">
              <div className="text-sm inline-flex items-center gap-1">
                <span className="h-2 w-2 bg-[#90EE90]"></span>
                {data ? data[2].name : ""}
              </div>
              <div className="text-sm inline-flex items-center gap-1">
                <span className="h-2 w-2 bg-[#ADD8E6]"></span>
                {data ? data[3].name : ""}
              </div>
            </div>
            <div className="flex  gap-3 justify-center items-start">
              <div className="text-sm inline-flex items-center gap-1">
                <span className="h-2 w-2 bg-[#0054a8]"></span>
                {data ? data[4].name : ""}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
